<template>
  <v-row
    no-gutters
    dense
  >
    <v-col
      cols="12"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card
          outlined
        >
          <v-card-text>
            <v-row
              dense
              no-gutters
            >
              <v-col
                cols="12"
              >
                <v-row
                  dense
                >
                  <v-col cols="12">
                    <h4>2FA</h4>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <img v-if="qrcode" :src="qrcode">
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Settings',
  created() {
    this.$api.get('partner/security/qr-2fa').then((response) => {
      this.qrcode = response.data.qr;
    });
  },
  data() {
    return {
      processing: false,
      qrcode: null,
      form: {
      },
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        nameserver: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value)
          || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
      },
      valid: false,
    };
  },
  methods: {
  },
};
</script>

<style scoped>
</style>

<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Partner System</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="padding-bottom-zero">
            <v-form>
              <v-row v-if="!otp">
                <v-col cols="12">
                  <v-text-field
                    autocomplete="username"
                    label="Email"
                    name="email"
                    prepend-icon="mdi-account"
                    type="text"
                    v-model="email"
                    :error="error.email"
                    :error-messages="errorMessage.email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="!otp">
                <v-col cols="12">
                  <v-text-field
                    autocomplete="current-password"
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="password"
                    :error="error.password"
                    :error-messages="errorMessage.password"
                  ></v-text-field>
                  <a class="float-right" @click="gotoForgotPassword()">Forgot Password?</a>
                </v-col>
              </v-row>
              <v-row align="center" v-if="otp">
                <v-col cols="12">
                  <h5>For your security, we've sent a one-time password to your email
                    address at {{ email }}</h5>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    id="otp"
                    label="OTP"
                    name="OTP"
                    prepend-icon="mdi-lock"
                    type="text"
                    v-model="emailOtp"
                    :error="error.emailOtp"
                    :error-messages="errorMessage.emailOtp"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <a @click="resendOtp()">Resend OTP</a>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div id="recaptcha" class="g-recaptcha"
                 data-sitekey="6Ldu9conAAAAAGixmLGmS0K1oJSZKIbf_Zi5BGvT"
                 data-callback="onRecaptchaSubmit"
                 data-size="invisible"></div>
            <v-btn
              class="sso-btn"
              v-if="!otp"
              color="primary"
              outlined
              :loading="processing"
              :disabled="processing"
              @click="loginOtp()"
            >
              <b>LOGIN</b>
            </v-btn>
            <v-btn
              class="sso-btn"
              v-else
              color="primary"
              outlined
              :loading="processing"
              :disabled="processing"
              @click="verifyOtp()"
            >
              <b>VERIFY OTP</b>
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div style="width: 224px;
            height: 12px;
            border-bottom: 1px solid black;
            text-align: center;
            margin-bottom: 8px;">
              <span style="
              font-weight: bold;
              font-size: 12px; background-color: white; padding: 0 32px;">
                OR
              </span>
            </div>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="sso-btn"
              color="primary"
              outlined
              :dark="false"
              @click="signInGoogle()"
            >
              <!-- eslint-disable-next-line -->
              <svg viewBox="0 0 20 20" class="googleLogo" style="width: 14px; height: 14px; display: block; fill: inherit; flex-shrink: 0; backface-visibility: hidden; margin-right: 6px;"><g><path d="M19.9996 10.2297C19.9996 9.54995 19.9434 8.8665 19.8234 8.19775H10.2002V12.0486H15.711C15.4823 13.2905 14.7475 14.3892 13.6716 15.0873V17.586H16.9593C18.89 15.8443 19.9996 13.2722 19.9996 10.2297Z" fill="#4285F4"></path><path d="M10.2002 20.0003C12.9518 20.0003 15.2723 19.1147 16.963 17.5862L13.6753 15.0875C12.7606 15.6975 11.5797 16.0429 10.2039 16.0429C7.54224 16.0429 5.28544 14.2828 4.4757 11.9165H1.08301V14.4923C2.81497 17.8691 6.34261 20.0003 10.2002 20.0003Z" fill="#34A853"></path><path d="M4.47227 11.9163C4.04491 10.6743 4.04491 9.32947 4.47227 8.0875V5.51172H1.08333C-0.363715 8.33737 -0.363715 11.6664 1.08333 14.4921L4.47227 11.9163Z" fill="#FBBC04"></path><path d="M10.2002 3.95756C11.6547 3.93552 13.0605 4.47198 14.1139 5.45674L17.0268 2.60169C15.1824 0.904099 12.7344 -0.0292099 10.2002 0.000185607C6.34261 0.000185607 2.81497 2.13136 1.08301 5.51185L4.47195 8.08764C5.27795 5.71762 7.53849 3.95756 10.2002 3.95756Z" fill="#EA4335"></path></g></svg>
              <b>Sign In With Google</b>
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="sso-btn"
              color="primary"
              outlined
              :dark="false"
              @click="signInApple()"
            >
              <!-- eslint-disable-next-line -->
              <svg viewBox="0 0 170 170" class="appleLogo" style="width: 16px; height: 16px; display: block; fill: inherit; flex-shrink: 0; backface-visibility: hidden; margin-right: 6px; margin-bottom: 2px;"><path d="m150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929 0.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002 0.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-0.9 2.61-1.85 5.11-2.86 7.51zm-31.26-123.01c0 8.1021-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375-0.119-0.972-0.188-1.995-0.188-3.07 0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.3113 11.45-8.597 4.62-2.2516 8.99-3.4968 13.1-3.71 0.12 1.0831 0.17 2.1663 0.17 3.2409z"></path></svg>
              <b>Sign In With Apple</b></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-footer dark
              padless
              :app="true"
              :fixed="true"
    >
      <v-card
        flat
        tile
        class="primary lighten-1 white--text text-center"
        width="100%"
      >
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Powered By GoCloudEasy Pte Ltd</strong><br>
          <a class="white--text" href="https://gocloudeasy.com/privacy-policy/">Privacy Policy</a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a class="white--text" href="https://gocloudeasy.com/terms-of-use/">Term of Use</a>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      emailOtp: '',
      needRecaptcha: false,
      recaptchaToken: '',
      otp: false,
      processing: false,
      error: {
        email: false,
        password: false,
        emailOtp: false,
      },
      errorMessage: {
        email: '',
        password: '',
        emailOtp: '',
      },
    };
  },
  methods: {
    login() {
      try {
        this.validate();
      } catch (e) {
        return;
      }

      this.processing = true;
      this.$api.post('login', {
        email: this.email,
        password: this.password,
      })
        .then((response) => {
          if (response.data.user) {
            this.$store.dispatch('reset');
            this.$store.dispatch('setCurrentUser', response.data.user);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({
              name: `${this.$store.state.user.role}.Home`,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    loginOtp() {
      try {
        this.validate();
      } catch (e) {
        return;
      }

      const loginData = {
        email: this.email,
        password: this.password,
      };

      if (this.recaptchaToken !== '') {
        loginData.recaptchaToken = this.recaptchaToken;
        this.recaptchaToken = '';
        // eslint-disable-next-line no-undef
        grecaptcha.reset();
      }

      this.processing = true;
      this.$api.post('login-otp', loginData)
        .then((response) => {
          if (response.data.needRecaptcha) {
            window.onRecaptchaSubmit = (token) => {
              this.recaptchaToken = token;
              this.loginOtp();
            };
            // eslint-disable-next-line no-undef
            grecaptcha.execute();
            this.needRecaptcha = true;
          }

          if (response.data.success) {
            this.otp = true;
          }

          if (response.data.user) {
            this.$store.dispatch('reset');
            this.$store.dispatch('setCurrentUser', response.data.user);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({
              name: `${this.$store.state.user.role}.Home`,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    verifyOtp() {
      try {
        this.validate();
      } catch (e) {
        return;
      }

      this.processing = true;
      this.$api.post('login-otp', {
        email: this.email,
        password: this.password,
        otp: this.emailOtp,
      })
        .then((response) => {
          if (response.data.user) {
            this.$store.dispatch('reset');
            this.$store.dispatch('setCurrentUser', response.data.user);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({
              name: `${this.$store.state.user.role}.Home`,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    validate() {
      this.error.email = false;
      this.error.password = false;
      this.errorMessage.email = '';
      this.errorMessage.password = '';

      if (this.$underscore.isEmpty(this.email)) {
        this.error.email = true;
        this.errorMessage.email = 'Email Address is required.';
        throw new Error('Validation error.');
      }

      if (this.$underscore.isEmpty(this.password)) {
        this.error.password = true;
        this.errorMessage.password = 'Password is required.';
        throw new Error('Validation error.');
      }
    },
    enterClickLogin(e) {
      if (e.code === 'Enter') {
        this.login();
      }
    },
    gotoForgotPassword() {
      this.$router.push({ name: 'ForgotPassword' })
        .catch(() => {
        });
    },
    signInGoogle() {
      this.$api.post('sso/auth-url', {
        type: 'google',
      }).then((response) => {
        if (response.data) {
          window.location.href = response.data;
        }
      });
    },
    signInApple() {
      this.$api.post('sso/auth-url', {
        type: 'apple',
      }).then((response) => {
        if (response.data) {
          window.location.href = response.data;
        }
      });
    },
    verifySignInGoogle() {
      this.$api.post('sso/login', {
        type: 'google',
        code: this.$route.query.code,
      }).then((response) => {
        if (response.data.user) {
          this.$store.dispatch('reset');
          this.$store.dispatch('setCurrentUser', response.data.user);
          this.$store.dispatch('showDashboard');
          localStorage.setItem('app', JSON.stringify(this.$store.state));
          if (this.$route.query.disable_password) {
            this.$router.push({
              name: `${this.$store.state.user.role}.Settings`,
              query: { disable_password: 1 },
            });
          } else {
            this.$router.push({ name: `${this.$store.state.user.role}.Home` });
          }
        }
      });
    },
    verifySignInApple() {
      this.$api.post('sso/login', {
        type: 'apple',
        code: this.$route.query.code,
      }).then((response) => {
        if (response.data.user) {
          this.$store.dispatch('reset');
          this.$store.dispatch('setCurrentUser', response.data.user);
          this.$store.dispatch('showDashboard');
          localStorage.setItem('app', JSON.stringify(this.$store.state));
          if (this.$route.query.disable_password) {
            this.$router.push({
              name: `${this.$store.state.user.role}.Settings`,
              query: { disable_password: 1 },
            });
          } else {
            this.$router.push({ name: `${this.$store.state.user.role}.Home` });
          }
        }
      });
    },
    resendOtp() {
      this.$api.post('resend-otp', {
        email: this.email,
        password: this.password,
      }).then((response) => {
        if (response.data.success) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: `New OTP has been sent to your email address ${this.email}`,
          });
        }
      });
    },
  },
  async mounted() {
    if (this.$route.query.sso) {
      this.$api.defaults.headers.common.Authorization = '';

      if (this.$route.query.sso === 'google') {
        this.verifySignInGoogle();
      }

      if (this.$route.query.sso === 'apple') {
        this.verifySignInApple();
      }
    } else {
      const currentSessionData = JSON.parse(localStorage.getItem('app'));
      if (currentSessionData && currentSessionData.user && currentSessionData.user.auth_token) {
        await this.$api.post('login', {
          token: window.btoa(currentSessionData.user.auth_token),
        })
          .then((response) => {
            if (response.data.user) {
              this.$store.dispatch('reset');
              this.$store.dispatch('setCurrentUser', response.data.user);
              this.$store.dispatch('showDashboard');
              localStorage.setItem('app', JSON.stringify(this.$store.state));
              this.$router.push({
                name: `${this.$store.state.user.role}.Home`,
              });
            }
          });
      }
      this.$api.defaults.headers.common.Authorization = '';
    }
  },
  created() {
    document.addEventListener('keyup', this.enterClickLogin);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.enterClickLogin);
  },
};
</script>

<style scoped>
  .v-application a {
    color: black;
    font-size: 0.8rem;
    text-decoration: none;
  }

  .sso-btn {
    min-width: 224px !important;
  }

  .padding-bottom-zero {
    padding-bottom: 0;
  }
</style>

<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader
        ref="skeleton"
        v-if="!showPage"
        type="card"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-row dense align="center" v-if="showPage">
        <v-col cols="12">
          <v-row>
            <v-col cols="3">
              <h3>{{ form.domain }}</h3>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                dense
                label="EPP Code"
                v-model="form.epp"
                :append-icon="showEpp ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="showEpp ? 'text' : 'password'"
                @click:append="showEpp = !showEpp"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <h5>Registrant Contact</h5>
              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    v-if="defaultContact"
                    x-small
                    color="success"
                    @click="copyContact('defaultContact', 'registrant')">
                    Use default contact</v-btn>
                </v-col>
              </v-row>
              <component
                v-bind:is="registrantComponent"
                ref="registrantProfileComp"
                :forceOrg="forceOrgAdmin"
                :setNewProfile="registrant"
              ></component>
            </v-col>
          </v-row>
          <v-col class="text-right">
            <v-btn
              text-align="right"
              color="primary"
              @click="transfer()"
              :disabled="transferDisabled && !transferProcessing"
              :loading="transferProcessing"
            >Transfer</v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Profile from '../../components/Profile.vue';
import ProfileSg from '../../components/ProfileSg.vue';

export default {
  name: 'Detail',
  components: {
    Profile,
    ProfileSg,
  },
  created() {
    this.id = this.$route.params.id;
    this.$api.get('partner/domain/default-contact').then((response) => {
      const { profile } = response.data;
      if (profile && !this.$underscore.isEmpty(profile.email)) {
        this.defaultContact = profile;
      }
    });
    this.reloadDomain();
  },
  data() {
    return {
      id: null,
      showPage: false,
      epp: null,
      form: {
        domain: '',
        product_id: null,
        product_term: null,
        status_name: '',
        tld: null,
        epp: null,
        registrant: {},
      },
      showEpp: false,
      defaultContact: null,
      registrantComponent: Profile,
      transferProcessing: false,
      transferDisabled: false,
      registrant: null,
      valid: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?/i).test(value) || 'This field must be valid domain name.',
        childNs: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(`${value}.${this.form.domain}`) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
        nameserver: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
        ip: (value) => (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/).test(value) || 'This field must be valid IP.',
      },
      forceOrgAdmin: false,
    };
  },
  methods: {
    reloadDomain() {
      this.$api.get(`partner/domain/${this.$route.params.id}`).then((response) => {
        const domainData = response.data;
        this.form.domain = domainData.service_identity;
        this.form.product_id = domainData.product_id;
        this.showPage = true;
        this.form.tld = domainData.domain_config.product_configuration.tld;
        if (this.form.tld === 'com.sg') {
          this.forceOrgAdmin = true;
        }
        if (this.form.tld === 'sg' || this.form.tld === 'com.sg') {
          this.registrantComponent = ProfileSg;
        }
      });
    },
    copyContact(from, to) {
      if (from === 'defaultContact') {
        this[to] = this.defaultContact;
        const sgTld = [
          'sg',
          'com.sg',
        ];
        if (sgTld.indexOf(this.form.tld) >= 0) {
          if (this.$underscore.isEmpty(this.defaultContact.corporate_no)) {
            this[to].special_field1 = 'p';
            this[to].special_field2 = this.defaultContact.personal_identity_no;
          } else {
            this[to].special_field1 = 'o';
            this[to].special_field2 = this.defaultContact.corporate_no;
          }
        }
      }
    },
    reset() {
      this.$router.go(0);
    },
    transfer() {
      this.transferProcessing = true;
      this.$api.post(`partner/domain/${this.$route.params.id}/transfer`, {
        epp: this.form.epp,
        registrant: this.$refs.registrantProfileComp.getProfileData(),
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Domain transfer is successfully initiated.',
        });
        this.reset();
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        }
      }).finally(() => {
        this.transferProcessing = false;
      });
    },
  },
  watch: {
    'form.domain': {
      handler(domain) {
        if (this.rules.domain(domain) !== true) {
          this.checkDomainInvalid = true;
        } else {
          this.checkDomainInvalid = false;
          this.domainErr = null;
        }
      },
    },
  },
};
</script>

<template>
  <v-row
    no-gutters
    dense
  >
    <v-col
      cols="6"
      offset="3"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card
          outlined
        >
          <v-card-text>
            <v-row
              dense
              no-gutters
            >
              <v-col
                cols="12"
              >
                <profile
                  ref="partnerProfile"
                ></profile>
                <v-row
                  dense
                  align="center"
                >
                  <v-col
                    cols="6"
                    md="6"
                  >
                    <v-text-field
                      dense
                      v-model="creditLimit"
                      :rules="[rules.isNumeric]"
                      label="Credit Limit"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    md="6"
                  >
                    <v-checkbox
                      v-model="welcomeEmail"
                      label="Send Welcome Email?"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      color="primary"
                      :loading="processing"
                      :disabled="processing"
                      @click="submitForm"
                    >Submit</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import Profile from '../../components/Profile.vue';

export default {
  name: 'Add',
  components: {
    Profile,
  },
  data() {
    return {
      creditLimit: 0,
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
      valid: false,
      welcomeEmail: false,
    };
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$api.post('admin/partner/add', this.$underscore.extend(this.$refs.partnerProfile.getProfileData(), {
          credit_limit: this.creditLimit,
          welcome_email: this.welcomeEmail,
        }))
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Partner is created. Welcome email will be sent to him shortly.',
            });
            this.$refs.form.reset();
          })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
  },
};
</script>

<style scoped>

</style>

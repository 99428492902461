<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col>
          <h5>Wrong Expiry</h5>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="3">
              Domain
            </v-col>
            <v-col cols="3">
              System Date
            </v-col>
            <v-col cols="3">
              Registrar Date
            </v-col>
            <v-col cols="3">
              Action
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-for="(domain, index) in wrongExpiryDomains" :key="index">
          <v-row>
            <v-col cols="3">
              {{ domain['service_identity'] }}
            </v-col>
            <v-col cols="3">
              {{ domain['system_date'] }}
            </v-col>
            <v-col cols="3">
              {{ domain['registrar_date'] }}
            </v-col>
            <v-col cols="3">
              <v-btn
                small
                color="primary"
                :loading="syncLoading"
                :disabled="syncLoading"
                @click="sync(domain)">Sync</v-btn>
              <v-btn
                small
                color="primary"
                :loading="syncLoading"
                :disabled="syncLoading"
                @click="transferAway(domain)">Set Transfer Away</v-btn>
              <v-btn
                small
                color="primary"
                :loading="fixExpiryLoading"
                :disabled="fixExpiryLoading"
                @click="fixExpiry(domain)">Fix Expiry</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col>
          <h5>Wrong Contacts</h5>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="3">
              Domain
            </v-col>
            <v-col cols="3">
              Registrant Email
            </v-col>
            <v-col cols="3">
              Admin Email
            </v-col>
            <v-col cols="3">
              Action
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-for="(domain, index) in wrongContactDomains" :key="index">
          <v-row>
            <v-col cols="3">
              {{ domain['service_identity'] }}
            </v-col>
            <v-col cols="3">
              System: {{ domain['system_registrant_email'] }}<br>
              Registrar: {{ domain['registrar_registrant_email'] }}
            </v-col>
            <v-col cols="3">
              System: {{ domain['system_admin_email'] }}<br>
              Registrar: {{ domain['registrar_admin_email'] }}
            </v-col>
            <v-col cols="3">
              <v-btn
                small
                color="primary"
                :loading="syncLoading"
                :disabled="syncLoading"
                @click="sync(domain)">Sync</v-btn>
              <v-btn
                small
                color="primary"
                :loading="fixContactsLoading"
                :disabled="fixContactsLoading"
                @click="fixContacts(domain)">Fix Contacts</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DomainAuditResellerclub',
  created() {
    this.reload();
  },
  data() {
    return {
      wrongExpiryDomains: [],
      wrongContactDomains: [],
      syncLoading: false,
      fixExpiryLoading: false,
      fixContactsLoading: false,
    };
  },
  methods: {
    reload() {
      this.$api.get('admin/domain-audit/resellerclub').then((response) => {
        this.$set(this, 'wrongExpiryDomains', response.data.wrong_expiry_domains);
        this.$set(this, 'wrongContactDomains', response.data.wrong_contact_domains);
      });
    },
    transferAway(domain) {
      this.syncLoading = true;
      this.$api.post(`admin/domain-audit/resellerclub/transfer-away/${domain.id}`, {
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Sync complete. Reloading page...',
        });
        this.reload();
      }).finally(() => {
        this.syncLoading = false;
      });
    },
    sync(domain) {
      this.fixExpiryLoading = true;
      this.$api.post(`admin/domain-audit/resellerclub/sync/${domain.id}`, {
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Sync complete. Reloading page...',
        });
        this.reload();
      }).finally(() => {
        this.fixExpiryLoading = false;
      });
    },
    fixExpiry(domain) {
      this.fixExpiryLoading = true;
      this.$api.post(`admin/domain-audit/resellerclub/fix-expiry/${domain.id}`, {
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Fixed. Reloading page...',
        });
        this.reload();
      }).finally(() => {
        this.fixExpiryLoading = false;
      });
    },
    fixContacts(domain) {
      this.fixContactsLoading = true;
      this.$api.post(`admin/domain-audit/resellerclub/fix-contacts/${domain.id}`, {
        registrant_match: domain.registrant_match,
        admin_match: domain.admin_match,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Fixed. Reloading page...',
        });
        this.reload();
      }).finally(() => {
        this.fixContactsLoading = false;
      });
    },
  },
};
</script>

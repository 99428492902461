<template>
  <v-dialog width="1024" v-model="display"
    @click:outside="closeDialog"
  >
  <v-card>
  <v-card-text>
    <h1 class="text-center" v-if="renewalTerms.length === 0">Loading renewal information ...</h1>
    <v-row v-else>
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Current Expiry"
              :readonly="true"
              v-model="service.expiryDate"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select label="Renewal Terms"
                      :items="renewalTerms"
                      v-model="renewalTerm"
                      @change="change()"
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense v-if="!service.addons.length && service.addons.length !== 0">
          <h4>Addons:</h4>
        </v-row>
        <v-row dense v-for="(groupAddons, groupId) in service.addons" :key="groupId">
          <v-col cols="12">
            <v-row dense v-for="addon in groupAddons" :key="addon.id">
              <v-col cols="6">
                <v-select
                  :label="addon.service_name"
                  v-model="renewalTerm"
                  :items="addon.product_prices"
                  :readonly="true"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card-text>
  <v-divider></v-divider>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
      color="error"
      text
      @click="closeDialog()"
    >
      Close
    </v-btn>
    <v-btn
      color="primary"
      text
      :loading="renewProcessing"
      :disabled="total < 0.01"
      @click="renew()"
    >
      Renew<span class="green--text">${{ total }}</span>
    </v-btn>

  </v-card-actions>
  </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ServiceRenewDialog',
  data() {
    return {
      display: true,
      renewProcessing: false,
      renewalTerms: [],
      service: null,
      renewalTerm: null,
      total: 0,
    };
  },
  props: ['serviceId'],
  created() {
    this.loadServiceInfo();
  },
  watch: {
    serviceId: {
      handler(newServiceId) {
        if (newServiceId) {
          this.serviceId = newServiceId;
          this.loadServiceInfo();
        }
      },
      deep: true,
    },
  },
  methods: {
    change() {
      this.total = 0;
      this.renewalTerms.forEach((term) => {
        if (term.value === this.renewalTerm) {
          if (term.product_price) {
            this.total += +term.product_price;
          }
        }
      });
      this.$underscore.each(this.service.addons, (groupAddons) => {
        groupAddons.forEach((addon) => {
          addon.product_prices.forEach((addonPrice) => {
            if (addonPrice.value === this.renewalTerm) {
              this.total += +addonPrice.product_price;
            }
          });
        });
      });
    },
    loadServiceInfo() {
      this.renewalTerms.splice(0, this.renewalTerms.length);
      this.$api.post('partner/service/get-renewal-terms', {
        id: this.serviceId,
      }).then((response) => {
        let defaultTermExist = false;
        let firstTerm = false;
        this.$set(this, 'service', response.data.service);
        this.renewalTerm = response.data.service.serviceMonthTerm;
        this.renewalTerms = response.data.terms;
        this.renewalTerms.forEach((term, termIndex) => {
          if (firstTerm === false) {
            firstTerm = term;
          }
          if (term.value === this.renewalTerm) {
            defaultTermExist = true;
            this.total += +term.product_price;
          }
          this.renewalTerms[termIndex].text += ` (${term.product_price_monthly}/month)`;
        });

        if (!defaultTermExist) {
          this.renewalTerm = firstTerm.value;
          this.total += +firstTerm.product_price;
        }

        this.$underscore.each(this.service.addons, (groupAddons) => {
          groupAddons.forEach((addon) => {
            addon.product_prices.forEach((addonPrice) => {
              if (addonPrice.value === this.renewalTerm) {
                this.total += +addonPrice.product_price;
              }
            });
          });
        });
      }).finally(() => {
      });
    },
    closeDialog() {
      this.$emit('close-dialog');
    },
    renew() {
      this.renewProcessing = true;
      this.$api.post('partner/service/renew', {
        id: this.serviceId,
        product_term: this.renewalTerm,
      }).then((data) => {
        if (this.$store.state.user.role === 'Partner') {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Your renewal request is submitted successfully.',
          });
        } else {
          this.$store.dispatch('syncShoppingCart', data.data);
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Your renewal request is successfully added to the shopping cart.',
          });
        }
        this.renewProcessing = false;
        this.closeDialog();
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.renewProcessing = false;
      });
    },
  },
};
</script>
<style scoped>
</style>

<template>
  <v-container
    class="base"
  >
    <router-view @reloadmenu="reloadMenu"></router-view>
  </v-container>
</template>

<script>
export default {
  name: 'Base',
  metaInfo: {
    title: 'GCE Partner Portal',
  },
  methods: {
    reloadMenu() {
      this.$emit('reloadmenu', { reload: true });
    },
  },
};
</script>

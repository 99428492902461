import Quill from 'quill/core';

import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';

import Blockquote from 'quill/formats/blockquote';
import Bold from 'quill/formats/bold';
import Code from 'quill/formats/code';
import Header from 'quill/formats/header';
import Italic from 'quill/formats/italic';
import Image from 'quill/formats/image';
import Script from 'quill/formats/script';
import Strike from 'quill/formats/strike';
import Link from 'quill/formats/link';
import List, { ListItem } from 'quill/formats/list';
import Underline from 'quill/formats/underline';
import Video from 'quill/formats/video';
import { IndentClass } from 'quill/formats/indent';
import { BackgroundStyle } from 'quill/formats/background';
import { AlignClass } from 'quill/formats/align';
import { ColorStyle } from 'quill/formats/color';

Quill.register({
  'modules/toolbar': Toolbar,
  'themes/snow': Snow,
  'formats/bold': Bold,
  'formats/italic': Italic,
  'formats/header': Header,
  'formats/blockquote': Blockquote,
  'formats/code': Code,
  'formats/image': Image,
  'formats/link': Link,
  'formats/list': List,
  'formats/list-item': ListItem,
  'formats/script': Script,
  'formats/strike': Strike,
  'formats/underline': Underline,
  'formats/video': Video,
  'formats/indent': IndentClass,
  'formats/background': BackgroundStyle,
  'formats/align': AlignClass,
  'formats/color': ColorStyle,
});

export default Quill;

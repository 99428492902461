<template>
  <v-col cols="12" style="margin-bottom: 20px;">
    <v-card
      outlined
    >
      <v-card-title>
        Enrolment of Default Saved Card
      </v-card-title>
      <v-card-text>
        <v-row no-gutters dense class="saved-card" v-if="savedCard" style="margin-bottom: 20px">
          <v-col cols="2">
            <h2>Saved Card</h2>
          </v-col>
          <v-col cols="4">
            <h2>{{ savedCard.vendor }} ******{{ savedCard.last_four_digits }}</h2>
          </v-col>
          <v-col cols="4">
            <h2>{{ savedCard.expiry_month }}/{{ savedCard.expiry_year}}</h2>
          </v-col>
        </v-row>
        <v-row no-gutters dense>
          <v-col cols="12" md="6">
            <paypal-payment
              :amount="amount"
              :orderid="paypalOrderId"
              @interface="getChildInterface"
              :saveasdefaultcard="true"
              :card.sync="creditCard"
              :invalid.sync="creditCardInvalid"
            ></paypal-payment>
          </v-col>
        </v-row>
        <v-row no-gutters dense>
          <v-col cols="12" class="text-right">
            <v-tooltip bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  :loading="loading"
                  v-bind="attrs"
                  v-on="on"
                  @click="saveCard">
                  Save Card To New Payment Vendor
                </v-btn>
              </template>
              <span>$5 will be authenticate for validation, there is no actual charge.</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { DateTime } from 'luxon';
import {
  PaypalPayment,
} from '../../components/payment_modules';

export default {
  name: 'SavePaypalCard',
  components: {
    PaypalPayment,
  },
  data() {
    return {
      bank: '',
      referenceNo: '',
      transferDatetime: null,
      pickerTransferDatetime: null,
      paymentMethod: 4,
      paymentMethods: [],
      paypalOrderId: '',
      amount: '',
      loading: false,
      saveAsDefaultCard: false,
      creditCardInvalid: null,
      creditCard: null,
      savedCard: null,
      hasSavedCard: false,
      stripePaymentMode: 'useNewCard',
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
    };
  },
  created() {
    this.transferDatetime = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');
    this.pickerTransferDatetime = DateTime.local().toISO();
    this.$api.get('partner/payment/get-saved-cards?payment_type_id=4&paypal_temp=1').then((response) => {
      if (response.data !== '') {
        this.savedCard = response.data;
      }
    });
  },
  methods: {
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    saveCard() {
      this.loading = true;
      this.$api.post('partner/payment/save-card', {
        payment_type_id: this.paymentMethod,
        extra_field5: 'paypal_temp',
      }).then((response) => {
        this.$set(this, 'paypalOrderId', response.data.order_id);
        this.$nextTick(() => {
          this.$options.childInterface.checkout()
            .then((payload) => {
              if (payload.liabilityShift !== 'NO'
                && payload.authenticationStatus
                && payload.authenticationStatus !== 'YES') {
                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'error',
                  message: '3D Secure Authentication fails. Please retry again.',
                });
                this.loading = false;
                return;
              }

              if (payload.liabilityShift === 'NO'
                && payload.liabilityShifted === false
                && payload.authenticationStatus
                && payload.authenticationStatus === 'NO') {
                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'error',
                  message: 'You have exited 3D Secure Authentication. Please retry again.',
                });
                this.loading = false;
                return;
              }

              this.$api.post('partner/payment/save-card', {
                payment_type_id: this.paymentMethod,
                confirm: 1,
                id: payload.orderId,
                extra_field5: 'paypal_temp',
              })
                .then(() => {
                  this.$emit('reloadcomp');
                  this.$store.dispatch('addAlert', {
                    id: Date.now(),
                    type: 'success',
                    message: 'Your card is saved successfully.',
                  });
                  this.reset();
                })
                .catch((error) => {
                  if (error.response) {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: error.response.data.message,
                    });
                  } else {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: error.message,
                    });
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            })
            .catch((error) => {
              if (error.message.indexOf('semantically incorrect') > 0) {
                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'error',
                  message: 'We are sorry, the transaction failed. Please check the card information or try with another card.',
                });
              } else {
                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'error',
                  message: `An error occurred ${error.message}. Please retry again.`,
                });
              }
              this.loading = false;
            });
        });
      });
    },
    reset() {
      this.$router.push({
        path: '/refresh',
        query: {
          path: this.$route.fullPath,
        },
      });
    },
    openDatetimePicker(event) {
      this.$refs.datetimePicker.open(event);
    },
  },
  watch: {
    pickerTransferDatetime: {
      handler(newPickerTransferDatetime) {
        if (newPickerTransferDatetime !== null) {
          this.transferDatetime = DateTime.fromISO(newPickerTransferDatetime).toFormat('yyyy-MM-dd HH:mm:ss');
        }
      },
      deep: true,
    },
    hasSavedCard: {
      handler(hasSavedCard) {
        if (hasSavedCard) {
          this.stripePaymentMode = 'useSavedCard';
        } else {
          this.stripePaymentMode = 'useNewCard';
        }
      },
    },
  },
};
</script>
<style>
.datetime-picker input {
  visibility: hidden !important;
}
</style>

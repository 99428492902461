<template>
  <v-row no-gutters dense>
    <v-col cols="10" offset="1" md="12" offset-md="0">
      <v-form ref="form" v-model="valid">
        <v-card outlined>
          <v-card-text>
            <v-row dense no-gutters>
              <v-col cols="12">
                <h3>Basic Information</h3>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-row dense>
                  <v-col cols="6" >
                    <v-text-field dense
                                  label="Product Name"
                                  v-model="form.product_name"
                                  :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" >
                    <v-text-field dense
                                  label="Display Order"
                                  v-model="form.display_order"
                                  :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12" >
                    <v-textarea
                      outlined
                      label="Product Description"
                      v-model="form.product_description"
                      :rules="[rules.required]"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" >
                    <v-textarea
                      outlined
                      label="Product Description2"
                      v-model="form.product_description2"
                      :rules="[]"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12" >
                    <product-type-dropdown
                      :defaultProductTypeId="form.default_product_type_id"
                      :productTypeId.sync="form.product_type_id"></product-type-dropdown>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6">
                <product-select label="Downgrades"
                                :defaultProducts="form.default_downgrades"
                                :products.sync="form.downgrades"></product-select>
              </v-col>
              <v-col cols="6">
                <product-select label="Upgrades"
                                :defaultProducts="form.default_upgrades"
                                :products.sync="form.upgrades"></product-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <h3>PRICE</h3>
              </v-col>
              <v-col cols="3">
                <v-switch v-model="form.renewable" label="Renewable" disabled></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch v-model="form.orderable" label="Orderable"></v-switch>
              </v-col>
            </v-row>
            <v-row dense v-for="currency in form.currencies" v-bind:key="currency.symbol">
              <v-col cols="12">
                <h4>{{ currency.symbol }}
                  <v-btn
                    color="primary"
                    v-if="form.renewable"
                    @click="calculatePriceBasedOnMonthly()"
                  >Fill all price based on monthly price</v-btn></h4>
              </v-col>
              <v-col cols="3" v-if="!form.renewable">
                <v-text-field
                  dense
                  :label="currency.onetime.label"
                  v-model="currency.onetime.value"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="form.renewable">
                <v-row>
                  <v-col cols="3" v-for="term in currency.renewal_terms" v-bind:key="term.index">
                    <v-text-field
                      dense
                      :label="term.label"
                      v-model="term.value"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense
                   v-for="(currencyPromo, index) in form.promotion_currencies"
                   v-bind:key="currencyPromo.symbol + index">
              <v-col cols="12">
                <h4>Promotion Price: {{ currencyPromo.symbol }}
                  <v-btn
                    color="primary"
                    v-if="form.renewable"
                    @click="calculatePromoPriceBasedOnMonthly()"
                  >Fill all price based on monthly price</v-btn></h4>
              </v-col>
              <v-col cols="3" v-if="!form.renewable">
                <v-text-field
                  dense
                  :label="currencyPromo.onetime.label"
                  v-model="currencyPromo.onetime.value"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="form.renewable">
                <v-row>
                  <v-col cols="3"
                         v-for="term in currencyPromo.renewal_terms" v-bind:key="term.index">
                    <v-text-field
                      dense
                      :label="term.label"
                      v-model="term.value"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <h3>MODULE</h3>
              </v-col>
              <v-col cols="3">
                <ProductModuleSelect
                  :disabled="true"
                  :rules="[rules.required]"
                  :module.sync="form.module">
                  ></ProductModuleSelect>
              </v-col>
              <v-col cols="12">
                <v-card outlined>
                  <v-card-text>
                    <keep-alive>
                      <component
                        :is="moduleComponent"
                        :configData.sync="form.module_data"
                        :defaultConfigData="form.default_module_data"
                      ></component>
                    </keep-alive>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="primary"
                  :loading="processing"
                  :disabled="processing"
                  @click="submitForm"
                >Submit</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import ProductModuleSelect from '../../components/ProductModuleSelect.vue';
import ProductSelect from '../../components/ProductSelect.vue';
import Module from '../../components/provisioning_modules';
import ProductTypeDropdown from '../../components/ProductTypeDropdown.vue';

export default {
  name: 'Detail',
  components: {
    ProductModuleSelect,
    ProductSelect,
    Module,
    ProductTypeDropdown,
  },
  mounted() {
    this.moduleComponent = this.moduleMap[this.form.module];

    this.$api.get(`admin/product/${this.$route.params.id}`).then((response) => {
      this.form.product_id = response.data.product_id;
      this.form.product_name = response.data.product_name;
      this.form.display_order = response.data.display_order;
      this.form.product_description = response.data.product_description;
      this.form.product_description2 = response.data.product_description2;
      this.form.default_product_type_id = response.data.product_type_id;
      this.form.product_type_id = response.data.product_type_id;
      this.form.renewable = response.data.renewable;
      this.form.orderable = response.data.orderable;
      this.$set(this.form, 'module', {
        module_name: response.data.product_module_name,
        id: response.data.product_module_id,
      });
      this.$set(this.form, 'product_module_name', response.data.product_module_name);
      this.$set(this.form, 'default_upgrades', response.data.upgrades);
      this.$set(this.form, 'upgrades', response.data.upgrades);
      this.$set(this.form, 'default_downgrades', response.data.downgrades);
      this.$set(this.form, 'downgrades', response.data.downgrades);

      this.$set(this.form, 'default_module_data', {});

      if (response.data.product_module_name === 'cpanel') {
        this.$set(this.form.default_module_data, 'id', response.data.module_data.id);
        this.$set(this.form.default_module_data, 'package_name', response.data.module_data.package_name);
        this.$set(this.form.default_module_data, 'server_group_ids', response.data.module_data.server_group_ids);
        this.$set(this.form.module_data, 'server_group_ids', response.data.module_data.server_group_ids);
      }

      if (response.data.product_module_name === 'manual') {
        this.$set(this.form, 'module_data', response.data.module_data);
        this.$set(this.form.module_data, 'readonly', true);
      }

      if (typeof response.data.currencies[1] !== 'undefined') {
        this.$set(this.form.currencies[0].onetime, 'value', response.data.currencies[1].productPrice);
      }

      for (let i = 2; i < 17; i += 1) {
        if (typeof response.data.currencies[i] !== 'undefined') {
          this.$set(this.form.currencies[0].renewal_terms[i - 2], 'value', response.data.currencies[i].productPrice);
        }
      }

      if (typeof response.data.promotion_currencies[1] !== 'undefined') {
        this.$set(this.form.promotion_currencies[0].onetime, 'value', response.data.promotion_currencies[1].productPrice);
      }

      for (let i = 2; i < 17; i += 1) {
        if (typeof response.data.promotion_currencies[i] !== 'undefined') {
          this.$set(this.form.promotion_currencies[0].renewal_terms[i - 2], 'value', response.data.promotion_currencies[i].productPrice);
        }
      }
    });
  },
  data() {
    return {
      form: {
        product_id: '',
        product_name: '',
        display_order: 1,
        product_description: '',
        product_type_id: '',
        product_module_name: '',
        default_product_type_id: '',
        renewable: true,
        orderable: true,
        module: {},
        module_data: {},
        default_module_data: {
          id: null,
          package_name: '',
          server_group_ids: [],
        },
        currencies: [
          {
            symbol: window.systemCurrency,
            currency_id: window.systemCurrencyId,
            onetime: {
              id: 1,
              label: 'One Time',
              value: null,
            },
            renewal_terms: [
              {
                id: 2,
                label: 'Monthly',
                value: null,
                month: 1,
              },
              {
                id: 3,
                label: 'Quarterly',
                value: null,
                month: 3,
              },
              {
                id: 4,
                label: 'Biannually',
                value: null,
                month: 6,
              },
              {
                id: 5,
                label: 'Annually',
                value: null,
                month: 12,
              },
              {
                id: 6,
                label: 'Biennial (2 years)',
                value: null,
                month: 24,
              },
              {
                id: 7,
                label: 'Triennial (3 years)',
                value: null,
                month: 36,
              },
              {
                id: 8,
                label: 'Quadrennial (4 years)',
                value: null,
                month: 48,
              },
              {
                id: 9,
                label: 'Quinquennial (5 years)',
                value: null,
                month: 60,
              },
              {
                id: 10,
                label: 'Sexennial (6 years)',
                value: null,
                month: 72,
              },
              {
                id: 11,
                label: 'Septennial (7 Years)',
                value: null,
                month: 84,
              },
              {
                id: 12,
                label: 'Octennial (8 Years)',
                value: null,
                month: 96,
              },
              {
                id: 13,
                label: 'Novennial (9 Years)',
                value: null,
                month: 108,
              },
              {
                id: 14,
                label: 'Decennial (10 Years)',
                value: null,
                month: 120,
              },
              {
                id: 15,
                label: '10 Months',
                value: null,
                month: 10,
              },
              {
                id: 16,
                label: '11 Months',
                value: null,
                month: 11,
              },
            ],
          },
        ],
        promotion_currencies: [
          {
            symbol: window.systemCurrency,
            currency_id: window.systemCurrencyId,
            onetime: {
              id: 1,
              label: 'One Time',
              value: null,
            },
            renewal_terms: [
              {
                id: 2,
                label: 'Monthly',
                value: null,
                month: 1,
              },
              {
                id: 3,
                label: 'Quarterly',
                value: null,
                month: 3,
              },
              {
                id: 4,
                label: 'Biannually',
                value: null,
                month: 6,
              },
              {
                id: 5,
                label: 'Annually',
                value: null,
                month: 12,
              },
              {
                id: 6,
                label: 'Biennial (2 years)',
                value: null,
                month: 24,
              },
              {
                id: 7,
                label: 'Triennial (3 years)',
                value: null,
                month: 36,
              },
              {
                id: 8,
                label: 'Quadrennial (4 years)',
                value: null,
                month: 48,
              },
              {
                id: 9,
                label: 'Quinquennial (5 years)',
                value: null,
                month: 60,
              },
              {
                id: 10,
                label: 'Sexennial (6 years)',
                value: null,
                month: 72,
              },
              {
                id: 11,
                label: 'Septennial (7 Years)',
                value: null,
                month: 84,
              },
              {
                id: 12,
                label: 'Octennial (8 Years)',
                value: null,
                month: 96,
              },
              {
                id: 13,
                label: 'Novennial (9 Years)',
                value: null,
                month: 108,
              },
              {
                id: 14,
                label: 'Decennial (10 Years)',
                value: null,
                month: 120,
              },
              {
                id: 15,
                label: '10 Months',
                value: null,
                month: 10,
              },
              {
                id: 16,
                label: '11 Months',
                value: null,
                month: 11,
              },
            ],
          },
        ],
        upgrades: [],
        downgrades: [],
        default_upgrades: [],
        default_downgrades: [],
      },
      moduleMap: {
        cpanel: Module.CpanelConfig,
        manual: Module.ManualConfig,
      },
      moduleComponent: null,
      showPassword: false,
      successMessage: 'Server group is successfully created.',
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
      valid: false,
    };
  },
  methods: {
    submitForm() {
      let priceSet = false;

      if (this.form.renewable === false) {
        this.form.currencies.forEach((onetimeTerm) => {
          if (!this.$underscore.isEmpty(onetimeTerm.onetime.value)) {
            priceSet = true;
          }
        });
      } else {
        this.form.currencies.forEach((value) => {
          const vm = this;
          value.renewal_terms.forEach((renewalTerm) => {
            if (!vm.$underscore.isEmpty(renewalTerm.value)) {
              priceSet = true;
            }
          });
        });
      }

      if (priceSet === false) {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'error',
          message: 'You must set at least 1 price term for the product.',
        });
      }

      if (this.$refs.form.validate()) {
        this.$api.put(`admin/product/${this.$route.params.id}`, this.form)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: this.successMessage,
            });
          })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
    calculatePriceBasedOnMonthly() {
      if (this.$underscore.isFinite(this.form.currencies[0].renewal_terms[0].value)) {
        this.form.currencies[0].renewal_terms.forEach((value, index) => {
          if (index > 0) {
            this.form
              .currencies[0]
              .renewal_terms[index]
              .value = this.form.currencies[0].renewal_terms[0].value * value.month;
          }
        });
      }
    },
    calculatePromotionPriceBasedOnMonthly() {
      if (this.$underscore.isFinite(this.form.promotion_currencies[0].renewal_terms[0].value)) {
        this.form.promotion_currencies[0].renewal_terms.forEach((value, index) => {
          if (index > 0) {
            this.form
              .promotion_currencies[0]
              .renewal_terms[index]
              .value = this.form.promotion_currencies[0].renewal_terms[0].value * value.month;
          }
        });
      }
    },
  },
  watch: {
    form: {
      handler(form) {
        this.moduleComponent = this.moduleMap[form.product_module_name];
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

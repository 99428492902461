<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="4">
          <v-autocomplete
            v-model="service"
            :loading="searching"
            :items="services"
            :search-input.sync="search"
            cache-items
            flat
            hide-no-data
            hide-details
            :clearable="true"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="2" v-if="typeof search === 'undefined' ||
        search === null ||
        search.indexOf('Domain TLD') === -1">
          <v-text-field label="New Service Start Date"
                        v-model="startDate"
                        @click.prevent="openDatetimePicker($event)"
                        :readonly="true"
          ></v-text-field>
          <datetime
            class="datetime-picker"
            ref="datetimePicker"
            type="date"
            v-model="pickerStartDate"
            format="yyyy-LL-dd"
            auto
          ></datetime>
        </v-col>
        <v-col cols="2" v-else>
          <v-select
            label="New Domain Start Year"
            :multiple="false"
            :chips="false"
            v-model="startYear"
            :items="years"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          v-if="hasFee"
        >
          {{ hasFee }}
        </v-col>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            color="primary"
            :loading="processing"
            :disabled="processing"
            @click="submitForm"
          >Cancel</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'CancelFirstcomService',
  components: {
  },
  data() {
    return {
      services: [],
      servicesObj: {},
      service: null,
      startDate: '',
      startYear: '',
      years: [
        2023,
        2024,
        2025,
        2026,
      ],
      pickerStartDate: null,
      processing: false,
      search: null,
      searching: false,
      hasFee: false,
    };
  },
  created() {
  },
  methods: {
    submitForm() {
      if ((this.service && this.pickerStartDate)
        || (this.service && this.startYear)) {
        this.$api.post('admin/service/process-firstcom-service-cancellation', {
          service: this.service,
          date: this.startDate,
          year: this.startYear,
        }).then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Service is cancelled successfully.',
          });
        }).finally(() => {
        });
      }
    },
    openDatetimePicker(event) {
      this.$refs.datetimePicker.open(event);
    },
    calculateFee() {
      this.hasFee = false;

      if ((this.service && this.pickerStartDate)
        || (this.service && this.startYear)) {
        this.$api.post('admin/service/calculate-firstcom-cancel-fee', {
          service: this.service,
          date: this.startDate,
          year: this.startYear,
        }).then((response) => {
          this.hasFee = response.data;
        }).finally(() => {
        });
      }
    },
    searchService(text) {
      this.searching = true;
      const localServiceObj = {};
      const emptyObj = {};
      this.$api.post('admin/service/search', {
        user: 4,
        search: text,
      }).then((response) => {
        this.services = response.data;
        if (this.services.length > 0) {
          this.services.forEach((service) => {
            localServiceObj[service.value] = service.text;
          });
          this.servicesObj = { ...emptyObj, ...localServiceObj };
        }
      }).finally(() => {
        this.searching = false;
      });
    },
  },
  watch: {
    service: {
      handler(newServiceId) {
        if (newServiceId !== null) {
          this.calculateFee();
        }
      },
      deep: true,
    },
    startYear: {
      handler(newStartYear) {
        if (newStartYear !== null) {
          this.calculateFee();
        }
      },
      deep: true,
    },
    pickerStartDate: {
      handler(newDate) {
        if (newDate !== null) {
          this.startDate = DateTime.fromISO(newDate).toFormat('yyyy-MM-dd');
          this.calculateFee();
        }
      },
      deep: true,
    },
    search(val) {
      if (val && val !== this.select) {
        this.searchService(val);
      }
    },
  },
};
</script>

<style scoped>
</style>

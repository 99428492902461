<template>
  <v-row
    no-gutters
    :key="componentKey"
  >
    <v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
            'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.cancelled="{ item }">
          {{ item.cancelled ? 'Yes' : 'No' }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="item.completed_at === null"
            small
            class="mr-2"
            @click="edit(item)"
          >
            mdi-pencil
          </v-icon>
          <!--v-icon
            v-if="item.completed_at === null"
            small
            @click="openCancelDialog(item)"
          >
            mdi-cancel
          </v-icon-->
        </template>
      </v-data-table>
    </v-col>
    <v-dialog
      v-model="cancelDialog"
      max-width="320"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          Are you sure to cancel this provision?
          <v-row no-gutters>
            <v-col cols="12">
              <v-textarea
                label="Cancel Reason"
                v-model="cancelReason"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="cancelDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :loading="cancelLoading"
            :disabled="cancelLoading"
            @click="cancel()"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'List',
  data() {
    return {
      componentKey: 0,
      totalItems: 0,
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'job_id' },
        { text: 'Partner Name', value: 'name' },
        { text: 'Product Name', value: 'service_name' },
        { text: 'Service Identity', value: 'service_identity' },
        { text: 'Service Status', value: 'status_name' },
        { text: 'Months', value: 'service_month_term' },
        { text: 'Requested At', value: 'reserved_at' },
        { text: 'Completed At', value: 'completed_at' },
        { text: 'Cancelled?', value: 'cancelled' },
        { text: 'Cancel Reason', value: 'cancel_reason' },
        { text: 'Actions', value: 'actions' },
      ],
      cancelDialog: false,
      cancelLoading: false,
      cancelItem: null,
      cancelReason: '',
    };
  },
  watch: {
    options: {
      handler() {
        this.loadTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/service-manual-provision-queue/list', {
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    edit(item) {
      this.$router.push({
        name: 'Admin.ServiceManualProvisionQueueDetail',
        params: {
          id: item.job_id,
        },
      });
    },
    openCancelDialog(item) {
      this.cancelItem = item;
      this.cancelDialog = true;
    },
    cancel() {
      this.cancelLoading = true;
      this.$api.post(`admin/service-manual-provision-queue/${this.cancelItem.job_id}/cancel`, {
        cancel_reason: this.cancelReason,
      })
        .then(() => {
          this.componentKey += 1;
        }).finally(() => {
          this.cancelDialog = false;
          this.cancelLoading = false;
        });
    },
  },
};
</script>

<style scoped>

</style>

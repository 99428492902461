<template>
  <v-dialog width="1024" v-model="display"
            @click:outside="closeDialog"
  >
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <h3>
                  You are requesting for service cancellation.
                  On expiry, your services will be terminated,
                  no further reminder will be sent to you.</h3>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="closeDialog()"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="cancelLoading"
          @click="cancel()"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ServiceCancelDialog',
  data() {
    return {
      display: true,
      cancelLoading: false,
    };
  },
  props: ['serviceId'],
  created() {
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    cancel() {
      this.cancelLoading = true;
      this.$api.delete(`partner/service/${this.serviceId}`)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'You have successfully cancelled this service.',
          });
          this.cancelLoading = false;
          this.closeDialog();
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.cancelLoading = false;
        });
    },
  },
};
</script>
<style scoped>
</style>

<template>
  <v-row no-gutters dense class="cldy-footer">
    <v-col col="12">
      <v-row no-gutters dense>
        <a :href="manageUrl">
          <img :src="`${publicPath}cldylogofooter.svg`" class="footer-logo" alt="">
        </a>
      </v-row>
      <v-row no-gutters dense class="footer-copyright">
        <v-col>
          <p v-if="currency === 'sgd'">All prices in Singapore Dollars (SGD)</p>
          <p v-if="currency === 'usd'">All prices in US Dollars (USD)</p>
          <p v-if="currency === 'aud'">All prices in Australia Dollars (AUD)</p>
          <p>Copyright © {{ curYear }} CLDY.com Pte Ltd. All rights reserved.</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CldyFooter',
  components: {},
  created() {
  },
  data() {
    return {
      currency: window.systemCurrency,
      publicPath: process.env.BASE_URL,
      curYear: (new Date()).getFullYear(),
    };
  },
  computed: {
    manageUrl() {
      let manageUrl;

      if (this.currency === 'sgd') {
        manageUrl = 'https://manage.cldy.com/';
      }

      if (this.currency === 'aud') {
        manageUrl = 'https://manage.cldy.com/aud/';
      }

      if (this.currency === 'usd') {
        manageUrl = 'https://manage.cldy.com/usd/';
      }

      return manageUrl;
    },
  },
};
</script>

<style>
  .cldy-footer > .col {
    max-width: 1350px;
    margin: 0 auto;
  }

  .cldy-footer {
    background-color: #000C19;
    padding: 64px 32px;
  }

  .footer-logo {
    width: 210px;
  }

  .footer-nav {
    margin-top: 20px;
  }

  .footer-nav .col > p{
    color: white;
    font-size: 16px;
    font-weight: 600;
  }

  .footer-nav ul {
    list-style-type: none;
    padding: 0;
  }

  .footer-nav .col a {
    color: rgb(103, 121, 136);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    transition: .3s;
  }

  .footer-nav a:hover {
    color: white;
  }

  .footer-copyright {
    margin-top: 64px;
    border-top: 1px solid white;
    padding-top: 32px;
  }

  .footer-copyright p {
    color: rgb(103, 121, 136);
    font-size: 14px;
    font-weight: 600;
  }
</style>

<template>
  <v-row>
    <v-col cols="12">
        <h5 v-if="data.reseller_balance">Reseller Balance: {{ data.reseller_balance }}</h5>
    </v-col>
    <v-col cols="12">
      <h5>Monthly Report</h5>
      <table border="1">
        <thead>
          <tr>
            <th>Month</th>
            <th>Total</th>
            <th>Reseller</th>
            <th>Cldy</th>
            <th>Other Retail</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(revenue, month) in data.monthly" :key="month">
            <td>{{ month }}</td>
            <td>{{ revenue.total }}</td>
            <td>{{ revenue.reseller }}</td>
            <td>{{ revenue.cldy }}</td>
            <td>{{ revenue.no_cldy }}</td>
          </tr>
        </tbody>
      </table>
    </v-col>
    <v-col cols="12">
      <h5>Daily Report</h5>
      <table border="1">
        <thead>
        <tr>
          <th>Date</th>
          <th>Total</th>
          <th>Reseller</th>
          <th>Cldy</th>
          <th>Other Retail</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(revenue, month) in data.daily" :key="month">
          <td>{{ month }}</td>
          <td>{{ revenue.total }}</td>
          <td>{{ revenue.reseller }}</td>
          <td>{{ revenue.cldy }}</td>
          <td>{{ revenue.no_cldy }}</td>
        </tr>
        </tbody>
      </table>
    </v-col>
    <v-col cols="12">
      <h5>Monthly Report Product Type Group</h5>
      <table border="1">
        <thead>
        <tr>
          <th>Month</th>
          <th v-for="(typeName, index) in data.product_type" :key="index"> {{ typeName }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(revenue, month) in data.monthly_product_type['total']" :key="month">
          <td>{{ month }}</td>
          <td v-for="(typeName, index) in data.product_type" :key="index">
            <span v-if="data.monthly_product_type['total'][month] &&
            data.monthly_product_type['total'][month][typeName]">
              {{ data.monthly_product_type['total'][month][typeName] }}
            </span>
            <span v-else>0</span>
          </td>
        </tr>
        </tbody>
      </table>
    </v-col>
    <v-col cols="12">
      <h5>Monthly Report Product Type Group Reseller</h5>
      <table border="1">
        <thead>
        <tr>
          <th>Month</th>
          <th v-for="(typeName, index) in data.product_type" :key="index"> {{ typeName }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(revenue, month) in data.monthly_product_type['reseller']" :key="month">
          <td>{{ month }}</td>
          <td v-for="(typeName, index) in data.product_type" :key="index">
            <span v-if="data.monthly_product_type['reseller'][month] &&
            data.monthly_product_type['reseller'][month][typeName]">
              {{ data.monthly_product_type['reseller'][month][typeName] }}
            </span>
            <span v-else>0</span>
          </td>
        </tr>
        </tbody>
      </table>
    </v-col>
    <v-col cols="12">
      <h5>Monthly Report Product Type Group CLDY</h5>
      <table border="1">
        <thead>
        <tr>
          <th>Month</th>
          <th v-for="(typeName, index) in data.product_type" :key="index"> {{ typeName }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(revenue, month) in data.monthly_product_type['cldy']" :key="month">
          <td>{{ month }}</td>
          <td v-for="(typeName, index) in data.product_type" :key="index">
            <span v-if="data.monthly_product_type['cldy'][month] &&
            data.monthly_product_type['cldy'][month][typeName]">
              {{ data.monthly_product_type['cldy'][month][typeName] }}
            </span>
            <span v-else>0</span>
          </td>
        </tr>
        </tbody>
      </table>
    </v-col>
    <v-col cols="12">
      <h5>Monthly Report Product Type Group Not CLDY</h5>
      <table border="1">
        <thead>
        <tr>
          <th>Month</th>
          <th v-for="(typeName, index) in data.product_type" :key="index"> {{ typeName }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(revenue, month) in data.monthly_product_type['no_cldy']" :key="month">
          <td>{{ month }}</td>
          <td v-for="(typeName, index) in data.product_type" :key="index">
            <span v-if="data.monthly_product_type['no_cldy'][month] &&
            data.monthly_product_type['no_cldy'][month][typeName]">
              {{ data.monthly_product_type['no_cldy'][month][typeName] }}
            </span>
            <span v-else>0</span>
          </td>
        </tr>
        </tbody>
      </table>
    </v-col>
    <v-col cols="12">
      <h5>For reseller, it is counted the date when credit is deducted</h5>
      <h5>For retail, it is counted the date when payment is made by credit card
        or received if paid by bank/cheque/paynow</h5>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'RevenueReport',
  created() {
    this.reload();
  },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    reload() {
      this.$api.get('admin/revenue-report').then((response) => {
        this.$set(this, 'data', response.data);
      });
    },
  },
};
</script>

<template>
  <v-row no-gutters>
    <v-col cols="6" offset="3">
      <v-form ref="form">
        <v-card outlined>
          <v-card-text>
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-row v-if="isAddon">
                  <v-col cols="3">
                    <v-text-field
                      label="Parent Service Name"
                      v-model="parent.serviceName"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Parent Service Identity"
                      v-model="parent.serviceIdentity"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Parent Service Expiry"
                      v-model="parent.timezoneExpiryDate"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Parent Service Status"
                      v-model="parent.statusName"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <hr>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <h2 v-if="isAddon">Addon:</h2>
                    <h2 v-else>Product:</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Partner Name"
                      v-model="partnerName"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Request Datetime"
                      v-model="requestDatetime"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Service Status"
                      v-model="serviceStatus"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Service"
                      v-model="serviceName"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Service Identity"
                      v-model="serviceIdentity"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <component
                      :is="moduleManageComponent"
                      :moduleData.sync="moduleData"
                      :parentModuleData="parentModuleData"
                    ></component>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      color="primary"
                      :loading="processing"
                      :disabled="processing"
                      @click="complete"
                    >Compelte</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import Module from '../../components/provisioning_modules';

export default {
  name: 'Detail',
  components: {
    Module,
  },
  created() {
    // this.moduleManageComponent = Module.ManualManage;
    this.$api.get(`admin/service-manual-provision-queue/${this.$route.params.id}`).then((response) => {
      this.isAddon = response.data.is_addon;
      this.parent = response.data.parent;
      this.partnerName = response.data.user_name;
      this.requestDatetime = response.data.request_datetime;
      this.serviceName = response.data.service_name;
      this.serviceIdentity = response.data.service_identity;
      this.serviceStatus = response.data.service_status;
      const moduleName = response.data.module_name;
      const ucModuleName = moduleName.charAt(0).toUpperCase() + moduleName.slice(1);
      this.moduleManageComponent = Module[`${ucModuleName}Manage`];
      this.$set(this, 'parentModuleData', response.data.module_data);
    });
  },
  data() {
    return {
      parent: null,
      isAddon: false,
      partnerName: '',
      requestDatetime: '',
      serviceName: '',
      serviceIdentity: '',
      serviceStatus: '',
      moduleData: null,
      parentModuleData: null,
      moduleManageComponent: null,
      processing: false,
    };
  },
  methods: {
    complete() {
      this.processing = true;
      this.$api.post(`admin/service-manual-provision-queue/${this.$route.params.id}/complete`, {
        module_data: this.moduleData,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'You have successfully activated this service.',
        });

        setTimeout(() => {
          this.$router.push({ name: 'Admin.ServiceManualProvisionQueueList' });
        }, 500);
      }).finally(() => {
        this.processing = false;
      });
    },
  },
};
</script>

<style scoped>

</style>

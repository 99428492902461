<template>
  <v-row no-gutters>
    <v-col cols="6">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Hostname
            </th>
            <th class="text-left">
              Monthly Revenue
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="row in rows"
            :key="row.id"
          >
            <td>{{ row.hostname }}</td>
            <td>{{ row.server_total }} ({{ row.server_total_no_margin }})</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CpanelServerRevenueMonthly',
  data() {
    return {
      rows: [],
    };
  },
  created() {
    this.$api.post('admin/finance/cpanel-server-revenue')
      .then((response) => {
        this.$set(this, 'rows', response.data);
      });
  },
  methods: {
  },
};
</script>

<style scoped>

</style>

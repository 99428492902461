<template>
  <v-row>
    <v-col cols="12">
      <v-tabs class="product-type-tab">
        <v-tab v-for="productType in productTypes" :key="productType.id"
               @change="changeProductType(productType)">
          <v-chip
            :label="true"
            x-small
            color="green"
            text-color="white"
          >{{ productType.active_services }}</v-chip>
          {{ productType.type_name }}
        </v-tab>
      </v-tabs>
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-toolbar v-if="initiated" dense :flat="true">
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              hide-details
              prepend-icon="mdi-text-search"
              single-line
              v-model="search"
              @keyup.enter.native="loadTable()"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn small color="primary" @click="loadTable()">Search</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.status_name="{ item }">
          <b v-if="item.status_name == 'Active'" class="green--text">{{ item.status_name }}</b>
          <b v-else class="red--text">{{ item.status_name }}</b>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'List',
  data() {
    return {
      search: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      strange: false,
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'service_name' },
        { text: 'Domain', value: 'service_identity' },
        { text: 'Status', value: 'status_name' },
        { text: 'Term', value: 'term_name' },
        { text: 'Creation Date', value: 'provision_date' },
        { text: 'Expiry', value: 'expiry_date' },
        { text: 'Archived Date', value: 'deleted_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    this.$api.get('partner/product-type/archive/menu').then((response) => {
      this.productTypes = response.data;
      if (response.data[0]) {
        this.productTypeId = response.data[0].id;
      } else {
        this.productTypeId = 0;
      }
      this.initiated = true;
      this.loadTable();
    });
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('partner/service/archive/list', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        product_type_id: this.productTypeId,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
  table td {
    font-size: 0.75rem !important;
  }

  .product-type-tab .v-tab {
    justify-content: flex-start;
  }

  .product-type-tab .v-chip {
    margin-right: 8px;
  }
</style>

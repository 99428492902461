<template>
  <v-row
    no-gutters
  >
    <v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
            'items-per-page-options': [15,30,60,100,150,250],
          }"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="edit(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteConfirm(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog
        v-model="deleteDialog"
        max-width="320"
      >
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>Are you sure to delete this article category?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="deleteDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              :loading="deleteLoading"
              :disabled="deleteLoading"
              @click="deleteRow"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Category Name', value: 'name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      deleteDialog: false,
      deleteItem: null,
      deleteLoading: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.loadTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/article-category/list', {
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    edit(item) {
      this.$router.push({ name: 'Admin.ArticleCategoryUpdate', params: { id: item.id } });
    },
    deleteConfirm(item) {
      this.deleteDialog = true;
      this.deleteItem = item;
    },
    deleteRow() {
      this.deleteLoading = true;
      this.$api.delete(`admin/article-category/${this.deleteItem.id}`).finally(() => {
        this.deleteDialog = false;
        this.loadTable();
      });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row no-gutters dense>
    <v-col cols="12">
      <h4>{{ companyName }}</h4>
      <h4>{{ email }}</h4>
    </v-col>
    <v-col cols="12">
      <v-expansion-panels
        multiple
      >
        <v-expansion-panel v-for="(productPrices, category) in prices" v-bind:key="category">
          <v-expansion-panel-header>Product Category: {{ category }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense>
              <v-col cols="12">
                <v-row dense v-for="(productTermPrices, productId) in productPrices"
                       v-bind:key="productId">
                  <v-col cols="12">
                    Product Name:
                    <b v-if="isAws(productId)">(AWS)</b>{{ productTermPrices.product_name }}
                    <v-checkbox
                      v-model="form.member_prices[category][productId]['orderable']"
                      :label="`Orderable?`"
                    ></v-checkbox>
                    <v-btn x-small color="primary"
                    @click="updateMemberPrice(productId, form.member_prices[category][productId])"
                    >Update Member Price</v-btn>
                  </v-col>
                  <v-col cols="2" v-for="(termName, termId) in terms" v-bind:key="termId">
                  <span class="price-detail">
                    <v-checkbox
                      v-model="form.member_prices[category][productId][termId]['allowRetail']"
                      :label="`${termName}, resale?`"
                    >
                    </v-checkbox>
                    <br/>Retail/Promo:
                    {{ retailPrices[category][productId]['retailPrices'][termId] }}/
                    {{ promotionPrices[category][productId]['promotionPrices'][termId] }}
                  </span>
                    <v-text-field
                      v-model="form.member_prices[category][productId][termId]['price']">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ProductMemberPrices',
  created() {
    this.$api.get(`admin/partner/${this.$route.params.id}/addon-prices`).then((response) => {
      this.loadPrices(response.data);
    });

    this.$api.get(`admin/partner/${this.$route.params.id}`).then((response) => {
      this.email = response.data.email;
      this.companyName = response.data.company_name;
    });
  },
  data() {
    return {
      companyName: '',
      email: '',
      prices: null,
      retailPrices: null,
      promotionPrices: null,
      memberPrices: null,
      terms: {
        1: 'One Time',
        2: 'Monthly',
        3: 'Quarterly',
        4: 'Biannually',
        5: 'Annually',
        6: 'Biennially',
        7: 'Triennially',
        8: 'Quadrennially',
        9: 'Quinquennially',
        10: 'Sexennially',
        11: 'Septennially',
        12: 'Octennially',
        13: 'Novennially',
        14: 'Decennially',
        15: '10 Months',
        16: '11 Months',
      },
      form: {
        member_prices: {},
      },
    };
  },
  methods: {
    isAws(productId) {
      const awsProducts = [8, 9, 10, 41, 42, 43, 44, 83, 84, 85, 86, 87, 92, 93, 94, 95, 144];
      return awsProducts.indexOf(parseInt(productId, 10)) >= 0;
    },
    updateMemberPrice(productId, memberPrice) {
      this.$api.post(`admin/partner/${this.$route.params.id}/product-member-price`, {
        product_id: productId,
        member_price: memberPrice,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Member price is updated successfully.',
          });
        });
    },
    loadPrices(prices) {
      const retailPrices = {};
      const promotionPrices = {};
      this.$underscore.forEach(prices, (productPrices, categoryName) => {
        retailPrices[categoryName] = {};
        promotionPrices[categoryName] = {};
        this.form.member_prices[categoryName] = {};
        this.$underscore.forEach(productPrices, (productTermPrice, productId) => {
          retailPrices[categoryName][productId] = {
            retailPrices: {},
          };
          promotionPrices[categoryName][productId] = {
            promotionPrices: {},
          };
          this.form.member_prices[categoryName][productId] = {};
          this.form.member_prices[categoryName][productId].orderable = productTermPrice.orderable;
          this.$underscore.forEach(this.terms, (termName, termId) => {
            if (typeof productTermPrice.retail_prices !== 'undefined'
              && typeof productTermPrice.retail_prices[termId] !== 'undefined') {
              retailPrices[categoryName][productId]
                .retailPrices[termId] = `$${productTermPrice
                  .retail_prices[termId].productPriceNoTax}`;
            } else {
              retailPrices[categoryName][productId].retailPrices[termId] = '-';
            }

            if (typeof productTermPrice.promotion_prices !== 'undefined'
              && typeof productTermPrice.promotion_prices[termId] !== 'undefined') {
              promotionPrices[categoryName][productId]
                .promotionPrices[termId] = `$${productTermPrice
                  .promotion_prices[termId].productPrice}`;
            } else {
              promotionPrices[categoryName][productId].promotionPrices[termId] = '-';
            }

            if (typeof productTermPrice.member_prices !== 'undefined'
              && typeof productTermPrice.member_prices[termId] !== 'undefined') {
              this.form.member_prices[categoryName][productId][termId] = {
                price: productTermPrice.member_prices[termId].productPrice,
                allowRetail: productTermPrice.member_prices[termId].allowRetail,
              };
            } else {
              this.form.member_prices[categoryName][productId][termId] = {
                price: null,
                allowRetail: 0,
              };
            }
          });
        });
      });
      this.$set(this, 'retailPrices', retailPrices);
      this.$set(this, 'promotionPrices', promotionPrices);
      this.$set(this, 'prices', prices);
    },
  },
};
</script>

<style scoped>
  .price-detail {
    font-size: 14px;
  }
</style>

import Base from './Base.vue';
import Home from './Home.vue';
import UserProfile from './UserProfile.vue';
import Settings from './Settings.vue';
import BalanceList from './balance/List.vue';
import BalanceTopup from './balance/Topup.vue';
import DomainList from './domain/List.vue';
import DomainListArchive from './domain/ListArchive.vue';
import DomainDetail from './domain/Detail.vue';
import DomainRegister from './domain/Register.vue';
import DomainRenew from './domain/Renew.vue';
import DomainTransfer from './domain/Transfer.vue';
import DomainRequestTransfer from './domain/RequestTransfer.vue';
import DomainDefaultContact from './domain/DefaultContact.vue';
import ServiceList from './service/List.vue';
import ServiceArchiveList from './service/ArchiveList.vue';
import ServiceDetail from './service/Detail.vue';
import ServiceRegister from './service/Register.vue';
import ServiceRenew from './service/Renew.vue';
import AddonList from './addon/List.vue';
import AddonArchiveList from './addon/ArchiveList.vue';
import Topup from './payment/Topup.vue';
import SavePaypalCard from './payment/SavePaypalCard.vue';
import TopupHistory from './payment/TopupHistory.vue';
import TaxInvoiceList from './payment/TaxInvoiceList.vue';
import ResellerCustomerList from './reseller/customer/List.vue';
import ResellerCustomerDetail from './reseller/customer/Detail.vue';
import ResellerCustomerInvoiceList from './reseller/invoice/List.vue';
import ResellerCustomerInvoiceDetail from './reseller/invoice/Detail.vue';
import ResellerProductPrices from './reseller/price/ProductPrices.vue';
import ResellerMemberProductPrices from './reseller/price/MemberProductPrices.vue';
import ResellerAddonPrices from './reseller/price/AddonPrices.vue';
import ResellerMemberAddonPrices from './reseller/price/MemberAddonPrices.vue';
import ResellerDomainProductPrices from './reseller/price/DomainProductPrices.vue';
import ResellerMemberDomainProductPrices from './reseller/price/MemberDomainProductPrices.vue';
import ResellerCustomerServiceList from './reseller/service/List.vue';
import ResellerCustomerDomainList from './reseller/domain/List.vue';
import ResellerCustomerTransferQueue from './reseller/transferqueue/List.vue';
import PartnerCommissionList from './commission/List.vue';
import PartnerCommissionWithdraw from './commission/Withdraw.vue';
import DnsEditor from './service/DnsEditor.vue';
import PartnerCommissionListYearMonth from './commission/PartnerCommissionListYearMonth.vue';
import PartnerCommissionYearMonth from './commission/PartnerCommissionYearMonth.vue';
import CollaboratorList from './collaborator/List.vue';
import CollaboratorNew from './collaborator/New.vue';
import CollaboratorUpdate from './collaborator/Update.vue';
import CollaboratorActionList from './collaborator/ListAction.vue';
import CollaboratorServiceList from './collaborator/ServiceList.vue';

export default {
  Home,
  Base,
  UserProfile,
  Settings,
  BalanceList,
  BalanceTopup,
  DomainList,
  DomainListArchive,
  DomainDetail,
  DomainRegister,
  DomainRenew,
  DomainTransfer,
  DomainRequestTransfer,
  DomainDefaultContact,
  ServiceList,
  ServiceArchiveList,
  ServiceDetail,
  ServiceRegister,
  ServiceRenew,
  AddonList,
  AddonArchiveList,
  Topup,
  SavePaypalCard,
  TopupHistory,
  TaxInvoiceList,
  ResellerCustomerList,
  ResellerCustomerDetail,
  ResellerCustomerInvoiceList,
  ResellerCustomerInvoiceDetail,
  ResellerProductPrices,
  ResellerAddonPrices,
  ResellerDomainProductPrices,
  ResellerMemberProductPrices,
  ResellerMemberAddonPrices,
  ResellerMemberDomainProductPrices,
  ResellerCustomerServiceList,
  ResellerCustomerDomainList,
  ResellerCustomerTransferQueue,
  DnsEditor,
  PartnerCommissionList,
  PartnerCommissionWithdraw,
  PartnerCommissionListYearMonth,
  PartnerCommissionYearMonth,
  CollaboratorList,
  CollaboratorActionList,
  CollaboratorServiceList,
  CollaboratorNew,
  CollaboratorUpdate,
};

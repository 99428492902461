<template>
  <v-row dense>
    <v-col cols="12" v-if="loading">
      <h3>Loading addons...</h3>
    </v-col>
    <v-col cols="12"
           v-if="!loading && (addonData1.list.length > 0 ||
           addonData2.list.length > 0 || addonData3.list.length > 0)">
      <h3 v-if="this.parentServiceId">Add New Addons</h3>
      <h3 v-else>Addons</h3>
    </v-col>
    <v-col cols="12"
           v-for="(addonGroup, addonGroupId) in addonData2.list" :key="'A' + addonGroupId"
    >
      <v-card
        elevation="1"
      >
        <v-card-text>
          <v-row dense>
            <v-col cols="4">
              <h4>{{ addonGroup.name }}</h4>
            </v-col>
            <v-col cols="4">
              <p>{{ addonGroup.description }}</p>
            </v-col>
          </v-row>
          <v-radio-group
            v-model="addonGroup.selected"
            @change="selectGroupAddon2()"
          >
            <v-row dense v-if="!addonGroup.default">
              <v-col cols="12">
              </v-col>
              <v-col cols="1">
                <v-radio :value="0"></v-radio>
              </v-col>
              <v-col cols="10">No, Thanks</v-col>
            </v-row>
            <v-row dense v-for="(addon, addonIndex) in addonGroup.list" :key="'AA' + addonIndex">
              <v-col cols="12">
                <hr>
              </v-col>
              <v-col cols="1">
                <v-radio :value="addon.id"></v-radio>
              </v-col>
              <v-col cols="8"><b>{{ addon.product_name }}</b></v-col>
              <v-col cols="3"
                     v-if="groupAddonSelected(addon.id, addonGroup.selected) &&
                     addon.addon_renewal_type === 1">
                <v-select
                  outlined
                  dense
                  v-model="addon.selectedPriceTerm"
                  :items="addon.priceTerms"
                  label="Term"
                  :rules="[rules.required]"
                  readonly
                ></v-select>
              </v-col>
              <v-col cols="3"
                     v-if="groupAddonSelected(addon.id, addonGroup.selected) &&
                     addon.addon_renewal_type === 2">
                <v-select
                  outlined
                  dense
                  v-model="addon.selectedPriceTerm"
                  :items="addon.priceTerms"
                  label="Term"
                  :rules="[rules.required]"
                  @change="addonPriceUpdate"
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="addon.addon_show_description">
                {{ addon.product_description }}
              </v-col>
              <v-col cols="12" v-if="groupAddonSelected(addon.id, addonGroup.selected)">
                <component
                  :key="addon.id"
                  :is="addon.moduleComponent"
                  :buy="addon.selected"
                  :moduleData.sync="addon.moduleData"
                  :parentModuleData="addon.defaultModuleData"
                ></component>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12"
           v-for="(addonGroup, addonGroupId) in addonData3.list" :key="'C' + addonGroupId"
    >
      <v-card
        elevation="1"
      >
        <v-card-text>
          <v-row dense>
            <v-col cols="4">
              <h4>{{ addonGroup.name }}</h4>
            </v-col>
            <v-col cols="4">
              <p>{{ addonGroup.description }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="9">
              <v-select
                v-model="addonGroup.selected"
                :items="addonGroup.list"
                :item-text="item => item.product_name"
                :item-value="item => item.id"
                label=""
                dense
                outlined
                @change="selectGroupAddon3()"
              ></v-select>
            </v-col>
            <v-col cols="3"
                   v-for="(addon, addonIndex) in addonGroup.list" :key="'CC' + addonIndex"
                   :class="{ hidden: !groupAddonSelected(addon.id, addonGroup.selected) }"
                   >
              <v-select
                v-if="groupAddonSelected(addon.id, addonGroup.selected) &&
                     addon.addon_renewal_type === 1"
                outlined
                dense
                v-model="addon.selectedPriceTerm"
                :items="addon.priceTerms"
                label="Term"
                :rules="[rules.required]"
                readonly
              ></v-select>
              <v-select
                v-if="groupAddonSelected(addon.id, addonGroup.selected) &&
                     addon.addon_renewal_type === 2"
                outlined
                dense
                v-model="addon.selectedPriceTerm"
                :items="addon.priceTerms"
                label="Term"
                :rules="[rules.required]"
                @change="addonPriceUpdate"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense v-for="(addon, addonIndex) in addonGroup.list" :key="'CC' + addonIndex">
            <v-col cols="12" v-if="groupAddonSelected(addon.id, addonGroup.selected) &&
            addon.addon_show_description">
              {{ addon.product_description }}
            </v-col>
            <v-col cols="12" v-if="groupAddonSelected(addon.id, addonGroup.selected)">
              <component
                :key="addon.id"
                :is="addon.moduleComponent"
                :buy="addon.selected"
                :moduleData.sync="addon.moduleData"
                :parentModuleData="addon.defaultModuleData"
              ></component>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12"
           v-for="(addon, index) in addonData1.list" :key="'B' + index">
      <v-card
        elevation="1"
      >
        <v-card-text>
          <v-row dense align="center">
            <v-col cols="2" v-if="addon.addon_quantity">
              <v-text-field label="Add More"
                            dense
                            outlined
                            v-model="addon.quantity"
                            :append-outer-icon="'mdi-plus'"
                            @click:append-outer="addAddon(addon, index)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="1">
              <v-checkbox v-model="addon.selected"
                          v-on:change="selectAddon()"
              ></v-checkbox>
            </v-col>
            <v-col cols="3"><b>{{ addon.product_name }}</b></v-col>
            <v-col cols="5">{{ addon.product_description }}</v-col>
            <v-col cols="3" v-if="addon.addon_renewal_type === 1">
              <v-select
                outlined
                dense
                v-model="addon.selectedPriceTerm"
                :items="addon.priceTerms"
                label="Term"
                :rules="[rules.required]"
                readonly
              ></v-select>
            </v-col>
            <v-col cols="3" v-if="addon.addon_renewal_type === 2">
              <v-select
                outlined
                dense
                v-model="addon.selectedPriceTerm"
                :items="addon.priceTerms"
                label="Term"
                :rules="[rules.required]"
                @change="addonPriceUpdate"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <component
                :key="addon.id"
                :is="addon.moduleComponent"
                :buy="addon.selected"
                :moduleData.sync="addon.moduleData"
                :parentModuleData="addon.defaultModuleData"
              ></component>
            </v-col>
          </v-row>
          <v-row dense align="center"
                 v-for="(addonQuantity, subindex) in addon.quantityList" :key="subindex"
          >
            <v-col cols="1">
              <v-checkbox v-model="addonQuantity.selected"
                          v-on:change="selectAddon()"
              ></v-checkbox>
            </v-col>
            <v-col cols="3"><b>{{ addonQuantity.product_name }}</b></v-col>
            <v-col cols="5">{{ addonQuantity.product_description }}</v-col>
            <v-col cols="3" v-if="addonQuantity.addon_renewal_type === 1">
              <v-select
                outlined
                dense
                v-model="addonQuantity.selectedPriceTerm"
                :items="addonQuantity.priceTerms"
                label="Term"
                :rules="[rules.required]"
                readonly
              ></v-select>
            </v-col>
            <v-col cols="3" v-if="addonQuantity.addon_renewal_type === 2">
              <v-select
                outlined
                dense
                v-model="addonQuantity.selectedPriceTerm"
                :items="addonQuantity.priceTerms"
                label="Term"
                :rules="[rules.required]"
                @change="addonPriceUpdate"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <component
                :key="addonQuantity.id"
                :is="addonQuantity.moduleComponent"
                :buy="addonQuantity.selected"
                :moduleData.sync="addonQuantity.moduleData"
                :parentModuleData="addonQuantity.defaultModuleData"
              ></component>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Module from './provisioning_modules';

export default {
  name: 'Addon',
  data() {
    return {
      moduleMap: {
        manual: Module.ManualAddonRegister,
        domainPrivacy: Module.DomainPrivacyAddonRegister,
      },
      addonData: {
        list: [],
        selected: [],
        map: {},
      },
      addonData1: {
        list: [],
        selected: [],
        toggle: 0, // used for force watch
      },
      addonData2: {
        list: {},
        selected: [],
        toggle: 0, // used for force watch
      },
      addonData3: {
        list: {},
        selected: [],
        toggle: 0, // used for force watch
      },
      addonGroups: {},
      addonTemplateList: {},
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || 'This field must be valid domain name.',
      },
      addonNames: '',
      loading: true,
    };
  },
  props: {
    parentProductId: {
      type: Number,
      default() {
        return 0;
      },
    },
    parentServiceId: {
      type: Number,
      default() {
        return 0;
      },
    },
    parentTermId: {
      type: Number,
      default() {
        return 0;
      },
    },
    parentProductTerms: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  async created() {
    let url = '';
    let url2 = '';
    if (this.parentProductId > 0) {
      url = `partner/product/${this.parentProductId}/addons`;
      url2 = `partner/product/${this.parentProductId}/addon-groups`;
    } else if (this.parentServiceId > 0) {
      url = `partner/service/${this.parentServiceId}/addons`;
      url2 = `partner/service/${this.parentServiceId}/addon-groups`;
    } else {
      this.loading = false;
      return;
    }

    await this.$api.get(url2).then((response) => {
      this.addonGroups = response.data;
    });

    await this.$api.get(url).then((response) => {
      const list = response.data;

      if (list.length === 0) {
        this.loading = false;
        return;
      }

      this.$underscore.each(list, (addonType) => {
        addonType.forEach((addon) => {
          this.addonNames += `${addon.product_name};`;
          if (
            this.addonGroups.has_addon_ids
            && this.addonGroups.has_addon_ids.indexOf(addon.id) >= 0) {
            return;
          }

          if (addon.currencies.length === 0) {
            return;
          }

          const tmp = {};
          tmp[addon.id] = 0;
          const addonCopy = addon;
          addonCopy.defaultModuleData = addon.module_data;
          addonCopy.moduleComponent = this.moduleMap[addon.module_name];
          addonCopy.quantity = 1;
          addonCopy.quantityList = [];
          this.addonData.selected.push(tmp);
          this.addonData.list.push(addonCopy);
          if (addon.addon_display_type === 1) {
            const addonClone = this.$underscore.clone(addonCopy);
            this.addonData1.list.push(addonClone);
            this.addonData.map[addon.id] = this.addonData1.list.length - 1;
          } else if (addon.addon_display_type === 2) {
            const addonGroup = this.addonGroups.addon_groups[this.addonGroups.addon_map[addon.id]];

            if (typeof addonGroup === 'undefined') {
              return;
            }

            if (typeof this.addonData2.list[addonGroup.id] === 'undefined') {
              this.$set(this.addonData2.list, addonGroup.id, addonGroup);
              this.$set(this.addonData2.list[addonGroup.id], 'list', []);
            }

            const addonClone = this.$underscore.clone(addonCopy);
            if (addonGroup.default) {
              addonGroup.selected = addonGroup.default.id;
              addonClone.selected = true;
            } else {
              addonGroup.selected = 0;
            }
            this.addonData2.list[addonGroup.id].list.push(addonClone);
            this.addonData.map[addon.id] = this.addonData2.list[addonGroup.id].list.length - 1;
          } else if (addon.addon_display_type === 3) {
            const addonGroup = this.addonGroups.addon_groups[this.addonGroups.addon_map[addon.id]];

            if (typeof addonGroup === 'undefined') {
              return;
            }

            if (typeof this.addonData3.list[addonGroup.id] === 'undefined') {
              this.$set(this.addonData3.list, addonGroup.id, addonGroup);
              this.$set(this.addonData3.list[addonGroup.id], 'list', []);
              // eslint-disable-next-line no-empty
              if (addonGroup.default) {
              } else {
                this.$set(this.addonData3.list[addonGroup.id].list, 0, {
                  id: 0,
                  product_name: 'No, Thanks',
                  product_description: '',
                });
              }
            }

            const addonClone = this.$underscore.clone(addonCopy);
            if (addonGroup.default) {
              addonGroup.selected = addonGroup.default.id;
              addonClone.selected = true;
            } else {
              addonGroup.selected = 0;
            }
            this.addonData3.list[addonGroup.id].list.push(addonClone);
            this.addonData.map[addon.id] = this.addonData3.list[addonGroup.id].list.length - 1;
          }
        });

        this.reloadAddonPrices();

        this.addonPriceUpdate();

        this.$emit('addonpulled', this.addonNames);

        this.loading = false;
      });
    });
  },
  methods: {
    groupAddonSelected(addonId, selectedId) {
      return addonId === selectedId;
    },
    addAddon(addon, index) {
      for (let i = 0; i < addon.quantity; i += 1) {
        const copy = this.$underscore.clone(this.addonTemplateList[addon.id]);
        copy.quantity = 1;
        this.addonData1.list.splice(index, 0, copy);
      }
    },
    selectGroupAddon2() {
      this.$set(this.addonData2, 'toggle', !this.addonData2.toggle);
      this.selectAddon();
    },
    selectGroupAddon3() {
      this.$set(this.addonData3, 'toggle', !this.addonData3.toggle);
      this.selectAddon();
    },
    selectAddon() {
      this.$emit('addonchecked');
      this.$forceUpdate();
      this.addonPriceUpdate();
    },
    addonPriceUpdate() {
      let total = 0;
      this.addonData1.list.forEach((addon) => {
        if (addon.selected) {
          addon.priceTerms.forEach((priceTerm) => {
            if (parseInt(priceTerm.value, 0) === parseInt(addon.selectedPriceTerm, 0)) {
              total += +priceTerm.price;
            }
          });
        }
      });
      this.$underscore.each(this.addonData2.list, (addonGroup, groupIndex) => {
        addonGroup.list.forEach((addon, addonIndex) => {
          if (addon.id === addonGroup.selected) {
            this.addonData2.list[groupIndex].list[addonIndex].selected = true;
            addon.priceTerms.forEach((priceTerm) => {
              if (parseInt(priceTerm.value, 0) === parseInt(addon.selectedPriceTerm, 0)) {
                total += +priceTerm.price;
              }
            });
          } else {
            this.addonData2.list[groupIndex].list[addonIndex].selected = null;
          }
        });
      });
      this.$underscore.each(this.addonData3.list, (addonGroup, groupIndex) => {
        addonGroup.list.forEach((addon, addonIndex) => {
          if (addon.id === addonGroup.selected) {
            this.addonData3.list[groupIndex].list[addonIndex].selected = true;
            addon.priceTerms.forEach((priceTerm) => {
              if (parseInt(priceTerm.value, 0) === parseInt(addon.selectedPriceTerm, 0)) {
                total += +priceTerm.price;
              }
            });
          } else {
            this.addonData3.list[groupIndex].list[addonIndex].selected = null;
          }
        });
      });
      this.$emit('addonPriceUpdate', total);
    },
    reloadAddonPrices() {
      this.$underscore.each(this.addonData1.list, (addon, key) => {
        this.$set(this.addonData1.list[key], 'priceTerms', []);
        this.$underscore.each(addon.currencies, (value) => {
          if (addon.renewable) {
            if (this.parentProductTerms.indexOf(value.productTermId) < 0) {
              return;
            }

            if (value.productTermId < 2) {
              return;
            }
          } else if (value.productTermId > 1) {
            return;
          }

          const currentProductPrice = {
            text: `${value.termName}: $${value.productPrice}`,
            value: value.productTermId,
            price: value.productPrice,
          };

          this.addonData1.list[key].priceTerms.push(currentProductPrice);
          if (addon.renewable) {
            this.$set(this.addonData1.list[key], 'selectedPriceTerm', this.parentTermId);
            this.$set(this.addonData1.list[key], 'product_term', this.parentTermId);
          } else {
            this.$set(this.addonData1.list[key], 'selectedPriceTerm', 1);
            this.$set(this.addonData1.list[key], 'product_term', 1);
          }
        });
        const addonCopy = this.$underscore.clone(addon);
        delete addonCopy.quantityList;
        this.addonTemplateList[addon.id] = addonCopy;
      });

      this.$underscore.each(this.addonData2.list, (addonGroup, key) => {
        this.$underscore.each(addonGroup.list, (addon, subkey) => {
          this.$set(this.addonData2.list[key].list[subkey], 'priceTerms', []);
          this.$underscore.each(addon.currencies, (value) => {
            if (addon.renewable) {
              if (this.parentProductTerms.indexOf(value.productTermId) < 0) {
                return;
              }

              if (value.productTermId < 2) {
                return;
              }
            } else if (value.productTermId > 1) {
              return;
            }

            const currentProductPrice = {
              text: `${value.termName}: $${value.productPrice}`,
              value: value.productTermId,
              price: value.productPrice,
            };

            this.addonData2.list[key].list[subkey].priceTerms.push(currentProductPrice);
            this.$set(this.addonData2.list[key].list[subkey], 'selectedPriceTerm', this.parentTermId);
            this.$set(this.addonData2.list[key].list[subkey], 'product_term', this.parentTermId);
          });
          const addonCopy = this.$underscore.clone(addon);
          delete addonCopy.quantityList;
          this.addonTemplateList[addon.id] = addonCopy;
        });
      });

      this.$underscore.each(this.addonData3.list, (addonGroup, key) => {
        this.$underscore.each(addonGroup.list, (addon, subkey) => {
          this.$set(this.addonData3.list[key].list[subkey], 'priceTerms', []);
          this.$underscore.each(addon.currencies, (value) => {
            if (addon.renewable) {
              if (this.parentProductTerms.indexOf(value.productTermId) < 0) {
                return;
              }

              if (value.productTermId < 2) {
                return;
              }
            } else if (value.productTermId > 1) {
              return;
            }

            const currentProductPrice = {
              text: `${value.termName}: $${value.productPrice}`,
              value: value.productTermId,
              price: value.productPrice,
            };

            this.addonData3.list[key].list[subkey].priceTerms.push(currentProductPrice);
            this.$set(this.addonData3.list[key].list[subkey], 'selectedPriceTerm', this.parentTermId);
            this.$set(this.addonData3.list[key].list[subkey], 'product_term', this.parentTermId);
          });
          const addonCopy = this.$underscore.clone(addon);
          delete addonCopy.quantityList;
          this.addonTemplateList[addon.id] = addonCopy;
        });
      });
    },
  },
  watch: {
    addonData1: {
      handler() {
        this.$underscore.each(this.addonData1.list, (addon) => {
          this.$underscore.each(this.addonData.list, (addonToUpdate, addonIndex) => {
            if (addonToUpdate && addonToUpdate.id === addon.id) {
              this.addonData.list.splice(addonIndex, 1);
            }
          });
        });
        this.$underscore.each(this.addonData1.list, (addon) => {
          if (addon.selected === 0) {
            return;
          }
          const addonClone = this.$underscore.clone(addon);
          addonClone.product_term = addon.selectedPriceTerm;
          this.addonData.list.push(addonClone);
        });
      },
      deep: true,
    },
    addonData2: {
      handler() {
        this.$underscore.each(this.addonData2.list, (addonGroup) => {
          if (addonGroup.selected === 0) {
            return;
          }
          this.$underscore.each(addonGroup.list, (addon) => {
            if (addon.id === addonGroup.selected) {
              this.$underscore.each(this.addonData.list, (addonToUpdate, addonIndex) => {
                if (addonToUpdate && addonToUpdate.id === addon.id) {
                  const addonClone = this.$underscore.clone(addon);
                  addonClone.product_term = addon.selectedPriceTerm;
                  addonClone.selected = true;
                  this.addonData.list[addonIndex] = addonClone;
                }
              });
            } else {
              this.$underscore.each(this.addonData.list, (addonToUpdate, addonIndex) => {
                if (addonToUpdate && addonToUpdate.id === addon.id) {
                  const addonClone = this.$underscore.clone(addon);
                  addonClone.selected = false;
                  this.addonData.list[addonIndex] = addonClone;
                }
              });
            }
          });
        });
      },
      deep: true,
    },
    addonData3: {
      handler() {
        this.$underscore.each(this.addonData3.list, (addonGroup) => {
          if (addonGroup.selected === 0) {
            return;
          }
          this.$underscore.each(addonGroup.list, (addon) => {
            if (addon.id === addonGroup.selected) {
              this.$underscore.each(this.addonData.list, (addonToUpdate, addonIndex) => {
                if (addonToUpdate && addonToUpdate.id === addon.id) {
                  const addonClone = this.$underscore.clone(addon);
                  addonClone.product_term = addon.selectedPriceTerm;
                  this.addonData.list[addonIndex] = addonClone;
                }
              });
            } else {
              this.$underscore.each(this.addonData.list, (addonToUpdate, addonIndex) => {
                if (addonToUpdate && addonToUpdate.id === addon.id) {
                  const addonClone = this.$underscore.clone(addon);
                  addonClone.selected = false;
                  this.addonData.list[addonIndex] = addonClone;
                }
              });
            }
          });
        });
      },
      deep: true,
    },
    addonData: {
      handler(newData) {
        this.$emit('update:addonData', newData);
      },
      deep: true,
    },
    parentTermId: {
      handler() {
        this.$underscore.each(this.addonData1.list, (addon, key) => {
          this.$set(this.addonData1.list[key], 'selectedPriceTerm', this.parentTermId);
        });
        this.$underscore.each(this.addonData2.list, (addonGroup, key) => {
          this.$underscore.each(addonGroup.list, (addon, subkey) => {
            this.$set(this.addonData2.list[key].list[subkey], 'selectedPriceTerm', this.parentTermId);
          });
        });
        this.$underscore.each(this.addonData3.list, (addonGroup, key) => {
          this.$underscore.each(addonGroup.list, (addon, subkey) => {
            this.$set(this.addonData3.list[key].list[subkey], 'selectedPriceTerm', this.parentTermId);
          });
        });
        this.addonPriceUpdate();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
  .hidden {
    display: none;
  }
</style>

<template>
  <v-row :key="componentKey">
    <v-col cols="12">
      <v-stepper
        v-model="step"
      >
        <v-stepper-header>
          <template v-for="n in steps">
            <v-stepper-step
              :complete="step > n.id || step == steps.length"
              :key="`${n.id}-step`"
              :step="n.id"
            >
              {{ n.label }}
            </v-stepper-step>

            <v-divider
              v-if="n.id < steps.length"
              :key="n.id"
            ></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            key="1-step"
            step="1"
          >
            <v-row dense v-if="step == 1">
              <v-col cols="12">
                <v-tabs
                  class="product-category"
                  centered
                  v-model="productTypeId"
                  next-icon="mdi-arrow-right-bold-box-outline"
                  prev-icon="mdi-arrow-left-bold-box-outline"
                  show-arrows="mobile"
                >
                  <v-tab v-for="(item, i) in categories"
                         :key="i" v-html="item.html"></v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12">
                <!--
                <product-type-dropdown
                  :defaultProductTypeId="defaultProductTypeId"
                  :productTypeId.sync="productTypeId">
                </product-type-dropdown>
                -->
                <v-row>
                  <v-col cols="12" lg="3" v-for="product in products" :key="product.id">
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        class="product"
                        :class="{ 'on-hover': hover || product.id === selectedProductId }"
                        @click="selectProduct(product)"
                      >
                        <v-card-title class="title">
                          {{ product.product_name }}
                        </v-card-title>
                        <v-card-subtitle>
                          <v-row dense v-if="product.product_description2.length === 0">
                            <v-col cols="12">
                              <h4 class="product-description">
                                <pre>{{ product.product_description }}</pre>
                              </h4>
                            </v-col>
                          </v-row>
                          <v-row dense v-else>
                            <v-col cols="6">
                              <h4 class="product-description">
                                <pre>{{ product.product_description }}</pre>
                              </h4>
                            </v-col>
                            <v-col cols="6">
                              <pre>{{ product.product_description2 }}</pre>
                            </v-col>
                          </v-row>
                        </v-card-subtitle>
                        <v-card-text>
                          <table class="product-price">
                            <tr
                              v-for="price in product.currencies"
                              :key="price.id">
                              <td><b>{{ price.termName }}:</b></td>
                              <td><span>${{ price.productPrice }}&nbsp;
                                (${{ price.productPriceMonthly }}/month)</span></td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-right" v-if="step == 1">
                <v-btn
                  text-align="right"
                  color="primary"
                  @click="nextStep()"
                  :disabled="disabled"
                >Continue</v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content
            key="2-step"
            step="2"
          >
            <v-row dense>
              <v-col cols="12">
                <v-card outlined>
                  <v-card-text>
                    <v-form ref="checkoutForm" v-model="validConfiguration">
                      <v-row dense v-if="selectedProduct">
                        <v-col cols="12">
                          <h1>{{ selectedProduct.product_name }}</h1>
                        </v-col>
                        <v-col cols="12" v-if="selectedProduct.product_description2.length === 0">
                          <h3><pre>{{ selectedProduct.product_description }}</pre></h3>
                          <br><br>
                        </v-col>
                        <v-col cols="12" v-else>
                          <v-row dense>
                            <v-col cols="8" md="3">
                              <h3><pre>{{ selectedProduct.product_description }}</pre></h3>
                              <br><br>
                            </v-col>
                            <v-col cols="4" md="3">
                              <p style="font-size:1.17em;">
                                <pre>{{ selectedProduct.product_description2 }}</pre>
                              </p>
                              <br><br>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="8">
                          <v-select
                            dense
                            outlined
                            v-model="selectedProductPrice"
                            :items="productPriceList"
                            label="Term"
                            :rules="[rules.required]"
                            @change="updateProductPrice"
                          ></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-switch v-model="autorenew"
                                    :readonly="oneTime === true"
                                    label="Auto Renew"></v-switch>
                        </v-col>
                      </v-row>
                      <keep-alive>
                        <component
                          :is="moduleComponent"
                          :moduleData.sync="moduleData"
                          :parentModuleData="parentModuleData"
                          :showDomainRegistrationButton="true"
                          v-on:showDomainRegistrationForm="showDomainRegistrationForm"
                          v-on:typing="typing"
                        ></component>
                      </keep-alive>
                      <v-row dense v-if="checkingDomainAvail">
                        <v-col cols="6">
                          <p class="amber--text"><b>Checking domain availability...</b></p>
                        </v-col>
                      </v-row>
                      <v-row dense
                             v-if="domainChecked && domainAvail === true && moduleData.domain">
                        <v-col cols="6">
                          <p class="green--text">
                            <b>Domain {{ moduleData.domain }} is available for registration</b>
                          </p>
                        </v-col>
                      </v-row>
                      <v-row dense v-show="domainChecked && domainAvail === true">
                        <v-radio-group
                          v-model="registerDomain"
                          v-on:change="checkRegistrationForm"
                          row
                        >
                          <v-radio
                            label="I wish to register domain"
                            value="on"
                          ></v-radio>
                          <v-radio
                            label="I do not wish to register domain"
                            value="off"
                          ></v-radio>
                        </v-radio-group>
                      </v-row>
                      <v-row dense
                             v-if="domainChecked && domainAvail === false && moduleData.domain">
                        <v-col cols="6">
                          <p class="red--text">
                            <b>Domain {{ moduleData.domain }} is currently registered</b>
                          </p>
                        </v-col>
                      </v-row>
                      <v-row dense v-show="domainChecked && domainAvail === false">
                        <v-radio-group
                          v-model="transferDomain"
                          v-on:change="checkTransferForm"
                          row
                        >
                          <v-radio
                            label="I do not wish to transfer domain"
                            value="off"
                          ></v-radio>
                          <v-radio
                            label="I wish to transfer domain"
                            value="on"
                          ></v-radio>
                        </v-radio-group>
                      </v-row>
                      <v-row dense>
                        <domain-register
                          :key="domainComponentKey"
                          :ref="`domainRegistrationForm`"
                          :no-checkout="true"
                          :hide-promo-banner="true"
                          v-show="domainChecked && domainAvail === true && registerDomain === 'on'"
                          :hide-affiliate="true"
                          v-on:updateDomainTotal="updateDomainPrice"
                        ></domain-register>
                        <v-col cols="6">
                          <v-select label="Transfer Term"
                                    :items="transferTerms"
                                    v-model="transferTerm"
                                    v-show="domainChecked &&
                                    domainAvail === false && transferDomain === 'on'"
                                    @change="updateDomainTransferPrice"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row dense v-show="showMigration && domainChecked">
                        <v-col cols="12">
                          <br>
                          <v-divider></v-divider>
                          <br>
                          <h3>Hosting Setup</h3>
                        </v-col>
                        <v-col cols="12">
                          <v-radio-group
                            v-model="needMigration"
                            row
                          >
                            <v-radio
                              label="Start with a fresh hosting account"
                              :value="0"
                            ></v-radio>
                            <v-radio
                              :value="1"
                            >
                              <template v-slot:label>
                                I need technical assistance to migrate over
                                <v-tooltip top v-if="$store.state.brand === 'cldy'">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      color="primary"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-information
                                    </v-icon>
                                  </template>
                                  <span>CLDY will get in touch with you via Email or Phone within
                                    12 hours.</span>
                                </v-tooltip>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <keep-alive>
                        <addon
                          :key="addonComponentKey"
                          v-on:addonpulled="addonReady"
                          v-on:addonchecked="revalidate()"
                          :addonData.sync="addonData"
                          :parentProductId="selectedProductId"
                          :parentTermId="selectedProductPrice"
                          :parentProductTerms="parentProductTerms"
                          v-on:addonPriceUpdate="updateAddonPrice"
                        ></addon>
                      </keep-alive>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-btn
                  color="warning"
                  @click="backStep()"
                >Back</v-btn>
              </v-col>
              <v-btn
                v-if="!domainChecked && step === 2"
                text-align="right"
                color="primary"
                @click="showDomainRegistrationForm()"
                style="margin-right: 10px"
              >Check Availability
              </v-btn>
              <v-btn
                text-align="right"
                color="primary"
                @click="checkout()"
                :disabled="checkoutDisabled && !checkoutProcessing"
                :loading="checkoutProcessing"
              >Checkout ${{ total }}</v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
  </v-row>
</template>

<script>
import Addon from '../../components/Addon.vue';
import ProductTypeDropdown from '../../components/ProductTypeDropdown.vue';
import Module from '../../components/provisioning_modules';
import DomainRegister from '../domain/Register.vue';

export default {
  name: 'Register',
  components: {
    Addon,
    ProductTypeDropdown,
    DomainRegister,
  },
  created() {
    this.$api.get('partner/product/list').then((response) => {
      this.defaultProducts = response.data;
      this.$api.get('partner/product-type')
        .then((subresponse) => {
          const list = subresponse.data;
          list.forEach((value) => {
            this.categories.push({
              text: value.type_name,
              html: value.type_name.replace(' ', '<br/>'),
              value: value.id,
            });
          });
          this.$set(this, 'products', this.defaultProducts[this.categories[0].value]);
          this.defaultProductTypeId = this.categories[0].value;
        });
    });

    this.$api.get('partner/domain/available-tlds').then((response) => {
      this.availableTlds = response.data;
    });

    this.$api.get('partner/domain/price-table?operation=9').then((response) => {
      this.tldPriceList = response.data;
    });

    this.$emit('reloadActivity');
  },
  data() {
    return {
      categories: [],
      total: 0,
      totalProduct: 0,
      totalAddon: 0,
      totalDomain: 0,
      totalDomainRegister: 0,
      totalDomainTransfer: 0,
      componentKey: 0,
      moduleComponentKey: 0,
      addonComponentKey: 0,
      domainComponentKey: 0,
      step: 1,
      steps: [
        { id: 1, label: 'Choose Product' },
        { id: 2, label: 'Configure Product' },
        { id: 3, label: 'Checkout' },
      ],
      registerDomain: 'off',
      transferDomain: 'off',
      checkingDomainAvail: false,
      domainPrefix: null,
      domainProductId: null,
      tldModuleId: null,
      domainTld: null,
      selectedProduct: null,
      selectedProductId: null,
      defaultProductTypeId: null,
      productTypeId: null,
      products: [],
      defaultProducts: [],
      defaultAddons: [],
      oneTime: false,
      domainAvail: null,
      disabled: true,
      checkoutDisabled: true,
      moduleComponent: null,
      selectedProductPrice: null,
      productPriceList: [],
      validConfiguration: false,
      checkoutProcessing: false,
      moduleData: null,
      parentModuleData: null,
      addonData: null,
      domainChecked: false,
      moduleMap: {
        cpanel: Module.CpanelRegister,
        manual: Module.ManualRegister,
      },
      autorenew: true,
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
      successMessage: 'Your service is pending setup now. An email will be sent to you when your service is ready.',
      availableTlds: null,
      domainRegistration: false,
      domainTransfer: false,
      tldPriceList: [],
      transferTerms: [],
      transferTerm: null,
      parentProductTerms: [],
      showMigration: false,
      needMigration: 0,
    };
  },
  methods: {
    updateProductPrice() {
      let total = 0;
      this.productPriceList.forEach((priceTerm) => {
        if (parseInt(priceTerm.value, 0) === parseInt(this.selectedProductPrice, 0)) {
          total += +priceTerm.price;
        }
      });
      this.totalProduct = +total;
    },
    updateAddonPrice(amount) {
      this.totalAddon = +amount;
    },
    updateDomainPrice(amount) {
      this.totalDomainRegister = +amount;
      if (this.registerDomain === 'on') {
        this.totalDomain = this.totalDomainRegister;
      }
    },
    updateDomainTransferPrice() {
      let total = 0;
      this.transferTerms.forEach((priceTerm) => {
        if (parseInt(priceTerm.value, 0) === parseInt(this.transferTerm, 0)) {
          total += +priceTerm.price;
        }
      });
      this.totalDomainTransfer = +total;
      if (this.transferDomain === 'on') {
        this.totalDomain = this.totalDomainTransfer;
      }
    },
    typing() {
      this.domainChecked = false;
    },
    revalidate() {
      this.$refs.checkoutForm.validate();
    },
    showDomainRegistrationForm() {
      this.domainRegistration = false;
      this.domainChecked = false;
      const { moduleData } = this;
      if (moduleData && moduleData.domain) {
        const { domain } = moduleData;
        if (domain.indexOf('.') > 0) {
          const tld = domain.substr(domain.indexOf('.') + 1);
          this.domainTld = tld;
          if (this.$underscore.keys(this.availableTlds).indexOf(tld) < 0) {
            this.domainChecked = true;
          } else {
            this.$underscore.forEach(this.availableTlds, (tldModuleId, key) => {
              if (tld === key) {
                const domainPrefix = domain.substr(0, domain.indexOf('.'));
                this.domainPrefix = domainPrefix;
                this.tldModuleId = tldModuleId;
                this.checkingDomainAvail = true;
                this.$api.post('partner/domain/check-availability', {
                  domain: domainPrefix,
                  tld: tldModuleId,
                })
                  .then((response) => {
                    if (response.data.avail) {
                      this.domainAvail = response.data.avail;
                      this.checkRegistrationForm();
                    } else {
                      this.domainAvail = false;
                    }
                  })
                  .catch(() => {
                    this.domainAvail = false;
                  })
                  .finally(() => {
                    this.domainChecked = true;
                    this.checkingDomainAvail = false;
                  });
              }
            });
          }
        }
      }
    },
    nextStep() {
      this.addonComponentKey += 1;
      if (this.step < this.steps.length) {
        this.step += 1;
        this.disabled = true;
      }
    },
    backStep() {
      this.step -= 1;
    },
    selectProduct(product) {
      this.selectedProduct = product;
      this.selectedProductId = product.id;
      this.moduleComponent = this.moduleMap[product.module_name];
      if (product.module_name === 'cpanel') {
        this.showMigration = true;
      } else {
        this.showMigration = false;
      }
      this.$set(this, 'productPriceList', []);
      this.$set(this, 'parentProductTerms', []);
      this.$underscore.each(product.currencies, (value) => {
        if (parseInt(value.termMonth, 10) === 0) {
          this.oneTime = true;
          this.autorenew = false;
        } else {
          this.oneTime = false;
          this.autorenew = true;
        }
        const currentProductPrice = {
          text: `${value.termName}: $${value.productPrice} ($${value.productPriceMonthly}/month)`,
          value: value.productTermId,
          price: value.productPrice,
        };
        this.parentProductTerms.push(currentProductPrice.value);
        this.productPriceList.push(currentProductPrice);
        if (!this.selectedProductPrice) {
          this.selectedProductPrice = currentProductPrice.value;
          this.updateProductPrice();
        }
      });
      this.$set(this, 'parentModuleData', product.module_data);
      this.disabled = false;
      this.nextStep();
    },
    checkRegistrationForm() {
      if (this.registerDomain === 'on') {
        this.$refs.domainRegistrationForm.activate(this.domainPrefix, this.tldModuleId);
        this.domainRegistration = true;
      } else {
        this.domainRegistration = false;
      }
    },
    checkTransferForm() {
      if (this.transferDomain === 'on') {
        this.reloadTerm();
        this.domainTransfer = true;
      } else {
        this.domainTransfer = false;
      }
    },
    checkout() {
      this.checkoutProcessing = true;
      let domainRegistrationData = null;
      let domainTransferData = null;

      if (this.domainRegistration) {
        if (this.$refs.domainRegistrationForm.validateForm()) {
          domainRegistrationData = this.$refs.domainRegistrationForm.getFormData();
        } else {
          this.checkoutProcessing = false;
          return;
        }
      } else if (this.domainTransfer) {
        domainTransferData = {
          domain: this.domainPrefix,
          tld: this.tldModuleId,
          product_id: this.domainProductId,
          product_term: this.transferTerm,
        };
      }

      this.$api.post('partner/product/register', {
        product_id: this.selectedProductId,
        product_term: this.selectedProductPrice,
        domain: this.moduleData.domain,
        module_data: this.moduleData,
        auto_renew: this.autorenew,
        domain_registration: domainRegistrationData,
        domain_transfer: domainTransferData,
        addons: this.addonData,
        migration: this.needMigration,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: this.successMessage,
          });
          setTimeout(() => {
            this.reset();
          }, 1000);
        })
        .finally(() => {
          this.checkoutProcessing = false;
        });
    },
    reset() {
      this.componentKey += 1;
      this.addonComponentKey += 1;
      this.moduleComponentKey += 1;
      this.selectedProductId = null;
      this.productTypeId = 0;
      this.$set(this, 'products', this.defaultProducts[this.categories[0].value]);
      this.defaultProductTypeId = this.categories[0].value;
      this.autorenew = true;
      this.domainAvail = null;
      this.domainChecked = false;
      this.registerDomain = 'off';
      this.transferDomain = 'off';
      this.step = 1;
    },
    reloadTerm() {
      this.$set(this, 'transferTerms', []);
      this.$underscore.map(this.tldPriceList, (tld) => {
        if (tld.tld_id === this.tldModuleId || tld.tld === this.domainTld) {
          this.tldModuleId = tld.tld_id;
          this.domainProductId = tld.id;
          this.$underscore.map(tld.terms, (term, index) => {
            this.transferTerm = tld.terms[1].term_id;
            let yearPrice = index === '1' ? 'Year' : 'Years';
            yearPrice = `${yearPrice} $${term.price}`;
            this.transferTerms.push({
              text: `${index} ${yearPrice}`,
              value: term.term_id,
              price: term.price,
            });
          });
        }
      });
    },
    addonReady(addonNames) {
      console.log(addonNames);
      if (addonNames.indexOf('Migration') > -1) {
        this.showMigration = false;
      } else {
        this.showMigration = true;
      }
    },
  },
  watch: {
    transferDomain: {
      handler(newData) {
        if (newData === 'on') {
          this.updateDomainTransferPrice();
        } else {
          this.totalDomain = 0;
        }
      },
    },
    registerDomain: {
      handler(newData) {
        if (newData === 'on') {
          this.totalDomain = +this.totalDomainRegister;
        } else {
          this.totalDomain = 0;
        }
      },
    },
    totalProduct: {
      handler(newData) {
        this.total = +newData + +this.totalAddon + +this.totalDomain;
        this.total = +this.total.toFixed(2);
      },
    },
    totalAddon: {
      handler(newData) {
        this.total = +newData + +this.totalProduct + +this.totalDomain;
        this.total = +this.total.toFixed(2);
      },
    },
    totalDomain: {
      handler(newData) {
        if (this.registerDomain === 'on' || this.transferDomain === 'on') {
          this.total = +newData + +this.totalProduct + +this.totalAddon;
        } else {
          this.total = +this.totalProduct + +this.totalAddon;
        }
        this.total = +this.total.toFixed(2);
      },
    },
    productTypeId: {
      handler(newProductTypeId) {
        this.$set(this, 'products', this.defaultProducts[this.categories[newProductTypeId].value]);
        this.defaultProductTypeId = this.categories[newProductTypeId].value;
      },
    },
    validConfiguration: {
      handler(validConfiguration) {
        if (validConfiguration && this.domainChecked) {
          this.checkoutDisabled = false;
        } else {
          this.checkoutDisabled = true;
        }
      },
    },
    domainChecked: {
      handler(domainChecked) {
        if (this.validConfiguration && domainChecked) {
          this.checkoutDisabled = false;
        } else {
          this.checkoutDisabled = true;
        }
      },
    },
  },
};
</script>

<style scoped>
  .product-price .discounted-price {
    color: #FF670D !important;
  }
  .product-price .line-through {
    color: #677988 !important;
  }
  .product {
    cursor: pointer;
    min-height: 360px;
  }
  .product.on-hover .title {
    background-color: #FF670D !important;
  }
  .product-description {
  }
  .product-price {
    list-style-type: none;
    padding: 0;
    margin-left: -2px;
  }
  .special .product {
    min-height: 360px;
  }
  .product .title {
    font-size: 1.5rem !important;
    font-weight: bold;
    padding: 24px 24px;
    background-color: #0077ff !important;
    border-color: #0077ff !important;
    border-top-left-radius: 14px !important;
    border-top-right-radius: 14px !important;
    color: white;
  }
  .v-card__title+.v-card__subtitle {
    margin-top: 0;
  }
  .product-grids > .row > div > .v-card {
    height: 100%;
  }
  .product.v-sheet.v-card {
    border-radius: 20px;
    border: 2px solid rgba(0,119,255,1);
    box-shadow: none;
  }
  .product.v-sheet.v-card.on-hover {
    border: 2px solid rgba(255,103,13,1);
  }
  .product .v-card__subtitle, .product .v-card__text {
    padding: 0 24px 24px 24px;
  }
  .product .v-card__title + .v-card__subtitle {
    padding-top: 24px;
  }
  .product-price tr > td:nth-child(1) {
    width: 84px;
  }
</style>

export default class GceCommon {
  install = (Vue) => {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$gceCommon = {
      async getDropdown(url) {
        const list = [];
        try {
          const response = await Vue.prototype.$api.get(url);
          const remoteList = response.data;
          remoteList.forEach((value) => {
            list.push({
              text: value.text,
              value: value.value,
            });
          });
        } catch (e) {
          return [];
        }
        return list;
      },
      pageRefresh(router) {
        setTimeout(() => {
          router.go();
        }, 500);
      },
      getTermIdByTermMonth($termMonth) {
        const map = {
          0: 1,
          1: 2,
          3: 3,
          6: 4,
          12: 5,
          24: 6,
          36: 7,
          48: 8,
          60: 9,
          72: 10,
          84: 11,
          96: 12,
          108: 13,
          120: 14,
        };
        return map[$termMonth];
      },
    };
  };
}

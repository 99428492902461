var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.initiated)?_c('v-tabs',{staticClass:"product-type-tab"},_vm._l((_vm.productTypes),function(productType){return _c('v-tab',{key:productType.id},[_c('v-chip',{attrs:{"label":true,"x-small":"","color":"blue","text-color":"white"}},[_vm._v(_vm._s(productType.active_services))]),_vm._v(" "+_vm._s(productType.type_name)+" ")],1)}),1):_vm._e(),(!_vm.initiated)?_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"table"}}):_vm._e(),(_vm.initiated)?_c('v-row',{staticClass:"toolbar",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"v-btn-more",attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.gotoBuy()}}},[_vm._v(" Buy More")])],1),_c('v-col',{attrs:{"cols":"8","md":"4"}},[_c('v-text-field',{staticClass:"search-input",attrs:{"hide-details":"","prepend-icon":"mdi-text-search","single-line":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loadTable()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('v-btn',{staticClass:"v-btn-search",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.loadTable()}}},[_vm._v("Search")])],1)],1):_vm._e(),(_vm.initiated)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [15,30,60,100,150,250],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status_name",fn:function(ref){
      var item = ref.item;
return [(item.status_name == 'Active')?_c('b',{staticClass:"green--text"},[_vm._v(_vm._s(item.status_name))]):_c('b',{staticClass:"red--text"},[_vm._v(_vm._s(item.status_name))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(item.status_name === 'Active')?_c('v-btn',{attrs:{"x-small":"","color":"success"},on:{"click":function($event){return _vm.view(item)}}},[_vm._v(" Manage ")]):_vm._e(),_vm._v(" "),(item.status_name === 'Active' && _vm.$store.state.brand == 'cldy'
          )?_c('v-btn',{attrs:{"x-small":"","color":"success"},on:{"click":function($event){return _vm.editDns(item)}}},[_vm._v(" Edit DNS ")]):_vm._e(),_vm._v(" "),(item.status_name == 'Requesting Transfer Info')?_c('v-btn',{attrs:{"x-small":"","color":"success"},on:{"click":function($event){return _vm.transfer(item)}}},[_vm._v(" Transfer ")]):_vm._e(),_vm._v(" "),(item.status_name == 'Transfer Invalid EPP Code')?_c('v-btn',{attrs:{"x-small":"","color":"error"},on:{"click":function($event){return _vm.openEppCodeDialog(item)}}},[_vm._v(" Retry EPP Code ")]):_vm._e(),_vm._v(" "),(item.status_name == 'Active'
          || item.status_name == 'Expired')?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.openRenewDialog(item)}}},[_vm._v(" Renew ")]):_vm._e()]}},{key:"item.auto_renew",fn:function(ref){
          var item = ref.item;
return [(item.status_name == 'Active')?_c('v-switch',{attrs:{"loading":item.loading,"readonly":true},on:{"click":function($event){$event.stopPropagation();return _vm.toggleAutoRenew(item)}},model:{value:(item.auto_renew),callback:function ($$v) {_vm.$set(item, "auto_renew", $$v)},expression:"item.auto_renew"}}):_vm._e()]}}],null,false,3910947796)}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"1024"},model:{value:(_vm.submitNewEppCodeDialog),callback:function ($$v) {_vm.submitNewEppCodeDialog=$$v},expression:"submitNewEppCodeDialog"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"text","label":"EPP Code"},model:{value:(_vm.newEppCode),callback:function ($$v) {_vm.newEppCode=$$v},expression:"newEppCode"}})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.submitNewEppCodeDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.submitNewEppCodeLoading},on:{"click":function($event){return _vm.submitNewEppCode()}}},[_vm._v(" Submit New EPP Code ")])],1)],1)],1),(_vm.showServiceRenewDialog)?_c('service-renew-dialog',{attrs:{"service-id":_vm.serviceId},on:{"close-dialog":function($event){_vm.showServiceRenewDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
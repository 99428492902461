<template>
  <v-row
    no-gutters
  >
    <v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
            'items-per-page-options': [15,30,60,100,150,250],
          }"
      >
        <template v-slot:item.actions="{ item }">
          <div class="my-1">
            <v-btn
              x-small
              block
              color="primary"
              @click="view(item)"
            >
              View Stripe
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      headers: [
        { text: 'User Type', value: 'user_type' },
        { text: 'Firstname', value: 'first_name' },
        { text: 'Lastname', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Auth Date', value: 'created_at' },
        { text: 'Amount', value: 'amount' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      deleteDialog: false,
      deleteItem: null,
      deleteLoading: false,
      searchTitle: '',
      searchText: '',
    };
  },
  watch: {
    options: {
      handler() {
        this.loadTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/stripe-no-matching-payment/list', {
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    view(item) {
      window.location.href = `https://dashboard.stripe.com/payments/${item.payment_id}`;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row
    no-gutters
    dense
  >
    <v-col cols="12">
      <v-card
        outlined
      >
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="4">
                <v-text-field label="Bank" :value="withdraw.bank" readonly>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Account Name" :value="withdraw.account_name" readonly>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Account No. / PayNow" :value="withdraw.account_no" readonly>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-for="(commission, index) in commissions" :key="index">
              <v-col cols="12">
                <h2>{{ commission.description }} ${{ commission.amount }}</h2>
                <hr>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-text-field label="Reference No"
                              :rules="[rules.required]"
                              v-model="referenceNo" readonly disabled></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Transfer Datetime"
                              :rules="[rules.required]"
                              v-model="transferDatetime"
                              @click.prevent="openDatetimePicker($event)"
                              :readonly="true"
                              disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row no-gutters dense>
            <v-col cols="12" class="text-right">
              <h2>Transfer Amount: ${{ withdraw.amount }}</h2>
              <h2 class="text-success" v-if="withdrawn">Withdrawn</h2>
              <h2 class="text-warning" v-else>Pending</h2>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'CommissionWithdrawDetail',
  components: {},
  data() {
    return {
      customer: null,
      withdraw: null,
      commissions: null,
      referenceNo: '',
      transferDatetime: null,
      pickerTransferDatetime: null,
      loading: false,
      withdrawn: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
    };
  },
  created() {
    this.transferDatetime = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');
    this.pickerTransferDatetime = DateTime.local().toISO();
    this.$api.get(`admin/affiliate-commission/withdraw/${this.$route.params.id}`).then((response) => {
      this.customer = response.data.customer;
      this.withdraw = response.data.withdraw;
      this.commissions = response.data.commissions;
      this.referenceNo = this.withdraw.reference_no;
      this.transferDatetime = this.withdraw.transferred_at;
      if (this.referenceNo) {
        this.withdrawn = true;
      }
    });
  },
  methods: {
    reset() {
      this.$router.push({
        path: '/refresh',
        query: {
          path: this.$route.fullPath,
        },
      });
    },
    openDatetimePicker(event) {
      this.$refs.datetimePicker.open(event);
    },
  },
  watch: {
    pickerTransferDatetime: {
      handler(newPickerTransferDatetime) {
        if (newPickerTransferDatetime !== null) {
          this.transferDatetime = DateTime.fromISO(newPickerTransferDatetime).toFormat('yyyy-MM-dd HH:mm:ss');
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.datetime-picker input {
  visibility: hidden !important;
}
.text-success {
  color: green;
}
.text-warning {
  color: orange;
}
</style>

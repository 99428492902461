<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn x-small
                 v-if="item.user_type_id === 4"
                 @click="loginAsPartner(item.user_id)">User Login</v-btn>
          <v-btn x-small
                 v-if="item.user_type_id === 4"
                 @click="viewPartnerInvoice(item)">View Invoice</v-btn>
          <v-btn x-small
                 v-if="item.user_type_id === 5"
                 @click="loginAsPartnerClient(item.user_id)">User Login</v-btn>
          <v-btn x-small
                 v-if="item.user_type_id === 5"
                 @click="viewPartnerClientInvoice(item)">Manage Invoice</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AuditUnpaidInvoices',
  data() {
    return {
      search: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'Invoice NO', value: 'invoice_no', sortable: true },
        { text: 'Email', value: 'email', sortable: true },
        { text: 'First Name', value: 'customer_first_name', sortable: true },
        { text: 'Last Name', value: 'customer_last_name', sortable: true },
        { text: 'Company Name', value: 'customer_company_name', sortable: true },
        { text: 'Invoice Date', value: 'invoice_datetime', sortable: true },
        { text: 'Invoice Status', value: 'status_name', sortable: true },
        { text: 'Subtotal', value: 'subtotal', sortable: true },
        { text: 'Tax', value: 'tax', sortable: true },
        { text: 'Discount', value: 'discount', sortable: true },
        { text: 'Total', value: 'total', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    this.initiated = true;
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/tax-invoice/list-unpaid', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        product_type_id: this.productTypeId,
        order: `${sortBy}:${sortDesc}`,
      })
        .then((response) => {
          this.totalItems = response.data.total;
          this.items = response.data.data;
          this.loading = false;
        });
    },
    view(invoiceId) {
      const authToken = window.btoa(this.$store.state.user.auth_token);
      window.open(`${process.env.VUE_APP_API_URL}/v1/partner/tax-invoice/${invoiceId}/view?auth_token=${authToken}`, '_blank');
    },
    download(invoiceId) {
      const authToken = window.btoa(this.$store.state.user.auth_token);
      window.open(`${process.env.VUE_APP_API_URL}/v1/partner/tax-invoice/${invoiceId}/download?auth_token=${authToken}`, '_blank');
    },
    loginAsPartner(userId) {
      this.$api.post(`admin/partner/${userId}/login`, {})
        .then((response) => {
          if (response.data.user) {
            const partner = response.data.user;
            partner.auth_token = `${userId}|${this.$store.state.user.auth_token}`;
            this.$store.dispatch('setCurrentAdminUser');
            this.$store.dispatch('setCurrentUser', partner);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({ name: `${this.$store.state.user.role}.Home` });
          }
        });
    },
    loginAsPartnerClient(userId) {
      this.$api.post(`partner/customer/${userId}/login`, {})
        .then((response) => {
          if (response.data) {
            window.location.href = response.data;
          }
        });
    },
    viewPartnerInvoice(item) {
      this.$api.post(`admin/partner/${item.user_id}/login`, {})
        .then((response) => {
          if (response.data.user) {
            const partner = response.data.user;
            partner.auth_token = `${item.user_id}|${this.$store.state.user.auth_token}`;
            this.$store.dispatch('setCurrentAdminUser');
            this.$store.dispatch('setCurrentUser', partner);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            window.open(`${process.env.VUE_APP_API_URL}/v1/partner/tax-invoice/${item.id}/view?auth_token=${item.view_token}`, '_blank');
          }
        });
    },
    viewPartnerClientInvoice(item) {
      this.$api.post(`admin/partner/${item.parent_id}/login`, {})
        .then((response) => {
          if (response.data.user) {
            const partner = response.data.user;
            partner.auth_token = `${item.parent_id}|${this.$store.state.user.auth_token}`;
            this.$store.dispatch('setCurrentAdminUser');
            this.$store.dispatch('setCurrentUser', partner);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({ name: 'Partner.ResellerCustomerInvoiceDetail', params: { id: item.id } });
          }
        });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-autocomplete
    dense
    v-model="partners"
    :items="partnerList"
    :label="label"
    :multiple="!single"
    :chips="!nochips"
    :rules="rules"
    @change="emitUpdate"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'PartnerDropdown',
  data() {
    return {
      partners: null,
      partnerList: [],
    };
  },
  created() {
    this.loadPartners();
    if (typeof this.defaultPartners !== 'undefined') {
      this.$set(this, 'partners', this.defaultPartners);
    }
  },
  mounted() {
    this.emitUpdate();
  },
  methods: {
    loadPartners() {
      this.$api.get('admin/partner/drop-down').then((response) => {
        const list = response.data;
        list.forEach((value) => {
          this.partnerList.push({
            text: `${value.first_name} ${value.last_name}|${value.email}|${value.company_name}`,
            value: value.id,
          });
        });
      });
    },
    emitUpdate() {
      this.$emit('update:partners', this.partners);
    },
  },
  props: ['label', 'rules', 'defaultPartners', 'single', 'nochips'],
  watch: {
    defaultPartners: {
      handler(newPartners) {
        this.partners = newPartners;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

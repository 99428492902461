<template>
  <v-row
    no-gutters
    dense
  >
    <v-col cols="12">
      <v-card
        outlined
      >
        <v-card-text>
          <v-row no-gutters dense>
            <v-col cols="12">
              <iframe v-if="invoiceNo"
                      :src="invoiceUrl"
                      width="100%" height="800px"></iframe>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TaxInvoice',
  data() {
    return {
      invoiceNo: '',
      viewToken: '',
      invoiceUrl: '',
    };
  },
  created() {
    this.$api.get(`partner/tax-invoice/${this.$route.params.id}/detail`).then((response) => {
      this.invoiceNo = response.data.invoiceNo;
      this.viewToken = response.data.viewToken;
      this.invoiceUrl = `${process.env.VUE_APP_API_URL}/v1/tax-invoice/${this.invoiceNo}/view?view_token=${this.viewToken}&system_currency=${window.systemCurrency}`;
      const invoiceItems = [];

      response.data.invoiceItems.forEach((invoiceItem) => {
        invoiceItems.push({
          item_id: invoiceItem.id,
          item_name: `${response.data.invoiceDatetime} ${invoiceItem.invoiceLineDescription}`,
          price: invoiceItem.subtotal,
          discount: invoiceItem.discount,
          tax: invoiceItem.tax,
          quantity: 1,
        });
      });

      if (this.$route.params.first && this.$store.state.brand === 'cldy') {
        // eslint-disable-next-line no-undef
        window.gtag('event', 'purchase', {
          currency: window.systemCurrency.toUpperCase(),
          value: response.data.total,
          discount: response.data.discount,
          tax: response.data.tax,
          items: invoiceItems,
        });
      }
    });
  },
};
</script>

<template>
  <v-autocomplete
    dense
    v-model="products"
    :items="productList"
    :label="label"
    :multiple="!single"
    :chips="!nochips"
    :rules="rules"
    @change="emitUpdate"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'ProductSelect',
  data() {
    return {
      products: null,
      productList: [],
    };
  },
  mounted() {
    let url = 'admin/product/drop-down';
    if (this.module_id) {
      url += `?product_module_id=${this.module_id}`;
    }
    this.$api.get(url).then((response) => {
      const list = response.data;
      list.forEach((value) => {
        this.productList.push({
          text: `#${value.id}|${value.product_name}`,
          value: value.id,
        });
      });
    });
    this.emitUpdate();
  },
  methods: {
    emitUpdate() {
      this.$emit('update:products', this.products);
    },
  },
  props: ['label', 'rules', 'defaultProducts', 'single', 'nochips', 'module_id'],
  watch: {
    defaultProducts(newValue) {
      this.$set(this, 'products', newValue);
    },
  },
};
</script>

<style scoped>

</style>

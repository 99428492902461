<template>
  <v-row
    no-gutters
  >
    <v-col>
      <v-expansion-panels
        v-model="searchOpen"
        multiple>
        <v-expansion-panel
        >
          <v-expansion-panel-header>Advance Search</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field v-model="quickSearch"
                          @keyup.enter.native="search()"
                          label="Quick wildcard search company, name and email"></v-text-field>
            <v-btn depressed color="primary"
              @click="search()"
            >Search</v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editPartner(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="loginAsPartner(item)"
            :loading="loginAsPartnerLoading[item.id]"
          >
            mdi-door
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="sendWelcomeEmail(item)"
          >
            mdi-email
          </v-icon>
          <v-icon
            small
            @click="deletePartnerConfirm(item)"
          >
            mdi-delete
          </v-icon>
          <br>
          <v-btn
            x-small
            @click="gotoManageProductMemberPrice(item)">Product Member Prices</v-btn>
          <v-btn
            x-small
            @click="gotoManageAddonMemberPrice(item)">Addon Member Prices</v-btn>
          <v-btn
            x-small
            @click="gotoManageDomainProductMemberPrice(item)">Domain Product Member Prices</v-btn>
          <v-btn
            x-small
            @click="updateAuthUsers(item)">Update Auth Users</v-btn>
          <v-btn
            x-small
            @click="gotoMemberOnlyRetailProducts(item)"
          >Update Retail Client Only Product Availability</v-btn>
        </template>
      </v-data-table>
      <v-dialog
        v-model="deletePartnerDialog"
        max-width="320"
      >
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>Are you sure to delete the partner?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="deletePartnerDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              :loading="deleteLoading"
              :disabled="deleteLoading"
              @click="deletePartner"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'List',
  data() {
    return {
      searchOpen: [0],
      quickSearch: '',
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      loginAsPartnerLoading: {},
      headers: [
        {
          text: 'Partner ID',
          align: 'start',
          value: 'id',
        },
        { text: 'Email', value: 'email' },
        { text: 'Balance', value: 'balance' },
        { text: 'Locked Balance', value: 'locked_balance' },
        { text: 'Credit Limit', value: 'credit_limit' },
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Company Name', value: 'company_name' },
        { text: 'Phone', value: 'phone_number', sortable: false },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      deletePartnerDialog: false,
      deleteCandidate: null,
      deleteLoading: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.loadTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    search() {
      this.loadTable({
        quick_search: this.quickSearch,
      });
    },
    loadTable(searchFields = {}) {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/partner/list', {
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
        search: searchFields,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    editPartner(item) {
      this.$router.push({ name: 'Admin.PartnerDetail', params: { id: item.id } });
    },
    gotoManageProductMemberPrice(item) {
      this.$router.push({ name: 'Admin.PartnerProductMemberPrices', params: { id: item.id } });
    },
    gotoManageAddonMemberPrice(item) {
      this.$router.push({ name: 'Admin.PartnerAddonMemberPrices', params: { id: item.id } });
    },
    gotoManageDomainProductMemberPrice(item) {
      this.$router.push({ name: 'Admin.PartnerDomainProductMemberPrices', params: { id: item.id } });
    },
    updateAuthUsers(item) {
      this.$router.push({ name: 'Admin.UpdateAuthUsers', params: { id: item.id } });
    },
    gotoMemberOnlyRetailProducts(item) {
      this.$router.push({ name: 'Admin.MemberOnlyRetailProducts', params: { id: item.id } });
    },
    deletePartnerConfirm(item) {
      this.deletePartnerDialog = true;
      this.deleteCandidate = item;
    },
    deletePartner() {
      this.deleteLoading = true;
      this.$api.delete(`admin/partner/${this.deleteCandidate.id}`).finally(() => {
        this.deletePartnerDialog = false;
        this.loadTable();
      });
    },
    loginAsPartner(item) {
      this.loginAsPartnerLoading[item.id] = true;
      this.$api.post(`admin/partner/${item.id}/login`, {})
        .then((response) => {
          if (response.data.user) {
            const partner = response.data.user;
            partner.auth_token = `${item.id}|${this.$store.state.user.auth_token}`;
            this.$store.dispatch('setCurrentAdminUser');
            this.$store.dispatch('setCurrentUser', partner);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({ name: `${this.$store.state.user.role}.Home` });
          }
        })
        .finally(() => {
          this.loginAsPartnerLoading[item.id] = false;
        });
    },
    sendWelcomeEmail(item) {
      // eslint-disable-next-line no-alert
      const r = window.confirm('Are you sure to send welcome email to the partner?');
      if (r === true) {
        this.$api.post(`admin/partner/${item.id}/send-welcome-email`, {})
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Welcome email is sent successfully.',
            });
          });
      }
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row no-gutters dense>
    <v-col cols="6" offset="3">
      <v-form ref="form" v-model="valid">
        <v-card outlined>
          <v-card-text>
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="12">
                    <ServerGroupDropdown
                      :serverGroupId.sync="form.server_group_id"
                      :defaultServerGroupId="defaultServerGroupId"
                    >
                    </ServerGroupDropdown>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" >
                    <v-text-field dense
                                  label="Server Name"
                                  v-model="form.name"
                                  :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field dense
                                  label="Hostname"
                                  v-model="form.hostname"
                                  :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      v-model="form.ip"
                      label="Primary IP"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      label="Username"
                      v-model="form.username"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      label="Password"
                      v-model="form.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required]"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-checkbox
                      dense
                      label="Active?"
                      v-model="form.active"
                      required
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      color="primary"
                      :loading="processing"
                      :disabled="processing"
                      @click="submitForm"
                    >Update</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import ServerGroupDropdown from '../../components/ServerGroupDropdown.vue';

export default {
  name: 'Detail',
  components: {
    ServerGroupDropdown,
  },
  mounted() {
    this.$api.get(`admin/server/${this.$route.params.id}`).then((response) => {
      this.form = response.data;
      this.defaultServerGroupId = response.data.server_group_id;
    });
  },
  data() {
    return {
      form: {
        name: '',
        server_group_id: null,
        hostname: '',
        ip: '',
        username: '',
        password: '',
        active: true,
      },
      defaultServerGroupId: null,
      showPassword: false,
      successMessage: 'Server is successfully updated.',
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
      valid: false,
    };
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$api.put(`admin/server/${this.$route.params.id}`, this.form)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: this.successMessage,
            });
          })
          .catch((error) => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data || error.message,
            });
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
  },
};
</script>

<style scoped>

</style>

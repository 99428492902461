<template>
  <v-row
    no-gutters
    dense
  >
    <v-col cols="12">
      <v-card
        outlined
      >
        <v-card-text>
          <v-row no-gutters dense>
            <v-col cols="12">
              <business-card-iframe :vcardUuid="vcardUuid"></business-card-iframe>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import BusinessCardIframe from '../../../components/BusinessCardIframe.vue';

export default {
  name: 'BusinessCardGenerator',
  components: {
    BusinessCardIframe,
  },
  data() {
    return {
      vcardUuid: false,
    };
  },
  created() {
    if (this.$route.params.uuid) {
      this.vcardUuid = this.$route.params.uuid;
    }

    window.addEventListener(
      'message',
      (event) => {
        if (event.data.error) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: event.data.error,
          });
        } else if (event.data.cardurl) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            raw: 1,
            type: 'success',
            message: `Your business card is ready. <a target="_blank" href="${event.data.cardurl}">${event.data.cardurl}</a>`,
          });
        }
      },
      false,
    );
  },
};
</script>

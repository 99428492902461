<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="12">
                  <h3>Service Configuration</h3>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4" v-if="moduleData.hostname !== null
        && moduleData.hostname.length > 0">
                  <v-text-field
                    label="Server"
                    v-model="moduleData.hostname"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="4" v-if="moduleData.domain !== null
        && moduleData.domain.length > 0">
                  <v-text-field
                      label="Cpanel Domain"
                      v-model="moduleData.domain"
                      :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="4" v-if="moduleData.username !== null
        && moduleData.username.length > 0">
                  <v-text-field
                      label="Cpanel Username"
                      v-model="moduleData.username"
                      :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="4" v-if="moduleData.ip !== null
        && moduleData.ip.length > 0">
                  <v-text-field
                      label="Cpanel IP"
                      v-model="moduleData.ip"
                      :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="4" v-if="ns">
                  <v-text-field
                    label="Cpanel Nameservers"
                    v-model="ns"
                    readonly></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Manage',
  data() {
    return {
      ns: false,
      moduleData: {
        domain: '',
        username: '',
        ip: '',
        server_id: '',
        hostname: '',
        isAdmin: false,
      },
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || 'This field must be valid domain name.',
      },
    };
  },
  mounted() {
    this.$underscore.each(this.parentModuleData, (propV, prop) => {
      if (typeof this.moduleData[prop] !== 'undefined') {
        this.$set(this.moduleData, prop, propV);
      }
    });

    if (this.$store.state.brand !== 'cldy') {
      if (this.moduleData.hostname.indexOf('web1') === 0
        || this.moduleData.hostname.indexOf('web2') === 0) {
        this.ns = 'ns1.gocloudeasy.com ns2.gocloudeasy.com ns3.gocloudeasy.com';
      } else {
        this.ns = 'ns4.gocloudeasy.com ns5.gocloudeasy.com ns6.gocloudeasy.com';
      }
    } else if (this.moduleData.hostname.indexOf('web1') === 0
      || this.moduleData.hostname.indexOf('web2') === 0) {
      if (window.systemCurrency && window.systemCurrency === 'aud') {
        this.ns = 'ns1.au.cldy.com ns2.au.cldy.com ns3.au.cldy.com';
      } else {
        this.ns = 'ns1.cldy.com ns2.cldy.com ns3.cldy.com';
      }
    } else if (window.systemCurrency && window.systemCurrency === 'aud') {
      this.ns = 'ns1.au.cldy.com ns2.au.cldy.com ns3.au.cldy.com';
    } else {
      this.ns = 'ns4.cldy.com ns5.cldy.com ns6.cldy.com';
    }
  },
  props: {
    readonly: {
      type: Boolean,
      default() {
        return true;
      },
    },
    parentModuleData: {
      type: Object,
      default() {
        return {
          domain: '',
          username: '',
          ip: '',
          server_id: '',
          hostname: '',
          isAdmin: false,
        };
      },
    },
  },
  watch: {
    moduleData: {
      handler(newModuleData) {
        this.$emit('update:moduleData', newModuleData);
      },
      deep: true,
    },
    parentModuleData: {
      handler(newParentModuleData) {
        this.$underscore.each(newParentModuleData, (propV, prop) => {
          if (typeof this.moduleData[prop] !== 'undefined') {
            this.$set(this.moduleData, prop, propV);
          }
        });

        if (this.$store.state.brand !== 'cldy') {
          if (this.moduleData.hostname.indexOf('web1') === 0
            || this.moduleData.hostname.indexOf('web2') === 0) {
            this.ns = 'ns1.gocloudeasy.com ns2.gocloudeasy.com ns3.gocloudeasy.com';
          } else {
            this.ns = 'ns4.gocloudeasy.com ns5.gocloudeasy.com ns6.gocloudeasy.com';
          }
        } else if (this.moduleData.hostname.indexOf('web1') === 0
            || this.moduleData.hostname.indexOf('web2') === 0) {
          this.ns = 'ns1.cldy.com ns2.cldy.com ns3.cldy.com';
        } else {
          this.ns = 'ns4.cldy.com ns5.cldy.com ns6.cldy.com';
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

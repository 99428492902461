<template>
  <v-row
    no-gutters
  >
    <v-col>
      <v-toolbar dense :flat="true">
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              hide-details
              prepend-icon="mdi-text-search"
              single-line
              v-model="search"
              @keyup.enter.native="loadTable()"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn small color="primary" @click="loadTable()">Search</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editPartner(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="loginAsPartner(item)"
            :loading="loginAsPartnerLoading[item.id]"
          >
            mdi-door
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="sendWelcomeEmail(item)"
          >
            mdi-email
          </v-icon>
          <br>
          <v-btn
            x-small
            @click="gotoManageProductMemberPrice(item)">Product Member Prices</v-btn>
          <v-btn
            x-small
            @click="gotoManageAddonMemberPrice(item)">Addon Member Prices</v-btn>
          <v-btn
            x-small
            @click="gotoManageDomainProductMemberPrice(item)">Domain Product Member Prices</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'List',
  data() {
    return {
      search: '',
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      loginAsPartnerLoading: {},
      headers: [
        {
          text: 'Customer ID',
          align: 'start',
          value: 'id',
        },
        { text: 'Email', value: 'email' },
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Company Name', value: 'company_name' },
        { text: 'Phone', value: 'phone_number', sortable: false },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      deletePartnerDialog: false,
      deleteCandidate: null,
      deleteLoading: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.loadTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('partner/customer/list', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    editPartner(item) {
      this.$router.push({ name: 'Partner.ResellerCustomerDetail', params: { id: item.id } });
    },
    gotoManageProductMemberPrice(item) {
      this.$router.push({ name: 'Partner.ResellerMemberProductPrices', params: { id: item.id } });
    },
    gotoManageAddonMemberPrice(item) {
      this.$router.push({ name: 'Partner.ResellerMemberAddonPrices', params: { id: item.id } });
    },
    gotoManageDomainProductMemberPrice(item) {
      this.$router.push({ name: 'Partner.ResellerMemberDomainProductPrices', params: { id: item.id } });
    },
    loginAsPartner(item) {
      this.loginAsPartnerLoading[item.id] = true;
      this.$api.post(`partner/customer/${item.id}/login`, {})
        .then((response) => {
          if (response.data) {
            if (this.$store.state.adminUser.auth_token) {
              window.open(`${response.data}&adminToken=${this.$store.state.adminUser.auth_token}`);
            } else {
              window.open(response.data);
            }
          }
        })
        .finally(() => {
          this.loginAsPartnerLoading[item.id] = false;
        });
    },
    sendWelcomeEmail(item) {
      // eslint-disable-next-line no-alert
      const r = window.confirm('Are you sure to send welcome email to the customer?');
      if (r === true) {
        this.$api.post(`partner/customer/${item.id}/send-welcome-email`, {})
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Welcome email is sent successfully.',
            });
          });
      }
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row dense>
    <v-col cols="12">
      <v-toolbar dense :flat="true">
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              hide-details
              prepend-icon="mdi-text-search"
              single-line
              v-model="searchKeywords"
              @keyup.enter.native="search()"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn small color="primary" @click="search()">Search</v-btn>
          </v-col>
          <v-col cols="2">
            <v-select
              label="Expire Within Days"
              v-model="expiryWithin" :items="[30, 60, 90, 180, 'all']" @change="reloadTable">
            </v-select>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :dense="true"
        :headers="headers"
        :fixed-header="true"
        :items="domains"
        :disable-pagination="true"
        :hide-default-footer="true"
        show-select
        v-model="selectedDomains"
        height="calc(100vh - 68px - 130px - 24px)"
      >
        <template v-slot:item.product_prices="{ item }">
          <v-select
            v-model="item.default_term"
            :items="item.product_prices"
            @change="setSelectedItem()"
          ></v-select>
          <v-row dense v-if="item.addons.length > 0">
            <h4>Addons:</h4>
          </v-row>
          <v-row dense v-for="addon in item.addons" :key="addon.id">
            <v-col cols="12">
              <v-select
                :label="addon.service_identity"
                v-model="item.default_term"
                :items="addon.product_prices"
                :readonly="true"
              ></v-select>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" class="v-bottom-navigation--fixed text-right">
      <h3>Renewal Total: ${{ total }}</h3>
      <v-btn color="primary" :loading="processing" :disabled="!canRenew"
             @click="renew()">Renew Now</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { all, create } from 'mathjs';

export default {
  name: 'BulkRenew',
  data() {
    return {
      expiryWithin: 60,
      headers: [
        {
          text: 'Domain',
          align: 'start',
          sortable: false,
          value: 'service_identity',
        },
        {
          text: 'Expiry',
          align: 'start',
          sortable: false,
          value: 'expiry_date',
        },
        {
          text: 'Renew Term',
          align: 'start',
          sortable: false,
          value: 'product_prices',
        },
      ],
      domains: [],
      initialDomains: [],
      selectedDomains: [],
      processing: false,
      canRenew: false,
      total: 0.00,
      searchKeywords: '',
    };
  },
  created() {
    this.reloadTable();
  },
  methods: {
    reloadTable() {
      this.$api.get(`partner/domain/pending-renewal?expiry_within=${this.expiryWithin}`).then((response) => {
        this.$set(this, 'domains', response.data);
        this.domains.forEach((service, index) => {
          service.product_prices.forEach((price) => {
            if (parseInt(price.term_month, 0) === service.service_month_term) {
              this.domains[index].default_term = price.value;
            }
          });
        });
        this.$set(this, 'initialDomains', response.data);
      });
    },
    renew() {
      this.processing = true;
      const postSelectedServices = [];
      this.selectedDomains.forEach((selectedService) => {
        postSelectedServices.push(selectedService);
        if (selectedService.addons.length > 0) {
          selectedService.addons.forEach((addon) => {
            const localAddon = addon;
            localAddon.default_term = selectedService.default_term;
            postSelectedServices.push(localAddon);
          });
        }
      });
      this.$api.post('partner/domain/bulk-renew', {
        domains: postSelectedServices,
      }).then(() => {
        const successMessage = 'Your domain are successfully added to the renewal queue.';
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: successMessage,
        });
        setTimeout(() => {
          this.$router.go(0);
        }, 500);
      }).finally(() => {
      });
    },
    setSelectedItem() {
      const config = {};
      const math = create(all, config);
      this.total = 0;

      this.selectedDomains.forEach((service) => {
        service.product_prices.forEach((price) => {
          if (price.value === service.default_term) {
            this.total = math.format(math.add(this.total, price.product_price), {
              precision: 14,
            });
          }
        });

        service.addons.forEach((addon) => {
          addon.product_prices.forEach((price) => {
            if (price.value === service.default_term) {
              this.total = math.format(math.add(this.total, price.product_price), {
                precision: 14,
              });
            }
          });
        });
      });
    },
    search() {
      if (this.searchKeywords !== '') {
        this.$set(this, 'domains',
          this.initialDomains.filter((service) => service.service_identity
            .toLowerCase()
            .includes(this.searchKeywords.toLowerCase()) === true));
      } else {
        this.$set(this, 'domains', this.initialServices);
      }
    },
  },
  watch: {
    selectedDomains: {
      handler(newSelectedDomains) {
        if (newSelectedDomains.length > 0) {
          this.canRenew = true;
        } else {
          this.canRenew = false;
        }
        this.setSelectedItem();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
  .scroller {
    height: calc(100vh - 68px - 48px - 10px)
  }
</style>

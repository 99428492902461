<template>
  <v-row dense no-gutters>
    <v-col cols="12" v-if="ccLinkages.length > 0">
      <v-row dense no-gutters>
        <v-col cols="12">
          <h3>These are the list of email address that will receive a copy of all the
            emails our system sends out:
          </h3>
        </v-col>
        <v-col cols="12" v-for="ccLinkage in ccLinkages" :key="ccLinkage.id">
          {{ ccLinkage.user_linkage_value }} <v-btn
          x-small
          color="error"
          @click="deleteUserLinkage(ccLinkage.id)">Delete</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="emailLinkages.length > 0">
      <v-row dense no-gutters>
        <v-col cols="12">
          <h3>These are the list of email address that are authorised to obtain hosting
            account password reset and hosting administrative changes:
          </h3>
        </v-col>
        <v-col cols="12" v-for="emailLinkage in emailLinkages" :key="emailLinkage.id">
          {{ emailLinkage.user_linkage_value }} <v-btn
          x-small
          color="error"
          @click="deleteUserLinkage(emailLinkage.id)">Delete</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="ssoLinkages.length > 0">
      <v-row dense no-gutters>
        <v-col cols="12">
          <h3>These are the list of email address which has the ability to use their SSO
            account to access this billing portal:
          </h3>
        </v-col>
        <v-col cols="12" v-for="ssoLinkage in ssoLinkages" :key="ssoLinkage.id">
          {{ ssoLinkage.user_linkage_value }} <v-btn
          x-small
          color="error"
          @click="deleteUserLinkage(ssoLinkage.id)">Delete</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="phoneLinkages.length > 0">
      <v-row dense no-gutters>
        <v-col cols="12">
          <h3>These are the lsit of numbers that are authorised to call:
          </h3>
        </v-col>
        <v-col cols="12" v-for="phoneLinkage in phoneLinkages" :key="phoneLinkage.id">
          {{ phoneLinkage.user_linkage_value }} <v-btn
          x-small
          color="error"
          @click="deleteUserLinkage(phoneLinkage.id)">Delete</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense no-gutters>
        <v-col cols="4">
          <br><br>
          <h4>Add new auth user</h4>
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col cols="4">
          <v-select
            v-model="linkageType"
            label="User Type"
            :items="linkageTypes"
          ></v-select>
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col cols="4">
          <v-text-field
            v-if="linkageType === 'phone'"
            label="Phone"
            v-model="linkageValue">
          </v-text-field>
          <v-text-field
            v-else
            label="Email"
            v-model="linkageValue">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col cols="4"><v-btn
          color="success"
          @click="addUserLinkage">Add</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'UpdateAuthUsers',
  created() {
    this.$api.get(`admin/partner/${this.$route.params.id}`).then((response) => {
      this.setProfile(response.data);
    });
  },
  data() {
    return {
      linkageTypes: [
        {
          text: 'SSO User',
          value: 'sso',
        },
        {
          text: 'CC User (CC system notification)',
          value: 'cc',
        },
        {
          text: 'Email Support User (Valid email that can ask for ticket support)',
          value: 'email',
        },
        {
          text: 'Phone Support User (Valid phone that can ask for ticket support)',
          value: 'phone',
        },
      ],
      linkageType: 'email',
      linkageValue: '',
      processing: false,
      profile: null,
      newPassword: '',
      showPassword: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        strongpassword: (value) => this.checkPasswordStrong(value)
          || 'You need at least 1 upper case character, 1 digits, 1 special symbol [?!@#$%^&*:;.,] and minimum length 10',
      },
      valid: false,
      emailLinkages: [],
      ccLinkages: [],
      ssoLinkages: [],
      phoneLinkages: [],
    };
  },
  methods: {
    setProfile(profile) {
      this.profile = profile;
      if (profile.linkages.sso) {
        this.ssoLinkages = profile.linkages.sso;
      }

      if (profile.linkages.email) {
        this.emailLinkages = profile.linkages.email;
      }

      if (profile.linkages.cc) {
        this.ccLinkages = profile.linkages.cc;
      }

      if (profile.linkages.phone) {
        this.phoneLinkages = profile.linkages.phone;
      }
    },
    deleteUserLinkage(id) {
      this.$api.delete(`admin/partner/${this.$route.params.id}/linkage/${id}`)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'User is deleted successfully.',
          });

          setTimeout(() => {
            window.location.reload();
          }, 800);
        });
    },
    addUserLinkage() {
      this.$api.post(`admin/partner/${this.$route.params.id}/linkage`, {
        linkage_type: this.linkageType,
        linkage_value: this.linkageValue,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'User is added successfully.',
          });

          setTimeout(() => {
            window.location.reload();
          }, 800);
        });
    },
  },
};
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader
        ref="skeleton"
        v-if="!showPage"
        type="card"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-row dense align="center" v-if="showPage">
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <h3>{{ form.service_name }} &nbsp; {{ form.domain }}</h3>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <h5>Status:<br>{{ form.status_name }}</h5>
            </v-col>
            <v-col cols="3">
              <h5>Registration Date:<br>{{ form.provision_date }}</h5>
            </v-col>
            <v-col cols="3">
              <h5>Expiry Date:<br>{{ form.expiry_date }}</h5>
            </v-col>
            <v-col cols="3">
              <div class="my-1" v-if="service.has_reset_password">
                <v-btn x-small color="success" @click="resetPassword()">RESET PASSWORD</v-btn>
              </div>
              <div class="my-1" v-if="service.has_login">
                <v-btn x-small color="success" @click="login(id)">Login Cpanel</v-btn>
              </div>
              <div class="my-1">
                <v-btn x-small color="success" @click="updateTag(service.tag)">UPDATE TAG</v-btn>
              </div>
              <div class="my-1">
                <v-btn x-small color="primary" @click="renew(id)">RENEW</v-btn>
              </div>
              <div class="my-1">
                <v-btn x-small color="primary" @click="upgrade(id)">UPGRADE/DOWNGRADE</v-btn>
              </div>
              <div class="my-1">
                <v-btn x-small color="error" @click="cancel(id)">CANCEL</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="3">
              <v-switch v-model="form.auto_renew" label="Auto Renew"
                        :loading="autoRenewLoading"
                        :readonly="true"
                        @click.stop="toggleAutoRenew()"
              ></v-switch>
            </v-col>
          </v-row>
          <manual-manage
            v-if="form.module_name == 'manual'"
            :moduleData.sync="form.module_data"
            :parentModuleData="parentModuleData"
            :readonly="false"
            :hide-admin-info="true"
          >
          </manual-manage>
          <cpanel-manage
            v-if="form.module_name == 'cpanel'"
            :moduleData.sync="form.module_data"
            :parentModuleData="parentModuleData"
            :readonly="false"
            :hide-admin-info="true"
          >
          </cpanel-manage>
          <v-row
            v-for="addon in this.service.addons"
            :key="addon.id"
          >
            <v-col>
              <v-card>
                <v-card-text>
                  <addon
                    :service-id="addon.id"
                    :parent-id="id"
                    @renew="renew"
                    @upgrade="upgrade"
                  ></addon>
                </v-card-text>
              </v-card></v-col></v-row>
          <v-row>
            <v-col>
              <addon-registration
                :key="'arck' + addonRegistrationComponentKey"
                v-on:addonchecked="revalidate()"
                :addonData.sync="addonData"
                :parentServiceId="id"
                :parentTermId="serviceTermId"
                :parentProductTerms="parentProductTerms"
                v-on:addonPriceUpdate="updateAddonPrice"
              ></addon-registration>
              <v-row dense v-if="totalAddon > 0">
                <v-col cols="11"></v-col>
                <v-col cols="1">
                  <v-btn
                    text-align="right"
                    color="primary"
                    @click="buyAddons()"
                    :disabled="totalAddon === 0"
                    :loading="buying"
                  >Buy ${{ totalAddon }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <service-renew-dialog
        v-if="showServiceRenewDialog"
        v-on:close-dialog="showServiceRenewDialog = false"
        :service-id="renewServiceId"
      ></service-renew-dialog>
      <service-upgrade-downgrade-dialog
        v-if="showServiceUpgradeDialog"
        v-on:close-dialog="showServiceUpgradeDialog = false"
        :service-id="upgradeServiceId"
      ></service-upgrade-downgrade-dialog>
      <service-cancel-dialog
        v-if="showServiceCancelDialog"
        v-on:close-dialog="showServiceCancelDialog = false"
        :service-id="cancelServiceId"
      ></service-cancel-dialog>
      <service-tag-dialog
        v-if="showServiceTagDialog"
        v-on:close-dialog="showServiceTagDialog = false"
        :service-id="id"
        :tag="tag"
      ></service-tag-dialog>
      <service-reset-password-dialog
        v-if="showServiceResetPasswordDialog"
        v-on:close-dialog="showServiceResetPasswordDialog = false"
        :service-id="id"
      ></service-reset-password-dialog>
    </v-col>
  </v-row>
</template>

<script>
import CpanelManage from '../../components/provisioning_modules/cpanel/Manage.vue';
import ManualManage from '../../components/provisioning_modules/manual/Manage.vue';
import AddonRegistration from '../../components/Addon.vue';
import Addon from '../../components/ServiceAddon.vue';
import ServiceRenewDialog from '../../components/dialogs/ServiceRenewDialog.vue';
import ServiceUpgradeDowngradeDialog from '../../components/dialogs/ServiceUpgradeDowngradeDialog.vue';
import ServiceCancelDialog from '../../components/dialogs/ServiceCancelDialog.vue';
import ServiceTagDialog from '../../components/dialogs/ServiceTagDialog.vue';
import ServiceResetPasswordDialog from '../../components/dialogs/ServiceResetPasswordDialog.vue';

export default {
  name: 'Detail',
  components: {
    CpanelManage,
    ManualManage,
    AddonRegistration,
    Addon,
    ServiceRenewDialog,
    ServiceUpgradeDowngradeDialog,
    ServiceCancelDialog,
    ServiceTagDialog,
    ServiceResetPasswordDialog,
  },
  created() {
    this.id = +this.$route.params.id;
    this.$api.get('partner/product/list?renewable=1').then((response) => {
      this.orderableProducts = response.data;
      this.reloadDomain();
    });
  },
  data() {
    return {
      id: null,
      showPage: false,
      epp: null,
      form: {
        domain: '',
        service_name: '',
        module_name: '',
        product_id: null,
        product_term: null,
        status_name: '',
        tld: null,
        auto_renew: true,
        locked: true,
        ns1: '',
        ns2: '',
        ns3: '',
        ns4: '',
        ns5: '',
        provision_date: '',
        expiry_date: '',
        registrant: {},
        admin: {},
        tech: {},
        billing: {},
      },
      childNss: [],
      addChildNss: [],
      deleteChildNss: [],
      updateChildNss: [],
      defaultContact: null,
      registrant: null,
      admin: null,
      tech: null,
      billing: null,
      domainErr: null,
      tld_list: [],
      tldPriceList: [],
      terms: [],
      checkDomainLoading: false,
      checkDomainInvalid: true,
      checkoutProcessing: false,
      checkoutDisabled: false,
      showPriceTable: false,
      showRegistrationForm: false,
      eppLoading: false,
      autoRenewLoading: false,
      lockLoading: false,
      updateNsLoading: false,
      updateRegistrantLoading: false,
      updateAdminLoading: false,
      updateTechLoading: false,
      updateBillingLoading: false,
      updateChildNsLoading: false,
      renewDialog: false,
      renewLoading: false,
      renewCheckoutLoading: false,
      renewalTerms: [],
      renewalTerm: null,
      successMessage:
        'We are registering your domain now. '
        + 'An email will be sent to you when your service is ready.',
      valid: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?/i).test(value) || 'This field must be valid domain name.',
        childNs: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(`${value}.${this.form.domain}`) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
        nameserver: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
        ip: (value) => (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/).test(value) || 'This field must be valid IP.',
      },

      orderableProducts: [],
      addonData: null,
      serviceTermId: null,
      parentProductTerms: [],
      addonRegistrationComponentKey: 1,
      totalAddon: 0,
      buying: false,
      service: null,

      showServiceRenewDialog: false,
      renewServiceId: null,
      showServiceUpgradeDialog: false,
      upgradeServiceId: null,
      showServiceCancelDialog: false,
      cancelServiceId: null,
      showServiceTagDialog: false,
      showServiceResetPasswordDialog: false,
      tag: '',
    };
  },
  methods: {
    renew(id) {
      this.renewServiceId = id;
      this.showServiceRenewDialog = true;
    },
    upgrade(id) {
      this.upgradeServiceId = id;
      this.showServiceUpgradeDialog = true;
    },
    cancel(id) {
      this.cancelServiceId = id;
      this.showServiceCancelDialog = true;
    },
    updateTag(tag) {
      this.showServiceTagDialog = true;
      this.tag = tag;
    },
    resetPassword() {
      this.showServiceResetPasswordDialog = true;
    },
    revalidate() {
    },
    updateAddonPrice(amount) {
      this.totalAddon = +amount;
    },
    buyAddons() {
      this.buying = true;
      this.$api.post('partner/addon/register', {
        service_id: this.id,
        domain: this.form.domain,
        addons: this.addonData,
        addon_only: true,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: this.successMessage,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .finally(() => {
          this.buying = false;
        });
    },
    reloadDomain() {
      this.$api.get(`partner/service/${this.$route.params.id}?with_addon_info=1`).then((response) => {
        const serviceData = response.data;
        this.serviceTermId = this.$gceCommon.getTermIdByTermMonth(serviceData.service_month_term);
        this.service = serviceData;
        this.form.domain = serviceData.service_identity;
        this.form.module_name = serviceData.module_name;
        this.form.service_name = serviceData.service_name;
        this.form.product_id = serviceData.product_id;
        this.form.provision_date = serviceData.provision_date;
        this.form.status_name = serviceData.service_status_name;
        this.form.expiry_date = serviceData.expiry_date;
        this.form.auto_renew = serviceData.auto_renew === 1;
        this.parentModuleData = serviceData.service_config;
        this.showPage = true;
        this.$underscore.each(this.orderableProducts, (products) => {
          this.$underscore.each(products, (product) => {
            if (product.id === serviceData.product_id) {
              this.$underscore.each(product.currencies, (value) => {
                const currentProductPrice = {
                  text: `${value.termName}: $${value.productPrice}`,
                  value: value.productTermId,
                  price: value.productPrice,
                };
                this.parentProductTerms.push(currentProductPrice.value);
              });
            }
          });
        });
      });
    },
    toggleAutoRenew() {
      this.autoRenewLoading = true;
      this.$api.post('partner/service/set-auto-renew', {
        id: this.id,
        auto_renew: this.form.auto_renew ? 0 : 1,
      }).then(() => {
        this.$set(this.form, 'auto_renew', !this.form.auto_renew);
        const successMessage = this.form.auto_renew ? 'on' : 'off';
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: `Auto renew is turned ${successMessage}.`,
        });
      }).finally(() => {
        this.autoRenewLoading = false;
      });
    },
    login(id) {
      this.$api.post('partner/service/login', {
        id,
      }).then((response) => {
        window.open(response.data.data.url, '_blank');
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
      });
    },
    reset() {
      window.location.reload();
    },
  },
  watch: {
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-toolbar v-if="initiated" dense :flat="true">
        <v-row dense>
          <v-col cols="2">
            <v-btn small color="success" @click="create()">Create Business Card</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.status_name="{ item }">
          <b v-if="item.status_name === 'Active'" class="green--text">{{ item.status_name }}</b>
          <b v-else class="red--text">{{ item.status_name }}</b>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn x-small color="primary" v-if="item.service_status_id === 2"
                 @click="update(item.uuid)">Update</v-btn>
          <v-btn x-small color="error" v-if="item.service_status_id === 2"
                 @click="cancel(item.uuid)">Cancel</v-btn>
          <v-btn x-small color="success" v-if="item.service_status_id === 2"
                 @click="preview(item.uuid)">Preview</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TaxInvoiceList',
  data() {
    return {
      search: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'uuid', sortable: true },
        { text: 'Status', value: 'status_name', sortable: true },
        { text: 'Creation Date', value: 'created_at', sortable: true },
        { text: 'Expiry Date', value: 'expiry_date', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    this.initiated = true;
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('partner/vcard/list', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      })
        .then((response) => {
          this.totalItems = response.data.total;
          this.items = response.data.data;
          this.loading = false;
        });
    },
    create() {
      this.$router.push({ name: 'Retail.VcardCreate' });
    },
    update(uuid) {
      this.$router.push({ name: 'Retail.VcardCreate', params: { uuid } });
    },
    cancel(uuid) {
      this.$api.delete(`partner/vcard/${uuid}`)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Your business card is cancelled successfully.',
          });
          this.loadTable();
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        });
    },
    preview(uuid) {
      const localeMap = {
        sgd: 'sg',
        aud: 'au',
        usd: 'global',
      };
      const locale = localeMap[window.systemCurrency];
      const a = document.createElement('a');
      const url = `https://vcard.cldy.online/${locale}/${uuid}`;
      a.target = '_blank';
      a.href = url;
      a.click();
    },
  },
};
</script>

<style scoped>
</style>

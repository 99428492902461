import CpanelConfig from './cpanel/Config.vue';
import CpanelManage from './cpanel/Manage.vue';
import CpanelRegister from './cpanel/Register.vue';
import ManualConfig from './manual/Config.vue';
import ManualManage from './manual/Manage.vue';
import ManualRegister from './manual/ManualRegister.vue';
import DomainPrivacyConfig from './domain/privacy/Config.vue';
import ManualAddonRegister from './manual/AddonRegister.vue';
import DomainPrivacyAddonRegister from './domain/privacy/AddonRegister.vue';

export default {
  CpanelConfig,
  CpanelManage,
  CpanelRegister,
  ManualConfig,
  ManualManage,
  ManualRegister,
  DomainPrivacyConfig,
  ManualAddonRegister,
  DomainPrivacyAddonRegister,
};

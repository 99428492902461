<template>
  <v-select
    clearable
    label="Server Group"
    :multiple="multiple"
    :chips="chips"
    v-model="serverGroupId"
    :items="serverGroups"
    :disabled="disabled"
    @change="emitUpdate"
  ></v-select>
</template>

<script>
export default {
  name: 'ServerGroupDropdown',
  data() {
    return {
      serverGroups: [],
      serverGroupId: null,
    };
  },
  created() {
    this.loadServerGroup();
    if (typeof this.defaultServerGroupId !== 'undefined') {
      this.$set(this, 'serverGroupId', this.defaultServerGroupId);
    }
  },
  mounted() {
    this.emitUpdate();
  },
  methods: {
    loadServerGroup() {
      this.$api.get('admin/server-group')
        .then((response) => {
          const serverGroupList = response.data;
          serverGroupList.forEach((value) => {
            this.serverGroups.push({
              text: value.group_name,
              value: value.id,
            });
          });
        });
    },
    emitUpdate() {
      this.$emit('update:serverGroupId', this.serverGroupId);
    },
  },
  props: ['multiple', 'chips', 'disabled', 'defaultServerGroupId'],
  watch: {
    defaultServerGroupId: {
      handler(newValue) {
        this.serverGroupId = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

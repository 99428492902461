<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
      class="text-center"
    >
      <v-col>
        <h1
          class="display-2 primary--text"
        >
          Whoops, 404
        </h1>

        <p>The page you were looking for does not exist</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style scoped>

</style>

<template>
  <v-container
    fluid
  >
    <v-row dense no-gutters v-if="$store.state.brand != 'cldy'">
      <v-app-bar
        app
        dense
      >
        <img v-if="$store.state.brand === 'qoxy'" src="/qoxylogo.png">
        <v-spacer></v-spacer>
        <a class="header-btn" @click="gotoLogin()">LOGIN</a>
        <span>|</span>
        <a class="header-btn" @click="gotoLogin()">REGISTER</a>
        <v-badge
          color="green"
          :content="$store.state.shoppingCart.total_count + ''"
          overlap
          offset-x="20"
          offset-y="20"
        >
          <v-btn
            icon
            large
            @click="goToCart()"
          >
            <v-avatar
              size="32px"
              item
            >
              <v-icon>mdi-cart-outline</v-icon>
            </v-avatar>
          </v-btn>
        </v-badge>
      </v-app-bar>
    </v-row>
    <CldyHeader v-if="$store.state.brand == 'cldy'"></CldyHeader>
    <v-row v-if="invoice && invoice.invoiceStatusId == 4">
      <v-col cols="1">
      </v-col>
      <v-col cols="10">
      <v-card
        outlined
      >
        <v-card-text>
          <v-row no-gutters dense>
            <v-col cols="12" md="6">
              <v-select
                v-model="paymentMethod"
                label="Payment Method"
                :items="paymentMethods"
              ></v-select>
            </v-col>
          </v-row>
          <v-form ref="form">
            <v-row no-gutters dense>
              <v-col cols="12" md="2">
                <h1>Total: {{ amount }}</h1>
              </v-col>
            </v-row>
            <v-row no-gutters dense v-if="paymentMethod == 1">
              <v-col cols="12">
                <br>
                <v-divider></v-divider>
                <br>
              </v-col>
              <v-col cols="12" md="4" v-if="$store.state.brand == 'cldy'">
                <h2>Bank Transfer</h2>
                <p>Payee: CLDY.com Pte Ltd<br>
                Name of Bank: DBS BANK<br>
                Account No.: 0720348400<br>
                Bank Code: 7171<br>
                Branch Code: 072</p>
              </v-col>
              <v-col cols="12" md="4" v-else>
                <h2>Bank Transfer</h2>
                <p>Payee: GoCloudEasy Pte Ltd<br>
                  Name of Bank: DBS BANK<br>
                  Account No.: 0109065458<br>
                  Bank Code: 7171<br>
                  Branch Code: 001</p>
              </v-col>
              <v-col cols="12" md="4" v-if="$store.state.brand == 'cldy'">
                  <h2>PayNow</h2>
                  <p>UEN No.: 202107287N<br>
                    Company Name: CLDY.com Pte Ltd</p>
                  <div>
                    <img alt="paynow image" :src="paynowImage">
                  </div>
              </v-col>
              <v-col cols="12" md="4" v-else>
                <h2>PayNow</h2>
                <p>UEN No.: 202022399N<br>
                  Company Name: GoCloudEasy Pte Ltd</p>
              </v-col>
              <v-col cols="12" md="4" v-if="$store.state.brand == 'cldy'">
                  <h2>Cheque Payment</h2>
                  <p>Payable to: CLDY.com Pte Ltd<br>
                    Notes:<br>
                    Write invoice number at the back of the cheque<br>
                    Drop into DBS Quick Cheque Deposit box</p>
              </v-col>
              <v-col cols="12" md="4" v-else>
                <h2>Cheque Payment</h2>
                <p>Payable to: Gocloudeasy Pte Ltd<br>
                  Notes:<br>
                  Write invoice number at the back of the cheque<br>
                  Drop into DBS Quick Cheque Deposit box</p>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field label="Bank"
                              :rules="[rules.required]"
                              v-model="bank"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field label="Reference No"
                              :rules="[rules.required]"
                              v-model="referenceNo"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field label="Transfer Datetime"
                              :rules="[rules.required]"
                              v-model="transferDatetime"
                              @click.prevent="openDatetimePicker($event)"
                              :readonly="true"
                ></v-text-field>
                <datetime
                  class="datetime-picker"
                  ref="datetimePicker"
                  type="datetime"
                  v-model="pickerTransferDatetime"
                  :hour-step="1"
                  :minute-step="5"
                  format="yyyy-MM-dd HH:mm:ss"
                  auto
                ></datetime>
              </v-col>
            </v-row>
            <div v-if="paymentMethod == 3">
              <v-row no-gutters dense>
                <v-col cols="12" md="6">
                  <stripe-payment
                    :amount="amount"
                    :card.sync="creditCard"
                    :invalid.sync="creditCardInvalid"
                  ></stripe-payment>
                </v-col>
              </v-row>
            </div>
            <div v-if="paymentMethod == 4">
              <v-row no-gutters dense>
                <v-col cols="12" md="6">
                  <paypal-payment
                    :amount="amount"
                    :orderid="paypalOrderId"
                    @interface="getChildInterface"
                    :saveasdefaultcard="false"
                    :card.sync="creditCard"
                    :invalid.sync="creditCardInvalid"
                  ></paypal-payment>
                </v-col>
              </v-row>
            </div>
          </v-form>
          <v-row no-gutters dense>
            <v-col cols="12" class="text-right">
              <v-btn
                v-if="paymentMethod == 4"
                color="primary"
                     :loading="loading"
                     @click="submit">Make Payment (Visa/Master)</v-btn>
              <v-btn
                v-else
                color="primary"
                :loading="loading"
                @click="submit">
                <span v-if="$store.state.brand == 'cldy'">Notify CLDY on Payment</span>
                <span v-else>Notify on Payment</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    </v-row>
    <v-row v-if="invoice && invoice.invoiceStatusId != 4">
      <v-col cols="1">
      </v-col>
      <v-col cols="10">
      <v-card
        outlined
      >
        <v-card-text>
          <v-row no-gutters dense v-if="invoice.invoiceStatusId == 2">
            <h2 class="orange--text">Pending Approval</h2>
          </v-row>
          <v-row no-gutters dense  v-if="invoice.invoiceStatusId == 1">
            <h2 class="green--text">Paid</h2>
          </v-row>
          <v-row no-gutters dense  v-if="invoice.invoiceStatusId == 5">
            <h2 class="red--text">Cancelled</h2>
          </v-row>
          <br>
          <v-row no-gutters dense>
            <v-col cols="12" class="text-left">
              <v-btn color="success"
                     @click="downloadPdf">Download PDF</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
      </v-col>
      <v-col cols="10">
      <v-row v-if="invoice">
        <v-col cols="12" md="6">
          <p><b>Invoice Date</b>: {{ invoice.invoiceDatetime }}</p>
          <p><b>Invoice NO</b>: {{ invoice.invoiceNo }}</p>
          <p><b>Name</b>: {{ invoice.customerFirstName }} {{ invoice.customerLastName }}</p>
          <p><b>Company</b>: {{ invoice.customerCompanyName }}</p>
          <p v-if="invoice.customerAddress1 != ''">
            <b>Address1</b>: {{ invoice.customerAddress1 }}</p>
          <p v-if="invoice.customerAddress2 != ''">
            <b>Address2</b>: {{ invoice.customerAddress2 }}</p>
          <p><b>City</b>: {{ invoice.customerCity }}</p>
          <p><b>Country</b>: {{ invoice.customerCountry }}</p>
          <p><b>Postcode</b>: {{ invoice.customerPostalCode }}</p>
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Description
            </th>
            <th class="text-right">
              Discount
            </th>
            <th class="text-right">
              Amount
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
          >
            <td><pre>{{ item.invoiceLineDescription }}</pre></td>
            <td class="text-right">${{ item.discount }}</td>
            <td class="text-right">${{ item.subtotal }}</td>
          </tr>
          <tr v-if="invoice.discount > 0">
            <td colspan="3" class="text-right">Discount: ${{ invoice.discount }}</td>
          </tr>
          <tr v-if="invoice.tax > 0">
            <td colspan="3" class="text-right">GST @9%: ${{ invoice.tax }}</td>
          </tr>
          <tr>
            <td colspan="3" class="text-right">Total: ${{ invoice.total }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    </v-row>
    <v-row v-if="invoice && invoice.invoiceStatusId == 4">
      <v-col cols="1">
      </v-col>
      <v-col cols="10">
      <v-card
        outlined
      >
        <v-card-text>
          <v-row no-gutters dense>
            <v-col cols="6" class="text-left">
              <v-btn color="success"
                     @click="downloadPdf">Download PDF</v-btn>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn color="error"
                     :loading="loading"
                     style="margin-right: 10px;"
                     @click="cancel">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    </v-row>
    <v-footer
      v-if="$store.state.brand != 'cldy'"
      dark
      padless
      :app="true"
      :fixed="true"
    >
      <v-card
        flat
        tile
        class="indigo lighten-1 white--text text-center"
        width="100%"
      >
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Powered By GoCloudEasy Pte Ltd</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <CldyFooter v-if="$store.state.brand == 'cldy'"></CldyFooter>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import { mapState } from 'vuex';
import { StripePayment, PaypalPayment } from '../components/payment_modules';
import CldyHeader from './CldyHeader.vue';
import CldyFooter from './CldyFooter.vue';

export default {
  name: 'ShoppingCart',
  components: {
    StripePayment,
    PaypalPayment,
    CldyHeader,
    CldyFooter,
  },
  data() {
    return {
      bank: '',
      referenceNo: '',
      transferDatetime: null,
      pickerTransferDatetime: null,
      paymentMethod: '',
      paymentMethods: [],
      amount: '',
      paypalOrderId: '',
      loading: false,
      creditCardInvalid: null,
      creditCard: null,
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
      items: [],
      invoice: null,
      firstInvoice: false,
      gtagObj: {},
      paynowImage: null,
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    localComputed() {
    },
    // mix this into the outer object with the object spread operator
    ...mapState({
      shoppingCart: 'shoppingCart',
    }),
  },
  created() {
    if (this.$route.query.payment) {
      this.paymentMethod = 1;
    } else {
      this.$api.get('partner/default-payment-method').then((response) => {
        this.$set(this, 'paymentMethod', response.data);
      });
    }

    this.$api.get(`has-no-invoice?invoice_id=${this.$route.params.id}&express_token=${this.$route.query.token}`)
      .then((response) => {
        this.$set(this, 'firstInvoice', response.data.has_no_invoice);
      });

    this.$api.get(`tax-invoice/${this.$route.params.id}/detail?express_token=${this.$route.query.token}`).then((response) => {
      this.amount = response.data.total;
      this.$set(this, 'invoice', response.data);
      this.$set(this, 'items', response.data.invoiceItems);
      const invoiceItems = [];

      this.$api.get(`paynow-image?invoice=${response.data.invoiceNo}&amount=${response.data.total}`).then((subresponse) => {
        this.paynowImage = subresponse.data;
      });

      response.data.invoiceItems.forEach((invoiceItem) => {
        invoiceItems.push({
          item_id: invoiceItem.id,
          item_name: `${response.data.invoiceDatetime} ${invoiceItem.invoiceLineDescription}`,
          price: invoiceItem.subtotal,
          discount: invoiceItem.discount,
          tax: invoiceItem.tax,
          quantity: 1,
        });
      });

      const gtagObj = {
        currency: window.systemCurrency.toUpperCase(),
        value: response.data.total,
        discount: response.data.discount,
        tax: response.data.tax,
        items: invoiceItems,
      };

      this.$set(this, 'gtagObj', gtagObj);
    });
    this.transferDatetime = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');
    this.pickerTransferDatetime = DateTime.local().toISO();
    this.$api.get('partner/payment-methods').then((response) => {
      this.$set(this, 'paymentMethods', response.data);
    });
  },
  methods: {
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    gotoLogin() {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.Login', params: { brand } })
        .catch(() => {
        });
    },
    goToCart() {
      const { brand } = this.$store.state;
      if (this.$store.state.user.role) {
        this.$router.push({
          name: 'Retail.ShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      } else {
        this.$router.push({
          name: 'Retail.GuestShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      }
    },
    gotoHome() {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.Guest', params: { brand } })
        .catch(() => {
        });
    },
    openDatetimePicker(event) {
      this.$refs.datetimePicker.open(event);
    },
    reset() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    submit() {
      this.loading = true;

      if (!this.$refs.form.validate()) {
        this.loading = false;
        return;
      }

      if (this.paymentMethod === 3 && this.creditCardInvalid) {
        this.loading = false;
        return;
      }

      if (this.paymentMethod === 1) {
        this.$api.post('payment', {
          payment_type_id: this.paymentMethod,
          bank: this.bank,
          reference_no: this.referenceNo,
          transfer_datetime: this.transferDatetime,
          amount: this.amount,
          invoice_id: this.$route.params.id,
          token: this.$route.query.token,
        })
          .then(() => {
            if (this.firstInvoice && this.$store.state.brand === 'cldy') {
              window.gtag('event', 'purchase', this.gtagObj);
            }
            const successMessage = 'Your payment is submitted successfully. Admin will review and confirm the payment.';
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: successMessage,
            });
            this.reset();
          })
          .finally(() => {
            this.loading = false;
          });
      }

      if (this.paymentMethod === 3) {
        this.$api.post('payment', {
          payment_type_id: this.paymentMethod,
          amount: this.amount,
          invoice_id: this.$route.params.id,
          token: this.$route.query.token,
          save_as_default_card: false,
        })
          .then((response) => {
            const stripePaymentObj = {
              payment_method: {
                card: this.creditCard,
              },
            };

            window.stripeClient.confirmCardPayment(response.data.client_secret, stripePaymentObj)
              .then(async (result) => {
                if (result.error) {
                  await this.$store.dispatch('addAlert', {
                    id: Date.now(),
                    type: 'error',
                    message: result.error,
                  });
                } else if (result.paymentIntent.status === 'requires_capture') {
                  await this.$api.post('payment', {
                    invoice_id: this.$route.params.id,
                    token: this.$route.query.token,
                    payment_type_id: this.paymentMethod,
                    capture: 1,
                    id: result.paymentIntent.id,
                  })
                    .then(() => {
                      if (this.firstInvoice && this.$store.state.brand === 'cldy') {
                        window.gtag('event', 'purchase', this.gtagObj);
                      }

                      const successMessage = 'Your payment is submitted successfully.';
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'success',
                        message: successMessage,
                      });
                      this.reset();
                    });
                }
              })
              .finally(() => {
                this.loading = false;
              });
          })
          .finally(() => {
            this.loading = false;
          });
      }

      if (this.paymentMethod === 4) {
        this.$api.post('payment', {
          payment_type_id: this.paymentMethod,
          amount: this.amount,
          invoice_id: this.$route.params.id,
          token: this.$route.query.token,
          save_as_default_card: false,
        })
          .then((response) => {
            this.$set(this, 'paypalOrderId', response.data.order_id);
            this.$nextTick(() => {
              this.$options.childInterface.checkout()
                .then((payload) => {
                  if (payload.liabilityShift !== 'NO'
                    && payload.authenticationStatus
                    && payload.authenticationStatus !== 'YES') {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: '3D Secure Authentication fails. Please retry again.',
                    });
                    this.loading = false;
                    return;
                  }

                  if (payload.liabilityShift === 'NO'
                    && payload.liabilityShifted === false
                    && payload.authenticationStatus
                    && payload.authenticationStatus === 'NO') {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: 'You have exited 3D Secure Authentication. Please retry again.',
                    });
                    this.loading = false;
                    return;
                  }

                  this.$api.post('payment', {
                    payment_type_id: this.paymentMethod,
                    confirm: 1,
                    capture: 1,
                    id: payload.orderId,
                    amount: this.amount,
                    invoice_id: this.$route.params.id,
                    token: this.$route.query.token,
                    save_as_default_card: false,
                  })
                    .then(() => {
                      if (this.firstInvoice && this.$store.state.brand === 'cldy') {
                        window.gtag('event', 'purchase', this.gtagObj);
                      }

                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'success',
                        message: 'Your payment is submitted successfully.',
                      });
                      this.reset();
                    });
                })
                .catch((error) => {
                  if (error.message.indexOf('semantically incorrect') > 0) {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: 'We are sorry, the transaction failed. Please check the card information or try with another card.',
                    });
                  } else {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: `An error occurred ${error.message}. Please retry again.`,
                    });
                  }
                  this.loading = false;
                });
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    cancel() {
      /* eslint-disable no-alert */
      const r = window.confirm('Are you sure to cancel this invoice?');
      if (r === true) {
        this.loading = true;

        this.$api.post('express-cancel', {
          invoice_id: this.$route.params.id,
          token: this.$route.query.token,
        })
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Invoice is cancelled successfully.',
            });

            this.reset();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    downloadPdf() {
      window.open(`/invoice.php?invoice_no=${this.invoice.invoiceNo}&view_token=${this.$route.query.token}`);
    },
  },
  watch: {
    pickerTransferDatetime: {
      handler(newPickerTransferDatetime) {
        if (newPickerTransferDatetime !== null) {
          this.transferDatetime = DateTime.fromISO(newPickerTransferDatetime)
            .toFormat('yyyy-MM-dd HH:mm:ss');
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.cart {
  max-height: 640px;
  overflow-y: scroll;
}

.pay-btn {
  margin-left: 10px;
}
</style>

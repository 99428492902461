<template>
  <v-row
    no-gutters
    dense
  >
    <v-col
      cols="6"
      offset="3"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card
          outlined
        >
          <v-card-text>
            <v-row
              dense
              no-gutters
            >
              <v-col
                cols="12"
              >
                <v-row
                  dense
                  align="center"
                >
                  <v-col
                    cols="12"
                  >
                    <v-radio-group v-model="loginMethod"
                                   row>
                      <v-radio
                        label="Use Email"
                        value="email"
                      ></v-radio>
                      <v-radio
                        label="Use Username"
                        value="username"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="6"
                    v-if="loginMethod === 'email'"
                  >
                    <v-text-field
                                  label="Email"
                                  name="email"
                                  prepend-icon="mdi-email"
                                  type="text"
                                  v-model="email"
                                  :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    v-if="loginMethod !== 'email'"
                  >
                  <v-text-field
                                label="Username"
                                name="username"
                                prepend-icon="mdi-account"
                                type="text"
                                v-model="username"
                                :rules="[rules.required]"
                  >
                  </v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    v-if="loginMethod !== 'email'"
                  >
                    <v-text-field
                                  label="Key"
                                  name="key"
                                  prepend-icon="mdi-lock"
                                  type="password"
                                  v-model="key"
                                  :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-autocomplete
                      :clearable="true"
                      label="Services"
                      :multiple="true"
                      :chips="true"
                      v-model="services"
                      :items="availServices"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      color="primary"
                      :loading="processing"
                      :disabled="processing"
                      @click="submitForm"
                    >Submit</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'New',
  data() {
    return {
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
      loginMethod: 'email',
      email: '',
      username: '',
      key: '',
      valid: false,
      services: null,
      availServices: [],
    };
  },
  created() {
    this.$api.get('partner/active-cpanel-services').then((response) => {
      this.$set(this, 'availServices', response.data);
    });
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$api.post('partner/collaborator/create', {
          method: this.loginMethod,
          email: this.email,
          username: this.username,
          key: this.key,
          services: this.services,
        })
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'New collaborator is added.',
            });
            this.$refs.form.reset();
            this.loginMethod = 'email';
          })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row no-gutters dense>
    <v-col cols="6" offset="3">
      <v-form ref="form" v-model="valid">
        <v-card outlined>
          <v-card-text>
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="8" >
                    <v-text-field dense
                                  label="Product Type Name"
                                  v-model="form.type_name"
                                  :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" >
                    <v-text-field dense
                                  label="Firstcom Commission % (If any)"
                                  v-model="form.commission_percent"
                                  :rules="[]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="primary"
                  :loading="processing"
                  :disabled="processing"
                  @click="submitForm"
                >Submit</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Add',
  components: {
  },
  data() {
    return {
      form: {
        type_name: '',
        commission_percent: 0,
      },
      showPassword: false,
      successMessage: 'Product type is successfully updated.',
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
      valid: false,
    };
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$api.put(`admin/product-type/${this.$route.params.id}`, this.form)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: this.successMessage,
            });
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
  },
  mounted() {
    this.$api.get(`admin/product-type/${this.$route.params.id}`).then((response) => {
      this.form = response.data;
    });
  },
};
</script>

<style scoped>

</style>

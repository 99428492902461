<template>
  <v-select
    :clearable="!!!readonly"
    :label="label ? label : 'Product Type'"
    :multiple="multiple"
    :chips="chips"
    v-model="productTypeId"
    :items="productTypes"
    :readonly="readonly"
    @change="$emit('update:productTypeId', productTypeId);"
  ></v-select>
</template>

<script>
export default {
  name: 'ProductTypeDropdown',
  data() {
    return {
      productTypes: [],
      productTypeId: null,
    };
  },
  created() {
    this.loadProductType();
  },
  mounted() {
    this.productTypeId = this.defaultProductTypeId;
  },
  methods: {
    loadProductType() {
      this.$api.get('admin/product-type')
        .then((response) => {
          const list = response.data;
          list.forEach((value) => {
            this.productTypes.push({
              text: value.type_name,
              value: value.id,
            });
          });
        });
    },
  },
  props: ['multiple', 'chips', 'defaultProductTypeId', 'label', 'readonly'],
  watch: {
    defaultProductTypeId(newValue) {
      this.productTypeId = newValue;
    },
  },
};
</script>

<style scoped>

</style>

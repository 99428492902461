<template>
  <v-dialog width="1024" v-model="display"
            @click:outside="closeDialog"
  >
    <v-card>
      <v-card-text>
        <h1 class="text-center"
            v-if="!upgradeLoaded">Loading available upgrades ...</h1>
        <v-row v-else>
          <v-col v-if="upgrades.length > 0" cols="12">
            <v-row>
              <v-col cols="6">
                <v-select label="Upgrade Options"
                          :items="upgrades"
                          v-model="upgrade"
                          @change="changeTerm()"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" v-if="upgrade.life_cycle_day > 0">
                <p>Service Leftover Days: {{ upgrade.upgrade_day_diff }}</p>
                <p>Service Total Days: {{ upgrade.life_cycle_day }}</p>
                <p v-if="upgrade.upgrade_cost > 0">Upgrade cost: ${{ upgrade.upgrade_cost }}</p>
                <p v-else>Downgrade cost: $0</p>
              </v-col>
              <v-col cols="6" v-else>
                <p>Please contact support for upgrading/downgrading this service.</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else>
            No upgrade/downgrades available.
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="closeDialog()"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="upgradeLoading"
          :disabled="upgrades.length < 1"
          @click="processUpgrade()"
          v-if="upgrade.life_cycle_day > 0"
        >
          <span v-if="upgrade && upgrade.upgrade_cost > 0">
            Upgrade <span class="green--text">${{ upgrade.upgrade_cost }}</span></span>
          <span v-else>Downgrade</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ServiceUpgradeDowngradeDialog',
  data() {
    return {
      display: true,
      upgradeLoading: false,
      upgrades: [],
      upgrade: null,
      upgradeLoaded: false,
    };
  },
  props: ['serviceId'],
  created() {
    this.loadServiceInfo();
  },
  watch: {
    serviceId: {
      handler(newServiceId) {
        if (newServiceId) {
          this.serviceId = newServiceId;
          this.loadServiceInfo();
        }
      },
      deep: true,
    },
  },
  methods: {
    processUpgrade() {
      this.upgradeLoading = true;
      this.$api.post('partner/addon/upgrade-downgrade', {
        id: this.serviceId,
        product_id: this.upgrade.product_id,
      }).then((data) => {
        if (this.$store.state.user.role === 'Partner') {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Your upgrade/downgrade request is submitted successfully.',
          });
        } else if (this.upgrade.upgrade_cost > 0) {
          this.$store.dispatch('syncShoppingCart', data.data);
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Your upgrade/downgrade request is successfully added to the shopping cart.',
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Your downgrade request is successfully submitted.',
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
        this.upgradeLoading = false;
        this.closeDialog();
      }).finally(() => {
        this.upgradeLoading = false;
      });
    },
    change() {
      this.total = 0;
      this.renewalTerms.forEach((term) => {
        if (term.value === this.renewalTerm) {
          if (term.product_price) {
            this.total += +term.product_price;
          }
        }
      });
      this.$underscore.each(this.service.addons, (groupAddons) => {
        groupAddons.forEach((addon) => {
          addon.product_prices.forEach((addonPrice) => {
            if (addonPrice.value === this.renewalTerm) {
              this.total += +addonPrice.product_price;
            }
          });
        });
      });
    },
    loadServiceInfo() {
      this.upgrades.splice(0, this.upgrades.length);
      this.$api.post('partner/addon/get-upgrade-downgrade', {
        id: this.serviceId,
      }).then((response) => {
        let optionCount = 0;

        this.$underscore.forEach(response.data, (upgrade) => {
          this.upgrades.push({
            text: `${upgrade.product_name} ${upgrade.term_name} $${upgrade.product_price}`,
            value: upgrade,
          });

          if (optionCount === 0) {
            this.upgrade = upgrade;
          }

          optionCount += 1;
        });
      }).finally(() => {
        this.upgradeLoaded = true;
      });
    },
    changeTerm() {
    },
    closeDialog() {
      this.$emit('close-dialog');
    },
    renew() {
      this.renewProcessing = true;
      this.$api.post('partner/service/renew', {
        id: this.serviceId,
        product_term: this.renewalTerm,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your renewal request is submitted successfully.',
        });
        this.renewProcessing = false;
        this.closeDialog();
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.renewProcessing = false;
      });
    },
  },
};
</script>
<style scoped>
</style>

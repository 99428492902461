<template>
  <v-autocomplete
    dense
    v-model="registrar"
    :items="registrars"
    :label="label"
    :multiple="false"
    :chips="!nochips"
    :rules="rules"
    @change="emitUpdate"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'RegistrarDropdown',
  data() {
    return {
      registrar: null,
      registrars: [],
    };
  },
  created() {
    this.load();
    if (typeof this.default !== 'undefined') {
      this.$set(this, 'registrar', this.default);
    }
  },
  mounted() {
    this.emitUpdate();
  },
  methods: {
    load() {
      this.$api.get('admin/registrar')
        .then((response) => {
          const list = response.data;
          list.forEach((value) => {
            this.registrars.push({
              text: value.registrar_name,
              value: value.id,
            });
          });
        });
    },
    emitUpdate() {
      this.$emit('update:registrar', this.registrar);
    },
  },
  props: ['label', 'rules', 'default', 'single', 'nochips'],
  watch: {
    default: {
      handler(newRegistrar) {
        this.registrar = newRegistrar;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>

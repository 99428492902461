<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row dense no-gutters v-if="$store.state.brand != 'cldy'">
      <v-app-bar
        app
        dense
      >
        <img v-if="$store.state.brand === 'qoxy'" src="/qoxylogo.png">
        <v-spacer></v-spacer>
        <a class="header-btn" @click="gotoLogin()">LOGIN</a>
        <span>|</span>
        <a class="header-btn" @click="gotoLogin()">REGISTER</a>
        <v-badge
          color="green"
          :content="$store.state.shoppingCart.total_count + ''"
          overlap
          offset-x="20"
          offset-y="20"
        >
          <v-btn
            icon
            large
            @click="goToCart()"
          >
            <v-avatar
              size="32px"
              item
            >
              <v-icon>mdi-cart-outline</v-icon>
            </v-avatar>
          </v-btn>
        </v-badge>
      </v-app-bar>
    </v-row>
    <CldyHeader v-if="$store.state.brand == 'cldy'"></CldyHeader>
    <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="8"
      md="4"
    >
      <v-card class="elevation-12">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>Billing System</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              label="Email"
              name="email"
              prepend-icon="mdi-account"
              type="text"
              v-model="email"
              :error="error.email"
              :error-messages="errorMessage.email"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="$router.go(-1)"
          >Back</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="processing"
            :disabled="processing"
            @click="resetPassword()"
          >Reset Password</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
    <v-footer v-if="$store.state.brand != 'cldy'"
      dark
      padless
      :app="true"
      :fixed="true"
    >
      <v-card
        flat
        tile
        class="indigo lighten-1 white--text text-center"
        width="100%"
      >
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Powered By GoCloudEasy Pte Ltd</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <CldyFooter v-if="$store.state.brand == 'cldy'"></CldyFooter>
  </v-container>
</template>

<script>
import CldyHeader from './CldyHeader.vue';
import CldyFooter from './CldyFooter.vue';

export default {
  name: 'ForgotPassword',
  components: {
    CldyHeader,
    CldyFooter,
  },
  data() {
    return {
      email: '',
      processing: false,
      error: {
        email: false,
      },
      errorMessage: {
        email: '',
      },
    };
  },
  methods: {
    resetPassword() {
      try {
        this.validate();
      } catch (e) {
        return;
      }

      this.processing = true;

      const { brand } = this.$store.state;

      this.$api.post('request-reset-password', {
        email: this.email,
        brand,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'We have emailed you a link to reset your password.',
          });
          setTimeout(() => {
            this.$router
              .push({ name: 'Retail.Login', params: { brand } })
              .catch(() => {
              });
          }, 1000);
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    validate() {
      this.error.email = false;
      this.errorMessage.email = '';

      if (this.$underscore.isEmpty(this.email)) {
        this.error.email = true;
        this.errorMessage.email = 'Email Address is required.';
        throw new Error('Validation error.');
      }
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <v-row
    no-gutters
    dense
  >
    <v-col cols="12">
      <v-card
        outlined
      >
        <v-card-text>
          <v-row no-gutters dense>
            <v-col cols="12">
              <business-card-iframe></business-card-iframe>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import BusinessCardIframe from '../components/BusinessCardIframe.vue';

export default {
  name: 'BusinessCardGenerator',
  components: {
    BusinessCardIframe,
  },
  data() {
    return {
    };
  },
  created() {
    window.addEventListener(
      'message',
      (event) => {
        console.log(event);
      },
      false,
    );
  },
};
</script>

<template>
  <v-row dense no-gutters class="dashboard-block">
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12" md="6" class="min-height-160">
          <v-card>
            <v-card-title class="headline">Services</v-card-title>
            <v-card-text>
              <h4>Active Services: {{ activeServices }}</h4>
              <h4>Expired Services: {{ expiredServices }}</h4>
            </v-card-text>
            <v-card-actions>
              <v-row dense>
                <v-col xs="12" sm="12" md="4">
                  <v-btn text small
                         @click="$router.push({name: 'Retail.ServiceList'})"
                  >View All</v-btn>
                </v-col>
                <v-col xs="12" sm="12" md="4">
                  <v-btn text small
                         @click="$router.push({name: 'Retail.ServiceRegister'})"
                  >Register</v-btn>
                </v-col>
                <v-col xs="12" sm="12" md="4">
                  <v-btn text small
                         @click="$router.push({name: 'Retail.ServiceRenew'})"
                  >Renew</v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-btn small style="visibility:hidden;"></v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="min-height-160">
          <v-card>
            <v-card-title class="headline">Domains</v-card-title>
            <v-card-text>
              <h4>Active Domains: {{ activeDomains }}</h4>
              <h4>Expired Domains: {{ expiredDomains }}</h4>
            </v-card-text>
            <v-card-actions>
              <v-row dense>
                <v-col sm="12" md="3">
                  <v-btn text small
                         @click="$router.push({name: 'Retail.DomainList'})"
                  >View All</v-btn>
                </v-col>
                <v-col sm="12" md="3">
                  <v-btn text small
                         @click="$router.push({name: 'Retail.DomainRegister'})"
                  >Register</v-btn>
                </v-col>
                <v-col sm="12" md="3">
                  <v-btn text small
                         @click="$router.push({name: 'Retail.DomainTransfer'})"
                  >Transfer</v-btn>
                </v-col>
                <v-col sm="12" md="3">
                  <v-btn text small
                         @click="$router.push({name: 'Retail.DomainRenew'})"
                  >Renew</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12" md="6" class="min-height-250">
          <v-card>
            <v-card-title class="headline">Unpaid Invoice</v-card-title>
            <v-card-text>
              <v-row v-for="invoice in unpaidInvoices" :key="invoice.id">
                <v-col>{{ invoice.created_at }}</v-col>
                <v-col>
                  {{ invoice.first_name }} {{ invoice.last_name }}
                  {{ invoice.customer_company_name }}
                </v-col>
                <v-col>${{ invoice.total }}</v-col>
                <v-col>
                  <v-btn text small
                         @click="$router.push({
                              name: 'Retail.InvoiceDetail',
                              params: {'id': invoice.id}
                            })">Pay</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn text small
                     @click="$router.push({name: 'Retail.TaxInvoiceList'})"
              >Full List</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="min-height-250">
          <v-card>
            <v-card-title class="headline">Paid Invoice</v-card-title>
            <v-card-text>
              <v-row v-for="invoice in paidInvoices" :key="invoice.id">
                <v-col>{{ invoice.created_at }}</v-col>
                <v-col>
                  {{ invoice.first_name }} {{ invoice.last_name }}
                  {{ invoice.customer_company_name }}
                </v-col>
                <v-col>${{ invoice.total }}</v-col>
                <v-col>
                  <v-btn text small
                         @click="viewInvoice(invoice.id)">View</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn text small
                     @click="$router.push({name: 'Retail.TaxInvoiceList'})"
              >Full List</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12">
          <v-card class="tickets" height="268px">
            <v-card-title class="headline">Approval Event</v-card-title>
            <v-card-text v-if="approvalEvents.length !== 0">
              <v-list two-line class="news-list">
                <template v-for="(item, index) in approvalEvents">
                  <v-list-item :key="item.id">
                    <template v-slot:default="">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.created_at"></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="`Transfer: ${item.service_name} - ${item.service_identity}
                          , Exp: ${item.expiry_date}`">
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn small color="primary" @click="gotoApprove(item.token)">
                          Approve
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index + 1 < approvalEvents.length"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list>
            </v-card-text>
            <v-card-text class="no-tickets" v-if="approvalEvents.length === 0">
              <h2>No Events.</h2>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12">
          <v-card class="tickets" height="268px">
            <v-card-title class="headline">Support Tickets</v-card-title>
            <v-card-text v-if="tickets.length !== 0">
              <v-list two-line class="news-list">
                <template v-for="(item, index) in tickets">
                  <v-list-item :key="item.title">
                    <template v-slot:default="">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-subtitle class="text--primary" v-text="item.headline">
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text v-text="item.datetime"></v-list-item-action-text>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index + 1 < items.length"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list>
            </v-card-text>
            <v-card-text class="no-tickets" v-if="tickets.length === 0">
              <h2>No Open Tickets.</h2>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12">
          <v-card class="news" height="268px">
            <v-card-title class="headline">
              <span>News</span>
            </v-card-title>
            <v-card-text v-if="news.length !== 0">
              <v-list two-line class="news-list">
                <template v-for="(item, index) in news">
                  <v-list-item :key="item.title">
                    <template v-slot:default="">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-subtitle class="text--primary" v-text="item.headline">
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text v-text="item.datetime"></v-list-item-action-text>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index + 1 < items.length"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list>
            </v-card-text>
            <v-card-text class="no-news" v-if="news.length === 0">
              <h2>No News.</h2>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      balance: 0,
      lockedBalance: 0,
      creditLimit: 0,
      activeServices: 0,
      expiredServices: 0,
      activeDomains: 0,
      expiredDomains: 0,
      forecasts: null,
      news: [],
      tickets: [],
      approvalEvents: [],
      unpaidInvoices: [],
      paidInvoices: [],
    };
  },
  created() {
    this.$api.post('login', {
      token: window.btoa(this.$store.state.user.auth_token),
    }).catch(() => {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.Login', params: { brand } })
        .catch(() => {
        });
    });

    this.$api.get('partner/summary').then((response) => {
      this.balance = response.data.balance;
      this.lockedBalance = response.data.locked_balance;
      this.creditLimit = response.data.credit_limit;
      this.activeServices = response.data.active_services;
      this.expiredServices = response.data.expired_services;
      this.activeDomains = response.data.active_domains;
      this.expiredDomains = response.data.expired_domains;
      this.approvalEvents = response.data.approval_events;
      this.unpaidInvoices = response.data.unpaid_invoices;
      this.paidInvoices = response.data.paid_invoices;
      this.$set(this, 'forecasts', response.data.renewal_forecast);
    });

    this.$api.get('partner/shopping-cart')
      .then((data) => {
        this.$store.dispatch('syncShoppingCart', data.data);
      });
  },
  methods: {
    gotoApprove(token) {
      const routeData = this.$router.resolve({ name: 'Retail.ServiceTransfer', params: { token } });
      window.open(routeData.href, '_blank');
    },
    viewInvoice(invoiceId) {
      const authToken = window.btoa(this.$store.state.user.auth_token);
      window.open(`${process.env.VUE_APP_API_URL}/v1/customer/tax-invoice/${invoiceId}/view?auth_token=${authToken}`, '_blank');
    },
  },
};
</script>

<style scoped>
  .v-application .headline, .tickets .headline, .news .headline {
    color: rgba(0,0,0,.6);
  }

  .news-list {
    height: 180px;
    overflow-y: auto;
    border-radius: 7px;
    padding: 0;
  }

  .no-tickets, .no-news {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 64px);
  }

  .no-tickets h2, .no-news h2 {
    color: rgba(0,0,0,.6);
  }

  .min-height-250 .v-card {
    min-height: 250px;
    height: 250px;
  }

  .min-height-250 .v-card__text {
    height: 145px;
    overflow: auto;
  }

  .min-height-160 .v-card {
    min-height: 160px;
    height: 160px;
  }

  .theme--dark.v-card > .v-card__text, .theme--dark.v-card .v-card__subtitle {
    color: rgba(255, 255, 255, 1);
  }

  .dashboard-block .row--dense>.col, .dashboard-block .row--dense>[class*=col-] {
    padding: 8px;
  }

  .v-card {
    /*background-color: #F3F9FF;*/
    background-color: #fff;
    border-radius: 12px;
  }

  .v-application .headline {
    background-color: #f3f9ff;
    padding-top: 12px;
    padding-bottom: 6px;
    border-bottom: 2px solid lightgray;
    margin-bottom: 8px;
    font-size: 1.2rem !important;
    font-weight: bold;
  }
</style>

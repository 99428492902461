<template>
  <v-container
    fluid
  >
    <v-row dense no-gutters v-if="$store.state.brand != 'cldy'">
      <v-app-bar
        app
        dense
      >
        <img v-if="$store.state.brand === 'qoxy'" src="/qoxylogo.png">
        <v-spacer></v-spacer>
        <a class="header-btn" @click="gotoLogin()">LOGIN</a>
        <span>|</span>
        <a class="header-btn" @click="gotoLogin()">REGISTER</a>
        <v-badge
          color="green"
          :content="$store.state.shoppingCart.total_count + ''"
          overlap
          offset-x="20"
          offset-y="20"
        >
          <v-btn
            icon
            large
            @click="goToCart()"
          >
            <v-avatar
              size="32px"
              item
            >
              <v-icon>mdi-cart-outline</v-icon>
            </v-avatar>
          </v-btn>
        </v-badge>
      </v-app-bar>
    </v-row>
    <v-row v-if="$store.state.brand != 'cldy'">
      <v-col cols="2">
        <v-list
          nav
          dense
        >
          <v-list-item-group
            v-model="selectedCategory"
            color="primary"
          >
            <v-list-item
              v-for="(item, i) in categories"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title v-html="item.html">
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="10">
        <component v-bind:is="component" v-bind="componentProps" :key="componentKey"
        ></component>
      </v-col>
    </v-row>
    <v-footer v-if="$store.state.brand != 'cldy'"
              dark
              padless
              :app="true"
              :fixed="true"
    >
      <v-card
        flat
        tile
        class="indigo lighten-1 white--text text-center"
        width="100%"
      >
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Powered By GoCloudEasy Pte Ltd</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <CldyHeader v-if="$store.state.brand == 'cldy'"></CldyHeader>
    <v-row v-if="$store.state.brand == 'cldy'" class="cldy-container">
      <v-col cols="12">
        <v-tabs
          class="product-category"
          centered
          v-model="selectedCategory"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows="mobile"
        >
          <v-tab v-for="(item, i) in categories"
                 :key="i" v-html="item.html"
          >
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <component v-bind:is="component" v-bind="componentProps" :key="componentKey"
        ></component>
      </v-col>
    </v-row>
    <CldyFooter v-if="$store.state.brand == 'cldy'"></CldyFooter>
  </v-container>
</template>

<script>
import DomainRegister from './domain/Register.vue';
import DomainTransfer from './domain/Transfer.vue';
import ProductRegistration from './service/Register.vue';
import CldyHeader from './CldyHeader.vue';
import CldyFooter from './CldyFooter.vue';

export default {
  name: 'Guest',
  components: {
    DomainRegister,
    DomainTransfer,
    ProductRegistration,
    CldyHeader,
    CldyFooter,
  },
  data() {
    return {
      loggedIn: true,
      selectedCategory: 0,
      categories: [
        { html: 'Domain', text: 'Domain', component: DomainRegister },
        { html: 'Domain<br>Transfer', text: 'Domain Transfer', component: DomainTransfer },
      ],
      component: DomainRegister,
      domain: null,
      tld: null,
      componentKey: 0,
    };
  },
  created() {
    if (this.$route.query.category === 'Domain') {
      if (this.$route.query.domain) {
        this.domain = this.$route.query.domain;
      }

      if (this.$route.query.tld) {
        this.tld = this.$route.query.tld;
      }

      if (this.$route.query.fulldomain) {
        const domainParts = this.$route.query.fulldomain.split('.');
        [this.domain, ...this.tld] = domainParts;
        this.tld = domainParts.slice(1).join('.');
      }
    }

    if (this.$route.query.category === 'DomainTransfer') {
      if (this.$route.query.domain) {
        this.domain = this.$route.query.domain;
      }

      if (this.$route.query.tld) {
        this.tld = this.$route.query.tld;
      }

      if (this.$route.query.fulldomain) {
        const domainParts = this.$route.query.fulldomain.split('.');
        [this.domain, ...this.tld] = domainParts;
        this.tld = domainParts.slice(1).join('.');
      }

      this.selectedCategory = 1;
    }

    if (this.$store.state.user.role) {
      this.loggedIn = false;
    }

    const shoppingCartId = this.$store.state.shoppingCartId || localStorage.getItem('shopping_cart_id');

    if (shoppingCartId) {
      this.$api.get(`partner/shopping-cart?shopping_cart_id=${shoppingCartId}`)
        .then((data) => {
          this.$store.dispatch('syncShoppingCart', data.data);
        });
    }

    this.$api.get('guest/product-type')
      .then((response) => {
        const list = response.data;
        list.forEach((value, index) => {
          this.categories.push({
            text: value.type_name,
            html: value.type_name.replace(' ', '<br/>'),
            value: value.id,
            component: ProductRegistration,
          });

          if (this.$route.query.category === value.type_name) {
            this.selectedCategory = index + 2;
          }
        });
      });
  },
  methods: {
    goToCart() {
      const { brand } = this.$store.state;
      if (this.$store.state.user.role) {
        this.$router.push({
          name: 'Retail.ShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      } else {
        this.$router.push({
          name: 'Retail.GuestShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      }
    },
    gotoLogin() {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.Login', params: { brand } })
        .catch(() => {
        });
    },
  },
  watch: {
    selectedCategory: {
      handler() {
        setTimeout(() => {
          this.component = this.categories[this.selectedCategory].component;
          this.componentKey += 1;
        }, 350);
      },
      deep: true,
    },
  },
  computed: {
    componentProps() {
      return {
        predefinedProductTypeId: this.categories[this.selectedCategory].value,
        predefinedProductName: this.$route.query.product,
        domain: this.domain,
        tld: this.tld,
        useLocalStorage: true,
      };
    },
  },
};
</script>

<style scoped>
  .header-btn {
    margin-right: 5px;
    margin-left: 5px;
  }

  .cldy-container {
    max-width: 1350px;
    min-height: 540px;
    margin: 0 auto;
  }

  .product-category .theme--light.v-tabs .v-tab:focus:before {
    opacity: 0 !important;
  }

  .product-category .v-tab {
    color: black !important;
    font-size: 16px;
    font-weight: 600;
  }
</style>

<template>
  <v-row no-gutters dense>
    <v-col cols="10" offset="1" md="12" offset-md="0">
      <v-form ref="form" v-model="valid">
        <v-card outlined>
          <v-card-text>
            <v-row dense no-gutters>
              <v-col cols="12">
                <h3>Basic Information</h3>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-row dense>
                  <v-col cols="12" >
                    <v-text-field dense
                                  label="Domain TLD"
                                  v-model="form.tld"
                                  :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      label="Registrar"
                      v-model="form.registrar_id"
                      :items="registrars"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-switch v-model="form.allow_directly_retrieve_epp"
                          label="Allow Retrieve EPP"></v-switch>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <h3>PRICE</h3>
              </v-col>
              <v-col cols="3">
                <v-switch v-model="form.orderable" label="Orderable"></v-switch>
              </v-col>
              <v-col cols="3">
                <v-switch v-if="form.orderable" v-model="form.default_tld"
                          label="Default TLD"></v-switch>
              </v-col>
            </v-row>
            <v-row dense v-for="currency in form.currencies" v-bind:key="currency.symbol">
              <v-col cols="12">
                <h4>{{ currency.symbol }}
                  <v-btn
                          color="primary"
                          v-if="form.renewable"
                          @click="calculatePriceBasedOnMonthly()"
                  >Fill all price based on monthly price</v-btn></h4>
              </v-col>
              <v-col cols="12" v-if="form.renewable">
                <v-row>
                  <v-col cols="3" v-for="term in currency.renewal_terms" v-bind:key="term.index">
                    <v-text-field
                            dense
                            :label="term.label"
                            v-model="term.value"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" class="text-right">
                <v-btn
                        color="primary"
                        :loading="processing"
                        :disabled="processing"
                        @click="submitForm"
                >Submit</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Add',
  components: {
  },
  created() {
    this.$api.get('admin/registrar')
      .then((response) => {
        const list = response.data;
        list.forEach((value) => {
          this.registrars.push({
            text: value.registrar_name,
            value: value.id,
          });
        });
      });
  },
  data() {
    return {
      form: {
        product_name: '',
        product_description: '',
        product_type_id: 1000000,
        tld: '',
        registrar_id: null,
        allow_directly_retrieve_epp: true,
        renewable: true,
        orderable: true,
        default_tld: true,
        currencies: [
          {
            symbol: window.systemCurrency,
            currency_id: window.systemCurrencyId,
            onetime: {
              id: 1,
              label: 'One Time',
              value: null,
            },
            renewal_terms: [
              {
                id: 5,
                label: 'Annually',
                value: null,
                month: 12,
              },
              {
                id: 6,
                label: 'Biennial (2 years)',
                value: null,
                month: 24,
              },
              {
                id: 7,
                label: 'Triennial (3 years)',
                value: null,
                month: 36,
              },
              {
                id: 8,
                label: 'Quadrennial (4 years)',
                value: null,
                month: 48,
              },
              {
                id: 9,
                label: 'Quinquennial (5 years)',
                value: null,
                month: 60,
              },
              {
                id: 10,
                label: 'Sexennial (6 years)',
                value: null,
                month: 72,
              },
              {
                id: 11,
                label: 'Septennial (7 Years)',
                value: null,
                month: 84,
              },
              {
                id: 12,
                label: 'Octennial (8 Years)',
                value: null,
                month: 96,
              },
              {
                id: 13,
                label: 'Novennial (9 Years)',
                value: null,
                month: 108,
              },
              {
                id: 14,
                label: 'Decennial (10 Years)',
                value: null,
                month: 120,
              },
            ],
          },
        ],
      },
      registrars: [],
      successMessage: 'Domain product is successfully created.',
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
      valid: false,
    };
  },
  methods: {
    submitForm() {
      let priceSet = false;

      if (this.form.renewable === false) {
        this.form.currencies.forEach((onetimeTerm) => {
          if (!this.$underscore.isEmpty(onetimeTerm.onetime.value)) {
            priceSet = true;
          }
        });
      } else {
        this.form.currencies.forEach((value) => {
          const vm = this;
          value.renewal_terms.forEach((renewalTerm) => {
            if (!vm.$underscore.isEmpty(renewalTerm.value)) {
              priceSet = true;
            }
          });
        });
      }

      if (priceSet === false) {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'error',
          message: 'You must set at least 1 price term for the product.',
        });
      }

      if (this.$refs.form.validate()) {
        this.$api.post('admin/domain-product', this.form)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: this.successMessage,
            });
            this.$refs.form.reset();
          })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
    calculatePriceBasedOnMonthly() {
      if (this.$underscore.isFinite(this.form.currencies[0].renewal_terms[0].value)) {
        this.form.currencies[0].renewal_terms.forEach((value, index) => {
          if (index > 0) {
            this.form
              .currencies[0]
              .renewal_terms[index]
              .value = this.form.currencies[0].renewal_terms[0].value * (value.month / 12);
          }
        });
      }
    },
  },
  watch: {
    form: {
      handler(form) {
        if (form.orderable === false) {
          this.form.default_tld = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row>
    <v-col cols="12">
      <v-tabs v-if="initiated" class="product-type-tab">
        <v-tab v-for="productType in productTypes" :key="productType.id">
          <v-chip
            :label="true"
            x-small
            color="green"
            text-color="white">{{ productType.active_services }}</v-chip>
          {{ productType.type_name }}
        </v-tab>
      </v-tabs>
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-toolbar v-if="initiated" dense :flat="true">
        <v-row dense>
          <v-btn  small color="primary" @click="downloadCsv()">Download List CSV</v-btn>
        </v-row>
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              hide-details
              prepend-icon="mdi-text-search"
              single-line
              v-model="search"
              @keyup.enter.native="loadTable()"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn small color="primary" @click="loadTable()">Search</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.status_name="{ item }">
          <b v-if="item.status_name == 'Active'" class="green--text">{{ item.status_name }}</b>
          <b v-else class="red--text">{{ item.status_name }}</b>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.status_name === 'Active'"
            x-small
            color="success"
            @click="view(item)"
          >
            Manage
          </v-btn>&nbsp;&nbsp;
          <v-btn
            v-if="item.status_name != 'Pending Provision'
            && item.status_name != 'Pending Transfer'
            && item.status_name != 'Transfer In Progress'
            && item.status_name != 'Transfer Invalid EPP Code'
            && item.status_name != 'Requesting Transfer Info'
            && item.status_name != 'Cancelled'
            && ($store.state.user.user_settings.userId == 12 ||
            $store.state.user.user_settings.userId == 176)
            "
            x-small
            color="success"
            @click="editDns(item)"
          >
            Edit DNS
          </v-btn>&nbsp;&nbsp;
          <v-btn
            v-if="item.status_name == 'Requesting Transfer Info'"
            x-small
            color="success"
            @click="transfer(item)"
          >
            Transfer
          </v-btn>&nbsp;&nbsp;
          <v-btn
            v-if="item.status_name == 'Transfer Invalid EPP Code'"
            x-small
            color="error"
            @click="openEppCodeDialog(item)"
          >
            Retry EPP Code
          </v-btn>&nbsp;&nbsp;
          <v-btn
            v-if="item.status_name == 'Active'
            || item.status_name == 'Expired'"
            x-small
            color="primary"
            @click="openRenewDialog(item)"
          >
            Renew
          </v-btn>&nbsp;&nbsp;
          <v-btn x-small
                 color="primary"
                 v-if="item.status_name == 'Active' ||
                 item.status_name == 'Expired'
                 "
                 @click="openServiceTransferDialog(item)">
            Transfer to client
          </v-btn>
        </template>

        <template v-slot:item.auto_renew="{ item }">
          <v-switch
            v-if="item.status_name == 'Active'"
            v-model="item.auto_renew"
            :loading="item.loading"
            :readonly="true"
            @click.stop="toggleAutoRenew(item)"
          ></v-switch>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog
      v-model="submitNewEppCodeDialog"
      width="1024"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    type="text"
                    label="EPP Code"
                    v-model="newEppCode"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="submitNewEppCodeDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="submitNewEppCodeLoading"
            @click="submitNewEppCode()"
          >
            Submit New EPP Code
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <service-transfer-dialog
      :show="showServiceTransferDialog"
      v-on:closeServiceTransferDialog="showServiceTransferDialog = false"
      :service-id="serviceId" :service-identity="serviceIdentity" :service-name="serviceName"
    ></service-transfer-dialog>
    <service-renew-dialog
      v-if="showServiceRenewDialog"
      v-on:close-dialog="showServiceRenewDialog = false"
      :service-id="serviceId"
    ></service-renew-dialog>
  </v-row>
</template>

<script>
import ServiceTransferDialog from '../../components/ServiceTransferDialog.vue';
import ServiceRenewDialog from '../../components/dialogs/ServiceRenewDialog.vue';

export default {
  name: 'List',
  components: {
    ServiceTransferDialog,
    ServiceRenewDialog,
  },
  data() {
    return {
      search: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      strange: false,
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Domain', value: 'service_identity' },
        { text: 'Status', value: 'status_name' },
        { text: 'Term', value: 'term_name' },
        // { text: 'Price', value: 'renewal_price' },
        { text: 'Registration Date', value: 'provision_date' },
        { text: 'Expiry Date', value: 'expiry_date' },
        { text: 'Auto Renew', value: 'auto_renew' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      loginLoading: false,
      cancelDialog: false,
      cancelItem: null,
      cancelLoading: false,
      renewDialog: false,
      renewItem: null,
      renewLoading: false,
      renewCheckoutLoading: false,
      renewalTerms: [],
      renewalTerm: null,
      resetPasswordDialog: false,
      resetPasswordItem: null,
      resetPasswordLoading: false,
      resetPasswordSaveLoading: false,
      resetPasswordError: false,
      newPassword: null,
      showPassword: false,
      downgradeDialog: false,
      downgradeItem: null,
      downgradeLoading: false,
      upgradeDialog: false,
      upgradeItem: null,
      upgradeLoading: false,
      upgrades: [],
      upgrade: null,
      upgradeLoaded: false,
      downgrades: [],
      downgrade: null,
      downgradeLoaded: false,
      submitNewEppCodeDialog: false,
      submitNewEppCodeLoading: false,
      submitNewEppCodeItem: null,
      newEppCode: '',
      showServiceTransferDialog: false,
      showServiceRenewDialog: false,
      serviceId: '',
      serviceName: '',
      serviceIdentity: '',
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    document.addEventListener('keyup', this.enterClickAction);
    this.$api.get('partner/domain-product-type/menu').then((response) => {
      this.productTypes = response.data;
      this.productTypeId = response.data[0].id;
      this.loadTable();
    });
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.enterClickAction);
  },
  methods: {
    enterClickAction(e) {
      if (e.code === 'Enter') {
        this.loadTable();
      }
    },
    login(item) {
      this.loginLoading = true;
      this.$api.post('partner/service/login', {
        id: item.id,
      }).then((response) => {
        window.open(response.data.data.url, '_blank');
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.loginLoading = false;
      });
    },
    openRenewDialog(item) {
      this.showServiceRenewDialog = true;
      this.serviceId = item.id;
    },
    openCancelDialog(item) {
      this.cancelDialog = true;
      this.cancelItem = item;
    },
    openDowngradeDialog(item) {
      this.downgrade = null;
      this.downgradeDialog = true;
      this.downgradeItem = item;
      this.downgradeLoaded = false;
      this.downgrades.splice(0, this.downgrades.length);
      this.$api.post('partner/service/get-downgrades', {
        id: item.id,
      }).then((response) => {
        response.data.forEach((downgrade, index) => {
          this.downgrades.push({
            text: `${downgrade.product_name} $${downgrade.product_price}`,
            value: downgrade,
          });

          if (index === 0) {
            this.downgrade = downgrade;
          }
        });
      }).finally(() => {
        this.downgradeLoaded = true;
      });
    },
    openUpgradeDialog(item) {
      this.upgrade = null;
      this.upgradeDialog = true;
      this.upgradeItem = item;
      this.upgradeLoaded = false;
      this.upgrades.splice(0, this.upgrades.length);
      this.$api.post('partner/service/get-upgrades', {
        id: item.id,
      }).then((response) => {
        response.data.forEach((upgrade, index) => {
          this.upgrades.push({
            text: `${upgrade.product_name} ${upgrade.term_name} $${upgrade.product_price}`,
            value: upgrade,
          });

          if (index === 0) {
            this.upgrade = upgrade;
          }
        });
      }).finally(() => {
        this.upgradeLoaded = true;
      });
    },
    openResetPasswordDialog(item) {
      this.resetPasswordItem = item;
      this.resetPasswordDialog = true;
      this.newPassword = '';
    },
    toggleAutoRenew(touchedItem) {
      const foundItemIndex = this.items.findIndex((item) => item.id === touchedItem.id);
      if (foundItemIndex >= 0) {
        const r = window.confirm(`Are you sure to toggle the auto renew for ${this.items[foundItemIndex].service_identity}?`);
        if (r === true) {
          this.$set(this.items[foundItemIndex], 'loading', true);
          this.$api.post('partner/service/set-auto-renew', {
            id: this.items[foundItemIndex].id,
            auto_renew: this.items[foundItemIndex].auto_renew ? 0 : 1,
          }).then(() => {
            this.$set(this.items[foundItemIndex], 'auto_renew', !this.items[foundItemIndex].auto_renew);
            const successMessage = this.items[foundItemIndex].auto_renew ? 'on' : 'off';
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: `Auto renew is turned ${successMessage}.`,
            });
          }).catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          }).finally(() => {
            this.$set(this.items[foundItemIndex], 'loading', false);
          });
        }
      }
    },
    view(item) {
      this.$router.push({ name: 'Partner.DomainDetail', params: { id: item.id } });
    },
    editDns(item) {
      this.$router.push({ name: 'Partner.DnsEditor', params: { id: item.id } });
    },
    transfer(item) {
      this.$router.push({ name: 'Partner.DomainRequestTransfer', params: { id: item.id } });
    },
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('partner/domain/list', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        product_type_id: this.productTypeId,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
        this.initiated = true;
      });
    },
    edit(item) {
      this.$router.push({ name: 'Admin.ProductDetail', params: { id: item.id } });
    },
    renew() {
      this.renewCheckoutLoading = true;
      this.$api.post('partner/service/renew', {
        id: this.renewItem.id,
        product_term: this.renewalTerm,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your renewal request is submitted successfully.',
        });
        this.renewDialog = false;
        this.loadTable();
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.renewCheckoutLoading = false;
      });
    },
    resetPassword() {
      this.resetPasswordSaveLoading = true;
      this.$api.post('partner/service/reset-password', {
        id: this.resetPasswordItem.id,
        new_password: this.newPassword,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your hosting password is reset successfully.',
        });
        this.resetPasswordDialog = false;
      }).catch((error) => {
        this.resetPasswordError = true;
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.resetPasswordSaveLoading = false;
      });
    },
    cancel() {
      this.cancelLoading = true;
      this.$api.delete(`partner/service/${this.cancelItem.id}`)
        .then(() => {
          this.cancelDialog = false;
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'You have successfully cancelled this service.',
          });
        })
        .catch(() => {
        })
        .finally(() => {
          this.cancelLoading = false;
          this.loadTable();
        });
    },
    processUpgrade() {
      this.upgradeLoading = true;
      this.$api.post('partner/service/upgrade', {
        id: this.upgradeItem.id,
        product_price_id: this.upgrade.product_price_id,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your upgrade request is submitted successfully.',
        });
        this.upgradeDialog = false;
        this.loadTable();
      }).finally(() => {
        this.upgradeLoading = false;
      });
    },
    processDowngrade() {
      this.downgradeLoading = true;
      this.$api.post('partner/service/downgrade', {
        id: this.downgradeItem.id,
        product_price_id: this.downgrade.product_price_id,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your downgrade request is submitted successfully.',
        });
        this.downgradeDialog = false;
        this.loadTable();
      }).finally(() => {
        this.downgradeLoading = false;
      });
    },
    openEppCodeDialog(item) {
      this.submitNewEppCodeItem = item;
      this.submitNewEppCodeDialog = true;
      this.newEppCode = '';
    },
    submitNewEppCode() {
      this.submitNewEppCodeLoading = true;
      this.$api.post(`partner/domain/${this.submitNewEppCodeItem.id}/retry-epp-code`, {
        epp_code: this.newEppCode,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your transfer request is submitted successfully.',
        });
        this.loadTable();
        this.submitNewEppCodeDialog = false;
      }).finally(() => {
        this.submitNewEppCodeLoading = false;
      });
    },
    openServiceTransferDialog(item) {
      this.serviceId = item.id;
      this.serviceName = item.service_name;
      this.serviceIdentity = item.service_identity;
      this.showServiceTransferDialog = true;
    },
    downloadCsv() {
      const authToken = window.btoa(this.$store.state.user.auth_token);
      window.open(`${process.env.VUE_APP_API_URL}/v1/partner/domain/download-csv?auth_token=${authToken}`, '_blank');
    },
  },
};
</script>

<style scoped>
  table td {
    font-size: 0.75rem !important;
  }

  .product-type-tab .v-tab {
    justify-content: flex-start;
  }

  .product-type-tab .v-chip {
    margin-right: 8px;
  }
</style>

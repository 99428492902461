<template>
  <v-row no-gutters dense class="saved-card" v-if="savedCard">
    <v-col cols="4">
      <h2>{{ savedCard.vendor }} ******{{ savedCard.last_four_digits }}</h2>
    </v-col>
    <v-col cols="4">
      <h2>{{ savedCard.expiry_month }}/{{ savedCard.expiry_year}}</h2>
    </v-col>
    <v-col cols="2">
      <v-btn x-small color="error" @click="removeCard"
             :loading="removeCardBtnLoading"
             :readonly="removeCardBtnLoading">
        Remove
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PaypalSavedCards',
  mounted() {
    this.init();
  },
  data() {
    return {
      savedCard: null,
      removeCardBtnLoading: false,
    };
  },
  methods: {
    init() {
      let postData = '';

      if (this.$route.params.token) {
        postData = `&token=${this.$route.params.token}`;
      } else if (this.$route.query.token) {
        postData = `&token=${this.$route.query.token}`;
      }

      this.$api.get(`partner/payment/get-saved-cards?payment_type_id=4${postData}`).then((response) => {
        if (response.data !== '') {
          this.savedCard = response.data;
          this.$emit('update:card', this.savedCard);
          this.$emit('update:hasCard', true);
        } else {
          this.$emit('update:hasCard', false);
        }
      });
    },
    reinit() {
      this.savedCard = null;
      this.init();
    },
    removeCard() {
      const postData = { id: this.savedCard.id };

      if (this.$route.params.token) {
        postData.token = this.$route.params.token;
      } else if (this.$route.query.token) {
        postData.token = this.$route.query.token;
      }

      this.removeCardBtnLoading = true;
      this.$api.post('partner/payment/remove-saved-card', postData)
        .then(() => {
          this.reinit();
          this.$emit('remove-card');
        }).finally(() => {
          this.removeCardBtnLoading = false;
        });
    },
  },
};
</script>

<style scoped>
  .saved-card {
    margin-bottom: 20px;
  }
</style>

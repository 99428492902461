<template>
  <v-row :key="componentKey">
    <v-col cols="12">
      <v-radio-group
        v-model="form.userType"
        row
      >
        <v-radio
          label="Partner"
          value="partner"
        ></v-radio>
        <v-radio
          label="Customer"
          value="customer"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12">
      <partner-dropdown
        v-if="form.userType == 'partner'"
        label="Partner"
        :rules="[rules.required]"
        :single="true"
        :partners.sync="form.partner"
        :nochips="true"
      ></partner-dropdown>
      <customer-dropdown
        v-if="form.userType == 'customer'"
        label="Customer"
        :rules="[rules.required]"
        :single="true"
        :partners.sync="form.customer"
        :nochips="true"
      ></customer-dropdown>
    </v-col>
    <v-col cols="12">
      <v-btn @click="addItem()">Add Item</v-btn>
    </v-col>
    <v-col cols="12" v-for="(item, index) in form.items" v-bind:key="index">
      <v-row>
        <v-col cols="8">
          <v-textarea label="Item description" v-model="item.desc"></v-textarea>
        </v-col>
        <v-col cols="2">
          <v-text-field label="Price" v-model="item.price"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn @click="form.items.splice(index,1)">Remove</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="text-right">
      <v-btn color="primary" @click="submit">Submit</v-btn>
    </v-col>
  </v-row>
</template>
<script>
import PartnerDropdown from '../../components/PartnerDropdown.vue';
import CustomerDropdown from '../../components/CustomerDropdown.vue';

export default {
  name: 'ManualInvoice',
  components: {
    PartnerDropdown,
    CustomerDropdown,
  },
  data() {
    return {
      componentKey: 0,
      form: {
        userType: 'partner',
        customer: null,
        partner: null,
        items: [
          {
            desc: '',
            price: '',
          },
        ],
      },
      rules: {
        required: (value) => (value !== 0 && !!value) || 'Required.',
      },
    };
  },
  methods: {
    addItem() {
      this.form.items.push({
        desc: '',
        price: '',
      });
    },
    submit() {
      this.$api.post('admin/payment/manual-invoice', this.form)
        .then(() => {
          if (this.form.userType === 'partner') {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Credit log is added. Charge is done.',
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Invoice is created successfully.',
            });
          }

          this.componentKey += 1;
          this.$set(this.form, 'items', [
            {
              desc: '',
              price: '',
            },
          ]);
        });
    },
  },
};
</script>

<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense align="center">
        <v-col cols="12" md="8">
          <v-text-field
            dense
            outlined
            label="Domain"
            v-model="moduleData.domain"
            :rules="[rules.required, rules.domain]"
            @keydown="typing()"
          ></v-text-field>
        </v-col>
        <v-col cols="4" v-if="showDomainRegistrationButton" class="btn-group">
          <v-btn
            small
            color="primary"
            @click="showDomainRegistrationForm()">Register</v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="6"
          v-if="moduleData.customer_field1 !== null && moduleData.customer_field1.length > 0">
          <v-text-field
            :label="moduleData.customer_field1"
            v-model="moduleData.customer_field1_value"
            :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col
          cols="6"
          v-if="moduleData.customer_field2 !== null && moduleData.customer_field2.length > 0">
          <v-text-field
            :label="moduleData.customer_field2"
            v-model="moduleData.customer_field2_value"
            :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col
          cols="6"
          v-if="moduleData.customer_field3 !== null && moduleData.customer_field3.length > 0">
          <v-text-field
            :label="moduleData.customer_field3"
            v-model="moduleData.customer_field3_value"
            :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col
          cols="6"
          v-if="moduleData.customer_field4 !== null && moduleData.customer_field4.length > 0">
          <v-text-field
            :label="moduleData.customer_field4"
            v-model="moduleData.customer_field4_value"
            :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col
          cols="6"
          v-if="moduleData.customer_field5 !== null && moduleData.customer_field5.length > 0">
          <v-text-field
            :label="moduleData.customer_field5"
            v-model="moduleData.customer_field5_value"
            :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col
          cols="6"
          v-if="moduleData.customer_field6 !== null && moduleData.customer_field6.length > 0">
          <v-text-field
            :label="moduleData.customer_field6"
            v-model="moduleData.customer_field6_value"
            :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col
          cols="6"
          v-if="moduleData.customer_field7 !== null && moduleData.customer_field7.length > 0">
          <v-text-field
            :label="moduleData.customer_field7"
            v-model="moduleData.customer_field7_value"
            :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col
          cols="6"
          v-if="moduleData.customer_field8 !== null && moduleData.customer_field8.length > 0">
          <v-text-field
            :label="moduleData.customer_field8"
            v-model="moduleData.customer_field8_value"
            :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col
          cols="6"
          v-if="moduleData.customer_field9 !== null && moduleData.customer_field9.length > 0">
          <v-text-field
            :label="moduleData.customer_field9"
            v-model="moduleData.customer_field9_value"
            :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col
          cols="6"
          v-if="moduleData.customer_field10 !== null && moduleData.customer_field10.length > 0">
          <v-text-field
            :label="moduleData.customer_field10"
            v-model="moduleData.customer_field10_value"
            :rules="[rules.required]"></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      moduleData: {
        domain: '',
        customer_field1: '',
        customer_field2: '',
        customer_field3: '',
        customer_field4: '',
        customer_field5: '',
        customer_field6: '',
        customer_field7: '',
        customer_field8: '',
        customer_field9: '',
        customer_field10: '',
        customer_field1_value: '',
        customer_field2_value: '',
        customer_field3_value: '',
        customer_field4_value: '',
        customer_field5_value: '',
        customer_field6_value: '',
        customer_field7_value: '',
        customer_field8_value: '',
        customer_field9_value: '',
        customer_field10_value: '',
      },
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || 'This field must be valid domain name.',
      },
    };
  },
  mounted() {
    this.$underscore.each(this.parentModuleData, (propV, prop) => {
      if (typeof this.moduleData[prop] !== 'undefined') {
        this.$set(this.moduleData, prop, propV);
      }
    });
  },
  props: {
    parentModuleData: {
      type: Object,
      default() {
        return {
          domain: '',
          customer_field1: '',
          customer_field2: '',
          customer_field3: '',
          customer_field4: '',
          customer_field5: '',
          customer_field6: '',
          customer_field7: '',
          customer_field8: '',
          customer_field9: '',
          customer_field10: '',
          customer_field1_value: '',
          customer_field2_value: '',
          customer_field3_value: '',
          customer_field4_value: '',
          customer_field5_value: '',
          customer_field6_value: '',
          customer_field7_value: '',
          customer_field8_value: '',
          customer_field9_value: '',
          customer_field10_value: '',
        };
      },
    },
    showDomainRegistrationButton: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    showDomainRegistrationForm() {
      this.$emit('showDomainRegistrationForm');
    },
    typing() {
      this.$emit('typing');
    },
  },
  watch: {
    moduleData: {
      handler(newModuleData) {
        this.$emit('update:moduleData', newModuleData);
      },
      deep: true,
    },
    parentModuleData: {
      handler(newParentModuleData) {
        this.$underscore.each(newParentModuleData, (propV, prop) => {
          if (typeof this.moduleData[prop] !== 'undefined') {
            this.$set(this.moduleData, prop, propV);
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

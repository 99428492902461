<template>
  <v-row dense align="center" no-gutters>
    <v-col cols="3">
      <v-text-field
        dense
        outlined
        v-model="form.domain"
        label="Domain"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-radio-group v-model="type" row>
        <v-radio value="admin" label="Change Admin Contact"></v-radio>
        <v-radio value="billing" label="Change Billing Contact"></v-radio>
        <v-radio value="tech" label="Change Tech Contact"></v-radio>
        <v-radio value="all" label="Change Admin, Billing, Tech Contact"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12">
      <v-radio-group v-model="contactMode" row>
        <v-radio value="gce" label="GCE (New)"></v-radio>
        <v-radio value="cldy" label="CLDY (New)"></v-radio>
        <v-radio value="10383918" label="GCE (10383918)"></v-radio>
        <v-radio value="10578227" label="CLDY (10578227)"></v-radio>
        <v-radio value="custom" label="Custom"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="6" v-if="contactMode === 'custom'">
      <h5>Admin Contact</h5>
      <profile
        v-if="profile === 1"
        ref="adminProfileComp"
        :setNewProfile="admin"
      ></profile>
      <profile-sg
        v-if="profile === 2"
        ref="adminProfileCompSg"
        :setNewProfile="admin"
      ></profile-sg>
      <profile-au
        v-if="profile === 3"
        ref="adminProfileCompAu"
        :setNewProfile="admin"
      ></profile-au>
    </v-col>
    <v-col cols="12">
      <v-btn
        color="primary"
        :loading="updateAdminLoading"
        @click="updateAdmin"
      >Update</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import Profile from '../../components/Profile.vue';
import ProfileSg from '../../components/ProfileSg.vue';
import ProfileAu from '../../components/ProfileAu.vue';

export default {
  name: 'ForceDsDomainAdminChange',
  components: {
    Profile,
    ProfileSg,
    ProfileAu,
  },
  created() {
  },
  data() {
    return {
      type: 'admin',
      contactMode: 'gce',
      profile: 1,
      profileContact: this.$refs.adminProfileComp,
      form: {
        domain: '',
      },
      updateAdminLoading: false,
    };
  },
  methods: {
    updateAdmin() {
      this.updateAdminLoading = true;
      this.$api.post('admin/force-ds-admin-contact', {
        domain: this.form.domain,
        mode: this.contactMode,
        contact: this.contactMode === 'custom' ? this.profileContact.getProfileData() : [],
        type: this.type,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Admin contact is updated successfully.',
        });
      }).finally(() => {
        this.updateAdminLoading = false;
      });
    },
  },
  watch: {
    'form.domain': {
      handler(domain) {
        if (domain.indexOf('.sg') > 0) {
          this.profile = 2;
          this.profileContact = this.$refs.adminProfileCompSg;
        } else if (domain.indexOf('.au') > 0) {
          this.profile = 3;
          this.profileContact = this.$refs.adminProfileCompAu;
        } else {
          this.profile = 1;
          this.profileContact = this.$refs.adminProfileComp;
        }
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-container
    class="base"
  >
    <v-row dense>
      <v-col cols="12">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from '@/store';

export default {
  name: 'CpBase',
  data() {
    return {
    };
  },
  metaInfo: {
    title: 'Client Portal',
    titleTemplate: (titleChunk) => {
      if (store.state.user.company_name) {
        return `${titleChunk} | ${store.state.user.company_name}`;
      }

      return titleChunk;
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>
</style>

<template>
  <v-row dense no-gutters class="dashboard-block">
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12" md="6" class="min-height-250">
          <v-card>
            <v-card-title class="headline">Balance</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <h4>Active Balance: ${{ balance }}</h4>
                  <h4>Locked Balance: ${{ lockedBalance }}</h4>
                  <h4>Credit Limit: ${{ creditLimit }}</h4>
                </v-col>
                <v-col cols="6">
                  <h4>Renewal Forecast:</h4>
                  <h4 v-for="(forecast, month) in forecasts" :key="month">
                    {{ month }}: ${{ forecast }}
                  </h4>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn text small
                     @click="$router.push({name: 'Partner.Topup'})">Top Up</v-btn>
              <v-btn text small
                     @click="$router.push({name: 'Partner.BalanceList'})"
              >Balance Log</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="min-height-250">
          <v-card>
            <v-card-title class="headline">Waiting Approval Invoice</v-card-title>
            <v-card-text class="scrollable">
              <v-row v-for="invoice in pendingInvoices" :key="invoice.id">
                <v-col>{{ invoice.created_at }}</v-col>
                <v-col>
                  {{ invoice.first_name }} {{ invoice.last_name }}
                  {{ invoice.customer_company_name }}
                </v-col>
                <v-col>${{ invoice.total }}</v-col>
                <v-col>
                  <v-btn text small
                            @click="$router.push({
                              name: 'Partner.ResellerCustomerInvoiceDetail',
                              params: {'id': invoice.id}
                            })">Goto {{ invoice.invoice_no }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn text small
                     @click="$router.push({name: 'Partner.ResellerCustomerInvoiceList'})"
              >Full List</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12" md="6" >
          <v-card>
            <v-card-title class="headline">Services</v-card-title>
            <v-card-text>
              <h4>Active Services: {{ activeServices }}</h4>
              <h4>Expired Services: {{ expiredServices }}</h4>
            </v-card-text>
            <v-card-actions>
              <v-btn text small
                     @click="$router.push({name: 'Partner.ServiceList'})"
              >View All</v-btn>
              <v-btn text small
                     @click="$router.push({name: 'Partner.ServiceRegister'})"
              >Register</v-btn>
              <v-btn text small
                     @click="$router.push({name: 'Partner.ServiceRenew'})"
              >Renew</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" >
          <v-card>
            <v-card-title class="headline">Domains</v-card-title>
            <v-card-text>
              <h4>Active Domains: {{ activeDomains }}</h4>
              <h4>Expired Domains: {{ expiredDomains }}</h4>
            </v-card-text>
            <v-card-actions>
              <v-btn text small
                     @click="$router.push({name: 'Partner.DomainList'})"
              >View All</v-btn>
              <v-btn text small
                     @click="$router.push({name: 'Partner.DomainRegister'})"
              >Register</v-btn>
              <v-btn text small
                     @click="$router.push({name: 'Partner.DomainTransfer'})"
              >Transfer</v-btn>
              <v-btn text small
                     @click="$router.push({name: 'Partner.DomainRenew'})"
              >Renew</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12">
          <v-card class="tickets" height="268px">
            <v-card-title class="headline">Support Tickets</v-card-title>
            <v-card-text v-if="tickets.length !== 0">
              <v-list two-line class="news-list">
                <template v-for="(item, index) in tickets">
                  <v-list-item :key="item.title">
                    <template v-slot:default="">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-subtitle class="text--primary" v-text="item.headline">
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text v-text="item.datetime"></v-list-item-action-text>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index + 1 < items.length"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list>
            </v-card-text>
            <v-card-text class="no-tickets" v-if="tickets.length === 0">
              <h2>No Open Tickets.</h2>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12">
          <v-card class="news" height="268px">
            <v-card-title class="headline">
              <span>News</span>
            </v-card-title>
            <v-card-text v-if="news.length !== 0">
              <v-list two-line class="news-list">
                <template v-for="(item, index) in news">
                  <v-list-item :key="item.title">
                    <template v-slot:default="">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-subtitle class="text--primary" v-text="item.headline">
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text v-text="item.datetime"></v-list-item-action-text>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index + 1 < items.length"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list>
            </v-card-text>
            <v-card-text class="no-news" v-if="news.length === 0">
              <h2>No News.</h2>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      balance: 0,
      lockedBalance: 0,
      creditLimit: 0,
      activeServices: 0,
      expiredServices: 0,
      activeDomains: 0,
      expiredDomains: 0,
      forecasts: null,
      news: [
      ],
      tickets: [
      ],
      pendingInvoices: [
      ],
    };
  },
  created() {
    this.$api.get('partner/summary').then((response) => {
      this.balance = response.data.balance;
      this.lockedBalance = response.data.locked_balance;
      this.creditLimit = response.data.credit_limit;
      this.activeServices = response.data.active_services;
      this.expiredServices = response.data.expired_services;
      this.activeDomains = response.data.active_domains;
      this.expiredDomains = response.data.expired_domains;
      this.pendingInvoices = response.data.pending_invoices;
      this.$set(this, 'forecasts', response.data.renewal_forecast);
    });
  },
};
</script>

<style scoped>
  .v-application .headline, .tickets .headline, .news .headline {
    color: rgba(0,0,0,.6);
  }
  .no-tickets h2, .no-news h2 {
    color: rgba(0,0,0,.6);
  }
  .news-list {
    height: 180px;
    overflow-y: auto;
    border-radius: 7px;
    padding: 0;
  }
  .no-tickets, .no-news {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 64px);
  }
  .min-height-250 .v-card {
    min-height: 250px;
    height: 250px;
  }
  .v-card__text.scrollable {
    height: calc(100% - 110px);
    overflow: auto;
  }
  .v-card {
    /*background-color: #F3F9FF;*/
    background-color: #fff;
    border-radius: 12px;
  }
  .v-application .headline {
    background-color: #f3f9ff;
    padding-top: 12px;
    padding-bottom: 6px;
    border-bottom: 2px solid lightgray;
    margin-bottom: 8px;
    font-size: 1.2rem !important;
    font-weight: bold;
  }
  .dashboard-block .row--dense>.col, .dashboard-block .row--dense>[class*=col-] {
    padding: 8px;
  }
</style>

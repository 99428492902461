<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>CP Login</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form"
                    v-model="formValid">
              <v-text-field v-if="loginMethod === 'email'"
                            label="Email"
                            name="email2"
                            prepend-icon="mdi-email"
                            type="text"
                            v-model="email2"
                            :error="error.email2"
                            :error-messages="errorMessage.email2"
                            :rules="[rules.required]"
              >
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="sso-btn"
              color="primary"
              outlined
              :loading="processing"
              :disabled="processing"
              @click="login()"
            ><b>Login</b></v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div style="width: 224px;
            height: 12px;
            border-bottom: 1px solid black;
            text-align: center;
            margin-bottom: 8px;">
              <span style="
              font-weight: bold;
              font-size: 12px; background-color: white; padding: 0 32px;">
                OR
              </span>
            </div>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="sso-btn"
              color="primary"
              outlined
              @click="signInGoogle()"
            >
              <!-- eslint-disable-next-line -->
              <svg viewBox="0 0 20 20" class="googleLogo" style="width: 14px; height: 14px; display: block; fill: inherit; flex-shrink: 0; backface-visibility: hidden; margin-right: 6px;"><g><path d="M19.9996 10.2297C19.9996 9.54995 19.9434 8.8665 19.8234 8.19775H10.2002V12.0486H15.711C15.4823 13.2905 14.7475 14.3892 13.6716 15.0873V17.586H16.9593C18.89 15.8443 19.9996 13.2722 19.9996 10.2297Z" fill="#4285F4"></path><path d="M10.2002 20.0003C12.9518 20.0003 15.2723 19.1147 16.963 17.5862L13.6753 15.0875C12.7606 15.6975 11.5797 16.0429 10.2039 16.0429C7.54224 16.0429 5.28544 14.2828 4.4757 11.9165H1.08301V14.4923C2.81497 17.8691 6.34261 20.0003 10.2002 20.0003Z" fill="#34A853"></path><path d="M4.47227 11.9163C4.04491 10.6743 4.04491 9.32947 4.47227 8.0875V5.51172H1.08333C-0.363715 8.33737 -0.363715 11.6664 1.08333 14.4921L4.47227 11.9163Z" fill="#FBBC04"></path><path d="M10.2002 3.95756C11.6547 3.93552 13.0605 4.47198 14.1139 5.45674L17.0268 2.60169C15.1824 0.904099 12.7344 -0.0292099 10.2002 0.000185607C6.34261 0.000185607 2.81497 2.13136 1.08301 5.51185L4.47195 8.08764C5.27795 5.71762 7.53849 3.95756 10.2002 3.95756Z" fill="#EA4335"></path></g></svg>
              <b>Sign In With Google</b>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="multiAccountDialog"
      width="512"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12">
              Multiple accounts are assigning you as collaborator,
              please select right email to login with.
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" v-for="account in accounts" :key="account.id"
                   @click="selectAccount(account.id)"
                   :class="{'account-selected': account.id === accountId,
               'currency-row': true}"
            >
              <h2>
                <b>{{ account.email }}</b>
              </h2>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :loading="processing"
            @click="loginMultiAccount()"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'CpLogin',
  data() {
    return {
      brand: 'cpcldy',
      formValid: false,
      processing: false,
      valid: false,
      email: 'support@cldy.com',
      email2: '',
      username: '',
      password: '',
      loginMethod: 'email',
      error: {
        email: false,
        email2: false,
        username: false,
        password: false,
      },
      errorMessage: {
        email: '',
        email2: '',
        username: '',
        password: '',
      },
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
      hidePartner: true,
      multiAccountDialog: false,
      accountId: null,
      accounts: null,
      multiAccountLoginDirect: false,
    };
  },
  async mounted() {
    const { host } = window.location;

    if (host.indexOf('gocloudeasy') > 0) {
      this.brand = 'cpretailgce';
    } else {
      this.brand = 'cpcldy';
    }

    if (this.$route.query.token) {
      await this.$api.post('cp/login', {
        brand: this.brand,
        login_method: 'token',
        token: this.$route.query.token,
      }).then((response) => {
        this.$store.dispatch('setCurrentUser', response.data);
        this.$store.dispatch('showDashboard');
        localStorage.setItem('app', JSON.stringify(this.$store.state));
        this.$router.push({
          name: 'CpLoginCpanel',
        });
      })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
          this.$store.dispatch('reset');
        });
    }

    if (this.$route.query.sso) {
      if (this.$route.query.sso === 'google') {
        this.verifySignInGoogle();
      }
    }
  },
  methods: {
    login() {
      this.multiAccountLoginDirect = false;
      if (this.$refs.form.validate()) {
        this.processing = true;
        this.$api.post('cp/login', {
          brand: this.brand,
          email: this.email,
          email2: this.email2,
          login_method: this.loginMethod,
          username: this.username,
          password: this.password,
        }).then((response) => {
          if (this.loginMethod === 'email') {
            if (response.data.multi_collab) {
              this.multiAccountDialog = true;
              const accounts = {};
              response.data.collaborators.forEach((collaborator) => {
                accounts[collaborator.id] = collaborator;
              });
              this.accounts = accounts;
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'success',
                message: 'A single sign on link has been sent to your email.',
              });
            }
          } else {
            this.$store.dispatch('setCurrentUser', response.data);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({
              name: 'CpLoginCpanel',
            });
          }
        })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
    signInGoogle() {
      this.$api.post('cp/sso/auth-url', {
        type: 'google',
        brand: this.brand,
      }).then((response) => {
        if (response.data) {
          window.location.href = response.data;
        }
      });
    },
    selectAccount(id) {
      this.accountId = id;
    },
    loginMultiAccount() {
      this.processing = true;
      if (this.multiAccountLoginDirect) {
        this.$store.dispatch('setCurrentUser', this.accounts[this.accountId]);
        this.$store.dispatch('showDashboard');
        localStorage.setItem('app', JSON.stringify(this.$store.state));
        this.$router.push({
          name: 'CpLoginCpanel',
        });
      } else {
        this.email2 = this.accounts[this.accountId].email;
        this.$api.post('cp/login', {
          brand: this.brand,
          email: this.email,
          email2: this.email2,
          login_method: this.loginMethod,
          username: this.username,
          password: this.password,
          uid: this.accounts[this.accountId].uid,
        }).then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'A single sign on link has been sent to your email.',
          });
        })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
    verifySignInGoogle() {
      this.$api.post('cp/sso/login', {
        type: 'google',
        brand: this.brand,
        code: this.$route.query.code,
      }).then((response) => {
        if (response.data.multi_collab) {
          this.multiAccountDialog = true;
          const accounts = {};
          response.data.collaborators.forEach((collaborator) => {
            accounts[collaborator.id] = collaborator;
          });
          this.accounts = accounts;
          this.multiAccountLoginDirect = true;
        } else {
          this.$store.dispatch('setCurrentUser', response.data);
          this.$store.dispatch('showDashboard');
          localStorage.setItem('app', JSON.stringify(this.$store.state));
          this.$router.push({
            name: 'CpLoginCpanel',
          });
        }
      })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<style scoped>
  .sso-btn {
    min-width: 224px !important;
  }

  .account-selected {
    border: 1px solid #07f;
  }
</style>

<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Request New Hosting</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form"
                    v-model="formValid">
              <v-text-field
                label="Email"
                name="email"
                prepend-icon="mdi-account"
                type="text"
                v-model="email"
                :error="error.email"
                :error-messages="errorMessage.email"
                :rules="[rules.strongpassword]"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="processing"
              :disabled="processing"
              @click="requestNewHosting()"
            >Request</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'RequestNewHosting',
  data() {
    return {
      processing: false,
      valid: false,
      email: '',
      error: {
        email: false,
      },
      errorMessage: {
        email: '',
      },
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
    };
  },
  mounted() {
  },
  methods: {
    requestNewHosting() {
      if (this.$refs.form.validate()) {
        this.processing = true;
        this.$api.post('request-new-hosting', {
          email: this.email,
        }).then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: '',
          });
        }).finally(() => {
          this.processing = false;
        });
      }
    },
  },
};
</script>

<style scoped>
</style>

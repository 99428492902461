<template>
  <div id="paypal-button-container" class="paypal-button-container"></div>
</template>

<script>
export default {
  name: 'PaypalExpress',
  data() {
    return {
      paypalForm: null,
      cardEl: {
        cardNo: '',
        expiry: '',
        cvv: '',
      },
      error: '',
    };
  },
  mounted() {
    this.$api.post('partner/payment/paypal-client-token', {})
      .then((response) => {
        const cur = window.systemCurrency.toUpperCase();
        const PAYPAL_SCRIPT = `https://www.paypal.com/sdk/js?components=buttons,hosted-fields&intent=authorize&currency=${cur}&client-id=${response.data.client_id}`;
        const script = document.createElement('script');
        script.setAttribute('src', PAYPAL_SCRIPT);
        script.setAttribute('data-client-token', response.data.client_token);
        script.setAttribute('data-csp-nonce', 'gcepaypal');
        script.setAttribute('nonce', 'gcepaypal');
        document.head.appendChild(script);
        script.onload = () => {
          this.emitInterface();
        };
      })
      .catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      });
  },
  methods: {
    /**
     * Emitting an interface with callable methods from outside
     */
    emitInterface() {
      const { paypal } = window;
      const api = this.$api;
      const vue = this;
      paypal
        .Buttons({
          // Sets up the transaction when a payment button is clicked
          async createOrder() {
            let orderId = '';
            await api.post('partner/payment/invoice', {
              payment_type_id: 5,
              amount: vue.amount,
              invoice_id: vue.invoiceid,
            })
              .then((response) => {
                orderId = response.data.order_id;
              });

            return orderId;
          },
          // Finalize the transaction after payer approval
          async onApprove(data) {
            api.post('partner/payment/invoice', {
              payment_type_id: 5,
              confirm: 1,
              capture: 1,
              id: data.orderID,
              amount: vue.amount,
              save_as_default_card: false,
              invoice_id: vue.$route.params.id,
            })
              .then(() => {
                vue.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'success',
                  message: 'Your payment is submitted successfully.',
                });
                vue.$emit('reset');
              });
          },
        })
        .render('#paypal-button-container');

      this.$emit('interface', {
        checkout: () => this.checkout(),
      });
    },
    checkout() {
      return this.paypalForm.submit({
        vault: this.saveasdefaultcard,
        contingencies: ['SCA_ALWAYS'],
      });
    },
  },
  beforeDestroy() {
  },
  props: [
    'amount', 'saveasdefaultcard', 'orderid', 'invoiceid',
  ],
  watch: {
    orderid: {
      handler(newOrderId) {
        this.orderid = newOrderId;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
  .paypal-credit-card {
    padding: 0 10px;
    margin-bottom: 8px;
    border: 1px rgba(0,0,0,0.87) solid;
    border-radius: 4px;
  }

  .card_field {
    height: 20px;
  }
</style>

<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.invoice_status_id="{ item }">
          <v-btn x-small color="success"
                   v-if="item.invoice_status_id === 1">Paid</v-btn>
          <v-btn x-small color="warning"
                   v-if="item.invoice_status_id === 2">Pending Approval</v-btn>
          <v-btn x-small color="error"
                   v-if="item.invoice_status_id === 3">Rejected</v-btn>
          <v-btn x-small color="error"
                 v-if="item.invoice_status_id === 4">Unpaid</v-btn>
          <v-btn x-small color="error"
                 v-if="item.invoice_status_id === 5">Cancelled</v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn x-small color="primary" @click="view(item.invoice_no, item.view_token)">
            View</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TaxInvoiceList',
  data() {
    return {
      search: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'Partner', value: 'partner', sortable: true },
        { text: 'Email', value: 'email', sortable: true },
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Company', value: 'company_name', sortable: true },
        { text: 'Invoice NO', value: 'invoice_no', sortable: true },
        { text: 'Invoice Date', value: 'invoice_datetime', sortable: true },
        { text: 'Subtotal', value: 'subtotal', sortable: true },
        { text: 'Tax', value: 'tax', sortable: true },
        { text: 'Discount', value: 'discount', sortable: true },
        { text: 'Total', value: 'total', sortable: true },
        { text: 'Status', value: 'invoice_status_id', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    this.initiated = true;
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/tax-invoice/list', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        product_type_id: this.productTypeId,
        order: `${sortBy}:${sortDesc}`,
      })
        .then((response) => {
          this.totalItems = response.data.total;
          this.items = response.data.data;
          this.loading = false;
        });
    },
    view(invoiceNo, viewToken) {
      window.open(`${process.env.VUE_APP_API_URL}/v1/tax-invoice/${invoiceNo}/view?system_currency=${window.systemCurrency}&view_token=${viewToken}`, '_blank');
    },
  },
};
</script>

<style scoped>

</style>

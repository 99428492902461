<template>
  <v-select
    dense
    v-model="servers"
    :items="serverList"
    :label="label"
    :multiple="!single"
    :chips="!nochips"
    :rules="rules"
    @change="emitUpdate"
  ></v-select>
</template>

<script>
export default {
  name: 'CpanelServerDropdown',
  data() {
    return {
      servers: null,
      serverList: [],
    };
  },
  created() {
    this.loadServers();
    if (typeof this.defaultServers !== 'undefined') {
      this.$set(this, 'servers', this.defaultServers);
    }
  },
  mounted() {
    this.emitUpdate();
  },
  methods: {
    loadServers() {
      this.$api.get('admin/server/cpanel-drop-down').then((response) => {
        const list = response.data;
        list.forEach((value) => {
          this.serverList.push({
            text: `${value.name}|${value.hostname}|${value.ip}`,
            value: value.id,
          });
        });
      });
    },
    emitUpdate() {
      this.$emit('update:servers', this.servers);
    },
  },
  props: ['label', 'rules', 'defaultServers', 'single', 'nochips'],
  watch: {
    defaultServers: {
      handler(newServers) {
        this.$set(this, 'servers', newServers);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-container
    class="base"
  >
    <v-row dense>
      <v-col cols="12" xl="10">
        <router-view @reloadActivity="reloadActivity"></router-view>
      </v-col>
      <v-col cols="2" class="hidden-lg-and-down">
        <v-row no-gutters>
          <v-col cols="12">
            <v-card class="activity overflow-y-auto">
              <v-card-text>
                <h4>Provisioning Queue</h4>
                <v-row dense>
                  <v-col cols="12">
                    <v-slide-y-transition
                      group
                    >
                      <v-row dense
                             v-for="activity in activityList"
                             :key="activity.job_id"
                      >
                        <v-col cols="12">
                          <v-card>
                            <v-card-text>
                              {{ activity.reserved_at }}<br>
                              <b>{{ activity.service_name }} {{ activity.service_identity }}</b><br>
                              <span
                                v-if="!activity.completed_at || !activity.cancelled"
                              >
                                Pending&nbsp;
                              </span>{{ activity.provision_type_name }}
                              <span
                                v-if="!!activity.completed_at && !activity.cancelled"
                              >
                                Complete
                              </span>
                              <span
                                v-if="!!activity.completed_at && activity.cancelled"
                              >
                                Cancelled
                              </span>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-if="!activity.completed_at"
                                          v-bind="attrs"
                                          v-on="on"
                                          v-text="`mdi-spin mdi-rotate-left`"></v-icon>
                                </template>
                                <span>
                                  Check back in 24 hours,
                                  an email will be sent to you upon completion
                                </span>
                              </v-tooltip>
                              <v-icon
                                v-if="!!activity.completed_at && !activity.cancelled"
                                color="success"
                                v-text="`mdi-checkbox-marked-circle`"></v-icon>
                              <v-icon
                                v-if="!!activity.completed_at && activity.cancelled"
                                color="error"
                                v-text="`mdi-close-circle`"></v-icon>
                              <v-chip
                                label
                                :color="activity.cancelled ? 'error' : 'success'"
                                v-if="!!activity.completed_at"
                              >
                                {{ activity.completed_at }}
                              </v-chip>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-slide-y-transition>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from '@/store';

export default {
  name: 'Base',
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  data() {
    return {
      activityList: [],
      timer: null,
    };
  },
  metaInfo: {
    title: 'Client Portal',
    titleTemplate: (titleChunk) => {
      if (store.state.user.company_name) {
        return `${titleChunk} | ${store.state.user.company_name}`;
      }

      return titleChunk;
    },
  },
  mounted() {
    this.reloadActivity();
    if (this.$underscore.isEmpty(this.timer)) {
      if (!document.hidden) {
        this.timer = setInterval(() => {
          this.reloadActivity();
        }, 30000);
      }
    }
  },
  methods: {
    reloadActivity() {
      this.$api.get('partner/activity/latest?limit=50').then((response) => {
        this.$set(this, 'activityList', response.data);
      });
    },
  },
};
</script>

<style scoped>
  .activity {
    height: calc(100vh - 92px);
    position: sticky;
  }
</style>

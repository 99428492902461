<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-expansion-panels :value="0">
        <v-expansion-panel
        >
          <v-expansion-panel-header>Advance Search</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col dense no-gutters cols="4">
                <v-text-field label="Email" v-model="email"></v-text-field>
              </v-col>
              <v-col dense no-gutters cols="4">
                <v-text-field label="From" v-model="fromDate"
                              @click.prevent="openFromDatetimePicker($event)"></v-text-field>
              </v-col>
              <v-col dense no-gutters cols="4">
                <v-text-field label="To" v-model="toDate"
                              @click.prevent="openToDatetimePicker($event)"></v-text-field>
              </v-col>
              <v-col dense no-gutters cols="12">
                <v-btn depressed color="primary" @click="loadTable">Search</v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [50,100,200,400],
        }"
      >
      </v-data-table>
    </v-col>
    <datetime
      class="datetime-picker"
      ref="fromDatetimePicker"
      type="date"
      v-model="from"
      format="yyyy-LL-dd"
      auto
    ></datetime>
    <datetime
      class="datetime-picker"
      ref="toDatetimePicker"
      type="date"
      v-model="to"
      format="yyyy-LL-dd"
      auto
    ></datetime>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'PartnerCreditDeduction',
  data() {
    return {
      search: '',
      email: '',
      fromDate: '',
      toDate: '',
      from: '',
      to: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 50,
      },
      headers: [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'Partner Email', value: 'email', sortable: true },
        { text: 'Service Name', value: 'service_name', sortable: true },
        { text: 'Domain', value: 'service_identity', sortable: true },
        { text: 'Deduction', value: 'balance_deduct', sortable: true },
        { text: 'Detail', value: 'detail', sortable: false },
        { text: 'Date', value: 'created_at', sortable: true },
      ],
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
    to: {
      handler(newDate) {
        if (newDate !== null) {
          this.toDate = DateTime.fromISO(newDate).toFormat('yyyy-MM-dd');
        }
      },
      deep: true,
    },
    from: {
      handler(newDate) {
        if (newDate !== null) {
          this.fromDate = DateTime.fromISO(newDate).toFormat('yyyy-MM-dd');
        }
      },
      deep: true,
    },
  },
  created() {
    this.initiated = true;
    this.loadTable();
  },
  methods: {
    openFromDatetimePicker(event) {
      this.$refs.fromDatetimePicker.open(event);
    },
    openToDatetimePicker(event) {
      this.$refs.toDatetimePicker.open(event);
    },
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/payment/partner-balance-list', {
        search: {
          '*': this.search,
        },
        email: this.email,
        from: this.fromDate,
        to: this.toDate,
        current_page: page,
        per_page: itemsPerPage,
        product_type_id: this.productTypeId,
        order: `${sortBy}:${sortDesc}`,
      })
        .then((response) => {
          this.totalItems = response.data.total;
          this.items = response.data.data;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined>
        <v-card-text>
          <v-col cols="12">
            <v-text-field dense
                          label="Article Title"
                          v-model="title"
                          :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              clearable
              label="Article Categories"
              :multiple="true"
              :chips="true"
              v-model="selectedArticleCategories"
              :items="articleCategories"
            >
            </v-select>
          </v-col>
          <v-col cols="12">
            <div id="editor" v-html='body'>
            </div>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn
              color="secondary"
              :loading="processing"
              :disabled="processing"
              @click="draft"
            >Draft</v-btn>
            <v-btn
              color="primary"
              :loading="processing"
              :disabled="processing"
              @click="publish"
            >Publish</v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :server-items-length="totalItems"
              :loading="loading"
              class="elevation-1"
              :footer-props="{
            'items-per-page-options': [15,30,60,100,150,250],
          }"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="load(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Quill from '@/plugins/quill';
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme

export default {
  name: 'Update Revision',
  mounted() {
    this.$api.get(`admin/article/revision/${this.$route.params.id}`).then((response) => {
      this.id = response.data.id;
      this.title = response.data.title;
      this.body = response.data.body;
      this.loadRevisions(response.data.article_id);
    });
  },
  created() {
    this.getArticleCategories();
  },
  data() {
    return {
      id: null,
      editor: null,
      title: '',
      body: '',
      articleCategories: [],
      selectedArticleCategories: null,
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      headers: [
        { text: 'Revision No', value: 'revision_no' },
        { text: 'Editor', value: 'editor_name' },
        { text: 'Title', value: 'title' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Updated At', value: 'updated_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    loadRevisions(articleId) {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post(`admin/article/${articleId}/revision/list`, {
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    initEditor() {
      this.editor = new Quill('#editor', {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            ['clean'],
          ],
        },
        theme: 'snow',
      });
    },
    draft() {
      this.processing = true;
      const postData = this.getPostDate();
      postData.article_status_id = 1;
      this.$api.put(`admin/article/${this.id}`, postData)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Draft article is updated.',
          });
          window.location.reload();
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    publish() {
      this.processing = true;
      const postData = this.getPostDate();
      postData.article_status_id = 2;
      this.$api.put(`admin/article/${this.id}`, postData)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Article is updated and published.',
          });
          window.location.reload();
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    getPostDate() {
      return {
        title: this.title,
        article_categories: this.selectedArticleCategories,
        body: this.editor.root.innerHTML,
        search_text: this.editor.getText(),
      };
    },
    getArticleCategories() {
      this.$api.post('admin/article-category/list', {
        current_page: 1,
        order: ':',
        per_page: 1000,
      })
        .then((response) => {
          const articleCategories = response.data.data;
          articleCategories.forEach((value) => {
            this.articleCategories.push({
              text: value.name,
              value: value.id,
            });
          });
        });
    },
  },
  watch: {
    body(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.initEditor();
        });
      }
    },
  },
};
</script>

<style>
.ql-editor {
  min-height: 560px;
}
</style>

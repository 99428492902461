<template>
  <v-row
    no-gutters
    dense
  >
    <v-col
      cols="6"
      offset="3"
    >
      <v-form
        ref="form"
      >
        <v-card
          outlined
        >
          <v-card-text>
            <v-row
              dense
              no-gutters
            >
              <v-col
                cols="12"
              >
                <v-row
                  dense
                >
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <h2 v-if="invoice.invoiceStatusId == 1" class="green--text">Paid</h2>
                    <h2 v-if="invoice.invoiceStatusId == 2" class="orange--text">Pending</h2>
                    <h2 v-if="invoice.invoiceStatusId == 3" class="red--text">Rejected</h2>
                    <p>#{{ invoice.invoiceNo }}</p>
                    <p>Name: {{ invoice.customerFirstName }} {{ invoice.customerLastName }}</p>
                    <p>Company: {{ invoice.customerCompanyName }}</p>
                    <p>Address1: {{ invoice.customerAddress1 }}</p>
                    <p>Address2: {{ invoice.customerAddress2 }}</p>
                    <p>City: {{ invoice.customerCity }}</p>
                    <p>Country: {{ invoice.customerCountry }}</p>
                    <p>Postcode: {{ invoice.customerPostalCode }}</p>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <h3>Items</h3>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="6"
                  >
                    <p>Line Item</p>
                  </v-col>
                  <v-col
                    cols="2"
                  >
                    <p>Subtotal</p>
                  </v-col>
                  <v-col
                    cols="2"
                  >
                    <p>Tax</p>
                  </v-col>
<!--                  <v-col-->
<!--                    cols="2"-->
<!--                  >-->
<!--                    <p>Discount</p>-->
<!--                  </v-col>-->
                  <v-col
                    cols="2"
                  >
                    <p>Commission</p>
                  </v-col>
                </v-row>
                <v-row dense
                  v-for="item in invoice['invoiceItems']" :key="item.id"
                >
                  <v-col
                    cols="6"
                  >
                    <p><pre>{{ item.invoiceLineDescription }}</pre></p>
                  </v-col>
                  <v-col
                    cols="2"
                  >
                    <p>{{ item.subtotal }}</p>
                  </v-col>
                  <v-col
                    cols="2"
                  >
                    <p>{{ item.tax }}</p>
                  </v-col>
<!--                  <v-col-->
<!--                    cols="2"-->
<!--                  >-->
<!--                    <p>{{ item.discount }}</p>-->
<!--                  </v-col>-->
                  <v-col
                    cols="2"
                  >
                    <p>{{ item.commission }}</p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                  <p class="text-right">Subtotal: {{ invoice.subtotal }}</p>
                  <p class="text-right">Tax: {{ invoice.tax }}</p>
<!--                  <p class="text-right">Discount: {{ invoice.discount }}</p>-->
                  <p class="text-right">Total: {{ invoice.total }}</p>
                  <p class="text-right">Commission: {{ invoice.commission }}</p>
                  </v-col>
                </v-row>
                <v-row dense v-if="invoice.payment && invoice.payment.paymentTypeId === 1">
                  <v-col cols="12">
                    <h3>Bank Transfer/Cheque</h3>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Transfer Date"
                      readonly
                      v-model="invoice.payment.paymentDatetime"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Receive Date"
                                  :rules="[rules.required]"
                                  v-model="receiveDatetime"
                                  @click.prevent="openDatetimePicker($event)"
                                  :readonly="true"
                    ></v-text-field>
                    <datetime
                      class="datetime-picker"
                      ref="datetimePicker"
                      type="datetime"
                      v-model="pickerReceiveDatetime"
                      :hour-step="1"
                      :minute-step="5"
                      format="yyyy-MM-dd HH:mm:ss"
                      auto
                    ></datetime>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Vendor"
                      v-model="paymentVendor"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Reference No"
                      v-model="paymentReferenceNo"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense v-if="invoice.payment && invoice.payment.paymentTypeId !== 1">
                  <v-col cols="12">
                    <h3>Credit Card</h3>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Payment Date"
                      readonly
                      v-model="invoice.payment.paymentDatetime"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Vendor"
                      readonly
                      v-model="invoice.payment.paymentVendor"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Reference No"
                      readonly
                      v-model="invoice.payment.paymentReferenceNo"></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  dense
                  v-if="invoice.invoiceStatusId == 2"
                >
                  <v-col
                    cols="12"
                    class="text-right"
                    v-if="invoice.payment && invoice.payment.paymentTypeId !== 1
                      || (typeof $store.state.adminUser.auth_token !== 'undefined'
                        && $store.state.adminUser.auth_token !== null
                        && ($store.state.adminUser.email == 'dan.chen@gocloudeasy.com'
                        || $store.state.adminUser.email == 'windskeleton@gmail.com')
                        && $store.state.user.role === 'Partner')"
                  >
                    <v-btn
                      color="success"
                      :loading="processing"
                      :disabled="processing"
                      @click="approve"
                    >Approve</v-btn>
                    <v-btn
                      color="error"
                      :loading="processing"
                      :disabled="processing"
                      @click="reject"
                    >Reject</v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    class="text-right"
                    v-else
                  >
                    <h2>We are still checking the payment from bank.</h2>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'Detail',
  components: {
  },
  created() {
    this.receiveDatetime = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');
    this.pickerReceiveDatetime = DateTime.local().toISO();
    this.$api.get(`partner/tax-invoice/${this.$route.params.id}`).then((data) => {
      this.$set(this, 'invoice', data.data);

      if (this.invoice.payment.payment_received_datetime) {
        this.receiveDatetime = this.invoice.payment.payment_received_datetime;
      }

      this.paymentVendor = this.invoice.payment.paymentVendor;
      this.paymentReferenceNo = this.invoice.payment.paymentReferenceNo;
    });
  },
  data() {
    return {
      invoice: {},
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
      receiveDatetime: null,
      pickerReceiveDatetime: null,
      paymentVendor: null,
      paymentReferenceNo: null,
    };
  },
  methods: {
    openDatetimePicker(event) {
      this.$refs.datetimePicker.open(event);
    },
    approve() {
      this.loading = true;
      this.$api.post(`partner/tax-invoice/approve/${this.$route.params.id}`, {
        payment_received_datetime: this.receiveDatetime,
        payment_vendor: this.paymentVendor,
        payment_reference_no: this.paymentReferenceNo,
      })
        .then(() => {
          this.$router.push({
            path: '/refresh',
            query: {
              path: this.$route.fullPath,
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reject() {
      this.loading = true;
      this.$api.get(`partner/tax-invoice/reject/${this.$route.params.id}`)
        .then(() => {
          this.$router.push({
            path: '/refresh',
            query: {
              path: this.$route.fullPath,
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    pickerReceiveDatetime: {
      handler(pickerReceiveDatetime) {
        if (pickerReceiveDatetime !== null) {
          this.receiveDatetime = DateTime.fromISO(pickerReceiveDatetime)
            .toFormat('yyyy-MM-dd HH:mm:ss');
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

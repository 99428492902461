<template>
  <v-row
    no-gutters
    dense
  >
    <v-col
      cols="12"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card
          outlined
        >
          <v-card-text>
            <v-row
              dense
              no-gutters
            >
              <v-col cols="2">
                <v-select :items="['registrant', 'admin', 'tech', 'billing']"
                          label="Contact Type"
                          v-model="contactType"
                          @change="changeContactType"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
              >
                <profile
                  ref="partnerProfile"
                  :setNewProfile="profile"
                ></profile>
                <v-row dense>
                  <v-col cols="6" class="text-right">
                    <v-text-field
                      dense
                      label="Personal Identity No: NRIC No./FIN No. for .sg tld"
                      v-model="personal_identity_no"></v-text-field>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-text-field
                      dense
                      label="Corporate No: UEN/Entity No./CorpPass No. for .sg tld"
                      v-model="corporate_no"></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      color="primary"
                      :loading="processing"
                      @click="submitForm"
                    >Submit</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import Profile from '../../components/Profile.vue';

export default {
  name: 'DomainDefaultContact',
  components: {
    Profile,
  },
  created() {
    this.$api.get(`partner/domain/default-contact?contact_type=${this.contactType}`).then((response) => {
      this.setProfile(response.data.profile);
    });
  },
  data() {
    return {
      processing: false,
      profile: null,
      personal_identity_no: null,
      corporate_no: null,
      contactType: 'registrant',
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
      valid: false,
    };
  },
  methods: {
    changeContactType() {
      this.$api.get(`partner/domain/default-contact?contact_type=${this.contactType}`).then((response) => {
        this.setProfile(response.data.profile);
      });
    },
    setProfile(profile) {
      if (profile && !this.$underscore.isEmpty(profile.email)) {
        this.profile = profile;
        this.corporate_no = profile.corporate_no;
        this.personal_identity_no = profile.personal_identity_no;
      } else {
        this.profile = null;
        this.corporate_no = null;
        this.personal_identity_no = null;
      }
    },
    submitForm() {
      this.processing = true;
      if (this.$refs.form.validate()) {
        const formData = this.$refs.partnerProfile.getProfileData();
        formData.contact_type = this.contactType;
        formData.corporate_no = this.corporate_no;
        formData.personal_identity_no = this.personal_identity_no;
        this.$api.put('partner/domain/default-contact', formData)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Domain default contact is updated successfully.',
            });
          }).finally(() => {
            this.processing = false;
          });
      } else {
        this.processing = false;
      }
    },
  },
};
</script>
<style scoped></style>

<template>
  <v-row no-gutters dense>
    <v-col cols="6" offset="3">
      <v-form ref="form">
        <v-card outlined>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="12">
                    <partner-dropdown
                      label="Partner"
                      :rules="[rules.required]"
                      :single="true"
                      :partners.sync="form.partner"
                      :nochips="true"
                    ></partner-dropdown>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      label="Domain"
                      v-model="form.domain"
                      :rules="[rules.domain]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="12">
                    <product-select
                      :module_id="2"
                      label="Product"
                      :rules="[rules.required]"
                      :single="true"
                      :products.sync="form.product"
                      :nochips="true"
                    ></product-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense v-if="form.product">
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="12">
                    <product-term-dropdown
                      label="Product Term Price"
                      :rules="[rules.required]"
                      :partnerId="form.partner"
                      :productId="form.product"
                      :price.sync="form.product_term"
                    ></product-term-dropdown>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="12">
                    <DatetimePicker
                      label="Import Datetime UTC"
                      :rules="[rules.required]"
                      :datetimeString.sync="form.datetime"
                    ></DatetimePicker>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="form.charge"
                      label="Charge?"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="form.autorenew"
                      label="AutoRenew?"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <manual-manage
              :moduleData.sync="form.module_data"
              :parentModuleData="parentModuleData"
              :readonly="false"
            >
            </manual-manage>
            <v-row dense>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="primary"
                  :loading="processing"
                  :disabled="processing"
                  @click="submitForm"
                >Submit</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import PartnerDropdown from '../../components/PartnerDropdown.vue';
import ProductSelect from '../../components/ProductSelect.vue';
import DatetimePicker from '../../components/DatetimePicker.vue';
import ProductTermDropdown from '../../components/ProductTermDropdown.vue';
import ManualManage from '../../components/provisioning_modules/manual/Manage.vue';

export default {
  name: 'ImportManualService',
  components: {
    ProductTermDropdown,
    DatetimePicker,
    ProductSelect,
    PartnerDropdown,
    ManualManage,
  },
  data() {
    return {
      form: {
        partner: null,
        datetime: '',
        domain: '',
        module_data: null,
        charge: 1,
        autorenew: 1,
      },
      parentModuleData: null,
      processing: false,
      rules: {
        required: (value) => (value !== 0 && !!value) || 'Required.',
        domain: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || 'This field must be valid domain name.',
      },
    };
  },
  methods: {
    reset() {
      this.$router.push({
        path: '/refresh',
        query: {
          path: this.$route.fullPath,
        },
      });
    },
    submitForm() {
      this.processing = true;

      if (this.$refs.form.validate()) {
        this.$api.post('admin/service/import-manual-service', this.form)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'The service has been imported successfully.',
            });
            this.reset();
          })
          .finally(() => {
            this.processing = false;
          });
      } else {
        this.processing = false;
      }
    },
    productChange() {
      this.$api.get(`admin/product/${this.form.product}`).then((response) => {
        this.$set(this, 'parentModuleData', response.data.module_data);
      });
    },
  },
  watch: {
    'form.product': {
      handler(newProduct) {
        if (newProduct !== null) {
          this.productChange();
        }
      },
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row
    no-gutters
  >
    <v-col>
      <v-expansion-panels :value="0">
        <v-expansion-panel
        >
          <v-expansion-panel-header>Advance Search</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col dense no-gutters cols="4">
                <v-text-field label="Discount Name" v-model="searchName"></v-text-field>
              </v-col>
              <v-col dense no-gutters cols="4">
                <v-text-field label="Discount Value" v-model="searchValue"></v-text-field>
              </v-col>
              <v-col dense no-gutters cols="4">
                <v-text-field label="Coupon Code" v-model="searchCode"></v-text-field>
              </v-col>
              <v-col dense no-gutters cols="4">
                <v-select label="Discount Type"
                    v-model="searchDiscountType"
                  :items="discountTypeList"
                  :multiple="true"
                ></v-select>
              </v-col>
              <v-col dense no-gutters cols="4">
                <v-select label="Discount Status"
                  v-model="searchDiscountStatus"
                  :items="discountStatusList"
                  :multiple="true"
                ></v-select>
              </v-col>
              <v-col dense no-gutters cols="4">
              </v-col>
              <v-col dense no-gutters cols="12">
                <v-btn depressed color="primary" @click="search">Search</v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
            'items-per-page-options': [15,30,60,100,150,250],
          }"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="edit(item)"
          >
            mdi-eye
          </v-icon>
          <v-btn
            x-small
            v-if="item.discount_status_id == 2"
            :loading="actionLoading"
            color="primary"
            @click="activate(item)"
          >
            Activate
          </v-btn>
          <v-btn
            x-small
            v-if="item.discount_status_id == 1"
            :loading="actionLoading"
            color="error"
            @click="disable(item)"
          >
            Disable
          </v-btn>
        </template>
        <template v-slot:item.discount_type_id="{ item }">
          <v-chip
            x-small
          >{{ item.discount_type_name }}
          </v-chip>
        </template>
        <template v-slot:item.discount_status_id="{ item }">
          <v-chip
            x-small
            :color="discountStatusColor[item.discount_status_id]"
          >{{ item.discount_status_name }}
          </v-chip>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      totalItems: 0,
      discountTypeList: [
        { text: 'Affiliate Discount', value: 1 },
        { text: 'Coupon Product Percentage', value: 2 },
        { text: 'Coupon Product Fixed Amount', value: 3 },
        { text: 'Promo Percentage', value: 4 },
        { text: 'Coupon Shopping Cart Fixed Amount', value: 6 },
        { text: 'Friend Discount', value: 7 },
        { text: 'Friend Voucher Fixed Amount', value: 8 },
      ],
      discountStatusList: [
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 2 },
      ],
      loading: true,
      options: {},
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Partner', value: 'email' },
        { text: 'Discount Name', value: 'discount_name' },
        { text: 'Discount Type', value: 'discount_type_id' },
        { text: 'Discount Value', value: 'value' },
        { text: 'Discount Status', value: 'discount_status_id' },
        { text: 'Coupon Code', value: 'unique_code' },
        { text: 'Applied Product Types', value: 'product_types' },
        { text: 'Applied Products', value: 'products' },
        { text: 'Applied Operations', value: 'operations' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      discountStatusColor: {
        1: 'success',
        2: 'error',
      },
      actionLoading: false,
      searchName: '',
      searchValue: '',
      searchCode: '',
      searchDiscountType: [],
      searchDiscountStatus: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.loadTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/discount/list', {
        search: {
          discount_name: this.searchName,
          unique_code: this.searchCode,
          value: this.searchValue,
          discount_type_id: this.searchDiscountType,
          discount_status_id: this.searchDiscountStatus,
        },
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    search() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/discount/list', {
        search: {
          discount_name: this.searchName,
          unique_code: this.searchCode,
          value: this.searchValue,
          discount_type_id: this.searchDiscountType,
          discount_status_id: this.searchDiscountStatus,
        },
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    edit(item) {
      this.$router.push({ name: 'Admin.DiscountUpdate', params: { id: item.id } });
    },
    activate(item) {
      this.actionLoading = true;
      this.$api.post(`admin/discount/${item.id}/activate`).finally(() => {
        this.actionLoading = false;
        this.loadTable();
      });
    },
    disable(item) {
      this.actionLoading = true;
      this.$api.post(`admin/discount/${item.id}/disable`).finally(() => {
        this.actionLoading = false;
        this.loadTable();
      });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row>
    <v-col cols="6">
      <v-text-field
        label="Friend Code"
        readonly
        v-model="$store.state.user.friend_code"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field
        label="Friend Discount URL (10% discount + 10% voucher rebate)"
        readonly
        v-model="$store.state.user.friend_url"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
            'items-per-page-options': [15,30,60,100,150,250],
          }"
      >
        <template v-slot:item.value="{ item }">
          ${{ item.value }}
        </template>
        <template v-slot:item.discount_status_id="{ item }">
          <v-chip
            x-small
            :color="discountStatusColor[item.discount_status_id]"
          >{{ item.discount_status_name }}
          </v-chip>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      totalItems: 0,
      loading: true,
      options: {},
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Friend Name', value: 'first_name' },
        { text: 'Voucher Value', value: 'value' },
        { text: 'Voucher Code', value: 'unique_code' },
        { text: 'Created At', value: 'created_at' },
      ],
      discountStatusColor: {
        1: 'success',
        2: 'error',
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.loadTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('customer/voucher/list', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row no-gutters row--dense>
    <v-col cols="12">
      <v-card
        outlined
      >
        <v-card-title>
          Items:
        </v-card-title>
        <v-card-text>
          <v-row class="cart">
            <v-col cols="12">
            </v-col>
            <v-col cols="12">
              <v-row align="center" justify="center"
                     v-for="(item, index) in shoppingCart.items" :key="item.id">
                <v-col cols="8" lg="6" class="text-left">
                  <b class="addon-item" v-if="item.parent_cart_item_id">
                    {{ item.description }}
                  </b>
                  <b class="service-item" v-else>
                    {{ item.description }}
                  </b>
                  <br v-if="item.upsale">
                  <a v-if="item.upsale" class="upsale" @click="quickCheckout(247, item.identity)">
                    Add a Email hosting at SGD5/month
                  </a>
                  <br>
                  <a v-if="item.upsale" class="upsale" @click="quickCheckout(129, item.identity)">
                    Add a Web hosting at SGD12/month
                  </a>
                </v-col>
                <v-col cols="4" lg="4" class="text-left">
                  <span :class="{ 'line-through': item.discount && item.discount > 0 }">
                    ${{ item.price }}
                  </span>
                  <span v-if="item.discount && item.discount > 0" class="discounted-price">
                    ${{ item.discounted_price }}
                  </span><br>
                  <span v-if="item.promo">
                    <v-chip
                      x-small
                      color="secondary"
                      label
                      outlined
                    >{{ item.promo_name }}-{{ item.promo.value }}%OFF</v-chip>
                  </span>
                  <span v-if="item.coupon">
                    <v-chip
                      x-small
                      close
                      color="secondary"
                      label
                      outlined
                      @click:close="removeCoupon(item.coupon.coupon_code)"
                    >{{ item.coupon.coupon_code }}
                    <span v-if="item.coupon.coupon_type == 'p'">-{{ item.coupon.value }}%OFF
                    </span>
                    <span v-if="item.coupon.coupon_type == 'f'">-${{ item.coupon.value }}OFF
                    </span>
                  </v-chip>
                  </span>
                </v-col>
                <v-col cols="12" lg="2" class="text-right">
                  <v-btn color="error" :loading="loadings[index]"
                         @click="deleteItem(item.id, index)">
                    <v-icon
                      small
                    >
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row justify="end">
                <v-col cols="11" lg="4" class="text-center ">
                  <v-text-field label="Coupon Code" v-model="couponCode">
                    <template v-slot:append-outer>
                      <v-btn color="primary"
                             @click="addCouponCode"
                             :loading="addCouponLoading"
                             :disabled="addCouponLoading">Add</v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row justify="center">
                <v-col cols="2" lg="8" class="text-center ">
                </v-col>
                <v-col cols="10" lg="4" class="text-right">
                  <h3>Total:
                    <span
                      :class="{'text-decoration-line-through':
                      shoppingCart.total_amount != shoppingCart.total_amount_after_cart_discount}">
                      ${{ shoppingCart.total_amount }}
                    </span>
                    <span
                      class="discounted-price"
                      v-if="
                      shoppingCart.total_amount != shoppingCart.total_amount_after_cart_discount"
                      >
                      ${{ shoppingCart.total_amount_after_cart_discount }}
                    </span>
                    <v-chip
                      x-small
                      v-if="shoppingCart.coupon"
                      close
                      color="secondary"
                      label
                      outlined
                      @click:close="removeCoupon(shoppingCart.coupon.coupon_code)"
                    >{{ shoppingCart.coupon.coupon_name }}
                      <span>- ${{ shoppingCart.coupon.value }} OFF
                      </span>
                    </v-chip>
                  </h3>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="showPaymentBlock" class="payment">
            <v-col cols="12" v-if="paymentMethod == 3">
              <v-form ref="form">
                <v-radio-group v-model="stripePaymentMode">
                <v-radio value="useSavedCard" v-if="hasSavedCard">
                  <template v-slot:label>
                    <div>Use saved card</div>
                  </template>
                </v-radio>
                <v-row no-gutters dense>
                  <v-col cols="6">
                    <stripe-saved-cards
                      :card.sync="savedCard"
                      :hasCard.sync="hasSavedCard"
                      v-on:remove-card="hasSavedCard = false"
                    ></stripe-saved-cards>
                  </v-col>
                </v-row>
                <v-row v-if="hasSavedCard"></v-row>
                <v-radio value="useNewCard" v-if="hasSavedCard">
                  <template v-slot:label>
                    <div>Use new card</div>
                  </template>
                </v-radio>
                <v-row no-gutters dense>
                  <v-col cols="12" md="6">
                    <stripe-payment
                      :amount="shoppingCart.total_amount_after_cart_discount"
                      :card.sync="creditCard"
                      :invalid.sync="creditCardInvalid"
                    ></stripe-payment>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="12">
                    <v-checkbox
                      v-if="$store.state.brand === 'cldy'"
                      label="Save as default card? I authorise CLDY.com PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                    <v-checkbox
                      v-else
                      label="Save as default card? I authorise GOCLOUDEASY PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-radio-group>
              </v-form>
            </v-col>
            <v-col cols="12" v-if="paymentMethod == 4">
              <v-form ref="form">
                <v-radio-group v-model="paypalPaymentMode">
                  <v-radio value="useSavedCard" v-if="hasSavedCard">
                    <template v-slot:label>
                      <div>Use saved card</div>
                    </template>
                  </v-radio>
                  <v-row no-gutters dense>
                    <v-col cols="6">
                      <paypal-saved-cards
                        :card.sync="savedCard"
                        :hasCard.sync="hasSavedCard"
                        v-on:remove-card="hasSavedCard = false"
                      ></paypal-saved-cards>
                    </v-col>
                  </v-row>
                  <v-row v-if="hasSavedCard"></v-row>
                  <v-radio value="useNewCard" v-if="hasSavedCard">
                    <template v-slot:label>
                      <div>Use new card</div>
                    </template>
                  </v-radio>
                  <v-row no-gutters dense>
                    <v-col cols="12" md="6">
                      <paypal-payment
                        :orderid="paypalOrderId"
                        :saveasdefaultcard="saveAsDefaultCard"
                        @interface="getChildInterface"
                        :amount="shoppingCart.total_amount_after_cart_discount"
                        :card.sync="creditCard"
                        :invalid.sync="creditCardInvalid"
                      ></paypal-payment>
                    </v-col>
                  </v-row>
                  <v-row no-gutters dense>
                    <v-col cols="12">
                      <v-checkbox
                        v-if="$store.state.brand === 'cldy'"
                        label="Save as default card? I authorise CLDY.com PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                        v-model="saveAsDefaultCard"
                      ></v-checkbox>
                      <v-checkbox
                        v-else
                        label="Save as default card? I authorise GOCLOUDEASY PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                        v-model="saveAsDefaultCard"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-form>
            </v-col>
          </v-row>
          <v-row v-if="showPaymentBlock" no-gutters dense>
            <v-col cols="12" class="text-right">
              <v-btn color="primary" class="pay-btn"
                     :loading="loading"
                     :disabled="(!(hasSavedCard && (stripePaymentMode === 'useSavedCard'
                     || paypalPaymentMode === 'useSavedCard')) &&
                     creditCardInvalid) ||
                     shoppingCart.total_amount_after_cart_discount < 0.01"
                     @click="submit">Pay by credit card (Visa/Master)</v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="showPaymentBlock && typeof $store.state.adminUser.auth_token !== 'undefined'">
            <v-col cols="12">
              <h3>Should you wish to pay using Bank Transfer / Cheque, click on the button below,
                payment information will be sent to your email.
              </h3>
              <h3>Once payment is completed, please follow the email link to notify us of your
                transaction date / time for us to match.
              </h3>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn color="primary" class="pay-btn"
                     :loading="unpaidLoading"
                     :disabled="shoppingCart.total_amount_after_cart_discount < 0.01"
                     @click="generateUnpaid">Pay by bank transfer/cheque
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import {
  StripePayment, StripeSavedCards, PaypalPayment, PaypalSavedCards,
} from '../components/payment_modules';

export default {
  name: 'ShoppingCart',
  components: {
    StripeSavedCards,
    StripePayment,
    PaypalSavedCards,
    PaypalPayment,
  },
  data() {
    return {
      valid: false,
      loading: false,
      unpaidLoading: false,
      addCouponLoading: false,
      loadings: [],
      saveAsDefaultCard: true,
      creditCardInvalid: true,
      couponCode: '',
      paymentMethod: '',
      paypalOrderId: '',
      creditCard: null,
      savedCard: null,
      hasSavedCard: false,
      stripePaymentMode: 'useNewCard',
      paypalPaymentMode: 'useNewCard',
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
      firstInvoice: false,
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    localComputed() {
    },
    // mix this into the outer object with the object spread operator
    ...mapState({
      shoppingCart: 'shoppingCart',
    }),
  },
  created() {
    this.$api.get('partner/default-payment-method').then((response) => {
      this.$set(this, 'paymentMethod', response.data);
      if (this.paymentMethod === 4) {
        this.creditCardInvalid = false;
      }
    });

    this.$api.get('partner/has-no-invoice')
      .then((response) => {
        this.$set(this, 'firstInvoice', response.data.has_no_invoice);
      });

    this.reloadShoppingCart();
  },
  methods: {
    quickCheckout(productId, domain) {
      const formData = {
        product_id: productId,
        product_term: 5,
        domain,
        module_data: {
          domain,
        },
        domain_registration: null,
        domain_transfer: null,
      };

      if (this.$store.state.shoppingCartId) {
        formData.shopping_cart_id = this.$store.state.shoppingCartId;
      }

      this.$api.post('partner/product/register', formData)
        .then((data) => {
          if (productId === '129') {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Web hosting is successfully added to your shopping cart.',
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Email hosting is successfully added to your shopping cart.',
            });
          }
          this.$store.dispatch('syncShoppingCart', data.data);
          setTimeout(() => {
            this.goToCart();
          }, 300);
        })
        .finally(() => {
        });
    },
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    reloadShoppingCart() {
      if (this.$store.state.user.role) {
        this.$api.get('partner/shopping-cart')
          .then((data) => {
            this.$store.dispatch('syncShoppingCart', data.data);
          });
      }
    },
    deleteItem(itemId, index) {
      this.$set(this.loadings, index, true);

      const { shoppingCartId } = this.$store.state;

      this.$api.delete(`partner/shopping-cart/${itemId}`, {
        data: {
          shopping_cart_id: shoppingCartId,
        },
      })
        .then((data) => {
          this.$set(this, 'loadings', []);
          this.$store.dispatch('syncShoppingCart', data.data);
        })
        .catch(() => {
          this.$set(this.loadings, index, false);
        });
    },
    submit() {
      this.loading = true;

      if (!this.$refs.form.validate()) {
        this.loading = false;
        return;
      }

      if (this.paymentMethod === 3) {
        if (this.hasSavedCard && this.stripePaymentMode === 'useSavedCard') {
          this.$api.post('partner/payment/use-saved-card', {
            amount: this.shoppingCart.total_amount_after_cart_discount,
            payment_card_id: this.savedCard.id,
            payment_type_id: this.paymentMethod,
          })
            .then((response) => {
              const stripePaymentObj = {};

              window.stripeClient.confirmCardPayment(response.data.client_secret, stripePaymentObj)
                .then(async (result) => {
                  if (result.error) {
                    await this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: result.error,
                    });
                  }

                  if (result.paymentIntent.status === 'requires_capture') {
                    await this.$api.post('partner/payment', {
                      payment_type_id: this.paymentMethod,
                      confirm: 1,
                      id: result.paymentIntent.id,
                    })
                      .then(() => {
                        const successMessage = 'Your payment is submitted successfully.';
                        this.$store.dispatch('resetShoppingCart');
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: successMessage,
                        });
                        this.reset();
                      });
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            })
            .catch((error) => {
              if (error.response) {
                const errorMsg = error.response.data.message || error.response.data;

                if (errorMsg.indexOf('remove it from the cart')) {
                  this.reloadShoppingCart();
                }

                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'error',
                  message: errorMsg,
                });
              } else {
                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'error',
                  message: error.message,
                });
              }
              this.loading = false;
            });
        } else {
          if (this.creditCardInvalid) {
            this.loading = false;
            return;
          }
          this.$api.post('partner/payment', {
            payment_type_id: this.paymentMethod,
            amount: this.shoppingCart.total_amount_after_cart_discount,
            save_as_default_card: this.saveAsDefaultCard,
          })
            .then((response) => {
              const stripePaymentObj = {
                payment_method: {
                  card: this.creditCard,
                },
              };

              if (this.saveAsDefaultCard) {
                stripePaymentObj.setup_future_usage = 'off_session';
              }

              window.stripeClient.confirmCardPayment(response.data.client_secret, stripePaymentObj)
                .then(async (result) => {
                  if (result.error) {
                    await this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: result.error,
                    });
                  } else if (result.paymentIntent.status === 'requires_capture') {
                    await this.$api.post('partner/payment', {
                      payment_type_id: this.paymentMethod,
                      confirm: 1,
                      id: result.paymentIntent.id,
                    })
                      .then(() => {
                        const successMessage = 'Your payment is submitted successfully.';
                        this.$store.dispatch('resetShoppingCart');
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: successMessage,
                        });
                        this.reset();
                      });
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            })
            .catch((error) => {
              if (error.response) {
                const errorMsg = error.response.data.message || error.response.data;

                if (errorMsg.indexOf('remove it from the cart')) {
                  this.reloadShoppingCart();
                }

                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'error',
                  message: errorMsg,
                });
              } else {
                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'error',
                  message: error.message,
                });
              }
              this.loading = false;
            });
        }
      }

      if (this.paymentMethod === 4) {
        if (this.hasSavedCard && this.paypalPaymentMode === 'useSavedCard') {
          this.$api.post('partner/payment/use-saved-card', {
            payment_type_id: this.paymentMethod,
            payment_card_id: this.savedCard.id,
            amount: this.shoppingCart.total_amount_after_cart_discount,
          })
            .then((response) => {
              this.$set(this, 'paypalOrderId', response.data.order_id);
              this.$nextTick(() => {
                this.$api.post('partner/payment/use-saved-card', {
                  payment_card_id: this.savedCard.id,
                  payment_type_id: this.paymentMethod,
                  confirm: 1,
                  id: this.paypalOrderId,
                  amount: this.shoppingCart.total_amount_after_cart_discount,
                })
                  .then((subresponse) => {
                    this.$store.dispatch('resetShoppingCart');
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'success',
                      message: 'Your payment is submitted successfully.',
                    });

                    this.$router.push({
                      name: 'Retail.TaxInvoice',
                      params: {
                        brand: this.$store.state.brand,
                        id: subresponse.data.invoice_id,
                        first: this.firstInvoice,
                      },
                    });
                  })
                  .catch((error) => {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: `An error occurred ${error.message}. Please retry again.`,
                    });
                    this.loading = false;
                  })
                  .finally(() => {
                    this.loading = false;
                  });
              });
            })
            .catch((error) => {
              if (error.response) {
                const errorMsg = error.response.data.message || error.response.data;

                if (errorMsg.indexOf('remove it from the cart')) {
                  this.reloadShoppingCart();
                }

                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'error',
                  message: errorMsg,
                });
              } else {
                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'error',
                  message: error.message,
                });
              }
              this.loading = false;
            });
        } else {
          this.$api.post('partner/payment', {
            payment_type_id: this.paymentMethod,
            amount: this.shoppingCart.total_amount_after_cart_discount,
            save_as_default_card: this.saveAsDefaultCard,
          })
            .then((response) => {
              this.$set(this, 'paypalOrderId', response.data.order_id);
              this.$nextTick(() => {
                this.$options.childInterface.checkout()
                  .then((payload) => {
                    if (payload.liabilityShift !== 'NO'
                      && payload.authenticationStatus
                      && payload.authenticationStatus !== 'YES') {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: '3D Secure Authentication fails. Please retry again.',
                      });
                      this.loading = false;
                      return;
                    }

                    if (payload.liabilityShift === 'NO'
                      && payload.liabilityShifted === false
                      && payload.authenticationStatus
                      && payload.authenticationStatus === 'NO') {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: 'You have exited 3D Secure Authentication. Please retry again.',
                      });
                      this.loading = false;
                      return;
                    }

                    this.$api.post('partner/payment', {
                      payment_type_id: this.paymentMethod,
                      confirm: 1,
                      id: payload.orderId,
                      amount: this.shoppingCart.total_amount_after_cart_discount,
                      save_as_default_card: this.saveAsDefaultCard,
                    })
                      .then((subresponse) => {
                        this.$store.dispatch('resetShoppingCart');
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: 'Your payment is submitted successfully.',
                        });

                        this.$router.push({
                          name: 'Retail.TaxInvoice',
                          params: {
                            brand: this.$store.state.brand,
                            id: subresponse.data.invoice_id,
                            first: this.firstInvoice,
                          },
                        });
                      });
                  })
                  .catch((error) => {
                    if (error.message.indexOf('semantically incorrect') > 0) {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: 'We are sorry, the transaction failed. Please check the card information or try with another card.',
                      });
                    } else {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: `An error occurred ${error.message}. Please retry again.`,
                      });
                    }
                    this.loading = false;
                  })
                  .finally(() => {
                    this.loading = false;
                  });
              });
            })
            .catch((error) => {
              if (error.response) {
                const errorMsg = error.response.data.message || error.response.data;

                if (errorMsg.indexOf('remove it from the cart')) {
                  this.reloadShoppingCart();
                }

                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'error',
                  message: errorMsg,
                });
              } else {
                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'error',
                  message: error.message,
                });
              }
              this.loading = false;
            });
        }
      }
    },
    addCouponCode() {
      this.addCouponLoading = true;
      const { shoppingCartId } = this.$store.state;
      this.$api.post('partner/shopping-cart/add-coupon', {
        coupon_code: this.couponCode,
        shopping_cart_id: shoppingCartId,
      }).then((response) => {
        const successMessage = 'Your coupon code is applied successfully.';
        this.couponCode = '';
        this.$store.dispatch('syncShoppingCart', response.data);
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: successMessage,
        });
      }).finally(() => {
        this.addCouponLoading = false;
      });
    },
    removeCoupon(couponCode) {
      this.addCouponLoading = true;
      const { shoppingCartId } = this.$store.state;
      this.$api.post('partner/shopping-cart/remove-coupon', {
        coupon_code: couponCode,
        shopping_cart_id: shoppingCartId,
      }).then((response) => {
        const successMessage = 'Your coupon code is removed successfully.';
        this.couponCode = '';
        this.$store.dispatch('syncShoppingCart', response.data);
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: successMessage,
        });
      }).finally(() => {
        this.addCouponLoading = false;
      });
    },
    generateUnpaid() {
      this.unpaidLoading = true;

      this.$api.post('partner/payment-bank-cheque', {
        payment_type_id: 1,
        amount: this.shoppingCart.total_amount_after_cart_discount,
      })
        .then((subresponse) => {
          const successMessage = 'Your invoice is generated successfully.';
          this.$store.dispatch('resetShoppingCart');
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: successMessage,
          });
          window.location.href = `${process.env.VUE_APP_API_URL}/v1/tax-invoice/${subresponse.data.invoice_no}/view?view_token=${subresponse.data.view_token}&system_currency=${window.systemCurrency}`;
        })
        .finally(() => {
          this.unpaidLoading = false;
        });
    },
    reset() {
      const { brand } = this.$store.state;
      this.$router
        .push({ name: 'Retail.TaxInvoiceList', params: { brand } })
        .catch(() => {
        });
    },
  },
  watch: {
    hasSavedCard: {
      handler(hasSavedCard) {
        if (hasSavedCard) {
          this.stripePaymentMode = 'useSavedCard';
          this.paypalPaymentMode = 'useSavedCard';
          this.saveAsDefaultCard = false;
        } else {
          this.stripePaymentMode = 'useNewCard';
          this.paypalPaymentMode = 'useNewCard';
          this.saveAsDefaultCard = true;
        }
      },
    },
  },
  props: {
    showPaymentBlock: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
  .cart {
    max-height: 640px;
    overflow-y: scroll;
  }

  .pay-btn {
    margin-left: 10px;
  }

  .addon-item {
    display:inline-block;
    font-size: 0.775rem;
    padding-left: 20px;
  }

  .upsale {
    font-size: .65rem;
    text-decoration: none;
  }
</style>

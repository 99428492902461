<template>
  <v-row
    no-gutters
  >
    <v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
            'items-per-page-options': [15,30,60,100,150,250],
          }"
      >
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      headers: [
        { text: 'Email', value: 'email' },
        { text: 'Domain', value: 'service_identity' },
        { text: 'Status', value: 'status_name' },
        { text: 'Expiry', value: 'expiry_date' },
        { text: 'Sync At', value: 'updated_at' },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.loadTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/domain-audit/list-server-renew-prohibited', {
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Request New Hosting</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form"
                    v-model="formValid">
              <v-text-field
                label="Password"
                name="password"
                prepend-icon="mdi-key"
                type="password"
                v-model="password"
                :rules="[rules.strongpassword]"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="processing"
              :disabled="processing"
              @click="reset()"
            >Reset</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'RequestNewHosting',
  data() {
    return {
      formValid: false,
      password: '',
      processing: false,
      error: {
        password: false,
      },
      errorMessage: {
        password: '',
      },
      valid: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        strongpassword: (value) => this.checkPasswordStrong(value)
          || 'You need at least 1 upper case character, 1 digits, 1 special symbol [?!@#$%^&*:;.,] and minimum length 10',
      },
    };
  },
  mounted() {
    this.$api.post('check-reset-password-token', {
      reset_password_token: this.$route.params.token,
    }).then(() => {
      this.valid = true;
    }).finally(() => {
    });
  },
  methods: {
    reset() {
      if (this.valid) {
        if (this.$refs.form.validate()) {
          this.processing = true;
          this.$api.post('reset-password', {
            reset_password_token: this.$route.params.token,
            password: this.password,
          }).then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Your password has been reset successfully.',
            });
            setTimeout(() => {
              this.$router.push({ name: 'Login' });
            }, 1000);
          }).finally(() => {
            this.processing = false;
          });
        }
      }
    },
    checkPasswordStrong(password) {
      let patt = /[A-Z]/g;
      let result = patt.test(password);
      if (!result) {
        return false;
      }

      patt = /[0-9]/g;
      result = patt.test(password);
      if (!result) {
        return false;
      }

      patt = /[?!@#$%^&*:;.,]/g;
      result = patt.test(password);
      if (!result) {
        return false;
      }

      if (password.length < 10) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row dense v-if="shouldShowConfiguration()">
    <v-col>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="12">
                  <h3>Service Configuration</h3>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6" v-if="moduleData.customer_field1 !== null
                && moduleData.customer_field1.length > 0">
                  <v-text-field
                    :label="moduleData.customer_field1"
                    v-model="moduleData.customer_field1_value"
                    :readonly="readonly"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.customer_field2 !== null
                && moduleData.customer_field2.length > 0">
                  <v-text-field
                    :label="moduleData.customer_field2"
                    v-model="moduleData.customer_field2_value"
                    :readonly="readonly"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.customer_field3 !== null
                && moduleData.customer_field3.length > 0">
                  <v-text-field
                    :label="moduleData.customer_field3"
                    v-model="moduleData.customer_field3_value"
                    :readonly="readonly"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.customer_field4 !== null
                && moduleData.customer_field4.length > 0">
                  <v-text-field
                    :label="moduleData.customer_field4"
                    v-model="moduleData.customer_field4_value"
                    :readonly="readonly"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.customer_field5 !== null
                && moduleData.customer_field5.length > 0">
                  <v-text-field
                    :label="moduleData.customer_field5"
                    v-model="moduleData.customer_field5_value"
                    :readonly="readonly"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.customer_field6 !== null
                && moduleData.customer_field6.length > 0">
                  <v-text-field
                    :label="moduleData.customer_field6"
                    v-model="moduleData.customer_field6_value"
                    :readonly="readonly"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.customer_field7 !== null
                && moduleData.customer_field7.length > 0">
                  <v-text-field
                    :label="moduleData.customer_field7"
                    v-model="moduleData.customer_field7_value"
                    :readonly="readonly"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.customer_field8 !== null
                && moduleData.customer_field8.length > 0">
                  <v-text-field
                    :label="moduleData.customer_field8"
                    v-model="moduleData.customer_field8_value"
                    :readonly="readonly"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.customer_field9 !== null
                && moduleData.customer_field9.length > 0">
                  <v-text-field
                    :label="moduleData.customer_field9"
                    v-model="moduleData.customer_field9_value"
                    :readonly="readonly"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.customer_field10 !== null
                && moduleData.customer_field10.length > 0">
                  <v-text-field
                    :label="moduleData.customer_field10"
                    v-model="moduleData.customer_field10_value"
                    :readonly="readonly"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row v-if="!hideAdminInfo" dense>
                <v-col cols="12">
                  <h2>Admin Fields</h2>
                </v-col>
              </v-row>
              <v-row v-if="!hideAdminInfo" dense>
                <v-col cols="6" v-if="moduleData.admin_field1 !== null
                && moduleData.admin_field1.length > 0">
                  <v-text-field
                    :label="moduleData.admin_field1"
                    v-model="moduleData.admin_field1_value"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.admin_field2 !== null
                && moduleData.admin_field2.length > 0">
                  <v-text-field
                    :label="moduleData.admin_field2"
                    v-model="moduleData.admin_field2_value"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.admin_field3 !== null
                && moduleData.admin_field3.length > 0">
                  <v-text-field
                    :label="moduleData.admin_field3"
                    v-model="moduleData.admin_field3_value"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.admin_field4 !== null
                && moduleData.admin_field4.length > 0">
                  <v-text-field
                    :label="moduleData.admin_field4"
                    v-model="moduleData.admin_field4_value"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.admin_field5 !== null
                && moduleData.admin_field5.length > 0">
                  <v-text-field
                    :label="moduleData.admin_field5"
                    v-model="moduleData.admin_field5_value"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.admin_field6 !== null
                && moduleData.admin_field6.length > 0">
                  <v-text-field
                    :label="moduleData.admin_field6"
                    v-model="moduleData.admin_field6_value"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.admin_field7 !== null
                && moduleData.admin_field7.length > 0">
                  <v-text-field
                    :label="moduleData.admin_field7"
                    v-model="moduleData.admin_field7_value"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.admin_field8 !== null
                && moduleData.admin_field8.length > 0">
                  <v-text-field
                    :label="moduleData.admin_field8"
                    v-model="moduleData.admin_field8_value"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.admin_field9 !== null
                && moduleData.admin_field9.length > 0">
                  <v-text-field
                    :label="moduleData.admin_field9"
                    v-model="moduleData.admin_field9_value"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="moduleData.admin_field10 !== null
                && moduleData.admin_field10.length > 0">
                  <v-text-field
                    :label="moduleData.admin_field10"
                    v-model="moduleData.admin_field10_value"
                    :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Manage',
  data() {
    return {
      moduleData: {
        domain: '',
        admin_field1: '',
        admin_field2: '',
        admin_field3: '',
        admin_field4: '',
        admin_field5: '',
        admin_field6: '',
        admin_field7: '',
        admin_field8: '',
        admin_field9: '',
        admin_field10: '',
        admin_field1_value: '',
        admin_field2_value: '',
        admin_field3_value: '',
        admin_field4_value: '',
        admin_field5_value: '',
        admin_field6_value: '',
        admin_field7_value: '',
        admin_field8_value: '',
        admin_field9_value: '',
        admin_field10_value: '',
        customer_field1: '',
        customer_field2: '',
        customer_field3: '',
        customer_field4: '',
        customer_field5: '',
        customer_field6: '',
        customer_field7: '',
        customer_field8: '',
        customer_field9: '',
        customer_field10: '',
        customer_field1_value: '',
        customer_field2_value: '',
        customer_field3_value: '',
        customer_field4_value: '',
        customer_field5_value: '',
        customer_field6_value: '',
        customer_field7_value: '',
        customer_field8_value: '',
        customer_field9_value: '',
        customer_field10_value: '',
      },
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || 'This field must be valid domain name.',
      },
    };
  },
  mounted() {
    this.$underscore.each(this.parentModuleData, (propV, prop) => {
      if (typeof this.moduleData[prop] !== 'undefined') {
        this.$set(this.moduleData, prop, propV);
      }
    });
  },
  methods: {
    shouldShowConfiguration() {
      return (this.moduleData.customer_field1 !== null
            && this.moduleData.customer_field1.length > 0)
          || (this.moduleData.customer_field2 !== null
            && this.moduleData.customer_field2.length > 0)
          || (this.moduleData.customer_field3 !== null
              && this.moduleData.customer_field3.length > 0)
          || (this.moduleData.customer_field4 !== null
              && this.moduleData.customer_field4.length > 0)
          || (this.moduleData.customer_field5 !== null
              && this.moduleData.customer_field5.length > 0)
          || (this.moduleData.customer_field6 !== null
              && this.moduleData.customer_field6.length > 0)
          || (this.moduleData.customer_field7 !== null
              && this.moduleData.customer_field7.length > 0)
          || (this.moduleData.customer_field8 !== null
              && this.moduleData.customer_field8.length > 0)
          || (this.moduleData.customer_field9 !== null
              && this.moduleData.customer_field9.length > 0)
          || (this.moduleData.customer_field10 !== null
              && this.moduleData.customer_field10.length > 0);
    },
  },
  props: {
    readonly: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hideAdminInfo: {
      type: Boolean,
      default() {
        return false;
      },
    },
    parentModuleData: {
      type: Object,
      default() {
        return {
          domain: '',
          admin_field1: '',
          admin_field2: '',
          admin_field3: '',
          admin_field4: '',
          admin_field5: '',
          admin_field6: '',
          admin_field7: '',
          admin_field8: '',
          admin_field9: '',
          admin_field10: '',
          admin_field1_value: '',
          admin_field2_value: '',
          admin_field3_value: '',
          admin_field4_value: '',
          admin_field5_value: '',
          admin_field6_value: '',
          admin_field7_value: '',
          admin_field8_value: '',
          admin_field9_value: '',
          admin_field10_value: '',
          customer_field1: '',
          customer_field2: '',
          customer_field3: '',
          customer_field4: '',
          customer_field5: '',
          customer_field6: '',
          customer_field7: '',
          customer_field8: '',
          customer_field9: '',
          customer_field10: '',
          customer_field1_value: '',
          customer_field2_value: '',
          customer_field3_value: '',
          customer_field4_value: '',
          customer_field5_value: '',
          customer_field6_value: '',
          customer_field7_value: '',
          customer_field8_value: '',
          customer_field9_value: '',
          customer_field10_value: '',
        };
      },
    },
  },
  watch: {
    moduleData: {
      handler(newModuleData) {
        this.$emit('update:moduleData', newModuleData);
      },
      deep: true,
    },
    parentModuleData: {
      handler(newParentModuleData) {
        this.$underscore.each(newParentModuleData, (propV, prop) => {
          if (typeof this.moduleData[prop] !== 'undefined') {
            this.$set(this.moduleData, prop, propV);
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Service ID"
            v-model="form.serviceId"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <h5>Rules: Service Status is "Pending Deletion" and expiry date
            is lesser than 28 days ago</h5>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            color="primary"
            :loading="processing"
            :disabled="processing"
            @click="submitForm"
          >Toggle to Expired</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ToggleExpired',
  components: {
  },
  data() {
    return {
      form: {
        serviceId: null,
      },
      successMessage: 'Status updated to Expired, please inform client to renew soon.',
      processing: false,
    };
  },
  created() {
  },
  methods: {
    submitForm() {
      this.$api.post(`admin/service/${this.form.serviceId}/toggle-expired`)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: this.successMessage,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <v-row no-gutters dense>
    <v-col cols="12" v-if="invoice.invoiceStatusId === 4">
      <v-card
        outlined
      >
        <v-card-text>
          <v-row no-gutters dense>
            <v-col cols="6">
              <v-select
                v-model="paymentMethod"
                label="Payment Method"
                :items="paymentMethods"
              ></v-select>
            </v-col>
          </v-row>
          <v-form ref="form">
            <v-row no-gutters dense>
              <v-col cols="2" >
                <h3>Total: {{ amount }}</h3>
              </v-col>
            </v-row>
            <v-row no-gutters dense v-if="paymentMethod == 1">
              <v-col cols="2" >
                <v-text-field label="Bank"
                              :rules="[rules.required]"
                              v-model="bank"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Reference No"
                              :rules="[rules.required]"
                              v-model="referenceNo"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Transfer Datetime"
                              :rules="[rules.required]"
                              v-model="transferDatetime"
                              @click.prevent="openDatetimePicker($event)"
                              :readonly="true"
                ></v-text-field>
                <datetime
                  class="datetime-picker"
                  ref="datetimePicker"
                  type="datetime"
                  v-model="pickerTransferDatetime"
                  :hour-step="1"
                  :minute-step="5"
                  format="yyyy-MM-dd HH:mm:ss"
                  auto
                ></datetime>
              </v-col>
            </v-row>
            <div v-if="paymentMethod == 3">
              <v-radio-group v-model="stripePaymentMode">
                <v-radio value="useSavedCard" v-if="hasSavedCard">
                  <template v-slot:label>
                    <div>Use saved card</div>
                  </template>
                </v-radio>
                <v-row no-gutters dense>
                  <v-col cols="6">
                    <stripe-saved-cards
                      :card.sync="savedCard"
                      :hasCard.sync="hasSavedCard"
                      v-on:remove-card="hasSavedCard = false"
                    ></stripe-saved-cards>
                  </v-col>
                </v-row>
                <v-row v-if="hasSavedCard"></v-row>
                <v-radio value="useNewCard" v-if="hasSavedCard">
                  <template v-slot:label>
                    <div>Use new card</div>
                  </template>
                </v-radio>
                <v-row no-gutters dense>
                  <v-col cols="12" md="6">
                    <stripe-payment
                      :amount="amount"
                      :card.sync="creditCard"
                      :invalid.sync="creditCardInvalid"
                    ></stripe-payment>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="12">
                    <v-checkbox
                      v-if="$store.state.brand === 'cldy'"
                      label="Save as default card? I authorise CLDY.com PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                    <v-checkbox
                      v-else
                      label="Save as default card? I authorise GOCLOUDEASY PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
            <div v-if="paymentMethod == 4">
              <v-radio-group v-model="paypalPaymentMode">
                <v-radio value="useSavedCard" v-if="hasSavedCard">
                  <template v-slot:label>
                    <div>Use saved card</div>
                  </template>
                </v-radio>
                <v-row no-gutters dense>
                  <v-col cols="6">
                    <paypal-saved-cards
                      :card.sync="savedCard"
                      :hasCard.sync="hasSavedCard"
                      v-on:remove-card="hasSavedCard = false"
                    ></paypal-saved-cards>
                  </v-col>
                </v-row>
                <v-row v-if="hasSavedCard"></v-row>
                <v-radio value="useNewCard" v-if="hasSavedCard">
                  <template v-slot:label>
                    <div>Use new card</div>
                  </template>
                </v-radio>
                <v-row no-gutters dense>
                  <v-col cols="12" md="6">
                    <paypal-payment
                      :amount="amount"
                      :orderid="paypalOrderId"
                      :saveasdefaultcard="saveAsDefaultCard"
                      @interface="getChildInterface"
                      @reset="reset"
                      :card.sync="creditCard"
                      :invalid.sync="creditCardInvalid"
                    ></paypal-payment>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="12">
                    <v-checkbox
                      v-if="$store.state.brand === 'cldy'"
                      label="Save as default card? I authorise CLDY.com PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                    <v-checkbox
                      v-else
                      label="Save as default card? I authorise GOCLOUDEASY PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
            <div v-if="paymentMethod == 5">
              <v-row no-gutters dense>
                <v-col cols="8"></v-col>
                <v-col cols="4">
                    <paypal-express
                      :amount="amount"
                      :invoiceid="invoiceId"
                      @interface="getChildInterface2"
                      v-on:reset="reset()"
                    ></paypal-express>
                </v-col>
              </v-row>
            </div>
          </v-form>
          <v-row no-gutters dense v-if="paymentMethod !== 5">
            <v-col cols="12" class="text-right">
              <v-btn color="primary"
                     v-if="paymentMethod === 4"
                     :loading="loading"
                     @click="submit">Make Payment (Visa/Master)</v-btn>
              <v-btn
                v-else
                color="primary"
                :loading="loading"
                @click="submit">Submit</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Description
            </th>
            <th class="text-left">
              Discount
            </th>
            <th class="text-left">
              Amount
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
          >
            <td><pre>{{ item.invoiceLineDescription }}</pre></td>
            <td>${{ item.discount }}</td>
            <td>${{ item.subtotal }}</td>
          </tr>
          <tr v-if="invoice.discount > 0">
            <td colspan="3" class="text-right">Discount: ${{ invoice.discount }}</td>
          </tr>
          <tr>
            <td colspan="3" class="text-right">Total: ${{ invoice.total }}</td>
          </tr>
          <tr>
            <td colspan="3" class="text-right">
              <v-chip :color="invoiceStatusColor[invoice.invoiceStatusId]">
                {{ invoiceStatus[invoice.invoiceStatusId] }}
              </v-chip>
            </td>
          </tr>
          <tr v-if="invoice.invoiceStatusId === 1">
            <td colspan="3" class="text-right">
              <h5>{{ invoice.creditTransactions[0] }}</h5>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';
import {
  StripePayment, StripeSavedCards, PaypalPayment, PaypalSavedCards, PaypalExpress,
} from '../../components/payment_modules';

export default {
  name: 'Detail',
  components: {
    StripeSavedCards,
    StripePayment,
    PaypalSavedCards,
    PaypalPayment,
    PaypalExpress,
  },
  data() {
    return {
      invoiceId: '',
      paypalOrderId: '',
      bank: '',
      referenceNo: '',
      transferDatetime: null,
      pickerTransferDatetime: null,
      paymentMethod: '',
      paymentMethods: [],
      amount: '',
      loading: false,
      saveAsDefaultCard: false,
      creditCardInvalid: null,
      creditCard: null,
      savedCard: null,
      hasSavedCard: false,
      stripePaymentMode: 'useNewCard',
      paypalPaymentMode: 'useNewCard',
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
      items: [],
      invoice: {
        invoiceStatusId: '',
        discount: 0,
      },
      invoiceStatus: {
        1: 'Paid',
        2: 'Pending',
        3: 'Rejected',
        4: 'Waiting Payment',
      },
      invoiceStatusColor: {
        1: 'success',
        2: 'warning',
        3: 'error',
        4: 'warning',
      },
      firstInvoice: false,
      gtagObj: {},
    };
  },
  created() {
    this.invoiceId = this.$route.params.id;

    this.$api.get('partner/default-payment-method').then((response) => {
      this.$set(this, 'paymentMethod', response.data);
      if (this.paymentMethod === 4) {
        this.creditCardInvalid = false;
      }
    });

    this.$api.get(`partner/has-no-invoice?invoice_id=${this.invoiceId}`)
      .then((response) => {
        this.$set(this, 'firstInvoice', response.data.has_no_invoice);
      });

    this.$api.get(`partner/tax-invoice/${this.$route.params.id}/detail`).then((response) => {
      this.amount = response.data.total;
      this.$set(this, 'items', response.data.invoiceItems);
      this.$set(this, 'invoice', response.data);
      const invoiceItems = [];

      response.data.invoiceItems.forEach((invoiceItem) => {
        invoiceItems.push({
          item_id: invoiceItem.id,
          item_name: `${response.data.invoiceDatetime} ${invoiceItem.invoiceLineDescription}`,
          price: invoiceItem.subtotal,
          discount: invoiceItem.discount,
          tax: invoiceItem.tax,
          quantity: 1,
        });
      });

      const gtagObj = {
        currency: window.systemCurrency.toUpperCase(),
        value: response.data.total,
        discount: response.data.discount,
        tax: response.data.tax,
        items: invoiceItems,
      };

      this.$set(this, 'gtagObj', gtagObj);
    });
    this.transferDatetime = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');
    this.pickerTransferDatetime = DateTime.local().toISO();
    this.$api.get('partner/payment-methods').then((response) => {
      if (this.$store.state.brand === 'paypal_prod' || this.$store.state.brand === 'cldy') {
        response.data.push({
          id: 5,
          text: 'PAYPAL',
          type_name: 'PAYPAL',
          value: 5,
        });
      }
      this.$set(this, 'paymentMethods', response.data);
    });
  },
  methods: {
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    getChildInterface2(childInterface) {
      this.$options.childInterface2 = childInterface;
    },
    submit() {
      this.loading = true;

      if (!this.$refs.form.validate()) {
        this.loading = false;
        return;
      }

      if (this.paymentMethod === 3 && this.creditCardInvalid) {
        this.loading = false;
        return;
      }

      if (this.paymentMethod === 1) {
        this.$api.post('partner/payment/invoice', {
          payment_type_id: this.paymentMethod,
          bank: this.bank,
          reference_no: this.referenceNo,
          transfer_datetime: this.transferDatetime,
          amount: this.amount,
          invoice_id: this.$route.params.id,
        })
          .then(() => {
            if (this.firstInvoice && this.$store.state.brand === 'cldy') {
              window.gtag('event', 'purchase', this.gtagObj);
            }

            const successMessage = 'Your payment is submitted successfully. Admin will review and confirm the payment.';
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: successMessage,
            });

            setTimeout(() => {
              this.$router.push({
                name: 'Retail.TaxInvoice',
                params: {
                  brand: this.$store.state.brand,
                  id: this.$route.params.id,
                  first: false,
                },
              });
            }, 500);
          })
          .finally(() => {
            this.loading = false;
          });
      }

      if (this.paymentMethod === 3) {
        if (this.hasSavedCard && this.stripePaymentMode === 'useSavedCard') {
          this.$api.post('partner/payment/invoice/use-saved-card', {
            payment_type_id: this.paymentMethod,
            amount: this.amount,
            payment_card_id: this.savedCard.id,
            invoice_id: this.$route.params.id,
          })
            .then((response) => {
              const stripePaymentObj = {};

              window.stripeClient.confirmCardPayment(response.data.client_secret, stripePaymentObj)
                .then(async (result) => {
                  if (result.error) {
                    await this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: result.error,
                    });
                  } else if (result.paymentIntent.status === 'requires_capture') {
                    await this.$api.post('partner/payment/invoice', {
                      payment_type_id: this.paymentMethod,
                      capture: 1,
                      id: result.paymentIntent.id,
                      invoice_id: this.$route.params.id,
                    })
                      .then(() => {
                        const successMessage = 'Your payment is submitted successfully.';
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: successMessage,
                        });
                        this.reset();
                      });
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            });
        } else {
          this.$api.post('partner/payment/invoice', {
            payment_type_id: this.paymentMethod,
            amount: this.amount,
            save_as_default_card: this.saveAsDefaultCard,
            invoice_id: this.$route.params.id,
          })
            .then((response) => {
              const stripePaymentObj = {
                payment_method: {
                  card: this.creditCard,
                },
              };

              if (this.saveAsDefaultCard) {
                stripePaymentObj.setup_future_usage = 'off_session';
              }

              window.stripeClient.confirmCardPayment(response.data.client_secret, stripePaymentObj)
                .then(async (result) => {
                  if (result.error) {
                    await this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: result.error,
                    });
                  } else if (result.paymentIntent.status === 'requires_capture') {
                    await this.$api.post('partner/payment/invoice', {
                      payment_type_id: this.paymentMethod,
                      capture: 1,
                      id: result.paymentIntent.id,
                      invoice_id: this.$route.params.id,
                    })
                      .then(() => {
                        const successMessage = 'Your payment is submitted successfully.';
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: successMessage,
                        });
                        this.reset();
                      });
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            });
        }
      }

      if (this.paymentMethod === 4) {
        if (this.hasSavedCard && this.paypalPaymentMode === 'useSavedCard') {
          this.$api.post('partner/payment/invoice/use-saved-card', {
            payment_type_id: this.paymentMethod,
            amount: this.amount,
            payment_card_id: this.savedCard.id,
            invoice_id: this.$route.params.id,
          })
            .then((response) => {
              this.$set(this, 'paypalOrderId', response.data.order_id);
              this.$nextTick(() => {
                this.$api.post('partner/payment/invoice', {
                  payment_card_id: this.savedCard.id,
                  payment_type_id: this.paymentMethod,
                  confirm: 1,
                  capture: 1,
                  id: this.paypalOrderId,
                  amount: this.amount,
                  invoice_id: this.$route.params.id,
                })
                  .then(() => {
                    if (this.firstInvoice && this.$store.state.brand === 'cldy') {
                      window.gtag('event', 'purchase', this.gtagObj);
                    }

                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'success',
                      message: 'Your payment is submitted successfully.',
                    });

                    setTimeout(() => {
                      this.$router.push({
                        name: 'Retail.TaxInvoice',
                        params: {
                          brand: this.$store.state.brand,
                          id: this.$route.params.id,
                          first: false,
                        },
                      });
                    }, 500);
                  })
                  .catch((error) => {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: `An error occurred ${error.message}. Please retry again.`,
                    });
                    this.loading = false;
                  })
                  .finally(() => {
                    this.loading = false;
                  });
              });
            });
        } else {
          this.$api.post('partner/payment/invoice', {
            payment_type_id: this.paymentMethod,
            amount: this.amount,
            save_as_default_card: this.saveAsDefaultCard,
            invoice_id: this.$route.params.id,
          })
            .then((response) => {
              this.$set(this, 'paypalOrderId', response.data.order_id);
              this.$nextTick(() => {
                this.$options.childInterface.checkout()
                  .then((payload) => {
                    if (payload.liabilityShift !== 'NO'
                      && payload.authenticationStatus
                      && payload.authenticationStatus !== 'YES') {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: '3D Secure Authentication fails. Please retry again.',
                      });
                      this.loading = false;
                      return;
                    }

                    if (payload.liabilityShift === 'NO'
                      && payload.liabilityShifted === false
                      && payload.authenticationStatus
                      && payload.authenticationStatus === 'NO') {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: 'You have exited 3D Secure Authentication. Please retry again.',
                      });
                      this.loading = false;
                      return;
                    }

                    this.$api.post('partner/payment/invoice', {
                      payment_type_id: this.paymentMethod,
                      confirm: 1,
                      capture: 1,
                      id: payload.orderId,
                      amount: this.amount,
                      save_as_default_card: this.saveAsDefaultCard,
                      invoice_id: this.$route.params.id,
                    })
                      .then(() => {
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: 'Your payment is submitted successfully.',
                        });
                        this.reset();
                      });
                  })
                  .catch((error) => {
                    if (error.message.indexOf('semantically incorrect') > 0) {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: 'We are sorry, the transaction failed. Please check the card information or try with another card.',
                      });
                    } else {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: `An error occurred ${error.message}. Please retry again.`,
                      });
                    }
                    this.loading = false;
                  })
                  .finally(() => {
                    this.loading = false;
                  });
              });
            });
        }
      }
    },
    reset() {
      const { brand } = this.$store.state;
      this.$router
        .push({ name: 'Retail.TaxInvoiceList', params: { brand } })
        .catch(() => {
        });
    },
    openDatetimePicker(event) {
      this.$refs.datetimePicker.open(event);
    },
  },
  watch: {
    pickerTransferDatetime: {
      handler(newPickerTransferDatetime) {
        if (newPickerTransferDatetime !== null) {
          this.transferDatetime = DateTime.fromISO(newPickerTransferDatetime).toFormat('yyyy-MM-dd HH:mm:ss');
        }
      },
      deep: true,
    },
    hasSavedCard: {
      handler(hasSavedCard) {
        if (hasSavedCard) {
          this.stripePaymentMode = 'useSavedCard';
          this.paypalPaymentMode = 'useSavedCard';
          this.saveAsDefaultCard = false;
        } else {
          this.stripePaymentMode = 'useNewCard';
          this.paypalPaymentMode = 'useNewCard';
          this.saveAsDefaultCard = true;
        }
      },
    },
  },
};
</script>
<style>
  .datetime-picker input {
    visibility: hidden !important;
  }
</style>

<template>
  <v-row no-gutters>
    <v-col cols="6" offset="3">
      <v-form ref="form">
        <v-card outlined>
          <v-card-text>
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Domain Name"
                      v-model="domain"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      color="primary"
                      :loading="processing"
                      :disabled="processing"
                      @click="whois"
                    >Whois</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <pre>{{ whoisResult }}</pre>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Whois',
  created() {
  },
  data() {
    return {
      domain: '',
      whoisResult: '',
      processing: false,
    };
  },
  methods: {
    whois() {
      this.processing = true;
      this.$api.post('admin/whois', {
        domain: this.domain,
      }).then((response) => {
        this.whoisResult = response.data;
      }).finally(() => {
        this.processing = false;
      });
    },
  },
};
</script>

<style scoped>

</style>

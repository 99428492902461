<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="6">
          <cpanel-server-dropdown
            label="Cpanel Server"
            :rules="[]"
            :single="true"
            :servers.sync="form.server_id"
            :defaultServers="defaultServers"
            :nochips="true"
          ></cpanel-server-dropdown>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            readonly
            label="Domain"
            v-model="form.domain"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            readonly
            label="User Name"
            v-model="form.username"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            color="primary"
            :loading="processing"
            :disabled="processing"
            @click="submitForm"
          >Submit</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CpanelServerDropdown from '../../components/CpanelServerDropdown.vue';

export default {
  name: 'Config',
  components: {
    CpanelServerDropdown,
  },
  data() {
    return {
      form: {
        server_id: null,
        domain: '',
        username: '',
        id: null,
      },
      defaultServers: null,
      successMessage: 'Service is successfully synced.',
      processing: false,
    };
  },
  created() {
    this.form.id = this.$route.params.id;
    this.$api.get(`admin/service/${this.$route.params.id}`).then((response) => {
      this.form.server_id = response.data.server_id;
      this.defaultServers = parseInt(response.data.server_id, 10);
      this.form.domain = response.data.domain;
      this.form.username = response.data.username;
    });
  },
  methods: {
    submitForm() {
      this.$api.post('admin/service/cpanel/:id/sync', this.form)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: this.successMessage,
          });
          window.location.reload();
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<style scoped>
</style>

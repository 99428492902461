<template>
  <v-row no-gutters dense id="main-header">
    <v-col cols="12" v-if="$route.name == 'Retail.Guest'">
      <aside
        id="ac-localeswitcher"
        data-analytics-region="locale switcher"
        data-analytics-activitymap-region-id="locale switcher"
        lang="en-SG" dir="ltr">
        <div class="ac-ls-content">
          <div class="ac-ls-copy">
            Choose another country or region to see content specific to your location and currency.
          </div>
          <div class="ac-ls-actions" id="ac-ls-select">
          </div>
        </div>
      </aside>
    </v-col>
    <v-col cols="12">
      <v-row dense no-gutters v-if="$store.state.brand == 'cldy'" class="main-logo-block">
        <v-col cols="12">
          <a :href="homeUrl" class="hidden-md-and-down">
            <img :src="`${publicPath}cldylogo.png`" class="main-logo" alt="">
          </a>
          <a :href="homeUrl" class="hidden-lg-and-up">
            <img :src="`${publicPath}cldylogomobile.svg`" class="main-logo" alt="">
          </a>
        </v-col>
      </v-row>
      <v-row class="top-nav" dense no-gutters v-if="$store.state.brand == 'cldy'">
        <v-spacer></v-spacer>
        <a href="https://wa.me/message/33PAPSKBXCYEE1" target="_blank">
          <v-icon dense color="white">mdi-whatsapp</v-icon>
          <span class="hidden-md-and-down">WhatsApp</span>
        </a>
        <a :href="contactUsUrl" target="_blank">
          <v-icon dense color="white">mdi-phone</v-icon>
          <span class="hidden-md-and-down">Call Us</span>
        </a>
        <a href="mailto:sales@cldy.com">
          <v-icon dense color="white">mdi-email-outline</v-icon>
          <span class="hidden-md-and-down">sales@cldy.com</span>
        </a>
        <a @click="gotoLogin()">
          <v-icon dense color="white">mdi-account-outline</v-icon>Sign in
        </a>
        <v-badge
          color="green"
          :content="$store.state.shoppingCart.total_count + ''"
          overlap
          offset-x="20"
          offset-y="20"
        >
          <v-btn
            icon
            large
            @click="goToCart()"
          >
            <v-avatar
              size="32px"
              item
            >
              <v-icon color="white">mdi-cart-outline</v-icon>
            </v-avatar>
          </v-btn>
        </v-badge>
      </v-row>
      <v-row
        class="bottom-nav" dense no-gutters v-if="$store.state.brand == 'cldy'"
      >
        <v-spacer></v-spacer>
        <a href="#" v-if="currency === 'sgd'">Singapore (SGD)</a>
        <a href="#" v-if="currency === 'usd'">World (USD)</a>
        <a href="#" v-if="currency === 'aud'">Australia (AUD)</a>
      </v-row>
    </v-col>
  </v-row>
</template>
<!-- eslint-disable -->
<script>
export default {
  name: 'CldyHeader',
  components: {
  },
  created() {
    if (this.$route.name == 'Retail.Guest') {
      //this.loadJquery(this.loadLocaleSelector);
    }
  },
  data() {
    return {
      currency: window.systemCurrency,
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    goToCart() {
      const { brand } = this.$store.state;
      if (this.$store.state.user.role) {
        this.$router.push({
          name: 'Retail.ShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      } else {
        this.$router.push({
          name: 'Retail.GuestShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      }
    },
    gotoLogin() {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.Home', params: { brand } })
        .catch(() => {
        });
    },
    loadJquery(callback) {
      const existingScript = document.getElementById('jquery-script');

      if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://code.jquery.com/jquery-3.6.0.min.js'; // URL for the third-party library being loaded.
        script.id = 'jquery-script'; // e.g., googleMaps or stripe
        document.body.appendChild(script);

        script.onload = () => {
          if (callback) callback();
        };
      }

      if (existingScript && callback) callback();
    },
    loadLocaleSelector() {
      (function($) {
        var time = Date.now || function() {
          return +new Date;
        };

        var url = window.location.href;

        if (url.indexOf('aud') > 0) {
        $.ajax({
          url: 'https://www.cldy.com/cf.php?ts=' + time(),
          dataType: 'json',
          success: function(data) {
            if (data.country_code == 'sg') {
              var template = '<div id="ac-ls-dropdown" class="ac-ls-dropdown ac-ls-actions-item select-collapsed">\n' +
                '                    <div id="ac-ls-dropdown-select" class="ac-ls-dropdown-select" type="text" role="button" aria-haspopup="true" tabindex="0">\n' +
                '                        <span id="ac-ls-dropdown-title"><span class="ac-ls-dropdown-check"></span> <span class="ac-ls-dropdown-copy">Singapore (SG$)</span></span>\n' +
                '                    </div>\n' +
                '                    <div id="ac-ls-dropdown-options" class="ac-ls-dropdown-options" role="menu" aria-expanded="false">\n' +
                '                        <ul class="ac-ls-dropdown-options-list" role="presentation">\n' +
                '                            <li role="menuitem" id="ac-ls-dropdown-option-sg" class="ac-ls-dropdown-option" aria-selected="true" tabindex="0" data-href="/sgd/">\n' +
                '                                <span class="ac-ls-dropdown-check"></span> <span class="ac-ls-dropdown-copy">Singapore (SG$)</span></li>\n' +
                '                            <li role="menuitem" id="ac-ls-dropdown-option-us" class="ac-ls-dropdown-option" data-href="/usd/" tabindex="0">\n' +
                '                                <span class="ac-ls-dropdown-check"></span> <span class="ac-ls-dropdown-copy">World (US$)</span></li>\n' +
                '                            <li role="menuitem" id="ac-ls-dropdown-option-au" class="ac-ls-dropdown-option" data-href="/aud/" tabindex="0">\n' +
                '                                <span class="ac-ls-dropdown-check"></span> <span class="ac-ls-dropdown-copy">Australia (AU$)</span></li>\n' +
                '                        </ul>\n' +
                '                    </div>\n' +
                '                </div>\n' +
                '                <a href="/sgd/" id="ac-ls-continue" class="ac-ls-button ac-ls-actions-item ac-ls-continue" role="button" data-analytics-title="continue button">Continue</a>\n' +
                '                <button id="ac-ls-close" class="ac-ls-close ac-ls-actions-item ac-ls-icon" data-analytics-title="exit button"><span class="ac-ls-close-text" aria-label="Exit"></span></button>'
            } else if (data.country_code == 'au') {
              var template = '<div id="ac-ls-dropdown" class="ac-ls-dropdown ac-ls-actions-item select-collapsed">\n' +
                '                    <div id="ac-ls-dropdown-select" class="ac-ls-dropdown-select" type="text" role="button" aria-haspopup="true" tabindex="0">\n' +
                '                        <span id="ac-ls-dropdown-title"><span class="ac-ls-dropdown-check"></span> <span class="ac-ls-dropdown-copy">Australia (AU$)</span></span>\n' +
                '                    </div>\n' +
                '                    <div id="ac-ls-dropdown-options" class="ac-ls-dropdown-options" role="menu" aria-expanded="false">\n' +
                '                        <ul class="ac-ls-dropdown-options-list" role="presentation">\n' +
                '                            <li role="menuitem" id="ac-ls-dropdown-option-sg" class="ac-ls-dropdown-option" aria-selected="true" tabindex="0" data-href="/aud/">\n' +
                '                                <span class="ac-ls-dropdown-check"></span> <span class="ac-ls-dropdown-copy">Australia (AU$)</span></li>\n' +
                '                            <li role="menuitem" id="ac-ls-dropdown-option-us" class="ac-ls-dropdown-option" data-href="/usd/" tabindex="0">\n' +
                '                                <span class="ac-ls-dropdown-check"></span> <span class="ac-ls-dropdown-copy">World (US$)</span></li>\n' +
                '                            <li role="menuitem" id="ac-ls-dropdown-option-au" class="ac-ls-dropdown-option" data-href="/sgd/" tabindex="0">\n' +
                '                                <span class="ac-ls-dropdown-check"></span> <span class="ac-ls-dropdown-copy">Singapore (SG$)</span></li>\n' +
                '                        </ul>\n' +
                '                    </div>\n' +
                '                </div>\n' +
                '                <a href="/aud/" id="ac-ls-continue" class="ac-ls-button ac-ls-actions-item ac-ls-continue" role="button" data-analytics-title="continue button">Continue</a>\n' +
                '                <button id="ac-ls-close" class="ac-ls-close ac-ls-actions-item ac-ls-icon" data-analytics-title="exit button"><span class="ac-ls-close-text" aria-label="Exit"></span></button>'
            } else {
              var template = '<div id="ac-ls-dropdown" class="ac-ls-dropdown ac-ls-actions-item select-collapsed">\n' +
                '                    <div id="ac-ls-dropdown-select" class="ac-ls-dropdown-select" type="text" role="button" aria-haspopup="true" tabindex="0">\n' +
                '                        <span id="ac-ls-dropdown-title"><span class="ac-ls-dropdown-check"></span> <span class="ac-ls-dropdown-copy">World (US$)</span></span>\n' +
                '                    </div>\n' +
                '                    <div id="ac-ls-dropdown-options" class="ac-ls-dropdown-options" role="menu" aria-expanded="false">\n' +
                '                        <ul class="ac-ls-dropdown-options-list" role="presentation">\n' +
                '                            <li role="menuitem" id="ac-ls-dropdown-option-sg" class="ac-ls-dropdown-option" aria-selected="true" tabindex="0" data-href="/usd/">\n' +
                '                                <span class="ac-ls-dropdown-check"></span> <span class="ac-ls-dropdown-copy">World (US$)</span></li>\n' +
                '                            <li role="menuitem" id="ac-ls-dropdown-option-us" class="ac-ls-dropdown-option" data-href="/aud/" tabindex="0">\n' +
                '                                <span class="ac-ls-dropdown-check"></span> <span class="ac-ls-dropdown-copy">Australia (AU$)</span></li>\n' +
                '                            <li role="menuitem" id="ac-ls-dropdown-option-au" class="ac-ls-dropdown-option" data-href="/sgd/" tabindex="0">\n' +
                '                                <span class="ac-ls-dropdown-check"></span> <span class="ac-ls-dropdown-copy">Singapore (SG$)</span></li>\n' +
                '                        </ul>\n' +
                '                    </div>\n' +
                '                </div>\n' +
                '                <a href="/usd/" id="ac-ls-continue" class="ac-ls-button ac-ls-actions-item ac-ls-continue" role="button" data-analytics-title="continue button">Continue</a>\n' +
                '                <button id="ac-ls-close" class="ac-ls-close ac-ls-actions-item ac-ls-icon" data-analytics-title="exit button"><span class="ac-ls-close-text" aria-label="Exit"></span></button>'
            }

            $('#ac-ls-select').html(template);

            var localeExpiry = localStorage.getItem('localeExpiry');
            var localeOff = localStorage.getItem('localeOff');
            var path = window.location.pathname;
            if (path.indexOf('/' + data.country_code) == 0 && (data.country_code == 'au' || data.country_code == 'us')) {
            } else if (data.country_code == 'sg' && path.indexOf('/au') < 0 && path.indexOf('/us') < 0) {
            } else {
              if (localeOff != '1' || localeExpiry < Date.now()) {
                if (!document.querySelector('html').classList.contains('ac-ls-visible')) {
                  document.querySelector('html').classList.toggle('ac-ls-visible');
                }
                if (!document.querySelector('html').classList.contains('ac-ls-fixed')) {
                  document.querySelector('html').classList.toggle('ac-ls-fixed');
                }
              }
            }

            var dropdown = document.getElementById("ac-ls-dropdown");
            var selector = document.getElementById("ac-ls-dropdown-select");

            selector.addEventListener("click", function () {
              dropdown.classList.toggle("select-collapsed");
            });

            var selectOptions = document.querySelectorAll(".ac-ls-dropdown-option");
            // var defaultNewUrl = url.replace(/\/sg\/|\/au\/|\/us\//gi, selectOptions[0].dataset.href);
            // document.getElementById('ac-ls-continue').setAttribute('href', defaultNewUrl);

            for (let i = 0; i < selectOptions.length; i++) {
              selectOptions[i].addEventListener("click", function () {
                for (let j = 0; j < selectOptions.length; j++) {
                  selectOptions[j].setAttribute("aria-selected", false);
                }
                this.setAttribute("aria-selected", true);
                dropdown.classList.toggle("select-collapsed");
                document.querySelector('.ac-ls-dropdown-copy').textContent = this.querySelector('.ac-ls-dropdown-copy').textContent;
                // var newUrl = url.replace(/\/sg\/|\/au\/|\/us\//gi,this.dataset.href);
                document.getElementById('ac-ls-continue').setAttribute('href', this.dataset.href);
                // document.getElementById('ac-ls-continue').setAttribute('href', newUrl);
              });
            }

            document.getElementById('ac-ls-close').addEventListener('click', function () {
              localStorage.setItem('localeOff', '1');
              localStorage.setItem('localeExpiry', Date.now() + 86400 * 1000);
              document.querySelector('html').classList.toggle('ac-ls-visible');
              document.querySelector('html').classList.toggle('ac-ls-fixed');
            });
          },
        });
        }
      })(window.jQuery);
    },
  },
  computed: {
    homeUrl() {
      let homeUrl;

      if (this.currency === 'sgd') {
        homeUrl = 'https://manage.cldy.com/';
      }

      if (this.currency === 'aud') {
        homeUrl = 'https://manage.cldy.com/aud/';
      }

      if (this.currency === 'usd') {
        homeUrl = 'https://manage.cldy.com/usd/';
      }

      return homeUrl;
    },
    contactUsUrl() {
      let contactUsUrl;

      if (this.currency === 'sgd') {
        contactUsUrl = 'https://www.cldy.com/sg/contact-us/#getintouch';
      }

      if (this.currency === 'aud') {
        contactUsUrl = 'https://www.cldy.com/au/contact-us/#getintouch';
      }

      if (this.currency === 'usd') {
        contactUsUrl = 'https://www.cldy.com/contact-us/#getintouch';
      }

      return contactUsUrl;
    },
  },
};
</script>
<style>
  .theme--light.v-application {
    /*background-color: #000C19;*/
  }

  .container {
    padding: 0;
    background-color: white;
  }

  .main-logo-block {
    height: 140px;
    /*width: 600px;*/
    position: absolute;
    /*top: 0;*/
  }

  .top-nav {
    height: 70px;
    background-color: rgb(0, 119, 255);
    padding: 13px 128px 13px 13px;
  }

  .top-nav a {
    color: white !important;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    font-family: Inter,sans-serif;
    margin-right: 16px;
    display: flex;
    align-items: center;
  }

  .bottom-nav {
    height: 71px;
    padding: 13px 128px 13px 13px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  }

  .bottom-nav a {
    position: relative;
    color: black !important;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    margin-left: 64px;
    display: flex;
    align-items: center;
  }

  .bottom-nav a::after {
    background-color: rgb(0, 119, 255);
    content: "";
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 0;
    position: absolute;
    transition: .3s;
    opacity: 0;
  }

  .bottom-nav a:hover:after  {
    opacity: 1;
  }

  .top-nav a > i {
    margin-right: 4px;
  }

  .bottom-nav {
    height: 70px;
  }

  img.main-logo {
    width: auto;
    height: 140px;
    margin-left: -1px;
  }

  #main-header {
    max-width: 1400px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 1264px) {
    .top-nav, .bottom-nav {
      padding: 13px 13px 13px 13px;
    }

    .main-logo-block {
      top: 70px;
      width: 100%;
    }

    img.main-logo {
      height: 60px;
      margin: 5px 13px;
    }
  }

  html{
    --r-globalnav-segmentbar-text-color: rgba(255,
    255,
    255,
    0.92);
    --r-globalnav-segmentbar-border-color: rgba(255,
    255,
    255,
    0.24);
    --r-globalnav-segmentbar-background: #424245;
  }
  #ac-localeswitcher button{
    background:none;
    border:0;
    box-sizing:content-box;
    color:inherit;
    cursor:pointer;
    font:inherit;
    line-height:inherit;
    vertical-align:inherit}
  #ac-localeswitcher button:disabled{
    cursor:default}
  #ac-localeswitcher *{
    direction:ltr}
  #ac-localeswitcher{
    font-weight:normal;
    -webkit-text-size-adjust:100%;
    text-size-adjust:100%}
  #ac-localeswitcher,
  #ac-localeswitcher:before,
  #ac-localeswitcher:after,
  #ac-localeswitcher *,
  #ac-localeswitcher *:before,
  #ac-localeswitcher *:after{
    box-sizing:content-box;
    margin:0;
    padding:0;
    float:initial;
    pointer-events:auto;
    letter-spacing:normal}
  #ac-localeswitcher *,
  #ac-localeswitcher *:before,
  #ac-localeswitcher *:after{
    float:inherit;
    font-size:1em;
    font-family:inherit;
    font-weight:inherit;
    line-height:inherit;
    letter-spacing:inherit;
    text-align:inherit}
  #ac-localeswitcher div,
  #ac-localeswitcher main,
  #ac-localeswitcher article,
  #ac-localeswitcher aside,
  #ac-localeswitcher details,
  #ac-localeswitcher figcaption,
  #ac-localeswitcher figure,
  #ac-localeswitcher footer,
  #ac-localeswitcher header,
  #ac-localeswitcher nav,
  #ac-localeswitcher section{
    display:block}
  #ac-localeswitcher img{
    border:0;
    vertical-align:middle}
  #ac-localeswitcher ul{
    list-style:none}
  #ac-localeswitcher,
  #ac-localeswitcher input,
  #ac-localeswitcher textarea,
  #ac-localeswitcher select,
  #ac-localeswitcher button{
    font-synthesis:none;
    -moz-font-feature-settings:'kern';
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    direction:ltr;
    text-align:left}
  #ac-localeswitcher,
  #ac-localeswitcher select,
  #ac-localeswitcher button{
    font-family:"SF Pro Text",
    "Myriad Set Pro",
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "SF Pro Icons",
    "Apple Legacy Icons",
    "Helvetica Neue",
    "Helvetica",
    "Arial",
    sans-serif}
  #ac-localeswitcher input,
  #ac-localeswitcher textarea{
    font-family:"SF Pro Text",
    "Myriad Set Pro",
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "SF Pro Icons",
    "Apple Legacy Icons",
    "Helvetica Neue",
    "Helvetica",
    "Arial",
    sans-serif}
  #ac-localeswitcher sup{
    font-size:0.6em;
    vertical-align:top;
    position:relative;
    bottom:-0.2em}
  h1 #ac-localeswitcher sup,
  h2 #ac-localeswitcher sup,
  h3 #ac-localeswitcher sup{
    font-size:0.4em}
  #ac-localeswitcher sup a{
    vertical-align:inherit;
    color:inherit}
  #ac-localeswitcher sup a:hover{
    color:#06c;
    text-decoration:none}
  #ac-localeswitcher sub{
    font-size:smaller;
    vertical-align:sub;
    line-height:1}
  #ac-localeswitcher{
    display:none;
    position:absolute;
    z-index:999;
    top:0;
    left:0;
    right:0;
    min-width:1024px;
    width:100%;
    font-size:17px;
    background:var(--r-globalnav-segmentbar-background,
    #424245)}
  html.ac-ls-visible #ac-localeswitcher{
    display:flex;}
  html.ac-ls-visible{
    margin-top:70px}
  @media only screen and (max-width: 833px){
    html.ac-ls-visible{
      margin-top:135px}
  }
  html.ac-ls-visible body{
    /*padding-top:70px;*/
    position:relative;
  }
  @media only screen and (max-width: 833px){
    html.ac-ls-visible body{
      padding-top:135px}
  }
  #ac-localeswitcher .ac-ls-content{
    display:flex;
    box-sizing:border-box;
    width:100%;
    max-width:1024px;
    margin:0 auto;
    padding-inline-start:max(22px,
    env(safe-area-inset-left));
    padding-inline-end:max(22px,
    env(safe-area-inset-right));
    flex-wrap:wrap;
    align-items:center;
    list-style:none;
    color:var(--r-globalnav-segmentbar-text-color,
    rgba(255,
      255,
      255,
      0.92))}
  @media only screen and (max-width: 833px){
    #ac-localeswitcher .ac-ls-content{
      padding-inline-start:max(16px,
      env(safe-area-inset-left));
      padding-inline-end:max(16px,
      env(safe-area-inset-right));
      padding-top:16px;
      padding-bottom:16px;
      margin-inline-start:0;
      max-width:520px}
  }
  @media only screen and (max-width: 419px){
    #ac-localeswitcher .ac-ls-content{
      align-items:flex-start}
  }
  #ac-localeswitcher .ac-ls-copy{
    font-size:12px;
    line-height:1.33337;
    font-weight:400;
    letter-spacing:-.01em;
    font-family:"SF Pro Text",
    "Myriad Set Pro",
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "SF Pro Icons",
    "Apple Legacy Icons",
    "Helvetica Neue",
    "Helvetica",
    "Arial",
    sans-serif;
    padding-inline-end:2%;
    display:inline-block;
    flex:1;
    word-break:initial;
    max-width:430px}
  #ac-localeswitcher .ac-ls-copy:lang(ko){
    word-break:keep-all}
  @media only screen and (max-width: 833px){
    #ac-localeswitcher .ac-ls-copy{
      padding-inline-end:max(2%,
      42px);
      box-sizing:border-box;
      flex:1 0 100%;
      max-width:100%}
  }
  #ac-localeswitcher .ac-ls-actions{
    padding-inline-start:32px;
    box-sizing:border-box;
    display:flex;
    flex:1;
    justify-content:flex-end}
  @media only screen and (max-width: 833px){
    #ac-localeswitcher .ac-ls-actions{
      padding-inline-start:0;
      flex:1 0 100%;
      max-width:100%}
  }
  @media only screen and (max-width: 419px){
    #ac-localeswitcher .ac-ls-actions{
      display:flex;
      flex:1 0 100%;
      margin-top:auto}
  }
  #ac-localeswitcher .ac-ls-actions-item{
    margin-inline-start:16px;
    display:inline-block}
  #ac-localeswitcher .ac-ls-actions-item:first-child{
    margin-inline-start:0}
  #ac-localeswitcher .ac-ls-actions-item:last-child{
    margin-inline-start:15px;
    margin-inline-end:-2px}
  @media only screen and (max-width: 833px){
    #ac-localeswitcher .ac-ls-actions-item:last-child{
      margin-inline-start:0}
  }
  #ac-localeswitcher .ac-ls-continue{
    cursor:pointer;
    display:inline-block;
    text-align:center;
    white-space:nowrap;
    font-size:17px;
    line-height:1.17648;
    font-weight:400;
    letter-spacing:-.022em;
    font-family:"SF Pro Text",
    "Myriad Set Pro",
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "SF Pro Icons",
    "Apple Legacy Icons",
    "Helvetica Neue",
    "Helvetica",
    "Arial",
    sans-serif;
    min-width:28px;
    padding-left:16px;
    padding-right:16px;
    padding-top:8px;
    padding-bottom:8px;
    border-radius:980px;
    background:#0071e3;
    color:#fff;
    box-sizing:border-box;
    display:block;
    width:100%;
    border-radius:8px;
    width:auto;
    text-decoration:none}
  #ac-localeswitcher .ac-ls-continue:hover{
    text-decoration:none}
  #ac-localeswitcher .ac-ls-continue:focus{
    box-shadow:0 0 0 4px rgba(0,
    125,
    250,
    0.6);
    outline:none}
  #ac-localeswitcher .ac-ls-continue:focus[data-focus-method="mouse"]:not(input):not(textarea):not(select),
  #ac-localeswitcher .ac-ls-continue:focus[data-focus-method="touch"]:not(input):not(textarea):not(select){
    box-shadow:none}
  #ac-localeswitcher .ac-ls-continue:active{
    outline:none}
  #ac-localeswitcher .ac-ls-continue:disabled,
  #ac-localeswitcher .ac-ls-continue.disabled{
    cursor:default}
  #ac-localeswitcher .ac-ls-continue:hover{
    background:#0077ED}
  #ac-localeswitcher .ac-ls-continue:active{
    background:#006EDB}
  #ac-localeswitcher .ac-ls-continue:disabled,
  #ac-localeswitcher .ac-ls-continue.disabled{
    background:#0071e3;
    color:#fff;
    opacity:.32}
  @media only screen and (max-width: 419px){
    #ac-localeswitcher .ac-ls-continue{
      overflow:hidden;
      text-overflow:ellipsis;
      max-width:calc(50% - 8px)}
  }
  #ac-localeswitcher .ac-ls-close{
    font-size:18px;
    font-weight:200;
    line-height:18px;
    color:rgba(255,
    255,
    255,
    0.56);
    display:block;
    width:auto;
    height:18px;
    margin-top:auto;
    margin-bottom:auto;
    opacity:0.8}
  #ac-localeswitcher .ac-ls-close::before,
  #ac-localeswitcher .ac-ls-close::after{
    font-family:"SF Pro Icons",
    "Apple Legacy Icons";
    color:white;
    display:inline-block;
    font-style:normal;
    font-weight:inherit;
    font-size:inherit;
    line-height:1;
    text-decoration:underline;
    position:relative;
    z-index:1;
    alt:''}
  #ac-localeswitcher .ac-ls-close::before,
  #ac-localeswitcher .ac-ls-close::after{
    text-decoration:none}
  #ac-localeswitcher .ac-ls-close::before{
    display:none}
  #ac-localeswitcher .ac-ls-close::before,
  #ac-localeswitcher .ac-ls-close::after{
    content:"\2716"}
  #ac-localeswitcher .ac-ls-close:hover,
  #ac-localeswitcher .ac-ls-close:focus{
    opacity:1}
  #ac-localeswitcher .ac-ls-close:focus{
    outline:4px solid rgba(0,
    125,
    250,
    0.6);
    outline-offset:1px}
  #ac-localeswitcher .ac-ls-close:focus[data-focus-method="mouse"]:not(input):not(textarea):not(select),
  #ac-localeswitcher .ac-ls-close:focus[data-focus-method="touch"]:not(input):not(textarea):not(select){
    outline:none}
  @media only screen and (max-width: 833px){
    #ac-localeswitcher .ac-ls-close{
      right:max(16px,
      env(safe-area-inset-right));
      top:17px;
      position:absolute}
  }
  #ac-localeswitcher .ac-ls-dropdown{
    position:relative;
    flex:1;
    max-width:343px;
    min-width:246px}
  @media only screen and (max-width: 833px){
    #ac-localeswitcher .ac-ls-dropdown{
      margin-inline-end:auto;
      position:relative;
      width:100%;
      max-width:100%;
      min-width:auto}
  }
  @media only screen and (max-width: 419px){
    #ac-localeswitcher .ac-ls-dropdown{
      width:56%;
      min-width:50%}
  }
  #ac-localeswitcher .ac-ls-dropdown-select,
  #ac-localeswitcher .ac-ls-dropdown-option{
    padding:0 14px;
    height:36px;
    line-height:35px;
    box-sizing:border-box;
    cursor:default;
    width:100%}
  #ac-localeswitcher .ac-ls-dropdown-select{
    border:1px solid var(--r-globalnav-segmentbar-border-color,
    rgba(255,
      255,
      255,
      0.24));
    border-radius:8px;
    border-bottom:0;
    border-bottom-left-radius:0;
    border-bottom-right-radius:0;
    background-color:var(--r-globalnav-segmentbar-background,
    #424245)}
  #ac-localeswitcher .ac-ls-dropdown-select::before,
  #ac-localeswitcher .ac-ls-dropdown-select::after{
    font-family:"SF Pro Icons",
    "Apple Legacy Icons";
    color:inherit;
    display:inline-block;
    font-style:normal;
    font-weight:inherit;
    font-size:inherit;
    line-height:1;
    text-decoration:underline;
    position:relative;
    z-index:1;
    alt:''}
  #ac-localeswitcher .ac-ls-dropdown-select::before,
  #ac-localeswitcher .ac-ls-dropdown-select::after{
    text-decoration:none}
  #ac-localeswitcher .ac-ls-dropdown-select::before{
    display:none}
  #ac-localeswitcher .ac-ls-dropdown-select::before,
  #ac-localeswitcher .ac-ls-dropdown-select::after{
    content:"\2304"}
  #ac-localeswitcher .ac-ls-dropdown-select:after{
    right:15px;
    top:-5px;
    position:absolute;
    height:34px;
    font-size:24px;
    line-height:30px;
    font-weight:300;
    color:rgba(255,
    255,
    255,
    0.56)}
  #ac-localeswitcher .ac-ls-dropdown-select:focus{
    box-shadow:0 0 0 4px rgba(0,
    125,
    250,
    0.6);
    outline:none;
    border-color:#0071e3}
  #ac-localeswitcher .ac-ls-dropdown-select:focus[data-focus-method="mouse"]:not(input):not(textarea):not(select),
  #ac-localeswitcher .ac-ls-dropdown-select:focus[data-focus-method="touch"]:not(input):not(textarea):not(select){
    box-shadow:none}
  #ac-localeswitcher .ac-ls-dropdown-check{
    font-size:24px;
    visibility:hidden;
    position:absolute}
  #ac-localeswitcher .ac-ls-dropdown-check::before,
  #ac-localeswitcher .ac-ls-dropdown-check::after{
    font-family:"SF Pro Icons",
    "Apple Legacy Icons";
    color:inherit;
    display:inline-block;
    font-style:normal;
    font-weight:inherit;
    font-size:inherit;
    line-height:1;
    text-decoration:underline;
    position:relative;
    z-index:1;
    alt:''}
  #ac-localeswitcher .ac-ls-dropdown-check::before,
  #ac-localeswitcher .ac-ls-dropdown-check::after{
    text-decoration:none}
  #ac-localeswitcher .ac-ls-dropdown-check::before{
    display:none}
  #ac-localeswitcher .ac-ls-dropdown-check::before,
  #ac-localeswitcher .ac-ls-dropdown-check::after{
    content:"\2713";
    font-size:18px;
  }
  #ac-localeswitcher .ac-ls-dropdown-check:after{
    left: 2px;
    top: -2px;
  }
  #ac-localeswitcher .ac-ls-dropdown-copy{
    padding-inline-start:29px;
    white-space:nowrap;
    text-overflow:ellipsis;
    display:inline-block;
    overflow:hidden;
    width:calc(100% - 48px);
    -webkit-user-select:none;
    user-select:none}
  #ac-localeswitcher .ac-ls-dropdown-options{
    display:block;
    position:absolute;
    width:100%;
    top:0}
  #ac-localeswitcher .ac-ls-dropdown-options-list{
    box-shadow:0 0 0 4px rgba(0,
    125,
    250,
    0.6);
    outline:none;
    margin-inline-start:0;
    list-style-type:none;
    left:0;
    right:0;
    top:-36px;
    background-color:#333336;
    border:1px solid var(--r-globalnav-segmentbar-border-color,
    rgba(255,
      255,
      255,
      0.24));
    border-radius:8px}
  #ac-localeswitcher .ac-ls-dropdown-options-list::before,
  #ac-localeswitcher .ac-ls-dropdown-options-list::after{
    font-family:"SF Pro Icons",
    "Apple Legacy Icons";
    color:inherit;
    display:inline-block;
    font-style:normal;
    font-weight:inherit;
    font-size:inherit;
    line-height:1;
    text-decoration:underline;
    position:relative;
    z-index:1;
    alt:''}
  #ac-localeswitcher .ac-ls-dropdown-options-list::before,
  #ac-localeswitcher .ac-ls-dropdown-options-list::after{
    text-decoration:none}
  #ac-localeswitcher .ac-ls-dropdown-options-list::before{
    display:none}
  #ac-localeswitcher .ac-ls-dropdown-options-list::before,
  #ac-localeswitcher .ac-ls-dropdown-options-list::after{
    content:"\2304"}
  #ac-localeswitcher .ac-ls-dropdown-options-list[data-focus-method="mouse"]:not(input):not(textarea):not(select),
  #ac-localeswitcher .ac-ls-dropdown-options-list[data-focus-method="touch"]:not(input):not(textarea):not(select){
    box-shadow:none}
  #ac-localeswitcher .ac-ls-dropdown-options-list:after{
    right:15px;
    top:8px;
    position:absolute;
    height:34px;
    transform:rotate(180deg);
    transform-origin:center;
    font-size:24px;
    line-height:30px;
    font-weight:300;
    color:rgba(255,
    255,
    255,
    0.56)}
  #ac-localeswitcher .ac-ls-dropdown-option{
    border-bottom:1px solid var(--r-globalnav-segmentbar-border-color,
    rgba(255,
      255,
      255,
      0.24));
    position:relative;
    background-color:var(--r-globalnav-segmentbar-background,
    #424245);
    outline:none}
  #ac-localeswitcher .ac-ls-dropdown-option[data-focus-method='key'],
  #ac-localeswitcher .ac-ls-dropdown-option:hover{
    background-color:#57575a}
  #ac-localeswitcher .ac-ls-dropdown-option:first-child{
    border-top-left-radius:7px;
    border-top-right-radius:7px}
  #ac-localeswitcher .ac-ls-dropdown-option:last-child{
    border-bottom:none;
    border-bottom-left-radius:7px;
    border-bottom-right-radius:7px}
  #ac-localeswitcher .select-collapsed .ac-ls-dropdown-select{
    border:1px solid var(--r-globalnav-segmentbar-border-color,
    rgba(255,
      255,
      255,
      0.24));
    border-radius:8px}
  #ac-localeswitcher .select-collapsed .ac-ls-dropdown-select:focus{
    box-shadow:0 0 0 4px rgba(0,
    125,
    250,
    0.6) !important;
    border-color:#0071e3}
  #ac-localeswitcher .select-collapsed .ac-ls-dropdown-select .ac-ls-dropdown-copy{
    width:calc(100% - 20px)}
  #ac-localeswitcher .select-collapsed .ac-ls-dropdown-select .ac-ls-dropdown-check+.ac-ls-dropdown-copy{
    width:calc(100% - 48px);
    padding-inline-start:29px}
  #ac-localeswitcher .select-collapsed .ac-ls-dropdown-select .ac-ls-dropdown-check{
    visibility:visible}
  #ac-localeswitcher .select-collapsed .ac-ls-dropdown-options{
    display:none}
  #ac-localeswitcher .ac-ls-dropdown:not(.select-collapsed) .ac-ls-dropdown-select{
    box-shadow:none;
    border-color:transparent}
  #ac-localeswitcher .ac-ls-dropdown:not(.select-collapsed) .ac-ls-dropdown-select:after{
    visibility:hidden}
  #ac-localeswitcher .ac-ls-dropdown:not(.select-collapsed) .ac-ls-dropdown-options-list{
    box-shadow:0 0 0 4px rgba(0,
    125,
    250,
    0.6);
    outline:none;
    border:1px solid #0071e3}
  #ac-localeswitcher .ac-ls-dropdown:not(.select-collapsed) .ac-ls-dropdown-options-list[data-focus-method="mouse"]:not(input):not(textarea):not(select),
  #ac-localeswitcher .ac-ls-dropdown:not(.select-collapsed) .ac-ls-dropdown-options-list[data-focus-method="touch"]:not(input):not(textarea):not(select){
    box-shadow:none}
  #ac-localeswitcher .ac-ls-dropdown-option[aria-selected="true"]{
    background-color:var(--r-globalnav-segmentbar-background,
    #424245)}
  #ac-localeswitcher .ac-ls-dropdown-option[aria-selected="true"][data-focus-method='key'],
  #ac-localeswitcher .ac-ls-dropdown-option[aria-selected="true"]:hover{
    background-color:#57575a}
  #ac-localeswitcher .ac-ls-dropdown-option[aria-selected="true"] .ac-ls-dropdown-check{
    visibility:visible}
  html.ac-ls-visible{
    margin-top:0 !important;
    top:0}
  html.ac-ls-visible #ac-localeswitcher{
    position:fixed !important;
    top: 40%;
    width: 60%;
    margin: 0 auto;
    padding: 40px;
    border-radius: 10px;
    min-width: 60%;}
  @media only screen and (max-width: 1023px){
    html.ac-ls-visible #ac-localeswitcher{
      width: calc(100% - 100px);
      min-width: calc(100% - 100px);}
  }
  html.ac-ls-visible .ac-nav-overlap #main,
  html.ac-ls-visible .ac-nav-overlap .main{
    margin-top:70px}
  @media only screen and (max-width: 833px){
    html.ac-ls-visible .ac-nav-overlap #main,
    html.ac-ls-visible .ac-nav-overlap .main{
      margin-top:135px}
  }
  html.ac-ls-visible .ac-nav-overlap #ac-localnav{
    margin-top:114px}
  @media only screen and (max-width: 833px){
    html.ac-ls-visible .ac-nav-overlap #ac-localnav{
      margin-top:183px}
  }
  html.ac-ls-visible .ac-nav-overlap #ac-localnav ~ #main,
  html.ac-ls-visible .ac-nav-overlap #ac-localnav ~ .main{
    margin-top:0}
  @media only screen and (max-width: 833px){
    html.ac-ls-visible .ac-nav-overlap #ac-localnav ~ #main,
    html.ac-ls-visible .ac-nav-overlap #ac-localnav ~ .main{
      margin-top:0}
  }
  html.ac-ls-visible #ac-gn-placeholder{
    height:114px}
  @media only screen and (max-width: 833px){
    html.ac-ls-visible #ac-gn-placeholder{
      height:183px}
  }
  html.ac-ls-visible #ac-globalnav{
    position:absolute;
    top:70px}
  @media only screen and (max-width: 833px){
    html.ac-ls-visible #ac-globalnav{
      top:135px}
  }
  html.ac-ls-visible #chapternav{
    position:absolute;
    top:70px;
    margin-top:44px}
  @media only screen and (max-width: 833px){
    html.ac-ls-visible #chapternav{
      top:135px;
      margin-top:48px}
  }
  html.ac-ls-visible .ac-nav-overlap #chapternav{
    position:absolute;
    top:70px}
  @media only screen and (max-width: 833px){
    html.ac-ls-visible .ac-nav-overlap #chapternav{
      top:135px}
  }
  html.ac-ls-visible #chapternav ~ #main,
  html.ac-ls-visible #chapternav ~ .main{
    margin-top:110px}
  @media only screen and (max-width: 833px){
    html.ac-ls-visible #chapternav ~ #main,
    html.ac-ls-visible #chapternav ~ .main{
      margin-top:110px}
  }
  html.ac-ls-visible .ac-nav-overlap #chapternav ~ #main,
  html.ac-ls-visible .ac-nav-overlap #chapternav ~ .main{
    margin-top:70px}
  @media only screen and (max-width: 833px){
    html.ac-ls-visible .ac-nav-overlap #chapternav ~ #main,
    html.ac-ls-visible .ac-nav-overlap #chapternav ~ .main{
      margin-top:135px}
  }
  @media only screen and (max-width: 833px){
    html.ac-ls-visible.ac-ls-fixed #ac-globalnav{
      top:135px}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible{
    margin-top:0 !important;
    top:0}
  html.ac-gn-segmentbar-visible.ac-ls-visible #ac-localeswitcher{
    position:absolute;
    top:0}
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #main,
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap .main{
    margin-top:70px}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #main,
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap .main{
      margin-top:135px}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #ac-localnav{
    margin-top:114px}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #ac-localnav{
      margin-top:183px}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #ac-localnav ~ #main,
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #ac-localnav ~ .main{
    margin-top:0}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #ac-localnav ~ #main,
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #ac-localnav ~ .main{
      margin-top:0}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible #ac-gn-placeholder{
    height:114px}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible #ac-gn-placeholder{
      height:183px}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible #ac-gn-segmentbar{
    top:70px}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible #ac-gn-segmentbar{
      top:135px}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible #ac-gn-segmentbar .ac-gn-segmentbar-content:before{
    background:var(--r-globalnav-segmentbar-border-color,
    rgba(255,
      255,
      255,
      0.24))}
  html.ac-gn-segmentbar-visible.ac-ls-visible #ac-globalnav{
    top:110px}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible #ac-globalnav{
      top:179px}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible #chapternav{
    top:110px;
    margin-top:44px}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible #chapternav{
      top:179px;
      margin-top:48px}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #chapternav{
    position:absolute;
    top:110px}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #chapternav{
      top:179px}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #chapternav ~ #main,
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #chapternav ~ .main{
    margin-top:110px}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #chapternav ~ #main,
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #chapternav ~ .main{
      margin-top:179px}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #main,
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap .main{
    margin-top:110px}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #main,
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap .main{
      margin-top:179px}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #ac-localnav{
    margin-top:154px}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #ac-localnav{
      margin-top:227px}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #ac-localnav ~ #main,
  html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #ac-localnav ~ .main{
    margin-top:0}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #ac-localnav ~ #main,
    html.ac-gn-segmentbar-visible.ac-ls-visible .ac-nav-overlap #ac-localnav ~ .main{
      margin-top:0}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible #ac-gn-placeholder{
    height:154px}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible #ac-gn-placeholder{
      height:227px}
  }
  html.ac-gn-segmentbar-visible.ac-ls-visible.ac-gn-segmentbar-visible.ac-ls-fixed #main,
  html.ac-gn-segmentbar-visible.ac-ls-visible.ac-gn-segmentbar-visible.ac-ls-fixed .main{
    top:40px}
  @media only screen and (max-width: 833px){
    html.ac-gn-segmentbar-visible.ac-ls-visible.ac-gn-segmentbar-visible.ac-ls-fixed #main,
    html.ac-gn-segmentbar-visible.ac-ls-visible.ac-gn-segmentbar-visible.ac-ls-fixed .main{
      top:44px}
  }
  html[lang=zh-CN].ac-ls-visible{
    margin-top:0 !important;
    top:0}
  html[lang=zh-CN].ac-ls-visible #ac-localeswitcher{
    position:absolute;
    top:0}
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #main,
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap .main{
    margin-top:70px}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #main,
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap .main{
      margin-top:135px}
  }
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #ac-localnav{
    margin-top:114px}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #ac-localnav{
      margin-top:183px}
  }
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #ac-localnav ~ #main,
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #ac-localnav ~ .main{
    margin-top:0}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #ac-localnav ~ #main,
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #ac-localnav ~ .main{
      margin-top:0}
  }
  html[lang=zh-CN].ac-ls-visible #ac-gn-placeholder{
    height:114px}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible #ac-gn-placeholder{
      height:183px}
  }
  html[lang=zh-CN].ac-ls-visible .adv-wrapper{
    top:70px}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible .adv-wrapper{
      top:135px}
  }
  html[lang=zh-CN].ac-ls-visible .adv-wrapper .adv-wrapper-text:before{
    content:'';
    display:block;
    width:100%;
    box-sizing:border-box;
    border-top:1px solid var(--r-globalnav-segmentbar-border-color,
    rgba(255,
      255,
      255,
      0.24))}
  html[lang=zh-CN].ac-ls-visible.ac-gn-segmentbar-visible .adv-wrapper-text:before{
    display:none}
  html[lang=zh-CN].ac-ls-visible #ac-globalnav.onmove{
    top:0}
  html[lang=zh-CN].ac-ls-visible #ac-globalnav{
    top:110px}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible #ac-globalnav{
      top:179px}
  }
  html[lang=zh-CN].ac-ls-visible #chapternav{
    top:110px;
    margin-top:44px}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible #chapternav{
      top:179px;
      margin-top:48px}
  }
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #chapternav{
    position:absolute;
    top:110px}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #chapternav{
      top:179px}
  }
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #chapternav ~ #main,
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #chapternav ~ .main{
    margin-top:110px}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #chapternav ~ #main,
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #chapternav ~ .main{
      margin-top:179px}
  }
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #main,
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap .main{
    margin-top:110px}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #main,
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap .main{
      margin-top:179px}
  }
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #ac-localnav{
    margin-top:154px}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #ac-localnav{
      margin-top:227px}
  }
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #ac-localnav ~ #main,
  html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #ac-localnav ~ .main{
    margin-top:0}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #ac-localnav ~ #main,
    html[lang=zh-CN].ac-ls-visible .ac-nav-overlap #ac-localnav ~ .main{
      margin-top:0}
  }
  html[lang=zh-CN].ac-ls-visible #ac-gn-placeholder{
    height:154px}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible #ac-gn-placeholder{
      height:227px}
  }
  html[lang=zh-CN].ac-ls-visible.ac-gn-segmentbar-visible.ac-ls-fixed #main,
  html[lang=zh-CN].ac-ls-visible.ac-gn-segmentbar-visible.ac-ls-fixed .main{
    top:40px}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible.ac-gn-segmentbar-visible.ac-ls-fixed #main,
    html[lang=zh-CN].ac-ls-visible.ac-gn-segmentbar-visible.ac-ls-fixed .main{
      top:44px}
  }
  html[lang=zh-CN].ac-ls-visible.ac-ls-cn-hp .ac-nav-overlap #main,
  html[lang=zh-CN].ac-ls-visible.ac-ls-cn-hp .ac-nav-overlap .main{
    margin-top:0}
  @media only screen and (max-width: 833px){
    html[lang=zh-CN].ac-ls-visible.ac-ls-cn-hp .ac-nav-overlap #main,
    html[lang=zh-CN].ac-ls-visible.ac-ls-cn-hp .ac-nav-overlap .main{
      margin-top:0}
  }
  html.ac-ls-fixed #ac-localeswitcher,
  html.ac-ls-fixed #ac-gn-segmentbar,
  html.ac-ls-fixed #ac-globalnav,
  html.ac-gn-segmentbar-visible.ac-ls-fixed #ac-localeswitcher,
  html.ac-gn-segmentbar-visible.ac-ls-fixed #ac-gn-segmentbar,
  html.ac-gn-segmentbar-visible.ac-ls-fixed #ac-globalnav{
    position:relative;
  }
  html.ac-ls-fixed .ac-nav-overlap #main,
  html.ac-ls-fixed .ac-nav-overlap .main,
  html.ac-gn-segmentbar-visible.ac-ls-fixed .ac-nav-overlap #main,
  html.ac-gn-segmentbar-visible.ac-ls-fixed .ac-nav-overlap .main{
    margin-top:0}
  @media only screen and (max-width: 833px){
    html.ac-ls-fixed .ac-nav-overlap #main,
    html.ac-ls-fixed .ac-nav-overlap .main,
    html.ac-gn-segmentbar-visible.ac-ls-fixed .ac-nav-overlap #main,
    html.ac-gn-segmentbar-visible.ac-ls-fixed .ac-nav-overlap .main{
      margin-top:0}
  }
  #ac-localeswitcher br.large{
    display:block}
  @media only screen and (max-width: 1023px){
    #ac-localeswitcher br.large{
      display:none}
  }
  @media only screen and (max-width: 833px){
    #ac-localeswitcher br.large{
      display:none}
  }
  #ac-localeswitcher br.medium{
    display:none}
  @media only screen and (max-width: 1023px){
    #ac-localeswitcher br.medium{
      display:block}
  }
  @media only screen and (max-width: 833px){
    #ac-localeswitcher br.medium{
      display:none}
  }
  #ac-localeswitcher br.small{
    display:none}
  @media only screen and (max-width: 1023px){
    #ac-localeswitcher br.small{
      display:none}
  }
  @media only screen and (max-width: 833px){
    #ac-localeswitcher br.small{
      display:block}
  }
  html{
    --r-localeswitcher-height:0px}
  html.ac-ls-visible{
    --r-localeswitcher-height:70px}
  @media only screen and (max-width: 833px){
    html.ac-ls-visible{
      --r-localeswitcher-height:135px}
  }
</style>
<!-- eslint-enable -->

<template>
  <v-row no-gutters dense>
    <v-col cols="12" class="pos-fixed">
      <h4>Member Price</h4>
      <h5>First name: {{ user.first_name }}</h5>
      <h5>Last name: {{ user.last_name }}</h5>
      <h5>Company name: {{ user.company_name }}</h5>
      <h5>Email: {{ user.email }}</h5>
    </v-col>
    <v-col cols="12" class="margin-top-100">
      <v-row dense v-for="(productPrices, category) in basePrices" v-bind:key="category">
        <v-col cols="12">
          <v-row dense v-for="(productTermPrices, productId) in productPrices"
                 v-bind:key="productId">
            <v-col cols="12"><h4>{{ category }}</h4>
              <v-btn x-small color="primary"
                     @click="updateMemberPrice(productId, form.extra_prices[category][productId])"
              >Update Reseller Price</v-btn>
            </v-col>
            <v-col cols="3" v-for="(item, termId) in productTermPrices.retailPrices"
                   v-bind:key="termId">
              <span class="price-detail">{{ item.termName }}<br/>
                  Base Price: {{ item.price }}</span>
              <v-row no-gutters align="center" justify="center">
                <v-col cols="12">
                  <v-text-field
                    v-model="form.extra_prices[category][productId][termId]"
                    label="Extra margin price"
                    @change="syncProfit(category, productId, termId)"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <b class="text--darken-1 green--text">
                    Sell Price: ${{ sell[`${category}_${productId}_${termId}`] }}
                  </b>
                </v-col>
                <v-col cols="12" v-if="form.extra_prices[category][productId][termId] > 0">
                  <b class="text--darken-1 green--text">
                    Profit: ${{ profit[`${category}_${productId}_${termId}`] }}
                  </b>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DomainResellerProductPrices',
  created() {
    this.form.user_id = this.$route.params.id;
    this.$api.get(`partner/customer/${this.$route.params.id}`).then((response) => {
      this.user = response.data;
    });
    this.$api.get(`partner/domain-product-retail-prices?user_id=${this.$route.params.id}`).then((response) => {
      this.loadPrices(response.data);
    });
  },
  data() {
    return {
      user: null,
      basePrices: null,
      terms: {
        // 1: 'One Time',
        // 2: 'Monthly',
        // 3: 'Quarterly',
        // 4: 'Biannually',
        5: 'Annually',
        6: 'Biennially',
        7: 'Triennially',
        8: 'Quadrennially',
        9: 'Quinquennially',
        10: 'Sexennially',
        11: 'Septennially',
        12: 'Octennially',
        13: 'Novennially',
        14: 'Decennially',
      },
      form: {
        user_id: null,
        extra_prices: {},
      },
      profit: {},
      sell: {},
    };
  },
  methods: {
    syncProfit(category, productId, termId) {
      const amount = this.form.extra_prices[category][productId][termId];
      this.$set(this.profit, `${category}_${productId}_${termId}`, this.getProfit(amount));
      this.$set(this.sell, `${category}_${productId}_${termId}`,
        amount * 1 + this.basePrices[category][productId][termId] * 1);
    },
    getProfit(amount) {
      return Math.round((amount * 0.7 + Number.EPSILON) * 100) / 100;
    },
    updateMemberPrice(productId, memberPrice) {
      this.$api.post('partner/domain-product-retail-prices', {
        user_id: this.$route.params.id,
        product_id: productId,
        extra_prices: memberPrice,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Reseller price is updated successfully.',
          });
        });
    },
    loadPrices(prices) {
      const basePrices = {};
      this.$underscore.forEach(prices, (productPrices, categoryName) => {
        if (Array.isArray(productPrices) && productPrices.length === 0) {
          return;
        }
        basePrices[categoryName] = {};
        this.form.extra_prices[categoryName] = {};
        this.$underscore.forEach(productPrices, (productTermPrice, productId) => {
          if (productTermPrice.base_prices.length === 0) {
            return;
          }
          basePrices[categoryName][productId] = {
            retailPrices: {},
            tld: productTermPrice.tld,
          };
          this.form.extra_prices[categoryName][productId] = {};
          this.$underscore.forEach(this.terms, (termName, termId) => {
            if (typeof productTermPrice.base_prices !== 'undefined'
              && typeof productTermPrice.base_prices[termId] !== 'undefined') {
              basePrices[categoryName][productId].retailPrices[termId] = {
                price: `$${productTermPrice.base_prices[termId].productPrice}`,
                termName: this.terms[termId],
              };
            } else {
              return;
            }
            if (typeof productTermPrice.extra_prices !== 'undefined'
              && typeof productTermPrice.extra_prices[termId] !== 'undefined') {
              this.form.extra_prices[categoryName][productId][termId] = productTermPrice
                .extra_prices[termId].productExtraPrice;
              this.$set(this.profit, `${categoryName}_${productId}_${termId}`, this
                .getProfit(this.form.extra_prices[categoryName][productId][termId]));
              this.$set(this.sell, `${categoryName}_${productId}_${termId}`,
                productTermPrice.base_prices[termId].productPrice * 1
                + this.form.extra_prices[categoryName][productId][termId] * 1);
            } else {
              this.form.extra_prices[categoryName][productId][termId] = null;
              this.$set(this.profit, `${categoryName}_${productId}_${termId}`, null);
              this.$set(this.sell, `${categoryName}_${productId}_${termId}`,
                productTermPrice.base_prices[termId].productPrice * 6);
            }
          });
        });
      });

      this.$set(this, 'basePrices', basePrices);
    },
  },
};
</script>

<style scoped>
  .price-detail {
    font-size: 14px;
  }

  .pos-fixed {
    position: fixed;
    background-color: #fff;
    z-index: 100;
    margin-top: -12px;
    padding-left: 12px;
    border-left: 4px solid #1565C0;
    height: 100px;
  }

  .margin-top-100 {
    margin-top: 100px;
  }
</style>

<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="8">
          <v-text-field
            dense
            outlined
            v-model="domain"
            label="Domain"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="4">
          <v-btn
            color="primary"
            @click="queryDomain"
          >Query</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense align="center" v-show="showPage">
        <v-col cols="12">
          <v-row>
            <v-col cols="3">
              <h3>{{ form.domain }}</h3>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <h5>Status:<br>{{ form.status_name }}</h5>
            </v-col>
            <v-col cols="3">
              <h5>Registration Date:<br>{{ form.provision_date }}</h5>
            </v-col>
            <v-col cols="3">
              <h5>Expiry Date:<br>{{ form.expiry_date }}</h5>
            </v-col>
            <v-col cols="3">
            </v-col>
          </v-row>
          <v-form ref="updateNsForm">
            <v-row dense>
              <v-col cols="3">
                <v-text-field
                  label="NS1*"
                  v-model="form.ns1"
                  :rules="[rules.required, rules.nameserver]"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="NS2*"
                  v-model="form.ns2"
                  :rules="[rules.required, rules.nameserver]"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col cols="3">
                <v-text-field
                  label="NS3"
                  v-model="form.ns3"
                  :rules="[rules.nameserver]"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="NS4"
                  v-model="form.ns4"
                  :rules="[rules.nameserver]"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="NS5"
                  v-model="form.ns5"
                  :rules="[rules.nameserver]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
              </v-col>
            </v-row>
          </v-form>
          <v-row dense>
            <v-col cols="6">
              <v-form>
                <h5>Registrant Contact</h5>
                <profile
                  v-show="profile === 1"
                  ref="registrantProfileComp"
                  :setNewProfile="registrant"
                ></profile>
                <profile-sg
                  v-show="profile === 2"
                  ref="registrantProfileCompSg"
                  :setNewProfile="registrant"
                ></profile-sg>
                <profile-au
                  v-show="profile === 3"
                  ref="registrantProfileCompAu"
                  :setNewProfile="registrant"
                ></profile-au>
                <v-row dense>
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      :loading="updateRegistrantLoading"
                      @click="updateRegistrant"
                    >Update</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="6">
              <v-form ref="updateAdminForm">
                <h5>Admin Contact</h5>
                <profile
                  ref="adminProfileComp"
                  :setNewProfile="admin"
                ></profile>
                <v-row dense>
                  <v-col cols="12">
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="6">
              <v-form ref="updateTechForm">
                <h5>Technique Contact</h5>
                <profile
                  ref="techProfileComp"
                  :setNewProfile="tech"
                ></profile>
                <v-row dense>
                  <v-col cols="12">
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="6">
              <v-form ref="updateBillingForm">
                <h5>Billing Contact</h5>
                <profile
                  ref="billingProfileComp"
                  :setNewProfile="billing"
                ></profile>
                <v-row dense>
                  <v-col cols="12">
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Profile from '../../components/Profile.vue';
import ProfileSg from '../../components/ProfileSg.vue';
import ProfileAu from '../../components/ProfileAu.vue';

export default {
  name: 'QueryDomainContact',
  components: {
    Profile,
    ProfileSg,
    ProfileAu,
  },
  created() {
    this.$api.get('partner/domain-product/list').then((response) => {
      this.orderableProducts = response.data;
    });
  },
  data() {
    return {
      profile: 1,
      profileContact: this.$refs.registrantProfileComp,
      domain: '',
      id: null,
      showPage: false,
      epp: null,
      form: {
        domain: '',
        product_id: null,
        product_term: null,
        status_name: '',
        tld: null,
        auto_renew: true,
        locked: true,
        ns1: '',
        ns2: '',
        ns3: '',
        ns4: '',
        ns5: '',
        provision_date: '',
        expiry_date: '',
        registrant: {},
        admin: {},
        tech: {},
        billing: {},
      },
      childNss: [],
      addChildNss: [],
      deleteChildNss: [],
      updateChildNss: [],
      defaultContact: null,
      registrant: null,
      admin: null,
      tech: null,
      billing: null,
      domainErr: null,
      tld_list: [],
      tldPriceList: [],
      terms: [],
      checkDomainLoading: false,
      checkDomainInvalid: true,
      checkoutProcessing: false,
      checkoutDisabled: false,
      showPriceTable: false,
      showRegistrationForm: false,
      eppLoading: false,
      autoRenewLoading: false,
      lockLoading: false,
      updateNsLoading: false,
      updateRegistrantLoading: false,
      updateAdminLoading: false,
      updateTechLoading: false,
      updateBillingLoading: false,
      updateChildNsLoading: false,
      renewDialog: false,
      renewLoading: false,
      renewCheckoutLoading: false,
      renewalTerms: [],
      renewalTerm: null,
      successMessage:
        'We are registering your domain now. '
        + 'An email will be sent to you when your service is ready.',
      valid: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?/i).test(value) || 'This field must be valid domain name.',
        childNs: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(`${value}.${this.form.domain}`) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
        nameserver: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
        ip: (value) => (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/).test(value) || 'This field must be valid IP.',
      },

      orderableProducts: [],
      addonData: null,
      serviceTermId: null,
      parentProductTerms: [],
      addonRegistrationComponentKey: 1,
      totalAddon: 0,
      buying: false,
      service: null,

      showServiceRenewDialog: false,
      renewServiceId: null,
      showServiceUpgradeDialog: false,
      upgradeServiceId: null,
      showServiceCancelDialog: false,
      cancelServiceId: null,
      cancelDialog: false,
      cancelLoading: false,
      cancelled: false,
    };
  },
  methods: {
    queryDomain() {
      this.$api.post('admin/service/query-domain', {
        domain: this.domain,
      })
        .then((response) => {
          if (parseInt(response.data.id, 10) !== 0) {
            this.id = response.data.id;
            this.$set(this.form, 'domain', this.domain);
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: 'Domain in "Pending Registration", "Pending Transfer", "Pending Approval" not found.',
            });
          }
        });
    },
    cancelPromp() {
      this.cancelDialog = true;
    },
    agreeCancel() {
      this.cancelLoading = true;
      this.$api.delete(`partner/service/${this.id}`)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'You have successfully discontinued to renew this service.',
          });
          this.cancelLoading = false;
          this.cancelled = true;
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.cancelLoading = false;
        });
    },
    buyAddons() {
      this.buying = true;
      this.$api.post('partner/addon/register', {
        service_id: this.id,
        domain: this.form.domain,
        addons: this.addonData,
        addon_only: true,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: this.successMessage,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .finally(() => {
          this.buying = false;
        });
    },
    reloadDomain() {
      this.$api.get(`partner/domain/${this.id}?with_addon_info=1`).then((response) => {
        const domainData = response.data;
        this.serviceTermId = this.$gceCommon.getTermIdByTermMonth(domainData.service_month_term);
        this.service = domainData;
        this.form.product_id = domainData.product_id;
        this.form.provision_date = domainData.provision_date;
        this.form.status_name = domainData.service_status_name;
        this.form.expiry_date = domainData.expiry_date;
        this.form.auto_renew = domainData.auto_renew === 1;
        this.form.locked = domainData.domain_config.service_configuration.locked === 1;
        this.form.ns1 = domainData.domain_config.service_configuration.ns1;
        this.form.ns2 = domainData.domain_config.service_configuration.ns2;
        this.form.ns3 = domainData.domain_config.service_configuration.ns3;
        this.form.ns4 = domainData.domain_config.service_configuration.ns4;
        this.form.ns5 = domainData.domain_config.service_configuration.ns5;
        this.$set(this, 'registrant', domainData.domain_config.service_configuration.registrant);
        this.$set(this, 'admin', domainData.domain_config.service_configuration.admin);
        this.$set(this, 'tech', domainData.domain_config.service_configuration.tech);
        this.$set(this, 'billing', domainData.domain_config.service_configuration.billing);
        this.$set(this, 'addChildNss', []);
        this.$set(this, 'deleteChildNss', []);
        this.$set(this, 'childNss', domainData.domain_config.service_configuration.child_nameservers);
        this.showPage = true;
        this.$underscore.each(this.orderableProducts, (products) => {
          this.$underscore.each(products, (product) => {
            if (product.id === domainData.product_id) {
              this.$underscore.each(product.currencies, (value) => {
                const currentProductPrice = {
                  text: `${value.termName}: $${value.productPrice}`,
                  value: value.productTermId,
                  price: value.productPrice,
                };
                this.parentProductTerms.push(currentProductPrice.value);
              });
            }
          });
        });
      });
    },
    checkDomain() {
      this.showRegistrationForm = false;
      this.domainErr = '';
      this.checkDomainLoading = true;
      this.$underscore.map(this.tldPriceList, (tld) => {
        if (tld.tld_id === this.form.tld) {
          this.$underscore.map(tld.terms, (term, index) => {
            this.form.product_term = tld.terms[1].term_id;
            let yearPrice = index === '1' ? 'Year' : 'Years';
            yearPrice = `${yearPrice} $${term.price}`;
            this.terms.push({
              text: `${index} ${yearPrice}`,
              value: term.term_id,
            });
          });
        }
      });

      this.$api.post('partner/domain/check-availability', this.form).then(() => {
        this.loadRegistrationForm();
      })
        .catch(() => {
          this.domainErr = 'The domain is not available.';
        })
        .finally(() => {
          this.checkDomainLoading = false;
        });
    },
    loadRegistrationForm() {
      this.showRegistrationForm = true;
    },
    showDomainPriceTable() {
      this.showPriceTable = true;
    },
    checkout() {
      this.checkoutProcessing = true;
      this.checkoutDisabled = true;
      if (this.$refs.form.validate()) {
        this.$underscore.map(this.tldPriceList, (value) => {
          if (value.tld_id === this.form.tld) {
            this.form.product_id = value.id;
          }
        });
        this.form.registrant = this.$refs.registrantProfileComp.getProfileData();
        this.form.admin = this.$refs.adminProfileComp.getProfileData();
        this.form.tech = this.$refs.techProfileComp.getProfileData();
        this.form.billing = this.$refs.billingProfileComp.getProfileData();
        this.$api.post('partner/domain/register', this.form)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: this.successMessage,
            });
            this.$emit('reloadActivity');
            this.reset();
          })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.checkoutProcessing = false;
            this.checkoutDisabled = false;
          });
      } else {
        this.checkoutProcessing = false;
        this.checkoutDisabled = false;
      }
    },
    copyContact(from, to) {
      this[to] = this.$refs[`${from}ProfileComp`].getProfileData();
    },
    reset() {
      this.$router.go(0);
    },
    toggleAutoRenew() {
      this.autoRenewLoading = true;
      this.$api.post('partner/service/set-auto-renew', {
        id: this.id,
        auto_renew: this.form.auto_renew ? 0 : 1,
      }).then(() => {
        this.$set(this.form, 'auto_renew', !this.form.auto_renew);
        const successMessage = this.form.auto_renew ? 'on' : 'off';
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: `Auto renew is turned ${successMessage}.`,
        });
      }).finally(() => {
        this.autoRenewLoading = false;
      });
    },
    toggleLock() {
      this.lockLoading = true;
      this.$api.post(`partner/domain/${this.id}/set-lock`, {
        locked: this.form.locked ? 0 : 1,
      }).then(() => {
        this.$set(this.form, 'locked', !this.form.locked);
        const successMessage = this.form.locked ? 'on' : 'off';
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: `Lock is turned ${successMessage}.`,
        });
      }).finally(() => {
        this.lockLoading = false;
      });
    },
    getEpp() {
      this.eppLoading = true;
      this.$api.get(`partner/domain/${this.id}/epp`).then((response) => {
        this.epp = response.data;
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: `EPP code: ${response.data}`,
        });
      }).finally(() => {
        this.eppLoading = false;
      });
    },
    updateNs() {
      this.updateNsLoading = true;
      this.$api.post(`partner/domain/${this.id}/update-nameservers`, {
        ns1: this.form.ns1,
        ns2: this.form.ns2,
        ns3: this.form.ns3,
        ns4: this.form.ns4,
        ns5: this.form.ns5,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'NS is updated successfully.',
        });
      }).finally(() => {
        this.updateNsLoading = false;
      });
    },
    updateRegistrant() {
      console.log(this.profileContact);
      this.updateRegistrantLoading = true;
      this.$api.post(`partner/domain/${this.id}/update-registrant-contact`,
        this.profileContact.getProfileData()).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Registrant contact is updated successfully.',
        });
      }).finally(() => {
        this.updateRegistrantLoading = false;
      });
    },
    updateAdmin() {
      this.updateAdminLoading = true;
      this.$api.post(`partner/domain/${this.id}/update-admin-contact`,
        this.$refs.adminProfileComp.getProfileData()).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Admin contact is updated successfully.',
        });
      }).finally(() => {
        this.updateAdminLoading = false;
      });
    },
    updateTech() {
      this.updateTechLoading = true;
      this.$api.post(`partner/domain/${this.id}/update-tech-contact`,
        this.$refs.techProfileComp.getProfileData()).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Tech contact is updated successfully.',
        });
      }).finally(() => {
        this.updateTechLoading = false;
      });
    },
    updateBilling() {
      this.updateBillingLoading = true;
      this.$api.post(`partner/domain/${this.id}/update-billing-contact`,
        this.$refs.billingProfileComp.getProfileData()).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Billing contact is updated successfully.',
        });
      }).finally(() => {
        this.updateBillingLoading = false;
      });
    },
    addChildNs() {
    },
    updateChildNs() {
    },
    deleteChildNs() {
    },
    openRenewDialog() {
      this.renewalTerms.splice(0, this.renewalTerms.length);
      this.renewDialog = true;
      this.$api.post('partner/service/get-renewal-terms', {
        id: this.id,
      }).then((response) => {
        response.data.terms.forEach((term, index) => {
          this.renewalTerms.push({
            text: `${term.term_name} $${term.product_price}`,
            value: term.id,
          });

          if (index === 0) {
            this.renewalTerm = term.id;
          }
        });
      }).finally(() => {
      });
    },
    renew(id) {
      this.renewServiceId = id;
      this.showServiceRenewDialog = true;
    },
    upgrade(id) {
      this.upgradeServiceId = id;
      this.showServiceUpgradeDialog = true;
    },
    cancel(id) {
      this.cancelServiceId = id;
      this.showServiceCancelDialog = true;
    },
    revalidate() {
    },
    updateAddonPrice(amount) {
      this.totalAddon = +amount;
    },
    oldRenew() {
      this.renewCheckoutLoading = true;
      this.$api.post('partner/service/renew', {
        id: this.id,
        product_term: this.renewalTerm,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your renewal request is submitted successfully.',
        });
        this.renewDialog = false;
        this.loadTable();
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.renewCheckoutLoading = false;
      });
    },
    addChildNameserver() {
      this.addChildNss.push({
        subdomain: '',
        ip: '',
      });
    },
    updateChildNameserver() {
      this.updateChildNsLoading = true;
      if (this.$refs.updateChildNsForm.validate()) {
        this.$api.post(`partner/domain/${this.id}/update-child-nameservers`, {
          add_child_nameservers: this.addChildNss,
          delete_child_nameservers: this.deleteChildNss,
          child_nameservers: this.childNss,
        })
          .then(() => {
            this.reloadDomain();
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Child nameservers are updated successfully.',
            });
          })
          .finally(() => {
            this.updateChildNsLoading = false;
          });
      } else {
        this.updateChildNsLoading = false;
      }
    },
    removeChildNs(index) {
      this.deleteChildNss.push(this.childNss.splice(index, 1)[0]);
    },
  },
  watch: {
    'form.domain': {
      handler(domain) {
        if (domain.indexOf('.sg') > 0) {
          this.profile = 2;
          this.profileContact = this.$refs.registrantProfileCompSg;
        } else if (domain.indexOf('.au') > 0) {
          this.profile = 3;
          this.profileContact = this.$refs.registrantProfileCompAu;
        } else {
          this.profile = 1;
          this.profileContact = this.$refs.registrantProfileComp;
        }
        this.reloadDomain();
      },
    },
  },
};
</script>

<style scoped>

</style>

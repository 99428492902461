<template>
  <v-row dense>
  </v-row>
</template>

<script>
export default {
  name: 'AddonRegister',
  data() {
    return {
      moduleData: {},
    };
  },
  mounted() {
    this.$underscore.each(this.parentModuleData, (propV, prop) => {
      if (typeof this.moduleData[prop] !== 'undefined') {
        this.$set(this.moduleData, prop, propV);
      }
    });
  },
  props: {
    parentModuleData: {
      type: Object,
      default() {
        return {
          domain: '',
        };
      },
    },
  },
  watch: {
    moduleData: {
      handler(newModuleData) {
        this.$emit('update:moduleData', newModuleData);
      },
      deep: true,
    },
    parentModuleData: {
      handler(newParentModuleData) {
        this.$underscore.each(newParentModuleData, (propV, prop) => {
          if (typeof this.moduleData[prop] !== 'undefined') {
            this.$set(this.moduleData, prop, propV);
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>

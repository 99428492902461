<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.status_name="{ item }">
          <b v-if="item.payment_status_id == 1"
             class="orange--text">{{ item.status_name }}</b>
          <b v-if="item.payment_status_id == 2"
             class="green--text">{{ item.status_name }}</b>
          <b v-if="item.payment_status_id == 3"
             class="red--text">{{ item.status_name }}</b>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn v-if="item.payment_status_id == 2"
                   color="success"
                   @click="approve(item)"
                   x-small
                   :loading="item.loading"
            >Approve</v-btn>
            <v-btn v-if="item.payment_status_id == 2"
                   color="error"
                   @click="openRejectDialog(item)"
                   x-small
            >Reject</v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog
      v-model="rejectDialog"
      width="1024"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="rejectItem.name"
                    label="Partner Name"
                    :readonly="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="rejectItem.payment_datetime"
                    label="Payment Datetime"
                    :readonly="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="rejectItem.type_name"
                    label="Payment Type"
                    :readonly="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="rejectItem.payment_reference"
                    label="Payment Reference"
                    :readonly="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="rejectItem.amount"
                    label="Amount"
                    :readonly="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="rejectReason"
                    label="Reject Reason*"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="renewDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="rejectLoading"
            @click="rejectPayment()"
          >
            Reject
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'PartnerPaymentHistory',
  data() {
    return {
      search: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      rejectReason: '',
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'Partner Name', value: 'name', sortable: true },
        { text: 'Payment Datetime', value: 'payment_datetime', sortable: true },
        { text: 'Payment Type', value: 'type_name', sortable: true },
        { text: 'Payment Reference', value: 'payment_reference', sortable: false },
        { text: 'Status', value: 'status_name', sortable: true },
        { text: 'Amount', value: 'amount', sortable: true },
        { text: 'Reject Reason', value: 'reject_reason', sortable: false },
        { text: 'IP', value: 'ip', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      rejectDialog: false,
      rejectItem: {
        name: '',
        payment_datetime: '',
        payment_reference: '',
        type_name: '',
        amount: '',
      },
      rejectLoading: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    this.initiated = true;
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/payment/history', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        product_type_id: this.productTypeId,
        order: `${sortBy}:${sortDesc}`,
      })
        .then((response) => {
          this.totalItems = response.data.total;
          this.items = response.data.data;
          this.loading = false;
        });
    },
    openRejectDialog(item) {
      this.rejectDialog = true;
      this.rejectItem = item;
      this.rejectReason = '';
    },
    rejectPayment() {
      this.rejectLoading = true;
      this.$api.post('admin/payment/reject', {
        id: this.rejectItem.id,
        reject_reason: this.rejectReason,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'You have rejected the payment request.',
        });
        this.rejectDialog = false;
        this.loadTable();
      }).finally(() => {
        this.rejectLoading = false;
      });
    },
    approve(item) {
      // eslint-disable-next-line no-param-reassign
      item.loading = true;
      this.$api.post('admin/payment/confirm', {
        id: item.id,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'You have approved the payment request.',
        });
        this.loadTable();
      }).finally(() => {
        // eslint-disable-next-line no-param-reassign
        item.loading = false;
      });
    },
  },
};
</script>

<style scoped>

</style>

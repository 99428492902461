<template>
  <v-row
    no-gutters
    dense
  >
    <v-col
      cols="6"
      offset="3"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card
          outlined
        >
          <v-card-text>
            <v-row
              dense
              no-gutters
            >
              <v-col
                cols="12"
              >
                <profile
                  ref="partnerProfile"
                  :setNewProfile="profile"
                ></profile>
                <v-row
                  dense
                >
                  <v-col
                    cols="6"
                    md="6"
                  >
                    <v-text-field
                      dense
                      v-model="creditLimit"
                      :rules="[rules.isNumeric]"
                      label="Credit Limit"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    md="6"
                  >
                    <v-text-field
                      dense
                      v-model="brand"
                      label="Reseller Portal ID"
                    ></v-text-field>
                    <span>(leave blank if do not want to open to partner)</span>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <v-col
                    cols="4"
                  >
                    <v-checkbox
                      dense
                      v-model="enableCpanelNs"
                      label="enable cPanel NS"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <v-col
                    cols="4"
                  >
                    <v-text-field
                      dense
                      v-model="defaultCpanelNs1"
                      label="Default cPanel NS1"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <v-text-field
                      dense
                      v-model="defaultCpanelNs2"
                      label="Default cPanel NS2"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <v-text-field
                      dense
                      v-model="defaultCpanelNs3"
                      label="Default cPanel NS3"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      color="primary"
                      :loading="processing"
                      :disabled="processing"
                      @click="submitForm"
                    >Submit</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import Profile from '../../components/Profile.vue';

export default {
  name: 'Detail',
  components: {
    Profile,
  },
  created() {
    this.$api.get(`admin/partner/${this.$route.params.id}`).then((response) => {
      this.setProfile(response.data);
      this.creditLimit = response.data.credit_limit;
      this.brand = response.data.brand;
      this.enableCpanelNs = response.data.enable_cpanel_ns;
      this.defaultCpanelNs1 = response.data.default_cpanel_ns1;
      this.defaultCpanelNs2 = response.data.default_cpanel_ns2;
      this.defaultCpanelNs3 = response.data.default_cpanel_ns3;
    });
  },
  data() {
    return {
      creditLimit: 0,
      brand: '',
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
      profile: null,
      valid: false,
      enableCpanelNs: 0,
      defaultCpanelNs1: null,
      defaultCpanelNs2: null,
      defaultCpanelNs3: null,
    };
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$api.put(`admin/partner/${this.$route.params.id}`, this.$underscore.extend(this.$refs.partnerProfile.getProfileData(), {
          credit_limit: this.creditLimit,
          brand: this.brand,
          enable_cpanel_ns: this.enableCpanelNs,
          default_cpanel_ns1: this.defaultCpanelNs1,
          default_cpanel_ns2: this.defaultCpanelNs2,
          default_cpanel_ns3: this.defaultCpanelNs3,
        }))
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Partner detail is updated successfully.',
            });
            setTimeout(() => {
              this.$router.go(0);
            }, 500);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
    setProfile(profile) {
      this.profile = profile;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row>
    <v-col cols="12" v-if="zone == 0">
      <v-row>
        <v-col cols="12">
          <v-btn @click="createZone">Create Zone</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="zone == 1">
      <v-row dense no-gutters justify="middle" align="center"
             v-if="dnsServers.length > 1">
        <v-col cols="4">
          <v-select
            label="DNS Servers"
            :multiple="false"
            v-model="defaultDnsServer"
            :items="dnsServers"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" @click="loadDns">Load Dns Records</v-btn>
        </v-col>
      </v-row>
      <v-row dense no-gutters justify="middle" align="center"
             v-if="!rightDns">
        <h5 class="red--text">
          You need to change NS to {{ correctNs }} in order to use this DNS server.</h5>
      </v-row>
      <v-row dense no-gutters justify="middle" align="center"
             v-if="rightDns">
        <h5 class="green--text">
          Your domain is currently resolved to {{ correctNs }}.
          The records below are your Active DNS zones.
        </h5>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn @click="openNewRecordDialog">New Record</v-btn>
        </v-col>
      </v-row>
      <v-row justify="middle" align="center" v-for="record in records['A']" :key="record.line">
        <v-col cols="4">
          <v-text-field v-model="record.name" label="name" :readonly="true" :disabled="true"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="record.address" label="ipv4"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="record.ttl" label="ttl"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn x-small color="primary" :loading="processing" :disabled="processing"
                 @click="updateRecord(record)"
          >Update</v-btn>
          <v-btn x-small color="primary" :loading="processing" :disabled="processing"
                 @click="deleteRecord(record)"
          >Delete</v-btn>
        </v-col>
      </v-row>
      <v-row justify="middle" align="center" v-for="record in records['AAAA']" :key="record.line">
        <v-col cols="4">
          <v-text-field v-model="record.name" label="name" :readonly="true" :disabled="true"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="record.address" label="ipv6"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="record.ttl" label="ttl"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn x-small color="primary" :loading="processing" :disabled="processing"
                 @click="updateRecord(record)"
          >Update</v-btn>
          <v-btn x-small color="primary" :loading="processing" :disabled="processing"
                 @click="deleteRecord(record)"
          >Delete</v-btn>
        </v-col>
      </v-row>
      <v-row justify="middle" align="center" v-for="record in records['CNAME']" :key="record.line">
        <v-col cols="4">
          <v-text-field v-model="record.name" label="name" :readonly="true" :disabled="true"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="record.cname" label="cname"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="record.ttl" label="ttl"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn x-small color="primary" :loading="processing" :disabled="processing"
                 @click="updateRecord(record)"
          >Update</v-btn>
          <v-btn x-small color="primary" :loading="processing" :disabled="processing"
                 @click="deleteRecord(record)"
          >Delete</v-btn>
        </v-col>
      </v-row>
      <v-row justify="middle" align="center" v-for="record in records['MX']" :key="record.line">
        <v-col cols="4">
          <v-text-field v-model="record.name" label="name" :readonly="true" :disabled="true"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="record.preference" label="preference"></v-text-field>
          <v-text-field v-model="record.exchange" label="exchange"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="record.ttl" label="ttl"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn x-small color="primary" :loading="processing" :disabled="processing"
                 @click="updateRecord(record)"
          >Update</v-btn>
          <v-btn x-small color="primary" :loading="processing" :disabled="processing"
                 @click="deleteRecord(record)"
          >Delete</v-btn>
        </v-col>
      </v-row>
      <v-row justify="middle" align="center" v-for="record in records['SRV']" :key="record.line">
        <v-col cols="4">
          <v-text-field v-model="record.name" label="name" :readonly="true" :disabled="true"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="record.priority" label="priority"></v-text-field>
          <v-text-field v-model="record.weight" label="weight"></v-text-field>
          <v-text-field v-model="record.port" label="port"></v-text-field>
          <v-text-field v-model="record.target" label="target"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="record.ttl" label="ttl"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn x-small color="primary" :loading="processing" :disabled="processing"
                 @click="updateRecord(record)"
          >Update</v-btn>
          <v-btn x-small color="primary" :loading="processing" :disabled="processing"
                 @click="deleteRecord(record)"
          >Delete</v-btn>
        </v-col>
      </v-row>
      <v-row justify="middle" align="center" v-for="record in records['TXT']" :key="record.line">
        <v-col cols="4">
          <v-text-field v-model="record.name" label="name" :readonly="true" :disabled="true"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-textarea rows="3" v-model="record.txtdata" label="TXT"></v-textarea>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="record.ttl" label="ttl"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn x-small color="primary" :loading="processing" :disabled="processing"
                 @click="updateRecord(record)"
          >Update</v-btn>
          <v-btn x-small color="primary" :loading="processing" :disabled="processing"
                 @click="deleteRecord(record)"
          >Delete</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-dialog width="1024" v-model="newRecordDialogOpen">
          <v-card>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <v-col cols="4">
                    <v-text-field v-model="newRecord.name" label="name"></v-text-field>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field v-model="domaindot" label="domain"
                                  :disabled="true" :readonly="true"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-select v-model="newRecord.type"
                              :items="recordTypes"
                              label="Record Type"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="newRecord.type === 'A'">
                  <v-col cols="12">
                    <v-text-field v-model="newRecord.address" label="ipv4"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="newRecord.type === 'AAAA'">
                  <v-col cols="12">
                    <v-text-field v-model="newRecord.address" label="ipv6"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="newRecord.type === 'CNAME'">
                  <v-col cols="12">
                    <v-text-field v-model="newRecord.cname" label="cname"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="newRecord.type === 'MX'">
                  <v-col cols="12">
                    <v-text-field v-model="newRecord.preference" label="preference"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="newRecord.exchange" label="exchange"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="newRecord.type === 'SRV'">
                  <v-col cols="12">
                    <v-text-field v-model="newRecord.priority" label="priority"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="newRecord.weight" label="weight"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="newRecord.port" label="port"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="newRecord.target" label="target"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="newRecord.type === 'TXT'">
                  <v-col cols="12">
                    <v-textarea rows="3" v-model="newRecord.txtdata" label="txt"></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn color="primary" @click="addRecord"
                           :loading="processing" :disabled="processing"
                    >New</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DnsEditor',
  components: {},
  created() {
    this.id = +this.$route.params.id;
    this.reloadDns();
  },
  data() {
    return {
      id: null,
      zone: null,
      processing: false,
      domain: null,
      domaindot: null,
      newRecordDialogOpen: false,
      recordType: 'A',
      recordTypes: [
        {
          text: 'A',
          value: 'A',
        },
        {
          text: 'AAAA',
          value: 'AAAA',
        },
        {
          text: 'CNAME',
          value: 'CNAME',
        },
        {
          text: 'MX',
          value: 'MX',
        },
        {
          text: 'SRV',
          value: 'SRV',
        },
        {
          text: 'TXT',
          value: 'TXT',
        },
      ],
      newRecord: {},
      rightDns: true,
      records: {
        A: [],
        AAAA: [],
        CNAME: [],
        MX: [],
        SRV: [],
        TXT: [],
      },
      dnsServers: null,
      defaultDnsServer: null,
      correctNs: null,
    };
  },
  methods: {
    loadDns() {
      this.$api.post('partner/dns/records', {
        id: this.id,
        server_id: this.defaultDnsServer,
      }).then((response) => {
        this.domain = response.data.domain;
        this.domaindot = `.${this.domain}`;
        this.zone = response.data.zone;
        this.rightDns = response.data.right_dns;
        this.$underscore.each(this.records, (records, type) => {
          this.$set(this.records, type, []);
        });
        this.$underscore.each(response.data.records, (records, type) => {
          this.$set(this.records, type, records);
        });
        this.processing = false;
        this.correctNs = response.data.correct_ns;
      });
    },
    reloadDns() {
      this.$api.post('partner/dns/records', {
        id: this.id,
      }).then((response) => {
        this.domain = response.data.domain;
        this.domaindot = `.${this.domain}`;
        this.zone = response.data.zone;
        this.$underscore.each(response.data.records, (records, type) => {
          this.$set(this.records, type, records);
        });
        this.processing = false;
        this.rightDns = response.data.right_dns;
        this.dnsServers = response.data.dns_options;
        this.defaultDnsServer = response.data.dns_default;
        this.correctNs = response.data.correct_ns;
      });
    },
    openNewRecordDialog() {
      this.newRecord = {
        type: 'A',
      };
      this.newRecordDialogOpen = true;
    },
    createZone() {
      this.$api.post('partner/dns/add-zone', {
        id: this.id,
        server_id: this.defaultDnsServer,
      }).then(() => {
        this.processing = true;
        this.newRecord = {};
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Zone is created successfully.',
        });
      }).finally(() => {
        this.loadDns();
      });
    },
    addRecord() {
      this.$api.post('partner/dns/add-record', {
        id: this.id,
        server_id: this.defaultDnsServer,
        record: this.newRecord,
      }).then(() => {
        this.processing = true;
        this.newRecord = {};
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Record is created successfully.',
        });
        this.newRecordDialogOpen = false;
      }).finally(() => {
        this.loadDns();
      });
    },
    updateRecord(record) {
      this.processing = true;
      this.$api.post('partner/dns/update-record', {
        id: this.id,
        server_id: this.defaultDnsServer,
        record,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Record is updated successfully.',
        });
      }).finally(() => {
        this.loadDns();
      });
    },
    deleteRecord(record) {
      this.processing = true;
      this.$api.post('partner/dns/delete-record', {
        id: this.id,
        server_id: this.defaultDnsServer,
        record,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Record is removed successfully.',
        });
      }).finally(() => {
        this.loadDns();
      });
    },
  },
};
</script>

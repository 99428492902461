var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.searchOpen),callback:function ($$v) {_vm.searchOpen=$$v},expression:"searchOpen"}},[_c('v-expansion-panel',[_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"dense":"","no-gutters":"","cols":"4"}},[_c('v-text-field',{attrs:{"label":"Name"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search()}},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1),_c('v-col',{attrs:{"dense":"","no-gutters":"","cols":"4"}},[_c('v-text-field',{attrs:{"label":"Description"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search()}},model:{value:(_vm.searchDescription),callback:function ($$v) {_vm.searchDescription=$$v},expression:"searchDescription"}})],1),_c('v-col',{attrs:{"dense":"","no-gutters":"","cols":"12"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.search}},[_vm._v("Search")])],1)],1),_c('v-row',_vm._l((_vm.typeCount),function(type){return _c('v-checkbox',{key:type.id,attrs:{"label":((type.type_name) + ": " + (type.type_count))},model:{value:(type.active),callback:function ($$v) {_vm.$set(type, "active", $$v)},expression:"type.active"}})}),1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{
          'items-per-page-options': [15,30,60,100,150,250],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.product_name",fn:function(ref){
        var item = ref.item;
return [(_vm.isAws(item.id))?_c('b',[_vm._v("(AWS)")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.product_name))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteConfirm(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.renewable",fn:function(ref){
        var item = ref.item;
return [(item.renewable == 1)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_vm._e(),(item.renewable == 0)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-close-circle")]):_vm._e()]}},{key:"item.orderable",fn:function(ref){
        var item = ref.item;
return [(item.orderable == 1)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_vm._e(),(item.orderable == 0)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-close-circle")]):_vm._e()]}}])}),_c('v-dialog',{attrs:{"max-width":"320"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_vm._v("Are you sure to delete the product type?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","loading":_vm.deleteLoading,"disabled":_vm.deleteLoading},on:{"click":_vm.deleteRow}},[_vm._v(" Delete ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row
    no-gutters
    dense
  >
    <v-col
      cols="12"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card
          outlined
        >
          <v-card-text>
            <v-row
              dense
              no-gutters
            >
              <v-col
                cols="12"
              >
                <v-row
                  dense
                >
                  <v-col cols="12">
                    <h4>Set Default Nameservers</h4>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <v-col
                    cols="4"
                  >
                    <v-text-field
                      label="NS1"
                      v-model="form.default_ns1"
                      :rules="[rules.nameserver]"></v-text-field>
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <v-text-field
                      label="NS2"
                      v-model="form.default_ns2"
                      :rules="[rules.nameserver]"></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <v-col
                    cols="4"
                  >
                    <v-text-field
                      label="NS3"
                      v-model="form.default_ns3"
                      :rules="[rules.nameserver]"></v-text-field>
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <v-text-field
                      label="NS4"
                      v-model="form.default_ns4"
                      :rules="[rules.nameserver]"></v-text-field>
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <v-text-field
                      label="NS5"
                      v-model="form.default_ns5"
                      :rules="[rules.nameserver]"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters dense>
              <v-row
                dense
              >
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    :loading="processing"
                    @click="submitForm"
                  >Submit</v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
    <v-col cols="12">
      <v-card outlined>
        <v-card-text>
          <v-row dense no-gutters>
            <v-col cols="12">
              <v-checkbox
                @change="disableLogin()"
                v-model="form.disable_password_login"
                label="Disable Password Login"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" v-if="allowDisablePasswordLogin">
              <v-btn
                class="sso-btn"
                color="primary"
                outlined
                @click="signInGoogle()"
              >
                <!-- eslint-disable-next-line -->
                <svg viewBox="0 0 20 20" class="googleLogo" style="width: 14px; height: 14px; display: block; fill: inherit; flex-shrink: 0; backface-visibility: hidden; margin-right: 6px;"><g><path d="M19.9996 10.2297C19.9996 9.54995 19.9434 8.8665 19.8234 8.19775H10.2002V12.0486H15.711C15.4823 13.2905 14.7475 14.3892 13.6716 15.0873V17.586H16.9593C18.89 15.8443 19.9996 13.2722 19.9996 10.2297Z" fill="#4285F4"></path><path d="M10.2002 20.0003C12.9518 20.0003 15.2723 19.1147 16.963 17.5862L13.6753 15.0875C12.7606 15.6975 11.5797 16.0429 10.2039 16.0429C7.54224 16.0429 5.28544 14.2828 4.4757 11.9165H1.08301V14.4923C2.81497 17.8691 6.34261 20.0003 10.2002 20.0003Z" fill="#34A853"></path><path d="M4.47227 11.9163C4.04491 10.6743 4.04491 9.32947 4.47227 8.0875V5.51172H1.08333C-0.363715 8.33737 -0.363715 11.6664 1.08333 14.4921L4.47227 11.9163Z" fill="#FBBC04"></path><path d="M10.2002 3.95756C11.6547 3.93552 13.0605 4.47198 14.1139 5.45674L17.0268 2.60169C15.1824 0.904099 12.7344 -0.0292099 10.2002 0.000185607C6.34261 0.000185607 2.81497 2.13136 1.08301 5.51185L4.47195 8.08764C5.27795 5.71762 7.53849 3.95756 10.2002 3.95756Z" fill="#EA4335"></path></g></svg>
                <b>Sign In Google To Verify</b>
              </v-btn>
              <br>
              <v-btn
                class="sso-btn"
                color="primary"
                outlined
                @click="signInApple()"
              >
                <!-- eslint-disable-next-line -->
                <svg viewBox="0 0 170 170" class="appleLogo" style="width: 16px; height: 16px; display: block; fill: inherit; flex-shrink: 0; backface-visibility: hidden; margin-right: 6px; margin-bottom: 2px;"><path d="m150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929 0.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002 0.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-0.9 2.61-1.85 5.11-2.86 7.51zm-31.26-123.01c0 8.1021-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375-0.119-0.972-0.188-1.995-0.188-3.07 0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.3113 11.45-8.597 4.62-2.2516 8.99-3.4968 13.1-3.71 0.12 1.0831 0.17 2.1663 0.17 3.2409z"></path></svg>
                <b>Sign In Apple To Verify</b>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Settings',
  created() {
    this.$api.get('partner/settings').then((response) => {
      this.setSettings(response.data);
    });
  },
  data() {
    return {
      allowDisablePasswordLogin: false,
      processing: false,
      form: {
        default_ns1: '',
        default_ns2: '',
        default_ns3: '',
        default_ns4: '',
        default_ns5: '',
        disable_password_login: false,
      },
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        nameserver: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value)
          || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
      },
      valid: false,
    };
  },
  methods: {
    setSettings(settings) {
      this.form.default_ns1 = settings.ns1;
      this.form.default_ns2 = settings.ns2;
      this.form.default_ns3 = settings.ns3;
      this.form.default_ns4 = settings.ns4;
      this.form.default_ns5 = settings.ns5;
    },
    submitForm() {
      this.processing = true;
      if (this.$refs.form.validate()) {
        this.$api.put('partner/settings',
          this.form)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Settings are updated successfully.',
            });
          }).finally(() => {
            this.processing = false;
          });
      } else {
        this.processing = false;
      }
    },
    disableLogin() {
      if (this.form.disable_password_login) {
        this.allowDisablePasswordLogin = true;
        this.$set(this.form, 'disable_password_login', false);
      } else {
        const r = window.confirm('Are you sure to enable password login?');
        if (r === true) {
          this.$api.put('partner/settings',
            this.form)
            .then(() => {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'success',
                message: 'Password login is enabled.',
              });
            });
        }
      }
    },
    signInGoogle() {
      this.$api.post('sso/auth-url', {
        type: 'google',
        brand: this.$store.state.brand,
        disable_password: 1,
      }).then((response) => {
        if (response.data) {
          window.location.href = response.data;
        }
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      });
    },
    signInApple() {
      this.$api.post('sso/auth-url', {
        type: 'apple',
        brand: this.$store.state.brand,
        disable_password: 1,
      }).then((response) => {
        if (response.data) {
          window.location.href = response.data;
        }
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>

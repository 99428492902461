import axios from 'axios';

export default class Vueaxios {
  constructor(options = {}) {
    this.options = options;
  }

  install = (Vue, options = {}) => {
    const { pathname } = window.location;

    if (typeof window.systemCurrency === 'undefined') {
      if (pathname.indexOf('/aud/') === 0) {
        window.systemCurrency = 'aud';
        window.systemCurrencyId = 3;
      } else if (pathname.indexOf('/usd/') === 0) {
        window.systemCurrency = 'usd';
        window.systemCurrencyId = 2;
      } else if (pathname.indexOf('/sgd/') === 0) {
        window.systemCurrency = 'sgd';
        window.systemCurrencyId = 1;
      } else {
        window.systemCurrency = 'sgd';
        window.systemCurrencyId = 1;
      }
    }

    const version = 'v1/';
    const instance = axios.create({
      baseURL: `${process.env.VUE_APP_API_URL}/${version}`,
      timeout: 60000,
      headers: {
        common: {
          'X-Gce-Currency': window.systemCurrency,
        },
      },
    });

    this.options = options;

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$api = instance;
  };
}

<template>
  <v-row no-gutters dense>
    <v-col cols="6" offset="3">
      <v-form ref="form">
        <v-card outlined>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <p>
                  Columns:
                  Partner ID,Product ID,Product Term Month,Server ID,Domain,Username,Datetime<br>
                  Tab delimited Content
                </p>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" class="text-right">
                <v-textarea
                  outlined
                  label="Import List CSV"
                  auto-grow
                  :rules="[rules.required]"
                  v-model="form.import_list"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" class="text-right">
                <span class="green--text">Total Success: {{ totalSuccess }}&nbsp;</span>
                <span class="red--text">Total Error: {{ totalError }}&nbsp;</span>
                <v-btn
                  color="primary"
                  :loading="processing"
                  :disabled="processing"
                  @click="submitForm"
                >Submit</v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" class="text-right">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">Service</th>
                      <th class="text-left">Status</th>
                      <th class="text-left">Error</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in importServices" :key="item.identifier">
                        <td class="text-left">{{ item.identifier }}</td>
                        <td class="text-left">
                          <p
                            :class="item.status === 'success' ? 'green--text' : 'red--text'"
                          >{{ item.status }}</p>
                        </td>
                        <td class="text-left">{{ item.error }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      form: {
        import_list: '',
      },
      importServices: [],
      totalSuccess: 0,
      totalError: 0,
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
      processing: false,
    };
  },
  methods: {
    submitForm() {
      this.processing = true;
      this.$api.post('admin/service/bulk-import-cpanel-service', this.form)
        .then((response) => {
          this.$set(this, 'importServices', []);
          this.$set(this, 'importServices', response.data);
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
  watch: {
    importServices: {
      handler(newImportServices) {
        let success = 0;
        let error = 0;
        newImportServices.forEach((service) => {
          if (service.status === 'success') {
            success += 1;
          } else {
            error += 1;
          }
        });
        this.totalSuccess = success;
        this.totalError = error;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

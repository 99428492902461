<template>
  <v-row
    no-gutters
  >
    <v-col>
      <v-toolbar dense :flat="true">
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              hide-details
              prepend-icon="mdi-text-search"
              single-line
              v-model="search"
              @keyup.enter.native="loadTable()"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn small color="primary" @click="loadTable()">Search</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn x-small color="error" @click="remove(item)">Delete</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'ServiceList',
  data() {
    return {
      search: '',
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      headers: [
        { text: 'Email', value: 'email' },
        { text: 'Username', value: 'username' },
        { text: 'Service', value: 'service_name' },
        { text: 'Domain', value: 'service_identity' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.loadTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('partner/collaborator/list-service', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    remove(item) {
      const r = window.confirm('Are you sure to delete the service permission from this collaborator?');
      if (r === true) {
        this.$api.post('partner/collaborator/service/delete', {
          id: item.id,
          service_id: item.service_id,
        }).then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Delete successfully.',
          });
          this.loadTable();
        });
      }
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <div v-if="paypalLoaded">
      <div class="paypal-credit-card" ref="card">
        <form id="paypal-credit-card">
          <v-row>
            <v-col cols="6">
              <div id="card-number" class="card_field"></div>
            </v-col>
            <v-col cols="4">
              <div id="expiration-date" class="card_field"></div>
            </v-col>
            <v-col cols="2">
              <div id="cvv" class="card_field"></div>
            </v-col>
          </v-row>
        </form>
      </div>
      <div>
        <v-row>
          <v-col cols="12">
            Note:
            <ul>
              <li>The card processing only accepts Visa & Master card</li>
              <li>When making credit card payment, do complete the card's 3D Secure authorisation if
                prompted.</li>
            </ul>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="!paypalLoaded">
      <p>Loading...</p>
    </div>
    <div class="v-text-field__details">
      <div class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">{{ error }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaypalPayment',
  data() {
    return {
      paypalLoaded: false,
      paypalForm: null,
      cardEl: {
        cardNo: '',
        expiry: '',
        cvv: '',
      },
      error: '',
    };
  },
  mounted() {
    const postData = {};

    if (this.$route.params.token) {
      postData.token = this.$route.params.token;
    } else if (this.$route.query.token) {
      postData.token = this.$route.query.token;
    }

    this.$api.post('partner/payment/paypal-client-token', postData)
      .then((response) => {
        const cur = window.systemCurrency.toUpperCase();
        const PAYPAL_SCRIPT = `https://www.paypal.com/sdk/js?components=buttons,hosted-fields&intent=authorize&currency=${cur}&client-id=${response.data.client_id}`;
        const script = document.createElement('script');
        script.setAttribute('src', PAYPAL_SCRIPT);
        script.setAttribute('data-client-token', response.data.client_token);
        script.setAttribute('data-csp-nonce', 'gcepaypal');
        script.setAttribute('nonce', 'gcepaypal');
        document.head.appendChild(script);
        script.onload = () => {
          this.paypalLoaded = true;
          this.emitInterface();
        };
      })
      .catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      });
  },
  methods: {
    /**
     * Emitting an interface with callable methods from outside
     */
    emitInterface() {
      const { paypal } = window;
      if (paypal.HostedFields.isEligible()) {
        paypal.HostedFields.render({
          createOrder: () => this.orderid,
          styles: {
            input: {
              'font-size': '16px',
              height: '20px',
            },
          },
          fields: {
            number: {
              selector: '#card-number',
              placeholder: 'card number',
            },
            cvv: {
              selector: '#cvv',
              placeholder: 'CVV',
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: 'mm/yyyy',
            },
          },
        }).then((hf) => {
          this.paypalForm = hf;
        });
      } else {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'error',
          message: 'Payment module is not available, please contact administrator for help',
        });
      }
      this.$emit('interface', {
        checkout: () => this.checkout(),
      });
    },
    checkout() {
      return this.paypalForm.submit({
        vault: this.saveasdefaultcard,
        contingencies: ['SCA_ALWAYS'],
      });
    },
  },
  beforeDestroy() {
  },
  props: [
    'amount', 'saveasdefaultcard', 'orderid',
  ],
  watch: {
    orderid: {
      handler(newOrderId) {
        this.orderid = newOrderId;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
  .paypal-credit-card {
    padding: 0 10px;
    margin-bottom: 8px;
    border: 1px rgba(0,0,0,0.87) solid;
    border-radius: 4px;
  }

  .card_field {
    height: 20px;
  }
</style>

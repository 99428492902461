<template>
  <v-row no-gutters dense>
    <v-col cols="12">
      <h4>{{ companyName }}</h4>
      <h4>{{ email }}</h4>
    </v-col>
    <v-col cols="12">
      <v-expansion-panels
        multiple
      >
        <v-expansion-panel v-for="(cproducts, category) in products" v-bind:key="category">
          <v-expansion-panel-header>Product Category: {{ category }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row dense>
              <v-col cols="12">
                <v-row dense v-for="(product, productName) in cproducts"
                       v-bind:key="productName">
                  <v-col cols="12">
                    <b>{{ productName }}</b>&nbsp;&nbsp;
                    <v-btn x-small color="primary" @click="openDialog(product['product_id'])">
                    Add
                    </v-btn>
                  </v-col>
                  <v-col cols="3" v-for="(user) in product['users']" v-bind:key="user.user_id">
                    {{ user.email }}
                    <v-btn x-small color="error" @click="deleteUser(user.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-dialog
      v-model="dialog"
      max-width="320"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <customer-dropdown
            label="Retail Customer"
            :parent="partnerId"
            :single="true"
            :partners.sync="form.retail"
            :nochips="true"
          ></customer-dropdown>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :loading="addLoading"
            :disabled="addLoading"
            @click="addUser"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CustomerDropdown from '../../components/CustomerDropdown.vue';

export default {
  name: 'MemberOnlyRetailProducts',
  components: {
    CustomerDropdown,
  },
  created() {
    this.partnerId = this.$route.params.id;

    this.$api.get(`admin/partner/${this.$route.params.id}/member-only-retail-products`).then((response) => {
      this.$set(this, 'products', response.data);
    });

    this.$api.get(`admin/partner/${this.$route.params.id}`).then((response) => {
      this.email = response.data.email;
      this.companyName = response.data.company_name;
    });
  },
  data() {
    return {
      dialog: false,
      selectedProductId: false,
      addLoading: false,
      partnerId: null,
      companyName: '',
      email: '',
      products: [],
      terms: {
        1: 'One Time',
        2: 'Monthly',
        3: 'Quarterly',
        4: 'Biannually',
        5: 'Annually',
        6: 'Biennially',
        7: 'Triennially',
        8: 'Quadrennially',
        9: 'Quinquennially',
        10: 'Sexennially',
        11: 'Septennially',
        12: 'Octennially',
        13: 'Novennially',
        14: 'Decennially',
        15: '10 Months',
        16: '11 Months',
      },
      form: {
        retail: null,
      },
    };
  },
  methods: {
    deleteUser(id) {
      this.$api.delete(`admin/member-only-retail-products/${id}`).then(() => {
        this.$api.get(`admin/partner/${this.$route.params.id}/member-only-retail-products`).then((response) => {
          this.$set(this, 'products', response.data);
        });
      });
    },
    openDialog(productId) {
      this.dialog = true;
      this.selectedProductId = productId;
    },
    addUser() {
      this.addLoading = true;
      this.$api.post(`admin/partner/${this.partnerId}/member-only-retail-products`, {
        product_id: this.selectedProductId,
        user_id: this.form.retail,
      }).then(() => {
        this.addLoading = false;
        this.dialog = false;
        this.$api.get(`admin/partner/${this.$route.params.id}/member-only-retail-products`).then((response) => {
          this.$set(this, 'products', response.data);
        });
      });
    },
  },
};
</script>

<style scoped>
.price-detail {
  font-size: 14px;
}
</style>

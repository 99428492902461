<template>
  <v-row no-gutters dense>
    <v-col cols="6" offset="3">
      <v-form ref="form" v-model="valid">
        <v-card outlined>
          <v-card-text>
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="12" >
                    <v-text-field dense
                                  label="Server Group Name"
                                  v-model="form.group_name"
                                  :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="12" >
                    <CountrySelect
                      label="Location"
                      :rules="[rules.required]"
                      :country.sync="form.group_location">
                    </CountrySelect>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="primary"
                  :loading="processing"
                  :disabled="processing"
                  @click="submitForm"
                >Submit</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import CountrySelect from '../../components/CountrySelect.vue';

export default {
  name: 'Add',
  components: {
    CountrySelect,
  },
  data() {
    return {
      form: {
        group_name: '',
        group_location: 'SG',
      },
      showPassword: false,
      successMessage: 'Server group is successfully updated.',
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
      valid: false,
    };
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$api.put(`admin/server-group/${this.$route.params.id}`, this.form)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: this.successMessage,
            });
          })
          .catch((error) => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data || error.message,
            });
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
  },
  mounted() {
    this.$api.get(`admin/server-group/${this.$route.params.id}`).then((response) => {
      this.form = response.data;
    });
  },
};
</script>

<style scoped>

</style>

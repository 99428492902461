<template>
  <v-container
    fluid
  >
    <v-row dense no-gutters v-if="$store.state.brand != 'cldy'">
      <v-app-bar
        app
        dense
      >
        <img v-if="$store.state.brand === 'qoxy'" src="/qoxylogo.png">
        <v-spacer></v-spacer>
        <a class="header-btn" @click="gotoLogin()">LOGIN</a>
        <span>|</span>
        <a class="header-btn" @click="gotoLogin()">REGISTER</a>
        <v-badge
          color="green"
          :content="$store.state.shoppingCart.total_count + ''"
          overlap
          offset-x="20"
          offset-y="20"
        >
          <v-btn
            icon
            large
            @click="goToCart()"
          >
            <v-avatar
              size="32px"
              item
            >
              <v-icon>mdi-cart-outline</v-icon>
            </v-avatar>
          </v-btn>
        </v-badge>
      </v-app-bar>
    </v-row>
    <CldyHeader v-if="$store.state.brand == 'cldy'"></CldyHeader>
    <v-row class="no-margin-horizontal">
      <v-col cols="1">
      </v-col>
      <v-col cols="10">
        <component v-bind:is="component" :show-payment-block="false"></component>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="shoppingCart.total_amount > 0">
      <v-col cols="1">
      </v-col>
      <v-col cols="10">
        <v-row>
          <v-col cols="12">
            <h3>Please enter your email address to continue checkout</h3>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="8" lg="4">
            <v-text-field
              v-model="email"
              dense
              outlined
              clearable
              label="Email"></v-text-field>
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="lookupProgressing"
            ></v-progress-circular>
          </v-col>
          <v-col cols="2" class="btn-group">
            <v-btn dense small color="primary" @click="search()">Continue</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showLogin" dense>
          <v-col cols="8" lg="4">
            <v-text-field type="password" label="Password" v-model="password"
              dense
              outlined
              hint="Please enter password to checkout as returning customer"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="btn-group">
            <v-btn
              v-if="!otp"
              dense small
              color="primary"
              :loading="loginProcessing"
              :disabled="loginProcessing"
              @click="loginOtp()"
            >Login</v-btn>
            <v-btn
              dense small
              color="error"
              @click="forgetPassword()"
            >Forget Password</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showLogin && otp" dense>
          <v-col cols="12">
            <h5>For your security, we've sent a one-time password to your email
              address at {{ email }}</h5>
          </v-col>
          <v-col cols="8" lg="4">
            <v-text-field
              id="otp"
              label="OTP"
              name="OTP"
              dense
              outlined
              v-model="emailOtp"
              :error="error.emailOtp"
              :error-messages="errorMessage.emailOtp"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
              dense small
              color="primary"
              :loading="loginProcessing"
              :disabled="loginProcessing"
              @click="verifyOtp()"
            >Verify Otp</v-btn>
            <v-btn
              dense small
              color="primary"
              @click="resendOtp()"
            >Resend OTP</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showSignUp">
          <v-col cols="12">
            <h3 class="red--text">
              Please fill up the information below and click on the Checkout button to
              complete your purchase
            </h3>
          </v-col>
          <v-col cols="12">
            <profile ref="profile" :default-email="email"></profile>
          </v-col>
        </v-row>
        <v-row v-if="showSignUp">
          <v-col cols="12">
            <v-card
            outlined
          >
            <v-card-text>
              <v-row no-gutters dense>
                <v-row v-if="paymentMethod == 3" no-gutters dense>
                  <v-col cols="12" lg="6">
                    <stripe-payment
                      :amount="shoppingCart.total_amount"
                      :card.sync="creditCard"
                      :invalid.sync="creditCardInvalid"
                    ></stripe-payment>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-if="$store.state.brand === 'cldy'"
                      label="Save as default card? I authorise CLDY.com PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                    <v-checkbox
                      v-else
                      label="Save as default card? I authorise GOCLOUDEASY PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="paymentMethod == 4" no-gutters dense>
                  <v-col cols="12" lg="6">
                    <paypal-payment
                      :orderid="paypalOrderId"
                      :amount="shoppingCart.total_amount"
                      :saveasdefaultcard="saveAsDefaultCard"
                      @interface="getChildInterface"
                      :card.sync="creditCard"
                      :invalid.sync="creditCardInvalid"
                    ></paypal-payment>
                  </v-col>
                  <!--
                  <v-col cols="12">
                    <v-checkbox
                      label="Save as default card? I authorise GOCLOUDEASY PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                  </v-col>
                  -->
                </v-row>
              </v-row>
            </v-card-text>
          </v-card>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn
              v-if="paymentMethod == 4"
              color="primary"
                   :loading="checkoutLoading"
                   :disabled="checkoutLoading || creditCardInvalid ||
                   shoppingCart.total_amount < 0.01"
                   @click="checkout">Checkout (Visa/Master)</v-btn>
            <v-btn
              v-else
              color="primary"
              :loading="checkoutLoading"
              :disabled="checkoutLoading || creditCardInvalid ||
                   shoppingCart.total_amount < 0.01"
              @click="checkout">Checkout</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-footer v-if="$store.state.brand != 'cldy'"
      dark
      padless
      :app="true"
      :fixed="true"
    >
      <v-card
        flat
        tile
        class="indigo lighten-1 white--text text-center"
        width="100%"
      >
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Powered By GoCloudEasy Pte Ltd</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <CldyFooter v-if="$store.state.brand == 'cldy'"></CldyFooter>
    <v-dialog
      v-model="resetPasswordDialog"
      width="1024"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <h3 class="text-center">Generate a one time code to login.
            An email with one-time login password will be sent to your registered email.</h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="resetPasswordDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="resetPasswordLoading"
            :disabled="resetPasswordLoading"
            @click="generateOneTimePassword()"
          >
            Generate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ShoppingCart from './ShoppingCart.vue';
import Profile from '../components/Profile.vue';
import {
  StripePayment, StripeSavedCards, PaypalPayment, PaypalSavedCards,
} from '../components/payment_modules';
import CldyHeader from './CldyHeader.vue';
import CldyFooter from './CldyFooter.vue';

export default {
  name: 'Guest',
  components: {
    Profile,
    ShoppingCart,
    StripePayment,
    StripeSavedCards,
    PaypalPayment,
    PaypalSavedCards,
    CldyHeader,
    CldyFooter,
  },
  data() {
    return {
      otp: false,
      emailOtp: '',
      paymentMethod: '',
      paypalOrderId: '',
      resetPasswordDialog: false,
      resetPasswordLoading: false,
      loggedIn: true,
      shoppingCartId: null,
      component: ShoppingCart,
      email: null,
      password: null,
      lookupProgressing: false,
      loginProcessing: false,
      checkoutLoading: false,
      showLogin: false,
      showSignUp: false,
      saveAsDefaultCard: true,
      creditCardInvalid: true,
      creditCard: null,
      signUpToken: false,
      error: {
        emailOtp: false,
      },
      errorMessage: {
        emailOtp: '',
      },
      rules: {
        required: (value) => !!value || 'This field is required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
    };
  },
  created() {
    this.$api.get('partner/default-payment-method').then((response) => {
      this.$set(this, 'paymentMethod', response.data);
      if (this.paymentMethod === 4) {
        this.creditCardInvalid = false;
      }
    });

    if (this.$store.state.user.role) {
      this.loggedIn = false;
    }

    this.shoppingCartId = localStorage.getItem('shopping_cart_id') || this.$store.state.shoppingCartId;

    if (this.shoppingCartId) {
      this.reloadShoppingCart();
    }
  },
  methods: {
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    reloadShoppingCart() {
      this.$api.get(`partner/shopping-cart?shopping_cart_id=${this.shoppingCartId}`)
        .then((data) => {
          this.$store.dispatch('syncShoppingCart', data.data);
        });
    },
    search() {
      if (this.email !== '') {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          if (this.rules.email(this.email) !== true) {
            return;
          }
          this.lookupProgressing = true;
          this.$api.post('guest/lookup-email', {
            email: this.email,
          })
            .then((response) => {
              if (response.data.exist) {
                this.showLogin = true;
                this.showSignUp = false;
              } else {
                this.showLogin = false;
                this.showSignUp = true;
              }
            })
            .finally(() => {
              this.lookupProgressing = false;
            });
        }, 500);
      } else {
        this.showLogin = false;
        this.showSignUp = false;
      }
    },
    gotoLogin() {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.Login', params: { brand } })
        .catch(() => {
        });
    },
    goToCart() {
      const { brand } = this.$store.state;
      if (this.$store.state.user.role) {
        this.$router.push({
          name: 'Retail.ShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      } else {
        this.$router.push({
          name: 'Retail.GuestShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      }
    },
    gotoHome() {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.Guest', params: { brand } })
        .catch(() => {
        });
    },
    login() {
      this.loginProcessing = true;
      this.$api.post('login', {
        email: this.email,
        password: this.password,
        shopping_cart_id: this.shoppingCartId,
      })
        .then((response) => {
          if (response.data.user) {
            this.$store.dispatch('setCurrentUser', response.data.user);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({
              name: 'Retail.ShoppingCart',
              params: { brand: this.$store.state.brand },
            })
              .catch(() => {
              });
          } else {
            this.loginProcessing = false;
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: 'Invalid login.',
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
          this.loginProcessing = false;
        });
    },
    loginOtp() {
      this.loginProcessing = true;
      this.$api.post('login-otp', {
        email: this.email,
        password: this.password,
        shopping_cart_id: this.shoppingCartId,
      })
        .then((response) => {
          this.loginProcessing = false;

          if (response.data.success) {
            this.otp = true;
          } else if (response.data.user) {
            this.$store.dispatch('setCurrentUser', response.data.user);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({
              name: 'Retail.ShoppingCart',
              params: { brand: this.$store.state.brand },
            })
              .catch(() => {
              });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: 'Invalid login.',
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
          this.loginProcessing = false;
        });
    },
    verifyOtp() {
      this.loginProcessing = true;
      this.$api.post('login-otp', {
        email: this.email,
        password: this.password,
        shopping_cart_id: this.shoppingCartId,
        otp: this.emailOtp,
      })
        .then((response) => {
          if (response.data.user) {
            this.$store.dispatch('setCurrentUser', response.data.user);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({
              name: 'Retail.ShoppingCart',
              params: { brand: this.$store.state.brand },
            })
              .catch(() => {
              });
          } else {
            this.loginProcessing = false;
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: 'Invalid login.',
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
          this.loginProcessing = false;
        });
    },
    resendOtp() {
      this.$api.post('resend-otp', {
        email: this.email,
        password: this.password,
      }).then((response) => {
        if (response.data.success) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: `New OTP has been sent to your email address ${this.email}`,
          });
        }
      });
    },
    forgetPassword() {
      this.resetPasswordDialog = true;
    },
    checkout() {
      this.checkoutLoading = true;
      const customerProfile = this.$refs.profile.getProfileData();
      customerProfile.email = this.email;
      customerProfile.shopping_cart_id = this.shoppingCartId;

      if (!this.signUpToken) {
        this.$api.post('sign-up', customerProfile)
          .then((response) => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Your account is created. We are now processing the payment.',
            });

            if (response.data.user) {
              this.$store.dispatch('setCurrentUser', response.data.user);
              localStorage.setItem('app', JSON.stringify(this.$store.state));
              this.signUpToken = response.data.user.auth_token;
              this.capturePayment(this.signUpToken);
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: 'System error. Please contact support.',
              });
              this.checkoutLoading = false;
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
            this.checkoutLoading = false;
          });
      } else {
        this.capturePayment(this.signUpToken);
      }
    },
    capturePayment(token) {
      if (this.paymentMethod === 3) {
        this.$api.post('customer/payment',
          {
            payment_type_id: this.paymentMethod,
            amount: this.shoppingCart.total_amount,
            save_as_default_card: this.saveAsDefaultCard,
            auth_token: window.btoa(token),
          })
          .then((paymentResponse) => {
            const stripePaymentObj = {
              payment_method: {
                card: this.creditCard,
              },
            };

            if (this.saveAsDefaultCard) {
              stripePaymentObj.setup_future_usage = 'off_session';
            }

            window.stripeClient.confirmCardPayment(paymentResponse.data.client_secret,
              stripePaymentObj)
              .then(async (result) => {
                if (result.error) {
                  await this.$store.dispatch('addAlert', {
                    id: Date.now(),
                    type: 'error',
                    message: result.error,
                  });
                }

                if (result.paymentIntent.status === 'requires_capture') {
                  await this.$api.post('customer/payment', {
                    payment_type_id: this.paymentMethod,
                    confirm: 1,
                    id: result.paymentIntent.id,
                    auth_token: window.btoa(token),
                  })
                    .then((subresponse) => {
                      const successMessage = 'Your payment is submitted successfully.';
                      this.$store.dispatch('resetShoppingCart');
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'success',
                        message: successMessage,
                      });
                      this.$store.dispatch('showDashboard');
                      localStorage.setItem('app', JSON.stringify(this.$store.state));
                      this.$router.push({
                        name: 'Retail.TaxInvoiceList',
                        params: { brand: this.$store.state.brand },
                      })
                        .catch(() => {
                        });
                      window.location.href = `${process.env.VUE_APP_API_URL}/v1/tax-invoice/${subresponse.data.invoice_no}/view?view_token=${subresponse.data.view_token}&system_currency=${window.systemCurrency}`;
                    });
                }
              });
          })
          .catch((error) => {
            if (error.response) {
              const errorMsg = error.response.data.message || error.response.data;

              if (errorMsg.indexOf('remove it from the cart')) {
                this.$store.dispatch('showDashboard');
                localStorage.setItem('app', JSON.stringify(this.$store.state));
                this.$router.push({
                  name: 'Retail.ShoppingCart',
                  params: { brand: this.$store.state.brand },
                })
                  .catch(() => {
                  });
              }

              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: errorMsg,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.checkoutLoading = false;
          });
      }

      if (this.paymentMethod === 4) {
        this.$api.post('customer/payment',
          {
            payment_type_id: this.paymentMethod,
            amount: this.shoppingCart.total_amount,
            save_as_default_card: this.saveAsDefaultCard,
            auth_token: window.btoa(token),
          })
          .then((response) => {
            this.$set(this, 'paypalOrderId', response.data.order_id);
            this.$nextTick(() => {
              this.$options.childInterface.checkout()
                .then((payload) => {
                  if (payload.liabilityShift !== 'NO'
                    && payload.authenticationStatus
                    && payload.authenticationStatus !== 'YES') {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: '3D Secure Authentication fails. Please retry again.',
                    });
                    this.loading = false;
                    return;
                  }

                  if (payload.liabilityShift === 'NO'
                    && payload.liabilityShifted === false
                    && payload.authenticationStatus
                    && payload.authenticationStatus === 'NO') {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: 'You have exited 3D Secure Authentication. Please retry again.',
                    });
                    this.loading = false;
                    return;
                  }

                  this.$api.post('customer/payment', {
                    id: this.paypalOrderId,
                    payment_type_id: this.paymentMethod,
                    amount: this.shoppingCart.total_amount,
                    save_as_default_card: this.saveAsDefaultCard,
                    confirm: 1,
                    auth_token: window.btoa(token),
                  })
                    .then((subresponse) => {
                      const successMessage = 'Your payment is submitted successfully.';
                      this.$store.dispatch('resetShoppingCart');
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'success',
                        message: successMessage,
                      });
                      this.$store.dispatch('showDashboard');
                      localStorage.setItem('app', JSON.stringify(this.$store.state));
                      this.$router.push({
                        name: 'Retail.TaxInvoice',
                        params: {
                          brand: this.$store.state.brand,
                          id: subresponse.data.invoice_id,
                          first: true,
                        },
                      });
                    });
                })
                .catch((error) => {
                  if (error.message.indexOf('semantically incorrect') > 0) {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: 'We are sorry, the transaction failed. Please check the card information or try with another card.',
                    });
                  } else {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: `An error occurred ${error.message}. Please retry again.`,
                    });
                  }
                  this.loading = false;
                })
                .finally(() => {
                  this.loading = false;
                });
            });
          })
          .catch((error) => {
            if (error.response) {
              const errorMsg = error.response.data.message || error.response.data;

              if (errorMsg.indexOf('remove it from the cart')) {
                this.$store.dispatch('showDashboard');
                localStorage.setItem('app', JSON.stringify(this.$store.state));
                this.$router.push({
                  name: 'Retail.ShoppingCart',
                  params: { brand: this.$store.state.brand },
                })
                  .catch(() => {
                  });
              }

              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: errorMsg,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.checkoutLoading = false;
          });
      }
    },
    generateOneTimePassword() {
      this.resetPasswordLoading = true;
      this.$api.post('onetime-password', {
        email: this.email,
        shopping_cart_id: this.$store.state.shoppingCartId,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Please check your email for one-time password!',
          });
          this.resetPasswordDialog = false;
        })
        .finally(() => {
          this.resetPasswordLoading = false;
        });
    },
  },
  watch: {
  },
  computed: {
    ...mapState({
      shoppingCart: 'shoppingCart',
    }),
  },
};
</script>

<style scoped>
  .header-btn {
    margin-right: 5px;
    margin-left: 5px;
  }

  .v-btn:not(:last-child) {
    margin-right: 10px;
  }

  .no-margin-horizontal {
    margin-left: 0;
    margin-right: 0;
  }
</style>

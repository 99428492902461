import underscore from 'underscore';

export default class Vueunderscore {
  constructor(options = {}) {
    this.options = options;
  }

  install = (Vue) => {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$underscore = underscore;
  };
}

<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Invoice Number
            </th>
            <th class="text-left">
              Invoice Date Paid
            </th>
            <th class="text-left">
              Invoice Total amount
            </th>
            <th class="text-left">
              Invoice Referral Fee
            </th>
            <th class="text-left">
              Invoice Breakdown
            </th>
            <th class="text-left">
              Referral Fee Breakdown
            </th>
            <th class="text-left">
              Referrer Fee First Invoice
            </th>
            <th class="text-left">
              Client name
            </th>
            <th class="text-left">
              Company
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="row in rows"
            :key="row.id"
          >
            <td>{{ row.invoice_no }}</td>
            <td>{{ row.invoice_paid_date }}</td>
            <td>{{ row.total }}</td>
            <td>{{ row.commission }}</td>
            <td>{{ row.invoice_breakdown }}</td>
            <td>{{ row.commission_breakdown }}</td>
            <td>{{ row.first_invoice_breakdown }}</td>
            <td>{{ row.client_name }}</td>
            <td>{{ row.client_company }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="12">
      <v-btn
        color="primary"
        text
        @click="download()"
      >Download CSV</v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PartnerCommissionYearMonth',
  data() {
    return {
      rows: [],
    };
  },
  created() {
    this.$api.get(`partner/fcs-commissions?yearmonth=${this.$route.params.yearmonth}`)
      .then((response) => {
        this.$set(this, 'rows', response.data);
      });
  },
  methods: {
    download() {
      const { yearmonth } = this.$route.params;
      const authToken = window.btoa(this.$store.state.user.auth_token);
      window.open(`${process.env.VUE_APP_API_URL}/v1/partner/download-fcs-commissions?yearmonth=${yearmonth}&auth_token=${authToken}`, '_blank');
    },
  },
};
</script>

<style scoped>

</style>

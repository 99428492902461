<template>
  <v-dialog width="1024" v-model="display"
            @click:outside="closeDialog"
  >
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="newTag" label="Tag:"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="closeDialog()"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          @click="updateTag()"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ServiceTagDialog',
  data() {
    return {
      display: true,
      loading: false,
      newTag: '',
    };
  },
  props: ['serviceId', 'tag'],
  created() {
    this.newTag = this.tag;
  },
  watch: {
    tag(newTag) {
      this.newTag = newTag;
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    updateTag() {
      this.loading = true;
      this.$api.post('partner/service/update-tag', {
        id: this.serviceId,
        tag: this.newTag,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Tag is updated successfully',
        });
        this.loading = false;
        this.closeDialog();
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
</style>

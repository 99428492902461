import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import Vueaxios from './plugins/vueaxios';
import Vueunderscore from './plugins/vueunderscore';
import GceCommon from './plugins/gceCommon';

if (window.Stripe) {
  const stripe = window.Stripe(`${process.env.VUE_APP_STRIPE_PUBLIC_KEY}`);
  window.stripeClient = stripe;
}
window.dataLayer = window.dataLayer || [];
// eslint-disable-next-line func-names
window.gtag = function () {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
};
window.gtag('js', new Date());
window.gtag('config', 'GTM-T52GS7Q');

Vue.config.productionTip = false;
Vue.use(new Vueaxios());
Vue.use(new Vueunderscore());
Vue.use(new GceCommon());
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
Vue.component('datetime', Datetime);

new Vue({
  router,
  store,
  vuetify,
  Vueaxios,
  Vueunderscore,
  render: (h) => h(App),
}).$mount('#app');

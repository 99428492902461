<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <div v-if="initiated">
        <v-row dense no-gutters justify="end" align="end">
          <v-col cols="4">
            <v-text-field
              hide-details
              prepend-icon="mdi-text-search"
              single-line
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn small color="primary" @click="loadTable()">Search</v-btn>
          </v-col>
          <v-col cols="6"></v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-checkbox v-model="vendor.active"
                      :label="vendor.name"
                      v-for="vendor in vendors" :key="vendor.name"
          ></v-checkbox>
        </v-row>
        <v-row dense no-gutters>
          <v-checkbox v-model="internal"
                      label="Internal"
          ></v-checkbox>
          <v-checkbox v-model="nointernal"
                      label="Not Internal"
          ></v-checkbox>
        </v-row>
        <v-row dense no-gutters>
          <v-checkbox v-model="matching"
                      label="Matching Billing"
          ></v-checkbox>
          <v-checkbox v-model="nomatching"
                      label="No Matching Billing"
          ></v-checkbox>
        </v-row>
      </div>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        item-key="iindex"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.status_name="{ item }">
          <b v-if="item.status_name == 'Active'" class="green--text">{{ item.status_name }}</b>
          <b v-else class="red--text">{{ item.status_name }}</b>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.internal === 'Internal'"
            x-small
            error
            class="mr-2"
            @click="setInternal(item.id, 0)"
          >
            Unset Internal
          </v-btn>
          <v-btn
            v-if="item.internal === ''"
            x-small
            primary
            class="mr-2"
            @click="setInternal(item.id, 1)"
          >
            Set Internal
          </v-btn>
          <v-btn
            v-if="item.service_id"
            x-small
            error
            class="mr-2"
            @click="setService(item.id)"
          >
            Unmap Service
          </v-btn>
          <v-btn
            v-if="!item.service_id"
            x-small
            error
            class="mr-2"
            @click="openDialog(item.id)"
          >
            Map Service
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog width="1024" v-model="display"
                @click:outside="closeDialog"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Service ID"
                      v-model="serviceId"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="setService()"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'VendorList',
  components: {},
  data() {
    return {
      display: false,
      internal: null,
      nointernal: true,
      nomatching: true,
      matching: null,
      search: '',
      vendors: {
        linode: {
          name: 'linode',
          active: 0,
        },
        digitalocean: {
          name: 'digitalocean',
          active: 0,
        },
        vultr: {
          name: 'vultr',
          active: 0,
        },
        jetbackup: {
          name: 'jetbackup',
          active: 0,
        },
        cpanel: {
          name: 'cpanel',
          active: 0,
        },
      },
      initiated: false,
      statusCount: {},
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      strange: false,
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'Vendor', value: 'vendor' },
        { text: 'Domain', value: 'domain' },
        { text: 'Ip', value: 'ip' },
        { text: 'Vendor Status', value: 'status' },
        { text: 'Vendor Package', value: 'package_name' },
        { text: 'Service Name', value: 'service_name' },
        { text: 'Service Expiry', value: 'expiry_date' },
        { text: 'Service Status', value: 'status_name' },
        { text: 'Internal', value: 'internal' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      selectId: null,
      serviceId: null,
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
    vendors: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
    internal: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
    nointernal: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
    matching: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
    nomatching: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/service/vendor-inventory', {
        search: {
          '*': this.search,
        },
        vendors: this.vendors,
        internal: this.internal,
        nointernal: this.nointernal,
        matching: this.matching,
        nomatching: this.nomatching,
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
        this.initiated = true;
      });
    },
    setInternal(itemId, internal) {
      this.$api.post('admin/service/vendor-set-internal', {
        id: itemId,
        internal,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Item is updated.',
        });
        this.loadTable();
      });
    },
    openDialog(selectId) {
      this.selectId = selectId;
      this.display = true;
    },
    closeDialog() {
      this.display = false;
    },
    setService(selectId) {
      if (selectId) {
        this.selectId = selectId;
        this.serviceId = null;
      }

      this.$api.post('admin/service/vendor-map-service', {
        id: this.selectId,
        service_id: this.serviceId,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'You have successfully map this service.',
          });
          this.closeDialog();
          this.loadTable();
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
        });
    },
  },
};
</script>

<style scoped>
table td {
  font-size: 0.75rem !important;
}

.product-type-tab .v-tab {
  justify-content: flex-start;
}

.product-type-tab .v-chip {
  margin-right: 8px;
}
</style>

<template>
  <v-row no-gutters dense>
    <v-col cols="10" offset="1" md="12" offset-md="0">
      <v-form ref="form" v-model="valid">
        <v-card outlined>
          <v-card-text>
            <v-row dense no-gutters>
              <v-col cols="12">
                <h3>Basic Information</h3>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-row dense>
                  <v-col cols="12" >
                    <v-text-field dense
                                  label="Addon Name"
                                  v-model="form.product_name"
                                  :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12" >
                    <v-textarea
                      outlined
                      label="Addon Description"
                      v-model="form.product_description"
                      :rules="[rules.required]"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12" >
                    <v-autocomplete
                      dense
                      v-model="form.product_type_id"
                      :items="productTypes"
                      label="Addon Type"
                      :multiple="false"
                      :chips="false"
                      :rules="[rules.required]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense v-if="form.product_type_id">
              <v-col cols="6">
                <v-row dense>
                  <v-col cols="12" >
                    <v-autocomplete
                      dense
                      v-model="form.parent_product_ids"
                      :items="parentProducts"
                      label="Parent Products"
                      :multiple="true"
                      :chips="true"
                      :rules="[rules.required]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <h3>PRICE</h3>
              </v-col>
              <v-col cols="2">
                <v-switch v-model="form.renewable" label="Renewable"></v-switch>
              </v-col>
              <v-col cols="2">
                <v-switch v-model="form.orderable" label="Orderable"></v-switch>
              </v-col>
              <v-col cols="2">
                <v-switch v-model="form.addon_quantity" label="Quantity Based"
                          v-if="form.addon_display_type === 1"></v-switch>
              </v-col>
              <v-col cols="2">
                <v-switch v-model="form.addon_show_description" label="Show Description"
                          v-if="form.addon_display_type !== 1"></v-switch>
              </v-col>
              <v-col cols="2">
                <v-switch v-model="form.addon_prorate" label="Prorate Charge"></v-switch>
              </v-col>
              <v-col cols="3">
                <v-radio-group
                  v-model="form.addon_renewal_type"
                  row
                >
                  <v-radio
                    label="Expiry as parent"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Flexible Expiry"
                    :value="2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="3">
                <v-radio-group
                  v-model="form.addon_display_type"
                  row
                >
                  <v-radio
                    label="Default"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Radio Button"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="Dropdown"
                    :value="3"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row dense v-if="form.addon_display_type !== 1">
              <v-col cols="8">
                <v-autocomplete
                  dense
                  v-model="form.group_addon_ids"
                  :items="groupAddons"
                  label="Group Addons"
                  :multiple="true"
                  :chips="true"
                  :rules="[]"
                  @change="loadGroupAddonInfo(form.group_addon_ids)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-checkbox v-model="form.is_default" label="Default Option"></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  label="Group Name"
                  v-model="form.group_addon_name"
                  :rules="[rules.required]"
                ></v-text-field>
                <p v-if="groupWarning"
                   class="red--text">Addons above will be merged as a new group,
                  please decide new group name and description.</p>
              </v-col>
              <v-col cols="8">
                <v-textarea
                  dense
                  outlined
                  label="Group Description"
                  v-model="form.group_addon_description"
                  :rules="[rules.required]"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense v-for="currency in form.currencies" v-bind:key="currency.symbol">
              <v-col cols="12">
                <h4>{{ currency.symbol }}</h4>
              </v-col>
              <v-col cols="3" v-if="!form.renewable">
                <v-text-field
                  dense
                  :label="currency.onetime.label"
                  v-model="currency.onetime.value"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="form.renewable">
                <v-row>
                  <v-col cols="3" v-for="term in currency.renewal_terms" v-bind:key="term.index">
                    <v-text-field
                      dense
                      :label="term.label"
                      v-model="term.value"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <h3>MODULE</h3>
              </v-col>
              <v-col cols="3">
                <AddonModuleSelect
                  :rules="[rules.required]"
                  :module.sync="form.module">
                ></AddonModuleSelect>
              </v-col>
              <v-col cols="12">
                <v-card outlined>
                  <v-card-text>
                    <keep-alive>
                      <component
                        :is="moduleComponent"
                        :configData.sync="form.module_data"></component>
                    </keep-alive>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" class="text-right">
                <v-btn
                  color="primary"
                  :loading="processing"
                  :disabled="processing"
                  @click="submitForm"
                >Submit</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import AddonModuleSelect from '../../components/AddonModuleSelect.vue';
import ProductSelect from '../../components/ProductSelect.vue';
import Module from '../../components/provisioning_modules';
import ProductTypeDropdown from '../../components/ProductTypeDropdown.vue';

export default {
  name: 'Add',
  components: {
    AddonModuleSelect,
    ProductSelect,
    Module,
    ProductTypeDropdown,
  },
  mounted() {
    this.moduleComponent = Module.ManualConfig;
    this.$api.get('admin/addon/product-type-drop-down')
      .then((response) => {
        const list = response.data;
        list.forEach((value) => {
          this.productTypes.push({
            text: value.type_name,
            value: value.id,
          });
        });
      });

    this.$api.get(`admin/addon/product-by-product-type?product_type_id=${this.form.product_type_id}`)
      .then((response) => {
        const list = response.data;
        list.forEach((value) => {
          this.parentProducts.push({
            text: value.product_name,
            value: value.id,
          });
        });
      });
  },
  data() {
    return {
      form: {
        product_name: '',
        parent_product_ids: [],
        product_description: '',
        product_type_id: '',
        renewable: true,
        orderable: true,
        addon_show_description: true,
        addon_prorate: true,
        addon_renewal_type: 1,
        addon_display_type: 2,
        module: { module_name: 'manual', id: 1 },
        module_data: {},
        currencies: [
          {
            symbol: window.systemCurrency,
            currency_id: window.systemCurrencyId,
            onetime: {
              id: 1,
              label: 'One Time',
              value: null,
            },
            renewal_terms: [
              {
                id: 2,
                label: 'Monthly',
                value: null,
                month: 1,
              },
              {
                id: 3,
                label: 'Quarterly',
                value: null,
                month: 3,
              },
              {
                id: 4,
                label: 'Biannually',
                value: null,
                month: 6,
              },
              {
                id: 5,
                label: 'Annually',
                value: null,
                month: 12,
              },
              {
                id: 6,
                label: 'Biennial (2 years)',
                value: null,
                month: 24,
              },
              {
                id: 7,
                label: 'Triennial (3 years)',
                value: null,
                month: 36,
              },
              {
                id: 8,
                label: 'Quadrennial (4 years)',
                value: null,
                month: 48,
              },
              {
                id: 9,
                label: 'Quinquennial (5 years)',
                value: null,
                month: 60,
              },
              {
                id: 10,
                label: 'Sexennial (6 years)',
                value: null,
                month: 72,
              },
              {
                id: 11,
                label: 'Septennial (7 Years)',
                value: null,
                month: 84,
              },
              {
                id: 12,
                label: 'Octennial (8 Years)',
                value: null,
                month: 96,
              },
              {
                id: 13,
                label: 'Novennial (9 Years)',
                value: null,
                month: 108,
              },
              {
                id: 14,
                label: 'Decennial (10 Years)',
                value: null,
                month: 120,
              },
            ],
          },
        ],
        group_addon_ids: [],
        is_default: 0,
      },
      groupWarning: 0,
      productTypes: [],
      parentProducts: [],
      groupAddons: [],
      moduleMap: {
        1: Module.ManualConfig,
        3: Module.DomainPrivacyConfig,
      },
      moduleComponent: null,
      showPassword: false,
      successMessage: 'Server group is successfully created.',
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
      valid: false,
    };
  },
  methods: {
    loadGroupAddonInfo(addonIds) {
      this.$api.post('admin/addon/group-by-addon-ids', {
        group_addon_ids: addonIds,
      })
        .then((response) => {
          if (response.data.name) {
            this.form.group_addon_name = response.data.name;
            this.form.group_addon_description = response.data.description;
            this.groupWarning = false;
          } else {
            this.groupWarning = true;
          }
        });
    },
    submitForm() {
      let priceSet = false;

      if (parseInt(this.form.product_type_id, 0) !== 10000
        && parseInt(this.form.module_data, 0) === 3) {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'error',
          message: 'Only domain addon can use privacy module.',
        });
      }

      if (this.form.renewable === false) {
        this.form.currencies.forEach((onetimeTerm) => {
          if (!this.$underscore.isEmpty(onetimeTerm.onetime.value)) {
            priceSet = true;
          }
        });
      } else {
        this.form.currencies.forEach((value) => {
          const vm = this;
          value.renewal_terms.forEach((renewalTerm) => {
            if (!vm.$underscore.isEmpty(renewalTerm.value)) {
              priceSet = true;
            }
          });
        });
      }

      if (priceSet === false) {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'error',
          message: 'You must set at least 1 price term for the product.',
        });
      }

      if (this.$refs.form.validate()) {
        this.$gceCommon.pageRefresh();
        this.$api.post('admin/addon', this.form)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: this.successMessage,
            });
            this.$gceCommon.pageRefresh(this.$router);
          })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
    calculatePriceBasedOnMonthly() {
      if (this.$underscore.isFinite(this.form.currencies[0].renewal_terms[0].value)) {
        this.form.currencies[0].renewal_terms.forEach((value, index) => {
          if (index > 0) {
            this.form
              .currencies[0]
              .renewal_terms[index]
              .value = this.form.currencies[0].renewal_terms[0].value * value.month;
          }
        });
      }
    },
    changeProductType() {
      this.$api.get(`admin/addon/product-by-product-type?product_type_id=${this.form.product_type_id}`)
        .then((response) => {
          const list = response.data;
          list.forEach((value) => {
            let productName = value.product_name.replace('#1', 'manual');
            productName = productName.replace('#2', 'resellerclub');
            productName = productName.replace('#3', 'dreamscape');
            this.parentProducts.push({
              text: productName,
              value: value.id,
            });
          });
        });

      this.$api.get(`admin/addon/addon-by-product-type?product_type_id=${this.form.product_type_id}`)
        .then((response) => {
          const list = response.data;
          list.forEach((value) => {
            this.groupAddons.push({
              text: value.product_name,
              value: value.id,
            });
          });
        });
    },
  },
  watch: {
    form: {
      handler(form) {
        this.moduleComponent = this.moduleMap[form.module.id];
        this.changeProductType();
        this.calculatePriceBasedOnMonthly();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row
    no-gutters
    dense
  >
    <v-col cols="12">
      <v-card
        outlined
      >
        <v-card-text>
          <v-row no-gutters dense>
            <v-col cols="6">
              <v-select
                v-model="paymentMethod"
                label="Payment Method"
                :items="paymentMethods"
              ></v-select>
            </v-col>
          </v-row>
          <v-form ref="form">
            <v-row no-gutters dense>
              <v-col cols="2" >
                <v-text-field label="Amount"
                              :rules="[rules.required]"
                              v-model="amount"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters dense v-if="paymentMethod == 1">
              <v-col cols="2" >
                <v-text-field label="Bank"
                              :rules="[rules.required]"
                              v-model="bank"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Reference No"
                              :rules="[rules.required]"
                              v-model="referenceNo"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Transfer Datetime"
                              :rules="[rules.required]"
                              v-model="transferDatetime"
                              @click.prevent="openDatetimePicker($event)"
                              :readonly="true"
                ></v-text-field>
                <datetime
                  class="datetime-picker"
                  ref="datetimePicker"
                  type="datetime"
                  v-model="pickerTransferDatetime"
                  :hour-step="1"
                  :minute-step="5"
                  format="yyyy-MM-dd HH:mm:ss"
                  auto
                ></datetime>
              </v-col>
            </v-row>
            <div v-if="paymentMethod == 3">
              <v-radio-group v-model="stripePaymentMode">
                <v-radio value="useSavedCard" v-if="hasSavedCard">
                  <template v-slot:label>
                    <div>Use saved card</div>
                  </template>
                </v-radio>
                <v-row no-gutters dense>
                  <v-col cols="6">
                    <stripe-saved-cards
                      :card.sync="savedCard"
                      :hasCard.sync="hasSavedCard"
                      v-on:remove-card="hasSavedCard = false"
                    ></stripe-saved-cards>
                  </v-col>
                </v-row>
                <v-row v-if="hasSavedCard"></v-row>
                <v-radio value="useNewCard" v-if="hasSavedCard">
                  <template v-slot:label>
                    <div>Use new card</div>
                  </template>
                </v-radio>
                <v-row no-gutters dense>
                  <v-col cols="12" md="6">
                    <stripe-payment
                      :amount="amount"
                      :card.sync="creditCard"
                      :invalid.sync="creditCardInvalid"
                    ></stripe-payment>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="12">
                    <v-checkbox
                      v-if="$store.state.brand === 'cldy'"
                      label="Save as default card? I authorise CLDY.com PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                    <v-checkbox
                      v-else
                      label="Save as default card? I authorise GOCLOUDEASY PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-form>
          <v-row no-gutters dense>
            <v-col cols="12" class="text-right">
              <v-btn color="primary"
                     :loading="loading"
                     @click="submit">Submit</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';
import { StripePayment, StripeSavedCards } from '../../components/payment_modules';

export default {
  name: 'Topup',
  components: {
    StripeSavedCards,
    StripePayment,
  },
  data() {
    return {
      bank: '',
      referenceNo: '',
      transferDatetime: null,
      pickerTransferDatetime: null,
      paymentMethod: 3,
      paymentMethods: [],
      amount: '',
      loading: false,
      saveAsDefaultCard: false,
      creditCardInvalid: null,
      creditCard: null,
      savedCard: null,
      hasSavedCard: false,
      stripePaymentMode: 'useNewCard',
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
    };
  },
  created() {
    this.transferDatetime = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');
    this.pickerTransferDatetime = DateTime.local().toISO();
    this.$api.get('partner/payment-methods').then((response) => {
      this.$set(this, 'paymentMethods', response.data);
    });
  },
  methods: {
    submit() {
      this.loading = true;

      if (!this.$refs.form.validate()) {
        this.loading = false;
        return;
      }

      if (this.paymentMethod === 3 && this.creditCardInvalid) {
        this.loading = false;
        return;
      }

      if (this.paymentMethod === 1) {
        this.$api.post('partner/payment', {
          payment_type_id: this.paymentMethod,
          bank: this.bank,
          reference_no: this.referenceNo,
          transfer_datetime: this.transferDatetime,
          amount: this.amount,
        })
          .then(() => {
            const successMessage = 'Your payment is submitted successfully. Admin will review and confirm the payment.';
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: successMessage,
            });
            this.reset();
          })
          .finally(() => {
            this.loading = false;
          });
      }

      if (this.paymentMethod === 3) {
        if (this.hasSavedCard && this.stripePaymentMode === 'useSavedCard') {
          this.$api.post('partner/payment/use-saved-card', {
            payment_type_id: this.paymentMethod,
            amount: this.amount,
            payment_card_id: this.savedCard.id,
          })
            .then((response) => {
              const stripePaymentObj = {};

              window.stripeClient.confirmCardPayment(response.data.client_secret, stripePaymentObj)
                .then(async (result) => {
                  if (result.error) {
                    await this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: result.error,
                    });
                  }

                  if (result.paymentIntent.status === 'succeeded') {
                    await this.$api.post('partner/payment', {
                      payment_type_id: this.paymentMethod,
                      capture: 1,
                      id: result.paymentIntent.id,
                    })
                      .then(() => {
                        const successMessage = 'Your payment is submitted successfully.';
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: successMessage,
                        });
                        this.reset();
                      });
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            });
        } else {
          this.$api.post('partner/payment', {
            payment_type_id: this.paymentMethod,
            amount: this.amount,
            save_as_default_card: this.saveAsDefaultCard,
          })
            .then((response) => {
              const stripePaymentObj = {
                payment_method: {
                  card: this.creditCard,
                },
              };

              if (this.saveAsDefaultCard) {
                stripePaymentObj.setup_future_usage = 'off_session';
              }

              window.stripeClient.confirmCardPayment(response.data.client_secret, stripePaymentObj)
                .then(async (result) => {
                  if (result.error) {
                    await this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: result.error,
                    });
                  }

                  if (result.paymentIntent.status === 'succeeded') {
                    await this.$api.post('partner/payment', {
                      payment_type_id: this.paymentMethod,
                      capture: 1,
                      id: result.paymentIntent.id,
                    })
                      .then(() => {
                        const successMessage = 'Your payment is submitted successfully.';
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: successMessage,
                        });
                        this.reset();
                      });
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            });
        }
      }
    },
    reset() {
      this.$router.push({
        path: '/refresh',
        query: {
          path: this.$route.fullPath,
        },
      });
    },
    openDatetimePicker(event) {
      this.$refs.datetimePicker.open(event);
    },
  },
  watch: {
    pickerTransferDatetime: {
      handler(newPickerTransferDatetime) {
        if (newPickerTransferDatetime !== null) {
          this.transferDatetime = DateTime.fromISO(newPickerTransferDatetime).toFormat('yyyy-MM-dd HH:mm:ss');
        }
      },
      deep: true,
    },
    hasSavedCard: {
      handler(hasSavedCard) {
        if (hasSavedCard) {
          this.stripePaymentMode = 'useSavedCard';
        } else {
          this.stripePaymentMode = 'useNewCard';
        }
      },
    },
  },
};
</script>
<style>
  .datetime-picker input {
    visibility: hidden !important;
  }
</style>

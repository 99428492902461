<template>
  <div></div>
</template>

<script>

export default {
  name: 'Home',
  components: {},
  created() {
    this.$emit('reloadmenu', { reload: true });
  },
};
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined>
        <v-card-text>
          <v-row dense>
            <v-col cols="6">
              <v-text-field dense
                            label="Discount Name"
                            v-model="discountName"
                            :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field dense
                            label="Discount Value"
                            v-model="discountValue"
                            :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" v-if="discountCodeRequired">
              <v-text-field dense
                            label="Discount Code"
                            v-model="discountCode"
                            :rules="[rules.discountCodeRequired]"
              ></v-text-field>
            </v-col>
<!--            <v-col cols="6" v-if="discountCodeRequired">-->
<!--              <v-text-field dense-->
<!--                            label="Usage Times Per User"-->
<!--                            v-model="perUserTimes"-->
<!--              ></v-text-field>-->
<!--            </v-col>-->
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-select
                label="Discount Type"
                :multiple="false"
                :chips="false"
                v-model="selectedDiscountType"
                :items="discountTypes"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Discount Status"
                :multiple="false"
                :chips="false"
                v-model="selectedDiscountStatus"
                :items="discountStatus"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="selectedDiscountType !== 1
          && selectedDiscountType !== 7
          && selectedDiscountType !== 8">
            <v-col cols="6">
              <partner-dropdown
                label="Partner"
                :single="true"
                :partners.sync="partnerId"
                :nochips="true"
              ></partner-dropdown>
            </v-col>
          </v-row>
          <v-row dense v-if="selectedDiscountType !== 1
          && selectedDiscountType !== 6
          && selectedDiscountType !== 7">
            <v-col cols="6">
              <v-select
                label="Applied Operation Type"
                :multiple="true"
                :chips="true"
                v-model="selectedDiscountOperationTypes"
                :items="discountOperationTypes"
                clearable
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="selectedDiscountType !== 6">
            <v-col cols="6">
              <v-select
                label="Applied Product Type"
                :multiple="true"
                :chips="true"
                v-model="selectedDiscountProductTypes"
                :items="discountProductTypes"
                clearable
              >
              </v-select>
            </v-col>
            <v-col cols="6">
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" v-if="selectedDiscountType !== 6">
              <v-select
                label="Applied Product"
                :multiple="true"
                :chips="true"
                v-model="selectedDiscountProducts"
                :items="discountProducts"
                clearable
                @change="loadDiscountProductTerms()"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
            </v-col>
          </v-row>
          <v-row dense v-if="discountProductTerms.length > 0">
            <v-col cols="12" v-for="(product, index) in discountProductTerms" :key="index">
              <v-row dense>
                <h5>{{ product.name }}</h5>
              </v-row>
              <v-row dense>
                <v-col cols="3" v-for="term in product.terms" :key="term.id">
                  <v-checkbox v-model="discountProductTerms[index]['enabled_terms'][term.value]"
                              :label="term.text"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="text-right">
              <v-btn
                color="primary"
                :loading="processing"
                :disabled="processing"
                @click="add"
              >Add</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PartnerDropdown from '../../components/PartnerDropdown.vue';

export default {
  name: 'Add',
  components: {
    PartnerDropdown,
  },
  async mounted() {
    await Promise.allSettled([
      this.getDiscountTypes(),
      this.getDiscountStatus(),
      this.getDiscountProductTypes(),
      this.getDiscountOperationTypes(),
      this.getDiscountProducts(),
    ]);
  },
  data() {
    return {
      partnerId: '',
      discountValue: '',
      discountName: '',
      discountCode: '',
      selectedDiscountType: null,
      selectedDiscountStatus: null,
      selectedDiscountProductTypes: null,
      selectedDiscountOperationTypes: null,
      selectedDiscountProducts: null,
      selectedDiscountProductTerms: null,
      perUserTimes: 0,
      discountTypes: [],
      discountStatus: [],
      discountProductTypes: [],
      discountOperationTypes: [],
      discountProducts: [],
      discountProductsMap: {},
      discountProductTerms: [],
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        discountCodeRequired: (value) => {
          if (this.discountCodeRequired) {
            return !!value || 'This field is required.';
          }
          return true;
        },
      },
    };
  },
  methods: {
    async getDiscountTypes() {
      this.discountTypes = await this.$gceCommon.getDropdown('admin/discount-type');
      this.selectedDiscountType = this.discountTypes[0].value;
    },
    async getDiscountStatus() {
      this.discountStatus = await this.$gceCommon.getDropdown('admin/discount-status');
      this.selectedDiscountStatus = this.discountStatus[0].value;
    },
    async getDiscountProductTypes() {
      this.discountProductTypes = await this.$gceCommon.getDropdown('admin/discount-product-type');
    },
    async getDiscountOperationTypes() {
      this.discountOperationTypes = await this.$gceCommon.getDropdown('admin/discount-operation-type');
    },
    async getDiscountProducts() {
      this.discountProducts = await this.$gceCommon.getDropdown('admin/discount-product');
      this.discountProducts.forEach((product) => {
        this.discountProductsMap[product.value] = product.text;
      });
    },
    loadDiscountProductTerms() {
      this.$set(this, 'discountProductTerms', []);
      this.selectedDiscountProducts.forEach(async (productId) => {
        await this.$api.get(`admin/discount-product-term/${productId}`).then((response) => {
          this.discountProductTerms.push({
            id: productId,
            name: this.discountProductsMap[productId],
            terms: response.data,
            enabled_terms: [],
          });
        });
      });
    },
    add() {
      this.discountProductTerms.forEach((discountProductTerm, index) => {
        discountProductTerm.enabled_terms.forEach((enabledDiscountProductTerm, subindex) => {
          if (enabledDiscountProductTerm !== true || enabledDiscountProductTerm === null) {
            this.discountProductTerms[index].enabled_terms.splice(subindex, 1);
          }
        });
      });
      this.$api.post('admin/discount', {
        partner_id: this.partnerId,
        discount_name: this.discountName,
        unique_code: this.discountCode,
        discount_type_id: this.selectedDiscountType,
        discount_status_id: this.selectedDiscountStatus,
        per_user_times: this.perUserTimes,
        value: this.discountValue,
        discount_product_types: this.selectedDiscountProductTypes,
        discount_products: this.selectedDiscountProducts,
        discount_product_terms: this.discountProductTerms,
        discount_operation_ids: this.selectedDiscountOperationTypes,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Discount is created successfully.',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
  computed: {
    discountCodeRequired() {
      if (this.selectedDiscountType === 2
        || this.selectedDiscountType === 3
        || this.selectedDiscountType === 6) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
</style>

<template>
  <v-row
    no-gutters
    dense
  >
    <save-paypal-card
      v-if="!hasPaypal"
      @reloadcomp="reloadComp"
      :key="`save-paypal-card-${savePaypalCardKey}`"
    >
    </save-paypal-card>
    <v-col cols="12">
      <v-card
        outlined
      >
        <v-card-text>
          <v-row no-gutters dense>
            <v-col cols="6">
              <v-select
                v-model="paymentMethod"
                label="Payment Method"
                :items="paymentMethods"
              ></v-select>
            </v-col>
          </v-row>
          <v-form ref="form">
            <v-row no-gutters dense>
              <v-col cols="2" >
                <v-text-field
                  v-if="$store.state.user.user_settings.userId != 245"
                  label="Amount"
                              :rules="[rules.required, rules.minimumtopup]"
                              v-model="amount"></v-text-field>
                <v-text-field
                  v-if="$store.state.user.user_settings.userId == 245"
                  label="Amount"
                  :rules="[rules.required]"
                  v-model="amount"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters dense v-if="paymentMethod == 1">
              <v-col cols="2" >
                <v-text-field label="Bank"
                              :rules="[rules.required]"
                              v-model="bank"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Reference No"
                              :rules="[rules.required]"
                              v-model="referenceNo"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Transfer Datetime"
                              :rules="[rules.required]"
                              v-model="transferDatetime"
                              @click.prevent="openDatetimePicker($event)"
                              :readonly="true"
                ></v-text-field>
                <datetime
                  class="datetime-picker"
                  ref="datetimePicker"
                  type="datetime"
                  v-model="pickerTransferDatetime"
                  :hour-step="1"
                  :minute-step="5"
                  format="yyyy-MM-dd HH:mm:ss"
                  auto
                ></datetime>
              </v-col>
            </v-row>
            <div v-if="paymentMethod == 3">
              <v-radio-group v-model="stripePaymentMode">
                <v-radio value="useSavedCard" v-if="hasSavedCard">
                  <template v-slot:label>
                    <div>Use saved card</div>
                  </template>
                </v-radio>
                <div no-gutters v-if="!hasPaypal" style="margin-bottom: 8px;">
                  Note: Your current saved card will be removed by end August 2022.
                  Please enrol again in the above section.
                </div>
                <v-row no-gutters dense>
                  <v-col cols="6">
                    <stripe-saved-cards
                      :card.sync="savedCard"
                      :hasCard.sync="hasSavedCard"
                      v-on:remove-card="hasSavedCard = false"
                    ></stripe-saved-cards>
                  </v-col>
                </v-row>
                <v-row v-if="hasSavedCard"></v-row>
                <v-radio value="useNewCard" v-if="hasSavedCard">
                  <template v-slot:label>
                    <div>Use new card</div>
                  </template>
                </v-radio>
                <v-row no-gutters dense>
                  <v-col cols="12" md="6">
                    <stripe-payment
                      :amount="amount"
                      :card.sync="creditCard"
                      :invalid.sync="creditCardInvalid"
                    ></stripe-payment>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="12">
                    <v-checkbox
                      label="Save as default card? I authorise GOCLOUDEASY PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
            <div v-if="paymentMethod == 4">
              <v-radio-group v-model="paypalPaymentMode">
                <v-radio value="useSavedCard" v-if="hasSavedCard">
                  <template v-slot:label>
                    <div>Use saved card</div>
                  </template>
                </v-radio>
                <v-row no-gutters dense>
                  <v-col cols="6">
                    <paypal-saved-cards
                      :card.sync="savedCard"
                      :hasCard.sync="hasSavedCard"
                      v-on:remove-card="hasSavedCard = false"
                    ></paypal-saved-cards>
                  </v-col>
                </v-row>
                <v-row v-if="hasSavedCard"></v-row>
                <v-radio value="useNewCard" v-if="hasSavedCard">
                  <template v-slot:label>
                    <div>Use new card</div>
                  </template>
                </v-radio>
                <v-row no-gutters dense>
                  <v-col cols="12" md="6">
                    <paypal-payment
                      :amount="amount"
                      :orderid="paypalOrderId"
                      @interface="getChildInterface"
                      :saveasdefaultcard="saveAsDefaultCard"
                      :card.sync="creditCard"
                      :invalid.sync="creditCardInvalid"
                    ></paypal-payment>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="12">
                    <v-checkbox
                      label="Save as default card? I authorise GOCLOUDEASY PTE. LTD. to send
                    instructions to the financial institution that issued my card to take payments
                    from my card account in accordance with the terms of my agreement with you."
                      v-model="saveAsDefaultCard"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </v-form>
          <v-row no-gutters dense>
            <v-col cols="12" class="text-right">
              <v-tooltip bottom
                v-if="((paymentMethod === 3 && stripePaymentMode !== 'useSavedCard') ||
                   paymentMethod === 4 && paypalPaymentMode !== 'useSavedCard')"
              >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="success"
                      :loading="loading"
                      v-bind="attrs"
                      v-on="on"
                      @click="saveCard">
                      Save Card Only
                    </v-btn>
                    <span>&nbsp;Or&nbsp;</span>
                </template>
                <span>$5 will be authenticate for validation, there is no actual charge.</span>
              </v-tooltip>
              <v-btn color="primary"
                     :loading="loading"
                     @click="submit">Topup Now (Visa/Master)</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from 'luxon';
import SavePaypalCard from './SavePaypalCard.vue';
import {
  StripePayment, StripeSavedCards, PaypalPayment, PaypalSavedCards,
} from '../../components/payment_modules';

export default {
  name: 'Topup',
  components: {
    SavePaypalCard,
    StripeSavedCards,
    StripePayment,
    PaypalPayment,
    PaypalSavedCards,
  },
  data() {
    return {
      hasPaypal: 1,
      savePaypalCardKey: 1,
      bank: '',
      referenceNo: '',
      transferDatetime: null,
      pickerTransferDatetime: null,
      paymentMethod: 1,
      paymentMethods: [],
      paypalOrderId: '',
      amount: '',
      loading: false,
      saveAsDefaultCard: true,
      creditCardInvalid: null,
      creditCard: null,
      savedCard: null,
      hasSavedCard: false,
      stripePaymentMode: 'useNewCard',
      paypalPaymentMode: 'useNewCard',
      rules: {
        required: (value) => !!value || 'This field is required.',
        minimumtopup: (value) => value >= 200 || 'Minimum top-up is $200.',
      },
    };
  },
  created() {
    this.transferDatetime = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');
    this.pickerTransferDatetime = DateTime.local().toISO();
    this.$api.get('partner/payment-methods').then((response) => {
      let checkPaypalExist = 0;
      response.data.forEach((paymentMethod) => {
        if (paymentMethod.id === 4) {
          checkPaypalExist = 1;
        }
      });

      if (!checkPaypalExist) {
        this.hasPaypal = 0;
      }
      this.$set(this, 'paymentMethods', response.data);
    });
    this.$api.get('partner/default-payment-method').then((response) => {
      this.$set(this, 'paymentMethod', response.data);
    });
  },
  methods: {
    reloadComp() {
      this.savePaypalCardKey += 1;
    },
    // Setting the interface when emitted from child
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    saveCard() {
      this.loading = true;
      this.saveAsDefaultCard = true;
      this.$api.post('partner/payment/save-card', {
        payment_type_id: this.paymentMethod,
      })
        .then((response) => {
          if (this.paymentMethod === 3) {
            this.processSaveCardStripe(response);
          }

          if (this.paymentMethod === 4) {
            this.processSaveCardPaypal(response);
          }
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    processSaveCardStripe(response) {
      const stripePaymentObj = {
        payment_method: {
          card: this.creditCard,
        },
      };

      if (this.saveAsDefaultCard) {
        stripePaymentObj.setup_future_usage = 'off_session';
      }

      window.stripeClient.confirmCardPayment(response.data.client_secret, stripePaymentObj)
        .then(async (result) => {
          if (result.error) {
            await this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: result.error,
            });
          }

          if (result.paymentIntent.status === 'requires_capture') {
            await this.$api.post('partner/payment/save-card', {
              payment_type_id: this.paymentMethod,
              confirm: 1,
              id: result.paymentIntent.id,
            })
              .then(() => {
                const successMessage = 'Your card is saved successfully.';
                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'success',
                  message: successMessage,
                });
                this.reset();
              });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    processSaveCardPaypal(response) {
      this.$set(this, 'paypalOrderId', response.data.order_id);
      this.$nextTick(() => {
        this.$options.childInterface.checkout()
          .then((payload) => {
            if (payload.liabilityShift !== 'NO'
              && payload.authenticationStatus
              && payload.authenticationStatus !== 'YES') {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: '3D Secure Authentication fails. Please retry again.',
              });
              this.loading = false;
              return;
            }

            if (payload.liabilityShift === 'NO'
              && payload.liabilityShifted === false
              && payload.authenticationStatus
              && payload.authenticationStatus === 'NO') {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: 'You have exited 3D Secure Authentication. Please retry again.',
              });
              this.loading = false;
              return;
            }

            this.$api.post('partner/payment/save-card', {
              payment_type_id: this.paymentMethod,
              confirm: 1,
              id: payload.orderId,
            })
              .then(() => {
                this.$store.dispatch('addAlert', {
                  id: Date.now(),
                  type: 'success',
                  message: 'Your card is saved successfully.',
                });
                this.reset();
              });
          })
          .catch((error) => {
            if (error.message.indexOf('semantically incorrect') > 0) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: 'We are sorry, the transaction failed. Please check the card information or try with another card.',
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: `An error occurred ${error.message}. Please retry again.`,
              });
            }
            this.loading = false;
          });
      });
    },
    submit() {
      this.loading = true;

      if (!this.$refs.form.validate()) {
        this.loading = false;
        return;
      }

      if (this.paymentMethod === 3 && this.creditCardInvalid) {
        this.loading = false;
        return;
      }

      if (this.paymentMethod === 1) {
        this.$api.post('partner/payment', {
          payment_type_id: this.paymentMethod,
          bank: this.bank,
          reference_no: this.referenceNo,
          transfer_datetime: this.transferDatetime,
          amount: this.amount,
        })
          .then(() => {
            const successMessage = 'Your payment is submitted successfully. Admin will review and confirm the payment.';
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: successMessage,
            });
            this.reset();
          })
          .finally(() => {
            this.loading = false;
          });
      }

      if (this.paymentMethod === 3) {
        if (this.hasSavedCard && this.stripePaymentMode === 'useSavedCard') {
          this.$api.post('partner/payment/use-saved-card', {
            payment_type_id: this.paymentMethod,
            amount: this.amount,
            payment_card_id: this.savedCard.id,
          })
            .then((response) => {
              const stripePaymentObj = {};

              window.stripeClient.confirmCardPayment(response.data.client_secret, stripePaymentObj)
                .then(async (result) => {
                  if (result.error) {
                    await this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: result.error,
                    });
                  }

                  if (result.paymentIntent.status === 'requires_capture') {
                    await this.$api.post('partner/payment', {
                      payment_type_id: this.paymentMethod,
                      capture: 1,
                      id: result.paymentIntent.id,
                    })
                      .then(() => {
                        const successMessage = 'Your payment is submitted successfully.';
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: successMessage,
                        });
                        this.reset();
                      });
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            });
        } else {
          this.$api.post('partner/payment', {
            payment_type_id: this.paymentMethod,
            amount: this.amount,
            save_as_default_card: this.saveAsDefaultCard,
          })
            .then((response) => {
              const stripePaymentObj = {
                payment_method: {
                  card: this.creditCard,
                },
              };

              if (this.saveAsDefaultCard) {
                stripePaymentObj.setup_future_usage = 'off_session';
              }

              window.stripeClient.confirmCardPayment(response.data.client_secret, stripePaymentObj)
                .then(async (result) => {
                  if (result.error) {
                    await this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: result.error,
                    });
                  }

                  if (result.paymentIntent.status === 'requires_capture') {
                    await this.$api.post('partner/payment', {
                      payment_type_id: this.paymentMethod,
                      capture: 1,
                      id: result.paymentIntent.id,
                    })
                      .then(() => {
                        const successMessage = 'Your payment is submitted successfully.';
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: successMessage,
                        });
                        this.reset();
                      });
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            });
        }
      }

      if (this.paymentMethod === 4) {
        if (this.hasSavedCard && this.paypalPaymentMode === 'useSavedCard') {
          this.$api.post('partner/payment/use-saved-card', {
            payment_type_id: this.paymentMethod,
            amount: this.amount,
            payment_card_id: this.savedCard.id,
          })
            .then((response) => {
              this.$set(this, 'paypalOrderId', response.data.order_id);
              this.$nextTick(() => {
                this.$api.post('partner/payment/use-saved-card', {
                  payment_type_id: this.paymentMethod,
                  amount: this.amount,
                  payment_card_id: this.savedCard.id,
                  save_as_default_card: this.saveAsDefaultCard,
                  confirm: 1,
                  capture: 1,
                  id: this.paypalOrderId,
                })
                  .then(() => {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'success',
                      message: 'Your payment is submitted successfully.',
                    });
                    this.reset();
                  })
                  .catch((error) => {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: `An error occurred ${error.message}. Please retry again.`,
                    });
                    this.loading = false;
                  });
              });
            })
            .catch((error) => {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: `An error occurred ${error.message}. Please retry again.`,
              });
              this.loading = false;
            });
        } else {
          this.$api.post('partner/payment', {
            payment_type_id: this.paymentMethod,
            amount: this.amount,
            save_as_default_card: this.saveAsDefaultCard,
          })
            .then((response) => {
              this.$set(this, 'paypalOrderId', response.data.order_id);
              this.$nextTick(() => {
                this.$options.childInterface.checkout()
                  .then((payload) => {
                    if (payload.liabilityShift !== 'NO'
                      && payload.authenticationStatus
                      && payload.authenticationStatus !== 'YES') {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: '3D Secure Authentication fails. Please retry again.',
                      });
                      this.loading = false;
                      return;
                    }

                    if (payload.liabilityShift === 'NO'
                      && payload.liabilityShifted === false
                      && payload.authenticationStatus
                      && payload.authenticationStatus === 'NO') {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: 'You have exited 3D Secure Authentication. Please retry again.',
                      });
                      this.loading = false;
                      return;
                    }

                    this.$api.post('partner/payment', {
                      payment_type_id: this.paymentMethod,
                      amount: this.amount,
                      save_as_default_card: this.saveAsDefaultCard,
                      confirm: 1,
                      capture: 1,
                      id: this.paypalOrderId,
                    })
                      .then(() => {
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: 'Your payment is submitted successfully.',
                        });
                        this.reset();
                      })
                      .catch((error) => {
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'error',
                          message: `An error occurred ${error.message}. Please retry again.`,
                        });
                        this.loading = false;
                      });
                  })
                  .catch((error) => {
                    if (error.message.indexOf('semantically incorrect') > 0) {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: 'We are sorry, the transaction failed. Please check the card information or try with another card.',
                      });
                    } else {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: `An error occurred ${error.message}. Please retry again.`,
                      });
                    }
                    this.loading = false;
                  });
              });
            });
        }
      }
    },
    reset() {
      this.$router.push({
        path: '/refresh',
        query: {
          path: this.$route.fullPath,
        },
      });
    },
    openDatetimePicker(event) {
      this.$refs.datetimePicker.open(event);
    },
  },
  watch: {
    pickerTransferDatetime: {
      handler(newPickerTransferDatetime) {
        if (newPickerTransferDatetime !== null) {
          this.transferDatetime = DateTime.fromISO(newPickerTransferDatetime).toFormat('yyyy-MM-dd HH:mm:ss');
        }
      },
      deep: true,
    },
    stripePaymentMode: {
      handler(newValue) {
        console.log(newValue);
      },
    },
    paypalPaymentMode: {
      handler(newValue) {
        console.log(newValue);
      },
    },
    hasSavedCard: {
      handler(hasSavedCard) {
        if (hasSavedCard) {
          this.stripePaymentMode = 'useSavedCard';
          this.paypalPaymentMode = 'useSavedCard';
          this.saveAsDefaultCard = false;
        } else {
          this.stripePaymentMode = 'useNewCard';
          this.paypalPaymentMode = 'useNewCard';
          this.saveAsDefaultCard = true;
        }
      },
    },
  },
};
</script>
<style>
  .datetime-picker input {
    visibility: hidden !important;
  }
</style>

<template>
  <v-row>
    <v-col cols="12">
      <v-tabs v-if="initiated" class="product-type-tab">
        <v-tab v-for="productType in productTypes" :key="productType.id">
          <v-chip
            :label="true"
            x-small
            color="green"
            text-color="white">{{ productType.active_services }}</v-chip>
          {{ productType.type_name }}
        </v-tab>
      </v-tabs>
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-toolbar v-if="initiated" dense :flat="true">
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              hide-details
              prepend-icon="mdi-text-search"
              single-line
              v-model="search"
              @keyup.enter.native="loadTable()"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn small color="primary" @click="loadTable()">Search</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.status_name="{ item }">
          <b v-if="item.status_name == 'Active'" class="green--text">{{ item.status_name }}</b>
          <b v-else class="red--text">{{ item.status_name }}</b>
        </template>
        <template v-slot:item.actions="{ item }">
<!--          <v-btn-->
<!--            v-if="item.status_name != 'Pending Provision'-->
<!--            && item.status_name != 'Pending Transfer'-->
<!--            && item.status_name != 'Transfer In Progress'-->
<!--            && item.status_name != 'Transfer Invalid EPP Code'-->
<!--            "-->
<!--            x-small-->
<!--            color="primary"-->
<!--            @click="view(item)"-->
<!--          >-->
<!--            View-->
<!--          </v-btn>-->
<!--          <v-btn-->
<!--            v-if="item.status_name == 'Transfer Invalid EPP Code'"-->
<!--            x-small-->
<!--            color="error"-->
<!--            @click="openEppCodeDialog(item)"-->
<!--          >-->
<!--            Retry EPP Code-->
<!--          </v-btn>-->
<!--          <v-btn-->
<!--            v-if="item.status_name == 'Active'-->
<!--            || item.status_name == 'Expired'"-->
<!--            x-small-->
<!--            color="primary"-->
<!--            @click="openRenewDialog(item)"-->
<!--          >-->
<!--            renew-->
<!--          </v-btn>-->
<!--          <v-btn-->
<!--            v-if="item.status_name == 'Active'"-->
<!--            x-small-->
<!--            color="error"-->
<!--            @click="openCancelDialog(item)"-->
<!--          >-->
<!--            cancel-->
<!--          </v-btn>-->
          <v-btn
            x-small
            color="success"
            @click="loginClient(item)"
          >
            login client
          </v-btn>
          <v-btn x-small @click="transferBack(item.id)">
            transfer back
          </v-btn>
        </template>

        <template v-slot:item.auto_renew="{ item }">
          <v-switch
            v-model="item.auto_renew"
            :loading="item.loading"
            :readonly="true"
            @click.stop="toggleAutoRenew(item)"
          ></v-switch>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog
      v-model="renewDialog"
      width="1024"
    >
      <v-card>
        <v-card-text>
          <h1 class="text-center" v-if="renewalTerms.length === 0">Loading renewal terms ...</h1>
          <v-row v-else>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Current Expiry"
                    :readonly="true"
                    v-model="renewItem.expiry_date"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select label="Renewal Terms"
                            :items="renewalTerms"
                            v-model="renewalTerm"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="renewDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="renewCheckoutLoading"
            @click="renew()"
          >
            Checkout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="resetPasswordDialog"
      width="1024">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    v-model="newPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :error="resetPasswordError"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="resetPasswordDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="resetPasswordSaveLoading"
            @click="resetPassword()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cancelDialog"
      width="1024">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <h3>Your service will be immediately terminated. Please be careful!</h3>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="cancelDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="cancelLoading"
            @click="cancel()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="upgradeDialog"
      width="1024"
    >
      <v-card>
        <v-card-text>
          <h1 class="text-center"
              v-if="!upgradeLoaded">Loading available upgrades ...</h1>
          <v-row v-else>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-select label="Upgrade Options"
                            :items="upgrades"
                            v-model="upgrade"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p>Service Leftover Days: {{ upgrade.upgrade_day_diff }}</p>
                  <p>Service Total Days: {{ upgrade.life_cycle_day }}</p>
                  <p>Upgrade cost: ${{ upgrade.upgrade_cost }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="upgradeDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="upgradeLoading"
            @click="processUpgrade()"
          >
            Checkout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="downgradeDialog"
      width="1024"
    >
      <v-card>
        <v-card-text>
          <h1 class="text-center"
              v-if="!downgradeLoaded">Loading available downgrades ...</h1>
          <v-row v-else>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-select label="Downgrade Options"
                            :items="downgrades"
                            v-model="downgrade"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h5 class="red--text">
                    Please notice that the difference of downgrade will not be refunded.
                  </h5>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="downgradeDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="downgradeLoading"
            @click="processDowngrade()"
          >
            Checkout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="submitNewEppCodeDialog"
      width="1024"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    type="text"
                    label="EPP Code"
                    v-model="newEppCode"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="submitNewEppCodeDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="submitNewEppCodeLoading"
            @click="submitNewEppCode()"
          >
            Submit New EPP Code
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <service-transfer-dialog
      :show="showServiceTransferDialog"
      v-on:closeServiceTransferDialog="showServiceTransferDialog = false"
      :service-id="serviceId" :service-identity="serviceIdentity" :service-name="serviceName"
    ></service-transfer-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'List',
  components: {
  },
  data() {
    return {
      search: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      strange: false,
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Customer Email', value: 'customer_email' },
        { text: 'Domain', value: 'service_identity' },
        { text: 'Status', value: 'status_name' },
        { text: 'Term', value: 'term_name' },
        // { text: 'Price', value: 'renewal_price' },
        { text: 'Registration Date', value: 'provision_date' },
        { text: 'Expiry Date', value: 'expiry_date' },
        { text: 'Auto Renew', value: 'auto_renew' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      loginLoading: false,
      cancelDialog: false,
      cancelItem: null,
      cancelLoading: false,
      renewDialog: false,
      renewItem: null,
      renewLoading: false,
      renewCheckoutLoading: false,
      renewalTerms: [],
      renewalTerm: null,
      resetPasswordDialog: false,
      resetPasswordItem: null,
      resetPasswordLoading: false,
      resetPasswordSaveLoading: false,
      resetPasswordError: false,
      newPassword: null,
      showPassword: false,
      downgradeDialog: false,
      downgradeItem: null,
      downgradeLoading: false,
      upgradeDialog: false,
      upgradeItem: null,
      upgradeLoading: false,
      upgrades: [],
      upgrade: null,
      upgradeLoaded: false,
      downgrades: [],
      downgrade: null,
      downgradeLoaded: false,
      submitNewEppCodeDialog: false,
      submitNewEppCodeLoading: false,
      submitNewEppCodeItem: null,
      newEppCode: '',
      showServiceTransferDialog: false,
      serviceId: '',
      serviceName: '',
      serviceIdentity: '',
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    this.$api.get('partner/domain-product-type/retail-menu').then((response) => {
      this.productTypes = response.data;
      this.productTypeId = response.data[0].id;
      this.loadTable();
    });
  },
  methods: {
    login(item) {
      this.loginLoading = true;
      this.$api.post('partner/service/login', {
        id: item.id,
      }).then((response) => {
        window.open(response.data.data.url, '_blank');
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.loginLoading = false;
      });
    },
    openRenewDialog(item) {
      this.renewItem = item;
      this.renewalTerms.splice(0, this.renewalTerms.length);
      this.renewDialog = true;
      this.$api.post('partner/service/get-renewal-terms', {
        id: item.id,
      }).then((response) => {
        response.data.forEach((term, index) => {
          this.renewalTerms.push({
            text: `${term.term_name} $${term.product_price}`,
            value: term.id,
          });

          if (index === 0) {
            this.renewalTerm = term.id;
          }
        });
      }).finally(() => {
      });
    },
    openCancelDialog(item) {
      this.cancelDialog = true;
      this.cancelItem = item;
    },
    openDowngradeDialog(item) {
      this.downgrade = null;
      this.downgradeDialog = true;
      this.downgradeItem = item;
      this.downgradeLoaded = false;
      this.downgrades.splice(0, this.downgrades.length);
      this.$api.post('partner/service/get-downgrades', {
        id: item.id,
      }).then((response) => {
        response.data.forEach((downgrade, index) => {
          this.downgrades.push({
            text: `${downgrade.product_name} $${downgrade.product_price}`,
            value: downgrade,
          });

          if (index === 0) {
            this.downgrade = downgrade;
          }
        });
      }).finally(() => {
        this.downgradeLoaded = true;
      });
    },
    openUpgradeDialog(item) {
      this.upgrade = null;
      this.upgradeDialog = true;
      this.upgradeItem = item;
      this.upgradeLoaded = false;
      this.upgrades.splice(0, this.upgrades.length);
      this.$api.post('partner/service/get-upgrades', {
        id: item.id,
      }).then((response) => {
        response.data.forEach((upgrade, index) => {
          this.upgrades.push({
            text: `${upgrade.product_name} ${upgrade.term_name} $${upgrade.product_price}`,
            value: upgrade,
          });

          if (index === 0) {
            this.upgrade = upgrade;
          }
        });
      }).finally(() => {
        this.upgradeLoaded = true;
      });
    },
    openResetPasswordDialog(item) {
      this.resetPasswordItem = item;
      this.resetPasswordDialog = true;
      this.newPassword = '';
    },
    toggleAutoRenew(touchedItem) {
      const foundItemIndex = this.items.findIndex((item) => item.id === touchedItem.id);
      if (foundItemIndex >= 0) {
        this.$set(this.items[foundItemIndex], 'loading', true);
        this.$api.post('partner/service/set-auto-renew', {
          id: this.items[foundItemIndex].id,
          auto_renew: this.items[foundItemIndex].auto_renew ? 0 : 1,
        }).then(() => {
          this.$set(this.items[foundItemIndex], 'auto_renew', !this.items[foundItemIndex].auto_renew);
          const successMessage = this.items[foundItemIndex].auto_renew ? 'on' : 'off';
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: `Auto renew is turned ${successMessage}.`,
          });
        }).catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        }).finally(() => {
          this.$set(this.items[foundItemIndex], 'loading', false);
        });
      }
    },
    loginClient(item) {
      this.$api.post(`partner/customer/${item.user_id}/login`, {})
        .then((response) => {
          if (response.data) {
            if (this.$store.state.adminUser.auth_token) {
              window.open(`${response.data}&adminToken=${this.$store.state.adminUser.auth_token}`);
            } else {
              window.open(response.data);
            }
          }
        })
        .finally(() => {
        });
    },
    view(item) {
      this.$router.push({ name: 'Partner.DomainDetail', params: { id: item.id } });
    },
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('partner/domain/list-retail', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        product_type_id: this.productTypeId,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
        this.initiated = true;
      });
    },
    edit(item) {
      this.$router.push({ name: 'Admin.ProductDetail', params: { id: item.id } });
    },
    renew() {
      this.renewCheckoutLoading = true;
      this.$api.post('partner/service/renew', {
        id: this.renewItem.id,
        product_term: this.renewalTerm,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your renewal request is submitted successfully.',
        });
        this.renewDialog = false;
        this.loadTable();
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.renewCheckoutLoading = false;
      });
    },
    resetPassword() {
      this.resetPasswordSaveLoading = true;
      this.$api.post('partner/service/reset-password', {
        id: this.resetPasswordItem.id,
        new_password: this.newPassword,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your hosting password is reset successfully.',
        });
        this.resetPasswordDialog = false;
      }).catch((error) => {
        this.resetPasswordError = true;
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.resetPasswordSaveLoading = false;
      });
    },
    cancel() {
      this.cancelLoading = true;
      this.$api.delete(`partner/service/${this.cancelItem.id}`)
        .then(() => {
          this.cancelDialog = false;
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'You have successfully cancelled this service.',
          });
        })
        .catch(() => {
        })
        .finally(() => {
          this.cancelLoading = false;
          this.loadTable();
        });
    },
    processUpgrade() {
      this.upgradeLoading = true;
      this.$api.post('partner/service/upgrade', {
        id: this.upgradeItem.id,
        product_price_id: this.upgrade.product_price_id,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your upgrade request is submitted successfully.',
        });
        this.upgradeDialog = false;
        this.loadTable();
      }).finally(() => {
        this.upgradeLoading = false;
      });
    },
    processDowngrade() {
      this.downgradeLoading = true;
      this.$api.post('partner/service/downgrade', {
        id: this.downgradeItem.id,
        product_price_id: this.downgrade.product_price_id,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your downgrade request is submitted successfully.',
        });
        this.downgradeDialog = false;
        this.loadTable();
      }).finally(() => {
        this.downgradeLoading = false;
      });
    },
    openEppCodeDialog(item) {
      this.submitNewEppCodeItem = item;
      this.submitNewEppCodeDialog = true;
      this.newEppCode = '';
    },
    submitNewEppCode() {
      this.submitNewEppCodeLoading = true;
      this.$api.post(`partner/domain/${this.submitNewEppCodeItem.id}/retry-epp-code`, {
        epp_code: this.newEppCode,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your transfer request is submitted successfully.',
        });
        this.loadTable();
        this.submitNewEppCodeDialog = false;
      }).finally(() => {
        this.submitNewEppCodeLoading = false;
      });
    },
    transferBack(serviceId) {
      const confirmTransferBack = window.confirm('Are you sure to take back the domain? customer will no longer able to manage it.');

      if (confirmTransferBack) {
        this.$api.post('partner/service/take-back', {
          service_id: serviceId,
        })
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'The domain has been transferred back successfully.',
            });
            this.loadTable();
          });
      }
    },
  },
};
</script>

<style scoped>
  table td {
    font-size: 0.75rem !important;
  }

  .product-type-tab .v-tab {
    justify-content: flex-start;
  }

  .product-type-tab .v-chip {
    margin-right: 8px;
  }
</style>

<template>
  <v-row>
    <v-col cols="12">
      <promo-banner product-category-id="1000000" operation-id="9"
                    :hide="hidePromoBanner"
      ></promo-banner>
    </v-col>
    <v-col cols="12">
      <v-row dense align="center">
        <v-col cols="8" lg="6">
          <v-text-field
            dense
            outlined
            label="Domain"
            v-model="form.domain"
            :error-messages="domainErr"
            :rules="[rules.domain]"
            v-on:blur="processDomain"
          ></v-text-field>
        </v-col>
        <v-col cols="4" lg="2">
          <v-select
            dense
            outlined
            label="TLD"
            :items="tld_list"
            v-model="form.tld"
            @change="changeTld()"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4" class="btn-group">
          <v-btn
            small
            color="primary"
            @click="showDomainPriceTable()">Show Price Table</v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            dense
            outlined
            label="EPP Code"
            v-model="form.epp"
            :append-icon="showEpp ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="showEpp ? 'text' : 'password'"
            @click:append="showEpp = !showEpp"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense align="center" v-if="showRegistrationForm">
        <v-col cols="12">
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row dense>
              <v-col cols="6">
                <v-select
                  dense
                  outlined
                  label="Term"
                  :rules="[rules.required]"
                  :items="terms"
                  v-model="form.product_term"
                  @change="updateDomainPrice"
                >
                  <template v-slot:selection="{ item }">
                    <div v-html="item.html"></div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div v-html="item.html"></div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <h5>Registrant Contact</h5>
                <v-row dense>
                  <v-col cols="12">
                    <v-btn
                      v-if="defaultContact"
                      x-small
                      color="success"
                      @click="copyContact('defaultContact', 'registrant')">
                      Use default contact</v-btn>
                    <v-btn
                      v-if="typeof $store.state.user.auth_token !== 'undefined'
                    && $store.state.user.auth_token !== null"
                      x-small
                      color="success"
                      @click="copyContact('profile', 'registrant')">Use profile</v-btn>
                    <v-btn
                      v-if="typeof $store.state.user.auth_token !== 'undefined'
                    && $store.state.user.auth_token !== null"
                      x-small
                      color="warning"
                      @click="clearContact('registrant')">Clear</v-btn>
                  </v-col>
                </v-row>
                <component
                  v-bind:is="registrantComponent"
                  ref="registrantProfileComp"
                  :forceOrg="forceOrgAdmin"
                  :setNewProfile="registrant"
                ></component>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="note-block-header">
                      Note
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="note-block">
                      <ul>
                        <li>a. Domain transfer will take 5-7 days to complete upon payment assuming
                          there is no domain related blockers.</li>
                        <li>b. Domain blockers may be associated to situation such as :<br>
                        -Domain is still locked and a transfer is initiated.<br>
                        -Domain EPP code is incorrect and a transfer is initiated.<br>
                        -Losing Domain Registrar is rejecting the domain transfer out as there are
                          loose end not resolved with domain owner.
                        (Contact Losing Domain Registrars to find out more.)<br>
                        </li>
                        <li>c. All domain that is transfer in will automatically retain the same
                          current nameservers records.</li>
                        <li>d. Changing nameservers will only be available before a domain transfer
                          or after a domain transfer is completed.</li>
                      </ul>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-col class="text-right">
              <v-btn
                text-align="right"
                color="primary"
                @click="checkout()"
                :disabled="checkoutDisabled && !checkoutProcessing"
                :loading="checkoutProcessing"
              >Add to cart ${{ total }}</v-btn>
            </v-col>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="showPriceTable" scrollable max-width="1024px">
      <v-card>
        <v-card-title>Price Table</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 1024px;">
          <v-simple-table dense fixed-header>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">TLD</th>
                <th class="text-left">1 Year</th>
                <th class="text-left">2 Years</th>
                <th class="text-left">3 Years</th>
                <th class="text-left">4 Years</th>
                <th class="text-left">5 Years</th>
                <th class="text-left">6 Years</th>
                <th class="text-left">7 Years</th>
                <th class="text-left">8 Years</th>
                <th class="text-left">9 Years</th>
                <th class="text-left">10 Years</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="tld in tldPriceList" :key="tld.name">
                <td><b>{{ tld.tld }}</b></td>
                <td v-for="(year, index) in years" :key="index">
                  <div v-if="tld.terms[year]">
                      <span :class="{ 'line-through': tld.terms[year].discount > 0 }">
                        ${{ tld.terms[year].price }}
                      </span>
                    <span class="discounted-price"
                          v-if="tld.terms[year].discount > 0">
                        ${{ tld.terms[year].discounted_price }}
                      </span>
                  </div>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Profile from '../../components/Profile.vue';
import ProfileSg from '../../components/ProfileSg.vue';
import PromoBanner from '../../components/PromoBanner.vue';
import ProfileAu from '../../components/ProfileAu.vue';

export default {
  name: 'Add',
  components: {
    PromoBanner,
    Profile,
    ProfileSg,
  },
  created() {
    if (this.domain) {
      this.form.domain = this.domain;
    }

    this.$api.get('partner/domain/price-table?operation=9').then((response) => {
      this.tldPriceList = response.data;
      this.$api.get('partner/domain/default-contact').then((subresponse) => {
        const { profile } = subresponse.data;

        if (typeof this.$store.state.user.auth_token !== 'undefined'
          && this.$store.state.user.auth_token !== null) {
          if (profile !== null && !this.$underscore.isEmpty(profile.email)) {
            this.defaultContact = profile;
          }
        }

        this.$api.get('partner/domain/tld?operation=9').then((subsubresponse) => {
          const tldList = subsubresponse.data;
          tldList.forEach((item, index) => {
            this.tld_list.push({
              text: `.${item.tld}`,
              value: item.id,
              tld: `${item.tld}`,
            });

            if (this.tld) {
              if (this.tld === item.tld) {
                this.form.tld = item.id;
                this.form.tld_name = item.tld;
                this.changeTld();
              }
            } else if (index === 0) {
              this.form.tld = item.id;
              this.form.tld_name = item.tld;
              this.changeTld();
            }
          });
        });
      });
    });
  },
  data() {
    return {
      total: 0,
      form: {
        domain: '',
        epp: '',
        product_id: null,
        product_term: null,
        tld: null,
        auto_renew: false,
        ns1: '',
        ns2: '',
        ns3: '',
        ns4: '',
        ns5: '',
        registrant: {},
      },
      currentContactMethod: 'defaultContact',
      years: [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
      ],
      showEpp: false,
      defaultContact: null,
      registrant: null,
      admin: null,
      tech: null,
      billing: null,
      domainErr: null,
      tld_list: [],
      tld_value: '',
      tldPriceList: [],
      terms: [],
      promoRunning: false,
      checkDomainLoading: false,
      checkDomainInvalid: true,
      checkoutProcessing: false,
      checkoutDisabled: false,
      showPriceTable: false,
      showRegistrationForm: true,
      registrantComponent: Profile,
      adminComponent: Profile,
      forceOrgAdmin: false,
      techComponent: Profile,
      billingComponent: Profile,
      successMessage: 'Your domain transfer request is successfully added to the shopping cart.',
      valid: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?/i).test(value) || 'This field must be valid domain name.',
        nameserver: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
      },
    };
  },
  props: {
    domain: {
      type: String,
    },
    tld: {
      type: String,
    },
    hidePromoBanner: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateDomainPrice() {
      let total = 0;
      this.terms.forEach((priceTerm) => {
        if (parseInt(priceTerm.value, 0) === parseInt(this.form.product_term, 0)) {
          total += +priceTerm.price;
        }
      });
      this.total = +total;
    },
    processDomain() {
      if (this.form.domain.indexOf('.') > 0) {
        const tldCur = `.${this.form.domain.split(/\./).slice(1).join('.')}`;
        this.$underscore.map(this.tld_list, (tldPrice) => {
          if (tldCur === tldPrice.text) {
            this.$set(this.form, 'tld', tldPrice.value);
            this.$set(this.form, 'domain', this.form.domain.split(/\./)[0]);
            this.changeTld();
            this.$underscore.map(this.tldPriceList, (tld) => {
              if (tld.tld_id === this.form.tld) {
                if (this.$underscore.isEmpty(this.form.product_term)) {
                  this.form.product_term = tld.terms[1].term_id;
                }
                this.updateDomainPrice();
              }
            });
          }
        });
      }
    },
    checkDomain() {
      this.showRegistrationForm = true;
      this.domainErr = '';
      this.checkDomainLoading = true;
      this.reloadTerm();
      this.$api.post('partner/domain/check-availability', this.form).then(() => {
        this.loadRegistrationForm();
      })
        .catch(() => {
          this.domainErr = 'The domain is not available.';
        })
        .finally(() => {
          this.checkDomainLoading = false;
        });
    },
    reloadTerm() {
      this.$set(this, 'terms', []);
      this.$underscore.map(this.tldPriceList, (tld) => {
        if (tld.tld_id === this.form.tld) {
          this.$underscore.map(tld.terms, (term, index) => {
            this.form.product_term = tld.terms[1].term_id;
            let yearPrice = index === '1' ? 'Year' : 'Years';
            let htmlText = `${index} ${yearPrice}`;
            yearPrice = `${yearPrice} $${term.price}`;
            let domainPrice = term.price;

            const termYearMap = {
              5: 1,
              6: 2,
              7: 3,
              8: 4,
              9: 5,
              10: 6,
              11: 7,
              12: 8,
              13: 9,
              14: 10,
            };

            if (term.discount > 0) {
              const yearlyPrice = term.discounted_price / termYearMap[term.term_id];
              htmlText += `&nbsp<span class="line-through">$${term.price}</span>
                        <span class="discounted-price">$${term.discounted_price}</span>
                        <span class="discounted-price">${term.discount_name} ${term.discount_rate}%OFF</span> ($${yearlyPrice}/year)`;
              domainPrice = term.discounted_price;
            } else {
              const yearlyPrice = term.price / termYearMap[term.term_id];
              htmlText += `&nbsp$${term.price} ($${yearlyPrice}/year)`;
            }
            this.terms.push({
              text: `${index} ${yearPrice}`,
              html: htmlText,
              value: term.term_id,
              price: domainPrice,
            });
          });
        }
      });
    },
    loadRegistrationForm() {
      this.showRegistrationForm = true;
    },
    showDomainPriceTable() {
      this.showPriceTable = true;
    },
    checkout() {
      this.checkoutProcessing = true;
      this.checkoutDisabled = true;
      if (this.$refs.form.validate()) {
        this.$underscore.map(this.tldPriceList, (value) => {
          if (value.tld_id === this.form.tld) {
            this.form.product_id = value.id;
          }
        });
        this.form.registrant = this.$refs.registrantProfileComp.getProfileData();
        if (this.$store.state.shoppingCartId) {
          this.form.shopping_cart_id = this.$store.state.shoppingCartId;
        }
        this.$api.post('partner/domain/transfer', this.form)
          .then((data) => {
            // eslint-disable-next-line no-undef
            if (this.$store.state.brand === 'cldy') {
              window.gtag('event', 'add_payment_info', {
                currency: window.systemCurrency.toUpperCase(),
                total: this.total,
              });
            }

            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: this.successMessage,
            });
            this.$store.dispatch('syncShoppingCart', data.data);
            setTimeout(() => {
              this.goToCart();
            }, 500);
          })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.checkoutProcessing = false;
            this.checkoutDisabled = false;
          });
      } else {
        this.checkoutProcessing = false;
        this.checkoutDisabled = false;
      }
    },
    copyContact(from, to) {
      if (this.currentContactMethod !== from) {
        this.currentContactMethod = from;
      }

      if (from === 'defaultContact') {
        this[to] = this.defaultContact;
        const sgTld = [
          'sg',
          'com.sg',
        ];

        const auTld = [
          'au',
          'com.au',
          'org.au',
          'net.au',
          'id.au',
        ];

        if (sgTld.indexOf(this.tld_value) >= 0) {
          if (this.defaultContact) {
            if (this.$underscore.isEmpty(this.defaultContact.corporate_no)) {
              this[to].special_field1 = 'p';
              this[to].special_field2 = this.defaultContact.personal_identity_no;
            } else if (this.defaultContact.corporate_no) {
              this[to].special_field1 = 'o';
              this[to].special_field2 = this.defaultContact.corporate_no;
            }
          }
        }

        if (auTld.indexOf(this.tld_value) >= 0) {
          if (this.defaultContact) {
            if (this.$underscore.isEmpty(this.defaultContact.corporate_no)) {
              this[to].special_field1 = 'p';
            } else {
              this[to].special_field1 = 'o';
              this[to].special_field2 = this.defaultContact.corporate_no;
            }
          }
        }
      } else if (from === 'profile') {
        this.$api.get('partner/profile').then((response) => {
          this[to] = {};
          const sgTld = [
            'com.sg',
          ];
          const auTld = [
            'com.au',
            'org.au',
            'net.au',
          ];
          if (sgTld.indexOf(this.tld_value) >= 0 || auTld.indexOf(this.tld_value) >= 0) {
            this[to].special_field1 = 'o';
          } else {
            this[to].special_field1 = 'p';
          }
          this[to].first_name = response.data.first_name;
          this[to].last_name = response.data.last_name;
          this[to].company_name = response.data.company_name;
          this[to].email = response.data.email;
          this[to].address1 = response.data.address1;
          this[to].city = response.data.city;
          this[to].state = response.data.state;
          this[to].country = response.data.country;
          this[to].postal_code = response.data.postal_code;
          this[to].phone = response.data.phone;
          this[to].phone_country_code = response.data.phone_country_code;
        });
      } else {
        this[to] = this.$refs[`${from}ProfileComp`].getProfileData();
      }
    },
    clearContact(to) {
      this[to] = {
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: 'SG',
        postal_code: '',
        phone_country_code: '',
        phone: '',
        fax_country_code: '',
        fax: '',
        special_field1: 'p',
        special_field2: '',
      };
      const sgTld = [
        'com.sg',
      ];
      const auTld = [
        'com.au',
        'org.au',
        'net.au',
      ];
      if (sgTld.indexOf(this.tld_value) >= 0 || auTld.indexOf(this.tld_value) >= 0) {
        this[to].special_field1 = 'o';
      }
    },
    goToCart() {
      const { brand } = this.$store.state;
      if (this.$store.state.user.role) {
        this.$router.push({
          name: 'Retail.ShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      } else {
        this.$router.push({
          name: 'Retail.GuestShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      }
    },
    changeTld() {
      this.showRegistrationForm = true;
      this.domainErr = null;
      this.reloadTerm();
      this.tld_list.forEach((tld) => {
        if (this.form.tld === tld.value) {
          if (tld.text === '.sg') {
            this.forceOrgAdmin = false;
            this.registrantComponent = ProfileSg;
            this.adminComponent = ProfileSg;
            this.techComponent = ProfileSg;
            this.billingComponent = ProfileSg;
          } else if (tld.text === '.com.sg') {
            this.forceOrgAdmin = true;
            this.registrantComponent = ProfileSg;
            this.adminComponent = ProfileSg;
            this.techComponent = ProfileSg;
            this.billingComponent = ProfileSg;
          } else if (tld.text === '.au' || tld.text === '.id.au') {
            this.forceOrgAdmin = false;
            this.registrantComponent = ProfileAu;
            this.adminComponent = ProfileAu;
            this.techComponent = ProfileAu;
            this.billingComponent = ProfileAu;
          } else if (tld.text === '.com.au' || tld.text === '.org.au' || tld.text === '.net.au' || tld.text === '.asn.au') {
            this.forceOrgAdmin = true;
            this.registrantComponent = ProfileAu;
            this.adminComponent = ProfileAu;
            this.techComponent = ProfileAu;
            this.billingComponent = ProfileAu;
          } else {
            this.forceOrgAdmin = false;
            this.registrantComponent = Profile;
            this.adminComponent = Profile;
            this.techComponent = Profile;
            this.billingComponent = Profile;
          }
          this.tld_value = tld.tld;
          this.copyContact(this.currentContactMethod, 'registrant');
        }
      });
    },
  },
  watch: {
    'form.domain': {
      handler(domain) {
        if (this.rules.domain(domain) !== true) {
          this.checkDomainInvalid = true;
        } else {
          this.checkDomainInvalid = false;
          this.domainErr = null;
        }
      },
    },
    'form.tld': {
      handler() {
        this.reloadTerm();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
  .note-block-header {
    background-color: slategrey;
    color: white;
    font-weight: 600;
  }

  .note-block {
    background-color: rgb(230, 230, 230);
    padding-top: 16px;
  }

  .btn-group {
    min-height: 74px;
    padding-top: 10px;
  }

  .btn-group button {
    margin-right: 5px;
  }
</style>

<template>
  <v-row no-gutters>
    <v-col cols="6">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Year Month
            </th>
            <th class="text-left">
              New Customer Count
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="row in rows"
            :key="row.id"
          >
            <td @click="renderCustomers(row.yearmonth)" class="clickable">{{ row.yearmonth }}</td>
            <td>{{ row.new_count }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="12" v-if="render">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left"></th>
            <th class="text-left">
              Email
            </th>
            <th class="text-left">
              Services
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="customer in customers"
            :key="customer.id"
          >
            <td><v-icon
              small
              class="mr-2"
              @click="loginAsPartner(customer)"
            >
              mdi-door
            </v-icon></td>
            <td>{{ customer.email }}</td>
            <td><pre>{{ customer.services }}</pre></td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CldyNewCustomerReport',
  data() {
    return {
      rows: [],
      customers: [],
      render: false,
    };
  },
  created() {
    this.$api.post('admin/finance/cldy-new-customer')
      .then((response) => {
        this.$set(this, 'rows', response.data);
      });
  },
  methods: {
    renderCustomers(yearmonth) {
      this.render = false;
      this.$api.post('admin/finance/cldy-new-customer', {
        yearmonth,
      })
        .then((response) => {
          this.$set(this, 'customers', response.data);
          this.render = true;
        });
    },
    loginAsPartner(item) {
      this.$api.post(`partner/customer/${item.id}/login`, {})
        .then((response) => {
          if (response.data) {
            if (this.$store.state.user.role === 'Admin') {
              window.open(`${response.data}&adminToken=${this.$store.state.user.auth_token}`);
            } else {
              window.open(response.data);
            }
          }
        });
    },
  },
};
</script>

<style scoped>
  .clickable {
    cursor: pointer;
  }
</style>

import Login from './Login.vue';
import PageNotFound from './PageNotFound.vue';
import ForgotPassword from './ForgotPassword.vue';
import ResetPassword from './ResetPassword.vue';
import ServiceTransfer from './ServiceTransfer.vue';
import Base from './Base.vue';
import Home from './Home.vue';
import UserProfile from './UserProfile.vue';
import Settings from './Settings.vue';
import Security from './Security.vue';
import BalanceList from './balance/List.vue';
import BalanceTopup from './balance/Topup.vue';
import DomainList from './domain/List.vue';
import DomainListArchive from './domain/ListArchive.vue';
import DomainDetail from './domain/Detail.vue';
import DomainRegister from './domain/Register.vue';
import DomainRenew from './domain/Renew.vue';
import DomainTransfer from './domain/Transfer.vue';
import DomainRequestTransfer from './domain/RequestTransfer.vue';
import DomainDefaultContact from './domain/DefaultContact.vue';
import ServiceList from './service/List.vue';
import ServiceArchiveList from './service/ArchiveList.vue';
import ServiceDetail from './service/Detail.vue';
import ServiceRegister from './service/Register.vue';
import ServiceRenew from './service/Renew.vue';
import AddonList from './addon/List.vue';
import AddonArchiveList from './addon/ArchiveList.vue';
import InvoiceDetail from './payment/Detail.vue';
import Topup from './payment/Topup.vue';
import TopupHistory from './payment/TopupHistory.vue';
import TaxInvoice from './payment/TaxInvoice.vue';
import TaxInvoiceList from './payment/TaxInvoiceList.vue';
import ShoppingCart from './ShoppingCart.vue';
import Guest from './Guest.vue';
import GuestShoppingCart from './GuestShoppingCart.vue';
import ExpressCheckout from './ExpressCheckout.vue';
import ExpressInvoicePayment from './ExpressInvoicePayment.vue';
import ExpressSaveCard from './ExpressSaveCard.vue';
import ExpressSaveCardPaypal from './ExpressSaveCardPaypal.vue';
import CldyHeader from './CldyHeader.vue';
import CldyFooter from './CldyFooter.vue';
import AffiliateSignup from './AffiliateSignup.vue';
import AffiliateCommissionList from './affiliate/List.vue';
import AffiliateCommissionWithdrawDetail from './affiliate/Withdraw.vue';
import FriendVoucherList from './voucher/List.vue';
import DnsEditor from './service/DnsEditor.vue';
import Card from './payment/Card.vue';
import PublicBase from './PublicBase.vue';
import BusinessCardGenerator from './BusinessCardGenerator.vue';
import VcardList from './extension/vcard/List.vue';
import VcardCreate from './extension/vcard/Register.vue';
import CollaboratorList from './collaborator/List.vue';
import CollaboratorNew from './collaborator/New.vue';
import CollaboratorUpdate from './collaborator/Update.vue';
import CollaboratorActionList from './collaborator/ListAction.vue';
import CollaboratorServiceList from './collaborator/ServiceList.vue';

export default {
  Login,
  ForgotPassword,
  ResetPassword,
  PageNotFound,
  ServiceTransfer,
  Home,
  Base,
  UserProfile,
  Settings,
  Security,
  BalanceList,
  BalanceTopup,
  DomainList,
  DomainListArchive,
  DomainDetail,
  DomainRegister,
  DomainRenew,
  DomainTransfer,
  DomainRequestTransfer,
  DomainDefaultContact,
  ServiceList,
  ServiceArchiveList,
  ServiceDetail,
  ServiceRegister,
  ServiceRenew,
  AddonList,
  AddonArchiveList,
  Topup,
  TopupHistory,
  TaxInvoice,
  TaxInvoiceList,
  InvoiceDetail,
  ShoppingCart,
  Guest,
  GuestShoppingCart,
  ExpressCheckout,
  ExpressInvoicePayment,
  ExpressSaveCard,
  ExpressSaveCardPaypal,
  CldyHeader,
  CldyFooter,
  AffiliateSignup,
  AffiliateCommissionList,
  AffiliateCommissionWithdrawDetail,
  FriendVoucherList,
  DnsEditor,
  Card,
  PublicBase,
  BusinessCardGenerator,
  VcardList,
  VcardCreate,
  CollaboratorList,
  CollaboratorActionList,
  CollaboratorServiceList,
  CollaboratorNew,
  CollaboratorUpdate,
};

<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense align="center">
        <v-col cols="12" md="8">
          <v-text-field
            dense
            outlined
            label="Enter your preferred domain name (for eg. mybusiness.sg)"
            v-model="moduleData.domain"
            :rules="[rules.required, rules.domain]"
            @keydown="typing()"
          ></v-text-field>
        </v-col>
        <v-col cols="4" v-if="showDomainRegistrationButton" class="btn-group">
          <v-btn
            small
            color="primary"
            @click="showDomainRegistrationForm()">Check Availability</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      moduleData: {
        domain: '',
      },
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i).test(value) || 'This field must be valid domain name.',
      },
    };
  },
  props: {
    showDomainRegistrationButton: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    showDomainRegistrationForm() {
      this.$emit('showDomainRegistrationForm');
    },
    typing() {
      this.$emit('typing');
    },
  },
  watch: {
    moduleData: {
      handler(newModuleData) {
        this.$emit('update:moduleData', newModuleData);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

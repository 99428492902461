<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row dense no-gutters v-if="$store.state.brand != 'cldy'">
      <v-app-bar
        app
        dense
      >
        <v-spacer></v-spacer>
        <a class="header-btn" @click="gotoLogin()">LOGIN</a>
        <span>|</span>
        <a class="header-btn" @click="gotoLogin()">REGISTER</a>
        <v-badge
          color="green"
          :content="$store.state.shoppingCart.total_count + ''"
          overlap
          offset-x="20"
          offset-y="20"
        >
          <v-btn
            icon
            large
            @click="goToCart()"
          >
            <v-avatar
              size="32px"
              item
            >
              <v-icon>mdi-cart-outline</v-icon>
            </v-avatar>
          </v-btn>
        </v-badge>
      </v-app-bar>
    </v-row>
    <CldyHeader v-if="$store.state.brand == 'cldy'"></CldyHeader>
    <v-row
      class="form"
      justify="center"
    >
      <v-col
        cols="12"
      >
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="10">
            <v-card class="elevation-12">
              <v-toolbar
                color="indigo"
                dark
                flat
              >
                <v-toolbar-title>Sign Up Affiliate</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-card-text>
                  <v-form ref="form"
                          v-model="valid">
                    <profile
                      ref="customerProfile"
                    ></profile>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="indigo"
                    :dark="true"
                    :loading="signUpProcessing"
                    @click="signUp()"
                  >Submit</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-row>
    <v-footer v-if="$store.state.brand != 'cldy'"
              dark
              padless
              :app="true"
              :fixed="true"
    >
      <v-card
        flat
        tile
        class="indigo lighten-1 white--text text-center"
        width="100%"
      >
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Powered By GoCloudEasy Pte Ltd</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <CldyFooter v-if="$store.state.brand == 'cldy'"></CldyFooter>
  </v-container>
</template>

<script>
import Profile from '../components/Profile.vue';
import CldyHeader from './CldyHeader.vue';
import CldyFooter from './CldyFooter.vue';

export default {
  name: 'AffiliateSignup',
  components: {
    Profile,
    CldyHeader,
    CldyFooter,
  },
  data() {
    return {
      email: '',
      password: '',
      processing: false,
      signUpProcessing: false,
      valid: false,
      error: {
        email: false,
        password: false,
      },
      errorMessage: {
        email: '',
        password: '',
      },
    };
  },
  methods: {
    signUp() {
      if (this.$refs.form.validate()) {
        this.signUpProcessing = true;
        this.$api.post('signup-affiliate', this.$refs.customerProfile.getProfileData())
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Your account is created. Welcome email will be sent to you shortly. We will review your affiliate status shortly.',
            });
            this.$refs.form.reset();
          })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.signUpProcessing = false;
          });
      }
    },
    validate() {
      this.error.email = false;
      this.error.password = false;
      this.errorMessage.email = '';
      this.errorMessage.password = '';

      if (this.$underscore.isEmpty(this.email)) {
        this.error.email = true;
        this.errorMessage.email = 'Email Address is required.';
        throw new Error('Validation error.');
      }

      if (this.$underscore.isEmpty(this.password)) {
        this.error.password = true;
        this.errorMessage.password = 'Password is required.';
        throw new Error('Validation error.');
      }
    },
    gotoHome() {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.Guest', params: { brand } })
        .catch(() => {
        });
    },
    goToCart() {
      const { brand } = this.$store.state;
      if (this.$store.state.user.role) {
        this.$router.push({
          name: 'Retail.ShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      } else {
        this.$router.push({
          name: 'Retail.GuestShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      }
    },
  },
  mounted() {
    this.$api.defaults.headers.common.Authorization = '';

    if (this.$route.query.token) {
      this.$api.post('login', {
        token: this.$route.query.token,
      })
        .then((response) => {
          if (response.data.user) {
            this.$store.dispatch('reset');
            this.$store.dispatch('setCurrentUser', response.data.user);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({ name: `${this.$store.state.user.role}.Home` });
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  },
};
</script>

<style scoped>
.v-application .form a {
  color: black;
  font-size: 0.8rem;
  text-decoration: none;
}

.header-btn {
  margin-right: 5px;
  margin-left: 5px;
}
</style>

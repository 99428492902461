<template>
  <v-app id="inspire">
    <v-navigation-drawer
      app
      dense
      v-if="appNavigationDrawerEnabled && $route.name !== 'Retail404' && $route.name !== '404'"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.mdAndUp"
    >
      <v-list
        dense
        class="left-menu"
      >
        <div
          v-for="menu in menus"
          :key="menu.title"
        >
          <v-list-item
            color="primary"
            v-if="!menu.group"
            :input-value="menu.active"
            :link="true"
            :to="menu.to"
          >
            <v-list-item-icon>
              <v-icon v-text="menu.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-avatar v-if="menu.counter_slug" color="red" size="24">
                  <span class="white--text">{{ menu.counter }}</span>
                </v-avatar>
                {{ menu.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="menu.group"
            no-action
            v-model="menu.active"
            :prepend-icon="menu.icon"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <v-avatar v-if="menu.counter_slug" color="red" size="24">
                    <span class="white--text">{{ menu.counter }}</span>
                  </v-avatar>
                  {{ menu.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="submenu in menu.submenus"
              :key="submenu.title"
              :link="true"
              :to="submenu.to"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-avatar v-if="submenu.counter_slug" color="red" size="24">
                    <span class="white--text">{{ submenu.counter }}</span>
                  </v-avatar>
                  {{ submenu.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout">Logout</v-btn>
          <img v-if="$store.state.brand == 'cldy'"
               :src="`${publicPath}cldylogomobile.svg`" class="sidebar-logo" alt="">
          <img v-if="$store.state.brand == 'qoxy'"
               :src="`${publicPath}qoxylogo.png`" class="sidebar-logo" alt="">
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="blue darken-3"
      dark
      dense
      v-if="appBarEnabled && $route.name !== 'Retail404' && $route.name !== '404'"
      :clipped-left="$vuetify.breakpoint.mdAndUp"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        style="width: 340px"
        class="ml-0 pl-4 hidden-md-and-down"
        v-if="$store.state.user.role !== 'Cp'"
      >
        <span class="hidden-sm-and-down" v-if="brand === false">
          Partner Portal | {{ $store.state.user.company_name }}
        </span>
        <span class="hidden-sm-and-down" v-else>
          Client Portal | {{ $store.state.user.company_name }}
        </span>
      </v-toolbar-title>
      <v-autocomplete
        @change="search=''"
        v-if="(typeof $store.state.adminUser.auth_token !== 'undefined'
        && $store.state.adminUser.auth_token !== null) || $store.state.user.role === 'Admin'"
        v-model="selected"
        :items="items"
        :loading="smartSearching"
        :search-input.sync="search"
        item-text="search"
        item-value="search"
        clearable
        hide-details
        hide-selected
        label="Smart Search"
        solo-inverted
        dense
        :menu-props="{ maxHeight: 800 }"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content v-if="item.search_type == 'user'">
            <v-list-item-subtitle v-if="item.user_type_id === 4"
                                  v-text="'Partner'">
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.user_type_id === 5"
                                  v-text="'Customer'">
            </v-list-item-subtitle>
            <v-list-item-title v-text="item.email"></v-list-item-title>
            <v-list-item-subtitle v-text="item.first_name + ' ' + item.last_name">
            </v-list-item-subtitle>
            <v-list-item-subtitle v-text="item.company_name">
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="item.search_type == 'user'">
            <v-btn x-small
                   color="primary"
                   v-if="item.user_type_id === 4"
                   @click="loginAsPartner(item.id)">User Login</v-btn>
            <v-btn x-small
                   color="primary"
                   v-if="item.user_type_id === 5"
                   @click="loginAsPartnerClient(item.id)">User Login</v-btn>
          </v-list-item-action>

          <v-list-item-content v-if="item.search_type == 'invoice'">
            <v-list-item-title v-text="item.invoice_no"></v-list-item-title>
            <v-list-item-subtitle v-if="item.user_type_id === 4"
                                  v-text="'Partner'">
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.user_type_id === 5"
                                  v-text="'Customer'">
            </v-list-item-subtitle>
            <v-list-item-subtitle v-text="item.email">
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span :class="{
                'text-success': item.status_name == 'paid',
                'text-error': item.status_name == 'pending' ||
                item.status_name == 'waiting payment',
              }">
                {{ item.status_name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-text="item.invoice_line_description">
            </v-list-item-subtitle>
            <v-list-item-subtitle v-text="item.payment_reference_no">
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="item.search_type == 'invoice'">
            <v-btn x-small
                   v-if="item.user_type_id === 4"
                   @click="loginAsPartner(item.user_id)">User Login</v-btn>
            <v-btn x-small
                   v-if="item.user_type_id === 4"
                   @click="viewPartnerInvoice(item)">View Invoice</v-btn>
            <v-btn x-small
                   v-if="item.user_type_id === 5"
                   @click="loginAsPartnerClient(item.user_id)">User Login</v-btn>
            <v-btn x-small
                   v-if="item.user_type_id === 5"
                   @click="viewPartnerClientInvoice(item)">Manage Invoice</v-btn>
          </v-list-item-action>

          <v-list-item-content v-if="item.search_type == 'product'">
            <v-list-item-title v-text="`${item.service_name} ${item.service_identity}`">
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.user_type_id === 4"
                                  v-text="'Partner'">
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.user_type_id === 5"
                                  v-text="'Customer'">
            </v-list-item-subtitle>
            <v-list-item-subtitle v-text="item.email">
            </v-list-item-subtitle>
            <v-list-item-subtitle v-text="`Expiry: ${item.expiry_date}`"></v-list-item-subtitle>
            <v-list-item-subtitle v-text="item.status_name"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="item.search_type == 'product'">
            <v-btn x-small
                   color="primary"
                   v-if="item.user_type_id === 4"
                   @click="loginAsPartner(item.user_id)">User Login</v-btn>
            <v-btn x-small
                   color="primary"
                   v-if="item.user_type_id === 5"
                   @click="loginAsPartnerClient(item.user_id)">User Login</v-btn>
            <v-btn x-small
                   color="secondary"
                   v-if="item.username"
                   @click="loginCpanel(item)">Login cPanel</v-btn>
            <v-btn x-small
                   color="secondary"
                   v-if="item.username"
                   @click="updateCpanel(item)">Change cPanel Mapping</v-btn>
            <v-btn x-small
                   color="secondary"
                   @click="viewService(item)">Manage</v-btn>
          </v-list-item-action>

          <v-list-item-content v-if="item.search_type == 'domain'">
            <v-list-item-title v-text="`Domain ${item.service_identity}`">
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.user_type_id === 4"
                                  v-text="'Partner'">
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.user_type_id === 5"
                                  v-text="'Customer'">
            </v-list-item-subtitle>
            <v-list-item-subtitle v-text="item.email">
            </v-list-item-subtitle>
            <v-list-item-subtitle v-text="`Expiry: ${item.expiry_date}`"></v-list-item-subtitle>
            <v-list-item-subtitle v-text="item.status_name"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="item.search_type == 'domain'">
            <v-btn x-small
                   color="primary"
                   v-if="item.user_type_id === 4"
                   @click="loginAsPartner(item.user_id)">User Login</v-btn>
            <v-btn x-small
                   color="primary"
                   v-if="item.user_type_id === 5"
                   @click="loginAsPartnerClient(item.user_id)">User Login</v-btn>
            <v-btn x-small
                   color="secondary"
                   @click="viewDomain(item)">Manage</v-btn>
          </v-list-item-action>
        </template>
      </v-autocomplete>
      <v-spacer></v-spacer>
      <v-btn color="success" v-if="typeof $store.state.user.auth_token !== 'undefined'
      && $store.state.user.auth_token !== null
      && $store.state.user.role == 'Admin'
      && ($store.state.user.email == 'dan.chen@gocloudeasy.com' ||
      $store.state.user.email == 'windskeleton@gmail.com')"
             @click="loginAsPartner(33)"
      >
        GoToCLDY
      </v-btn>
      <v-btn color="success" v-if="typeof $store.state.adminUser.auth_token !== 'undefined'
      && $store.state.adminUser.auth_token !== null
      && $store.state.user.role === 'Partner'"
        @click="returnAdminDashboard()"
      >
        Return Admin Dashboard
      </v-btn>
      <v-btn color="orange"
             v-if="$store.state.user.role === 'Retail' && $store.state.user.multi_acc === 1"
             @click="openMultiAccountSwitchDialog()"
      >
        Switch Region
      </v-btn>
      <v-toolbar-title class="margin-small" v-if="$store.state.user.role !== 'Cp'">
        <span v-if="systemCurrency === 'sgd'"> Singapore Dollars (SGD)
          <span v-if="$store.state.brand !== 'cldy'">(9% GST Inclusive)</span>
        </span>
        <span v-if="systemCurrency === 'usd'"> US Dollars (USD)</span>
        <span v-if="systemCurrency === 'aud'"> Australia Dollars (AUD)</span>
      </v-toolbar-title>
      <v-badge
        v-if="brand !== false"
        color="green"
        :content="$store.state.shoppingCart.total_count + ''"
        overlap
        offset-x="20"
        offset-y="20"
      >
        <v-btn
          icon
          large
          @click="goToCart()"
        >
          <v-avatar
            size="32px"
            item
          >
            <v-icon>mdi-cart-outline</v-icon>
          </v-avatar>
        </v-btn>
      </v-badge>
      <v-btn
        icon
        large
        @click="goToProfile()"
      >
        <v-avatar
          size="32px"
          item
        >
          <v-icon>mdi-account-circle</v-icon>
        </v-avatar>
      </v-btn>
    </v-app-bar>
    <div
      style="position:fixed;top:0;width:80%;margin:0 auto;left:0;right:0;z-index:1000;"
    >
      <v-scroll-y-reverse-transition
        group
        :hide-on-leave="true"
      >
        <v-alert
          v-for="alert in alertList"
          :key="alert.id"
          :type="alert.type"
        >
          <span v-if="alert.raw === 1" v-html="alert.message"></span>
          <span v-else>{{ alert.message }}</span>
        </v-alert>
      </v-scroll-y-reverse-transition>
    </div>
    <v-main class="main">
      <router-view
        @reloadmenu="reloadMenu"
      ></router-view>
    </v-main>
    <v-dialog
      v-model="multiAccountSwitchDialog"
      width="512"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" v-for="account in this.$store.state.user.accounts"
                   :key="account.currency"
                   @click="selectAccount(account.currency)"
                   :class="{'currency-selected': account.currency === accountCurrency,
               'currency-row': true}"
            >
              <h2>
                <b>{{ account.currency }} - {{ account.first_name }} {{ account.last_name }}</b>
              </h2>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="loginMultiAccount()"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',

  data() {
    return {
      drawer: this.$vuetify.breakpoint.mdAndUp,
      selected: null,
      items: [],
      search: null,
      smartSearching: false,
      timerId: null,
      multiAccountSwitchDialog: false,
      accountCurrency: null,
      systemCurrency: window.systemCurrency,
      publicPath: process.env.BASE_URL,
    };
  },

  components: {
    //
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    localComputed() {
      //
    },
    // mix this into the outer object with the object spread operator
    ...mapState({
      appNavigationDrawerEnabled: 'appNavigationDrawerEnabled',
      appBarEnabled: 'appBarEnabled',
      alertList: 'alertList',
      menus: 'menus',
      brand: 'brand',
      shoppingCart: 'shoppingCart',
    }),
  },
  methods: {
    openMultiAccountSwitchDialog() {
      this.multiAccountSwitchDialog = true;
    },
    selectAccount(currency) {
      this.accountCurrency = currency;
    },
    loginMultiAccount() {
      window.location = `/${this.accountCurrency}/login?token=${this.$store.state.user.accounts[this.accountCurrency].auth_token}`;
    },
    reloadMenu() {
      if ((typeof this.$store.state.adminUser.auth_token !== 'undefined'
          && this.$store.state.adminUser.auth_token !== null)
        || this.$store.state.user.role === 'Admin') {
        this.$api.post('admin/menu/count', {})
          .then((response) => {
            this.$underscore.each(this.menus, (menu, key) => {
              if (menu.counter_slug) {
                if (response.data[menu.counter_slug]) {
                  this.menus[key].counter = response.data[menu.counter_slug];
                }
              }

              this.$underscore.each(menu.submenus, (submenu, subkey) => {
                if (submenu.counter_slug) {
                  if (response.data[submenu.counter_slug]) {
                    this.menus[key].submenus[subkey].counter = response.data[submenu.counter_slug];
                  }
                }
              });
            });
          }).finally(() => {
          });
      }
    },
    activeMenu(menuTitle) {
      this.$store.dispatch('activeMenu', menuTitle);
    },
    goToCart() {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.ShoppingCart', params: { brand } })
        .catch(() => {
        });
    },
    goToProfile() {
      this.$router.push({
        name: `${this.$store.state.user.role}.Profile`,
      });
    },
    logout() {
      this.$store.dispatch('reset');
      localStorage.clear();
      const { brand } = this.$store.state;
      if (brand !== false) {
        this.$router.push({ name: 'Retail.Login', params: { brand } })
          .catch(() => {
          });
      } else {
        this.$router.push({ name: 'Login' })
          .catch(() => {
          });
      }
    },
    returnAdminDashboard() {
      this.$store.dispatch('setCurrentUser', this.$store.state.adminUser);
      this.$store.dispatch('resetCurrentAdminUser');
      localStorage.setItem('app', JSON.stringify(this.$store.state));
      this.$router.push({ name: `${this.$store.state.user.role}.PartnerList` });
    },
    loginAsPartner(userId) {
      this.$api.post(`admin/partner/${userId}/login`, {})
        .then((response) => {
          if (response.data.user) {
            const partner = response.data.user;
            partner.auth_token = `${userId}|${this.$store.state.user.auth_token}`;
            this.$store.dispatch('setCurrentAdminUser');
            this.$store.dispatch('setCurrentUser', partner);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({ name: `${this.$store.state.user.role}.Home` });
          }
        });
    },
    loginAsPartnerClient(userId) {
      this.$api.post(`partner/customer/${userId}/login`, {})
        .then((response) => {
          if (response.data) {
            window.location.href = `${response.data}&adminToken=${this.$store.state.user.auth_token}`;
          }
        });
    },
    viewPartnerInvoice(item) {
      this.$api.post(`admin/partner/${item.user_id}/login`, {})
        .then((response) => {
          if (response.data.user) {
            const partner = response.data.user;
            partner.auth_token = `${item.user_id}|${this.$store.state.user.auth_token}`;
            this.$store.dispatch('setCurrentAdminUser');
            this.$store.dispatch('setCurrentUser', partner);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            window.open(`${process.env.VUE_APP_API_URL}/v1/partner/tax-invoice/${item.id}/view?auth_token=${item.view_token}`, '_blank');
          }
        });
    },
    viewPartnerClientInvoice(item) {
      this.$api.post(`admin/partner/${item.parent_id}/login`, {})
        .then((response) => {
          if (response.data.user) {
            const partner = response.data.user;
            partner.auth_token = `${item.parent_id}|${this.$store.state.user.auth_token}`;
            this.$store.dispatch('setCurrentAdminUser');
            this.$store.dispatch('setCurrentUser', partner);
            this.$store.dispatch('showDashboard');
            localStorage.setItem('app', JSON.stringify(this.$store.state));
            this.$router.push({ name: 'Partner.ResellerCustomerInvoiceDetail', params: { id: item.id } });
          }
        });
    },
    loginCpanel(item) {
      this.$api.post('partner/service/login', {
        id: item.id,
      }).then((response) => {
        window.open(response.data.data.url, '_blank');
      });
    },
    updateCpanel(item) {
      this.$router.push({ name: 'Admin.ServiceCpanelUpdate', params: { id: item.id } });
    },
    viewDomain(item) {
      this.$router.push({ name: 'Admin.DomainDetail', params: { id: item.id } });
    },
    viewService(item) {
      this.$router.push({ name: 'Admin.ServiceDetail', params: { id: item.id } });
    },
  },
  mounted() {
    this.$api.interceptors.response.use((response) => {
      if (response.data.shopping_cart_id) {
        localStorage.setItem('shopping_cart_id', response.data.shopping_cart_id);
        this.$store.dispatch('setShoppingCartId', response.data.shopping_cart_id);
      }
      return response;
    }, (error) => {
      if (error.response) {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'error',
          message: error.response.data.message || error.response.data,
        });
      } else {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'error',
          message: error.message,
        });
      }

      if (error.response.status === 401) {
        this.logout();
      }
      return Promise.reject(error);
    });
  },
  created() {
    this.reloadMenu();
  },
  watch: {
    search(searchHint) {
      if (!searchHint || searchHint.length === 0) {
        this.items = [];
        return;
      }

      this.items = [];
      clearTimeout(this.timerId);

      // delay new call 500ms
      this.timerId = setTimeout(() => {
        this.smartSearching = true;
        this.$api.post('admin/services/smart-search', {
          search: searchHint,
        })
          .then((response) => {
            const list = response.data;
            list.forEach((item, index) => {
              const localItem = item;
              localItem.search = searchHint + index;
              this.items.push(localItem);
            });
          })
          .finally(() => {
            this.smartSearching = false;
          });
      }, 500);
    },
  },
};
</script>

<style>
  html {
    overflow-x: hidden;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0);
  }

  ::-webkit-scrollbar {
    background-color: rgba(0,0,0,0);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.2);
    border-radius: 10px;
  }

  .main .base {
    max-width: 100% !important;
    padding: 8px;
  }

  .v-application .main .v-btn.primary {
    /*border-color: #FF670D !important;*/
    /*background-color: #FF670D !important;*/
  }

  .main pre {
    white-space: pre-wrap;
  }

  .v-application .main .btn-group {
    min-height: 74px;
    padding-top: 10px;
  }

  .v-application .main .btn-group button {
    margin-right: 5px;
  }

  pre {
    font-family: Inter,sans-serif!important;
  }

  .line-through {
    text-decoration: line-through;
  }

  .discounted-price {
    color: #FF670D;
  }

  .text-success {
    color: green;
  }

  .text-error {
    color: #FF670D;
  }

  .currency-row {
    cursor: pointer;
  }

  .currency-selected {
    border: 1px solid #07f;
  }

  .v-toolbar__title {
    font-size: 1rem;
  }

  .orange {
    background-color: #FF670D;
  }

  .margin-small {
    margin: 0 10px;
  }

  body .v-navigation-drawer__content {
    height: calc(100% - 120px);
  }

  .left-menu .theme--light.v-list-item--active:before,
  .left-menu .theme--light.v-list-item--active:hover:before,
  .left-menu .theme--light.v-list-item--active:focus:before,
  .left-menu .theme--light.v-list-item:focus:before,
  .left-menu .theme--light.v-list-item:hover:before {
    opacity: 0;
  }

  .left-menu .v-list-item--link:before {
    background-color: unset;
    opacity: 1;
  }

  .left-menu.v-list--dense .v-list-item,
  .left-menu .v-list-item--dense {
    min-height: 52px;
  }

  .left-menu.v-list--dense .v-list-item .v-list-item__icon,
  .left-menu .v-list-item--dense .v-list-item__icon {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .left-menu.v-list .v-list-item--active,
  .left-menu.v-list .v-list-item--active:hover {
    border-left: 2px solid #0077ff !important;
  }

  .left-menu.v-list .v-list-item:hover {
    border-left: 2px solid rgba(0,0,0,.87);
  }

  .v-navigation-drawer__border {
    background-color: unset !important;
  }

  .sidebar-logo {
    width: 100%;
    padding-top: 6px;
  }
</style>

<template>
  <v-container
    fluid
  >
    <v-row dense no-gutters v-if="$store.state.brand != 'cldy'">
      <v-app-bar
        app
        dense
      >
        <img v-if="$store.state.brand === 'qoxy'" src="/qoxylogo.png">
        <v-spacer></v-spacer>
        <a class="header-btn" @click="gotoLogin()">LOGIN</a>
        <span>|</span>
        <a class="header-btn" @click="gotoLogin()">REGISTER</a>
        <v-badge
          color="green"
          :content="$store.state.shoppingCart.total_count + ''"
          overlap
          offset-x="20"
          offset-y="20"
        >
          <v-btn
            icon
            large
            @click="goToCart()"
          >
            <v-avatar
              size="32px"
              item
            >
              <v-icon>mdi-cart-outline</v-icon>
            </v-avatar>
          </v-btn>
        </v-badge>
      </v-app-bar>
    </v-row>
    <CldyHeader v-if="$store.state.brand == 'cldy'"></CldyHeader>
    <v-row
    >
      <v-col cols="1">
      </v-col>
      <v-col cols="10">
        <v-card
          outlined
        >
          <v-card-title>
            Pending Renewal:
          </v-card-title>
          <v-card-text>
            <v-row class="cart">
              <v-col cols="12">
              </v-col>
              <v-col cols="12">
                <v-row align="center" justify="center"
                       v-for="(item, index) in items" :key="item.id">
                  <v-col cols="12" lg="6" class="text-left">
                    <b>{{ item.service_identity }}</b> Expiry: {{ item.expiry_date }}
                  </v-col>
                  <v-col cols="12" lg="2" class="text-center">
                    <v-select
                      :items="item.product_prices"
                      v-model="item.default_term"
                      item-text="term_name"
                      solo
                      dense
                      @change="setSelectedItem(index)"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="8" lg="2" class="text-center">
                    ${{ item.price }}
                  </v-col>
                  <v-col cols="4" lg="2" class="text-center">
                    <v-btn color="error" :loading="loadings[index]"
                           @click="deleteItem(index)">
                      <v-icon
                        small
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" v-for="addon in item.addons" :key="addon.id">
                    <v-row>
                      <v-col cols="8" lg="6" class="text-left addon-text">
                        <b>Addon: {{ addon.service_identity }}</b>
                      </v-col>
                      <v-col cols="4" lg="2" class="text-center">
                        <v-select
                          :items="addon.product_prices"
                          v-model="item.default_term"
                          item-text="term_name"
                          solo
                          dense
                          readonly
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col cols="8" lg="2" class="text-center">
                        ${{ addon.price }}
                      </v-col>
                      <v-col cols="4" lg="2" class="text-center">
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row justify="center">
                  <v-col lg="8" cols="2" class="text-center">
                  </v-col>
                  <v-col lg="2" cols="2" class="text-center">
                  </v-col>
                  <v-col lg="2" cols="8" class="text-center">
                    <h3>Total: ${{ total }}</h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="payment" v-if="tokenValid">
              <v-col cols="12" lg="6">
                <stripe-payment
                  v-if="paymentMethod == 3"
                  :amount="shoppingCart.total_amount"
                  :card.sync="creditCard"
                  :invalid.sync="creditCardInvalid"
                ></stripe-payment>
                <paypal-payment
                  v-if="paymentMethod == 4"
                  :amount="shoppingCart.total_amount"
                  :orderid="paypalOrderId"
                  @interface="getChildInterface"
                  :saveasdefaultcard="false"
                  :card.sync="creditCard"
                  :invalid.sync="creditCardInvalid"
                ></paypal-payment>
              </v-col>
            </v-row>
            <v-row no-gutters dense v-if="tokenValid">
              <v-col cols="12" class="text-right">
                <v-btn
                  v-if="paymentMethod == 4"
                  color="primary" class="pay-btn"
                       :loading="loading"
                       :disabled="creditCardInvalid"
                       @click="submit">Checkout (Visa/Master)</v-btn>
                <v-btn
                  v-else
                  color="primary" class="pay-btn"
                       :loading="loading"
                       :disabled="creditCardInvalid"
                       @click="submit">Checkout</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-footer v-if="$store.state.brand != 'cldy'"
      dark
      padless
      :app="true"
      :fixed="true"
    >
      <v-card
        flat
        tile
        class="indigo lighten-1 white--text text-center"
        width="100%"
      >
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Powered By GoCloudEasy Pte Ltd</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <CldyFooter v-if="$store.state.brand == 'cldy'"></CldyFooter>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { create, all } from 'mathjs';
import { StripePayment, PaypalPayment } from '../components/payment_modules';
import CldyHeader from './CldyHeader.vue';
import CldyFooter from './CldyFooter.vue';

export default {
  name: 'ShoppingCart',
  components: {
    StripePayment,
    PaypalPayment,
    CldyHeader,
    CldyFooter,
  },
  data() {
    return {
      paymentMethod: '',
      tokenValid: false,
      valid: false,
      loading: false,
      unpaidLoading: false,
      loadings: [],
      items: [],
      paypalOrderId: '',
      saveAsDefaultCard: false,
      creditCardInvalid: true,
      creditCard: null,
      total: 0,
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
      firstInvoice: false,
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    localComputed() {
    },
    // mix this into the outer object with the object spread operator
    ...mapState({
      shoppingCart: 'shoppingCart',
    }),
  },
  created() {
    this.$api.post(`pending-renewal/${this.$route.params.token}`)
      .then((response) => {
        this.tokenValid = true;
        const localItems = [];
        response.data.forEach((item) => {
          if (item.product_prices && item.product_prices.length > 0) {
            localItems.push(item);
          }
        });
        this.$set(this, 'items', localItems);
        for (let i = 0; i < this.items.length; i += 1) {
          const item = this.items[i];
          for (let j = 0; j < item.product_prices.length; j += 1) {
            const price = this.items[i].product_prices[j];
            if (j === 0) {
              this.items[i].default_term = price.value;
              this.items[i].price = price.product_price;
            }

            if (parseInt(price.term_month, 0) === item.service_month_term) {
              this.items[i].default_term = price.value;
              this.items[i].price = price.product_price;
            }
          }
        }
        this.setSelectedItem();
      });

    this.$api.get(`has-no-invoice?token=${this.$route.params.token}`)
      .then((response) => {
        this.$set(this, 'firstInvoice', response.data.has_no_invoice);
      });

    this.$api.get('partner/default-payment-method').then((response) => {
      this.$set(this, 'paymentMethod', response.data);
      if (response.data === 4) {
        this.creditCardInvalid = false;
      }
    });
  },
  methods: {
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    deleteItem(index) {
      this.items.splice(index, 1);
      this.setSelectedItem();
    },
    submit() {
      this.loading = true;

      if (this.paymentMethod === 3) {
        if (this.creditCardInvalid) {
          this.loading = false;
          return;
        }
        this.$api.post(`express-checkout/${this.$route.params.token}`, {
          items: this.items,
          payment_type_id: this.paymentMethod,
        })
          .then((response) => {
            const stripePaymentObj = {
              payment_method: {
                card: this.creditCard,
              },
            };

            window.stripeClient.confirmCardPayment(response.data.client_secret, stripePaymentObj)
              .then(async (result) => {
                if (result.error) {
                  await this.$store.dispatch('addAlert', {
                    id: Date.now(),
                    type: 'error',
                    message: result.error,
                  });
                }

                if (result.paymentIntent.status === 'requires_capture') {
                  await this.$api.post(`express-checkout/${this.$route.params.token}`, {
                    payment_type_id: this.paymentMethod,
                    confirm: 1,
                    id: result.paymentIntent.id,
                  })
                    .then((subresponse) => {
                      if (this.firstInvoice) {
                        this.$api.get(`tax-invoice/${subresponse.data.invoice_id}/detail?express_token=${subresponse.data.view_token}`)
                          .then((subSubResponse) => {
                            const invoiceItems = [];

                            subSubResponse.data.invoiceItems.forEach((invoiceItem) => {
                              invoiceItems.push({
                                item_id: invoiceItem.id,
                                item_name: `${subSubResponse.data.invoiceDatetime} ${invoiceItem.invoiceLineDescription}`,
                                price: invoiceItem.subtotal,
                                discount: invoiceItem.discount,
                                tax: invoiceItem.tax,
                                quantity: 1,
                              });
                            });

                            const gtagObj = {
                              currency: window.systemCurrency.toUpperCase(),
                              value: subSubResponse.data.total,
                              discount: subSubResponse.data.discount,
                              tax: subSubResponse.data.tax,
                              items: invoiceItems,
                            };

                            if (this.$store.state.brand === 'cldy') {
                              window.gtag('event', 'purchase', gtagObj);
                            }

                            const successMessage = 'Your payment is submitted successfully.';

                            this.$store.dispatch('addAlert', {
                              id: Date.now(),
                              type: 'success',
                              message: successMessage,
                            });

                            setTimeout(() => {
                              window.location.href = `${process.env.VUE_APP_API_URL}/v1/tax-invoice/${subresponse.data.invoice_no}/view?view_token=${subresponse.data.view_token}&system_currency=${window.systemCurrency}`;
                            }, 500);
                          });
                      } else {
                        const successMessage = 'Your payment is submitted successfully.';

                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: successMessage,
                        });

                        window.location.href = `${process.env.VUE_APP_API_URL}/v1/tax-invoice/${subresponse.data.invoice_no}/view?view_token=${subresponse.data.view_token}&system_currency=${window.systemCurrency}`;
                      }
                    });
                }
              })
              .finally(() => {
                this.loading = false;
              });
          })
          .finally(() => {
            this.loading = false;
          });
      }

      if (this.paymentMethod === 4) {
        this.$api.post(`express-checkout/${this.$route.params.token}`, {
          items: this.items,
          payment_type_id: this.paymentMethod,
        })
          .then((response) => {
            this.$set(this, 'paypalOrderId', response.data.order_id);
            this.$nextTick(() => {
              this.$options.childInterface.checkout()
                .then((payload) => {
                  if (payload.liabilityShift !== 'NO'
                    && payload.authenticationStatus
                    && payload.authenticationStatus !== 'YES') {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: '3D Secure Authentication fails. Please retry again.',
                    });
                    this.loading = false;
                    return;
                  }

                  if (payload.liabilityShift === 'NO'
                    && payload.liabilityShifted === false
                    && payload.authenticationStatus
                    && payload.authenticationStatus === 'NO') {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: 'You have exited 3D Secure Authentication. Please retry again.',
                    });
                    this.loading = false;
                    return;
                  }

                  this.$api.post(`express-checkout/${this.$route.params.token}`, {
                    payment_type_id: this.paymentMethod,
                    confirm: 1,
                    id: payload.orderId,
                  })
                    .then((subresponse) => {
                      if (this.firstInvoice) {
                        this.$api.get(`tax-invoice/${subresponse.data.invoice_id}/detail?express_token=${subresponse.data.view_token}`)
                          .then((subSubResponse) => {
                            const invoiceItems = [];

                            subSubResponse.data.invoiceItems.forEach((invoiceItem) => {
                              invoiceItems.push({
                                item_id: invoiceItem.id,
                                item_name: `${subSubResponse.data.invoiceDatetime} ${invoiceItem.invoiceLineDescription}`,
                                price: invoiceItem.subtotal,
                                discount: invoiceItem.discount,
                                tax: invoiceItem.tax,
                                quantity: 1,
                              });
                            });

                            const gtagObj = {
                              currency: window.systemCurrency.toUpperCase(),
                              value: subSubResponse.data.total,
                              discount: subSubResponse.data.discount,
                              tax: subSubResponse.data.tax,
                              items: invoiceItems,
                            };

                            if (this.$store.state.brand === 'cldy') {
                              window.gtag('event', 'purchase', gtagObj);
                            }

                            const successMessage = 'Your payment is submitted successfully.';

                            this.$store.dispatch('addAlert', {
                              id: Date.now(),
                              type: 'success',
                              message: successMessage,
                            });

                            setTimeout(() => {
                              window.location.href = `${process.env.VUE_APP_API_URL}/v1/tax-invoice/${subresponse.data.invoice_no}/view?view_token=${subresponse.data.view_token}&system_currency=${window.systemCurrency}`;
                            }, 500);
                          });
                      } else {
                        this.$store.dispatch('addAlert', {
                          id: Date.now(),
                          type: 'success',
                          message: 'Your payment is submitted successfully.',
                        });
                        window.location.href = `${process.env.VUE_APP_API_URL}/v1/tax-invoice/${subresponse.data.invoice_no}/view?view_token=${subresponse.data.view_token}&system_currency=${window.systemCurrency}`;
                      }
                    });
                })
                .catch((error) => {
                  if (error.message.indexOf('semantically incorrect') > 0) {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: 'We are sorry, the transaction failed. Please check the card information or try with another card.',
                    });
                  } else {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'error',
                      message: `An error occurred ${error.message}. Please retry again.`,
                    });
                  }
                  this.loading = false;
                });
            });
          });
      }
    },
    gotoLogin() {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.Login', params: { brand } })
        .catch(() => {
        });
    },
    goToCart() {
      const { brand } = this.$store.state;
      if (this.$store.state.user.role) {
        this.$router.push({
          name: 'Retail.ShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      } else {
        this.$router.push({
          name: 'Retail.GuestShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      }
    },
    gotoHome() {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.Guest', params: { brand } })
        .catch(() => {
        });
    },
    setSelectedItem() {
      const config = {};
      const math = create(all, config);
      this.total = 0;

      this.items.forEach((item, index) => {
        item.product_prices.forEach((price) => {
          if (price.value === item.default_term) {
            this.items[index].price = price.product_price;
            this.total = math.format(math.add(this.total, price.product_price), {
              precision: 14,
            });
          }
        });

        item.addons.forEach((addon, addonIndex) => {
          addon.product_prices.forEach((price) => {
            if (price.value === item.default_term) {
              this.items[index].addons[addonIndex].price = price.product_price;
              this.total = math.format(math.add(this.total, price.product_price), {
                precision: 14,
              });
            }
          });
        });
      });
    },
  },
};
</script>

<style scoped>
.cart {
  max-height: 640px;
  overflow-y: scroll;
}

.pay-btn {
  margin-left: 10px;
}

.addon-text {
  padding-left: 20px;
  font-size: 12px;
}
</style>

<template>
  <v-row
    no-gutters
  >
    <v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
            'items-per-page-options': [15,30,60,100,150,250],
          }"
      >
        <template v-slot:top>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-text-field label="Customer Email" v-model="search['users.email']">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Customer First Name" v-model="search['users.first_name']">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Partner Email" v-model="search['u2.email']">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Partner First Name"
                                v-model="search['u2.first_name']">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Invoice No"
                                v-model="search['invoice_no']">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                    clearable
                    label="Commission Status"
                    v-model="search['partner_commission_status_id']"
                    :items="commissionStatusDropdown"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn small color="primary" @click="loadTable()">Search</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template v-slot:item.invoice_no="{ item }">
          <a :href="`https://manage.gocloudeasy.com/invoice.php?invoice_no=${item.invoice_no}&view_token=${item.view_token}`"
             target="_blank">{{ item.invoice_no }}</a>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.partner_commission_status_id === 3 ||
            item.partner_commission_status_id === 4"
            x-small
            class="mr-2"
            color="primary"
            @click="seeWithdrawDetail(item)"
          >
            Withdraw Detail
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      commissionStatusDropdown: [
        { text: 'Pending', value: 1 },
        { text: 'Withdrawable', value: 2 },
        { text: 'Withdrawn', value: 3 },
        { text: 'Processing', value: 4 },
      ],
      search: {
        'users.email': '',
        'users.first_name': '',
        'u2.email': '',
        'u2.first_name': '',
        invoice_no: '',
        affiliate_commission_status_id: '',
      },
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Client First Name', value: 'customer_first_name', sortable: true },
        { text: 'Client Email', value: 'customer_email', sortable: true },
        { text: 'Partner First Name', value: 'partner_first_name', sortable: true },
        { text: 'Partner Email', value: 'partner_email', sortable: true },
        { text: 'Invoice No', value: 'invoice_no', sortable: true },
        { text: 'Line Description', value: 'invoice_line_description', sortable: true },
        { text: 'Commission', value: 'amount', sortable: true },
        { text: 'Created At', value: 'created_at', sortable: true },
        { text: 'Status', value: 'partner_commission_status_name', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.loadTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/commission/list', {
        search: this.search,
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    seeWithdrawDetail(item) {
      this.$router.push({ name: 'Admin.PartnerCommissionWithdrawDetail', params: { id: item.withdraw_id } });
    },
  },
};
</script>

<style scoped>
</style>

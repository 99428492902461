<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="4">
                  <v-switch v-model="moduleData.enable" label="On/Off Domain Privacy"
                            :loading="togglePrivacyProcessing"
                            @click.stop="togglePrivacy()"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Manage',
  data() {
    return {
      togglePrivacyProcessing: false,
      moduleData: {
        id: null,
        service_id: null,
        enable: false,
      },
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || 'This field must be valid domain name.',
      },
    };
  },
  methods: {
    togglePrivacy() {
      this.togglePrivacyProcessing = true;
      let successMessage = '';
      let url = '';

      if (!this.moduleData.enable) {
        successMessage = 'Domain privacy is turning on.';
        url = `partner/domain/${this.moduleData.service_id}/enable-privacy`;
      } else {
        successMessage = 'Domain privacy is turning off.';
        url = `partner/domain/${this.moduleData.service_id}/disable-privacy`;
      }

      this.$api.post(url, {
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: successMessage,
        });
        this.moduleData.enable = !this.moduleData.enable;
      }).finally(() => {
        this.togglePrivacyProcessing = false;
      });
    },
  },
  mounted() {
    this.$underscore.each(this.parentModuleData, (propV, prop) => {
      if (typeof this.moduleData[prop] !== 'undefined') {
        this.$set(this.moduleData, prop, propV);
      }
    });
  },
  props: {
    readonly: {
      type: Boolean,
      default() {
        return true;
      },
    },
    parentModuleData: {
      type: Object,
      default() {
        return {
          id: '',
          service_id: '',
          enable: '',
        };
      },
    },
  },
  watch: {
    moduleData: {
      handler(newModuleData) {
        this.$emit('update:moduleData', newModuleData);
      },
      deep: true,
    },
    parentModuleData: {
      handler(newParentModuleData) {
        this.$underscore.each(newParentModuleData, (propV, prop) => {
          if (typeof this.moduleData[prop] !== 'undefined') {
            this.$set(this.moduleData, prop, propV);
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row
    no-gutters
  >
    <v-col>
      <v-expansion-panels :value="0">
        <v-expansion-panel
        >
          <v-expansion-panel-header>Advance Search</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col dense no-gutters cols="4">
                <v-text-field label="Email" v-model="searchEmail"></v-text-field>
              </v-col>
              <v-col dense no-gutters cols="4">
                <v-text-field label="First Name" v-model="searchFirstName"></v-text-field>
              </v-col>
              <v-col dense no-gutters cols="4">
                <v-text-field label="First Name" v-model="searchLastName"></v-text-field>
              </v-col>
              <v-col dense no-gutters cols="12">
                <v-btn depressed color="primary" @click="search">Search</v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
            'items-per-page-options': [15,30,60,100,150,250],
          }"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="review(item)"
          >
            mdi-eye
          </v-icon>
        </template>
        <template v-slot:item.affiliate_status_id="{ item }">
          <v-chip
            :label="true"
            x-small
            :color="affiliateStatusColor[item.affiliate_status_id]"
            text-color="white">{{ item.affiliate_status_name }}
          </v-chip>
        </template>
      </v-data-table>
      <v-dialog
        v-model="deleteDialog"
        max-width="320"
      >
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>Are you sure to delete this article?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="deleteDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              :loading="deleteLoading"
              :disabled="deleteLoading"
              @click="deleteRow"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Email', value: 'email' },
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Affiliate Code', value: 'affiliate_code' },
        { text: 'Affiliate Status', value: 'affiliate_status_id' },
        { text: 'Application Date', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      affiliateStatusColor: {
        1: 'orange',
        2: 'green',
        3: 'red',
      },
      searchEmail: '',
      searchFirstName: '',
      searchLastName: '',
    };
  },
  watch: {
    options: {
      handler() {
        this.loadTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/affiliate/list', {
        search: {
          email: this.searchEmail,
          first_name: this.searchFirstName,
          last_name: this.searchLastName,
        },
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    search() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/affiliate/list', {
        search: {
          email: this.searchEmail,
          first_name: this.searchFirstName,
          last_name: this.searchLastName,
        },
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    review(item) {
      this.$router.push({ name: 'Admin.AffiliateReview', params: { id: item.id } });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Year Month
            </th>
            <th class="text-left">
              Total
            </th>
            <th class="text-left">
              Action
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="yearmonth in yearmonths"
            :key="yearmonth.yearmonth"
          >
            <td>{{ yearmonth.yearmonth }}</td>
            <td>{{ yearmonth.total }}</td>
            <td>
              <v-btn
                color="primary"
                text
                @click="view(yearmonth.yearmonth)"
              >View Details</v-btn>
              <v-btn
                color="primary"
                text
                @click="download(yearmonth.yearmonth)"
              >Download CSV</v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PartnerCommissionListYearMonth',
  data() {
    return {
      yearmonths: [],
    };
  },
  created() {
    this.$api.get('partner/fcs-commission-months')
      .then((response) => {
        this.$set(this, 'yearmonths', response.data);
      });
  },
  methods: {
    view(yearmonth) {
      this.$router.push({ name: 'Partner.PartnerCommissionYearMonth', params: { yearmonth } })
        .catch(() => {
        });
    },
    download(yearmonth) {
      const authToken = window.btoa(this.$store.state.user.auth_token);
      window.open(`${process.env.VUE_APP_API_URL}/v1/partner/download-fcs-commissions?yearmonth=${yearmonth}&auth_token=${authToken}`, '_blank');
    },
  },
};
</script>

<style scoped>

</style>

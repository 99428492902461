<template>
  <v-banner
    v-if="!hide && promoRunning && (discountRateReferral > 0 || discountRateFriend > 0)"
    color="indigo"
    dark
    rounded
  >
    <span v-if="discountRateReferral > 0">
      Referral Promotion
      <span class="promo">{{ discountRateReferral }}% OFF</span> for your first purchase.<br>
    </span>
    <span v-if="discountRateFriend > 0">
      Friend Promotion
      <span class="promo">{{ discountRateFriend }}% OFF</span> for your first purchase.<br>
    </span>
  </v-banner>
</template>

<script>
export default {
  name: 'PromoBanner',
  created() {
    this.$api.post('get-promo', {
      product_category_id: this.productCategoryId,
      operation: this.operationId,
    }).then((response) => {
      this.promoRunning = response.data.valid;
      this.discountRateReferral = response.data.referral;
      this.discountRateFriend = response.data.friend;
      this.discountRatePromo = response.data.promo;
    });
  },
  data() {
    return {
      promoRunning: false,
      discountRateReferral: 0,
      discountRateFriend: 0,
      discountRatePromo: 0,
    };
  },
  props: ['productCategoryId', 'operationId', 'hide'],
};
</script>

<style scoped>
  .promo {
    color: #FF670D;
    font-weight: bold;
    font-size: 1.2rem;
  }
</style>

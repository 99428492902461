<template>
  <v-row>
    <v-col cols="12" v-if="!hidePromoBanner">
      <promo-banner product-category-id="1000000" operation-id="3"></promo-banner>
    </v-col>
    <v-col cols="12">
      <v-row v-if="!noCheckout" dense align="center">
        <v-col cols="8" lg="6">
          <v-text-field
            dense
            outlined
            label="Domain"
            v-model="form.domain"
            :error-messages="domainErr"
            :rules="[rules.domain]"
            v-on:blur="processDomain"
          ></v-text-field>
        </v-col>
        <v-col cols="4" lg="2">
          <v-select label="TLD"
            dense
            outlined
            :items="tld_list"
            v-model="form.tld"
            @change="changeTld()"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="4" class="btn-group">
          <v-btn
            small
            color="primary"
            :loading="checkDomainLoading"
            :disabled="checkDomainLoading || checkDomainInvalid"
            @click="checkDomain()">Check availability</v-btn>
          <v-btn
            small
            color="primary"
            @click="showDomainPriceTable()">Show Price Table</v-btn>
        </v-col>
      </v-row>
      <v-row dense v-if="showRegistrationForm">
        <v-col cols="6">
          <p class="green--text">
            <b>Domain {{ full_domain }} is available for registration</b>
          </p>
        </v-col>
      </v-row>
      <v-row dense v-if="domainErr">
        <v-col cols="6">
          <p class="red--text">
            <b>Domain {{ full_domain }} is not available</b>
          </p>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <h2>Popular Domain Extensions</h2>
            </v-col>
          </v-row>
          <v-row v-for="otherTld in otherTlds" :key="otherTld.domain">
            <v-col cols="6">
              <b>{{ otherTld.domain }}</b>
              <v-progress-circular
                v-if="otherTld.status === 'checking'"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <span class="green--text" v-if="otherTld.status === 'avail'">
                <b style="margin-right:10px;"> is available for registration
                  at ${{ otherTld.price }}/year</b>
                <v-btn
                  x-small
                  color="primary"
                  @click="processPopularDomain(otherTld.domain)">Register</v-btn>
              </span>
              <span class="red--text" v-if="otherTld.status === 'unavail'">
                <b> is not available</b>
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense align="center" v-if="showRegistrationForm">
        <v-col cols="12">
          <v-form
            ref="form"
            v-model="valid"
          >
          <v-row dense>
            <v-col cols="6">
              <v-select label="Term"
                dense
                outlined
                :rules="[rules.required]"
                :items="terms"
                v-model="form.product_term"
                @change="updateDomainPrice"
              >
                <template v-slot:selection="{ item }">
                  <div v-html="item.html"></div>
                </template>
                <template v-slot:item="{ item }">
                  <div v-html="item.html"></div>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="false">
            <v-col cols="3">
              <v-text-field
                label="NS1"
                v-model="form.ns1"
                :rules="[rules.required, rules.nameserver]"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="NS2"
                v-model="form.ns2"
                :rules="[rules.required, rules.nameserver]"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="false">
            <v-col cols="3">
              <v-text-field
                label="NS3"
                v-model="form.ns3"
                :rules="[rules.nameserver]"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="NS4"
                v-model="form.ns4"
                :rules="[rules.nameserver]"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="NS5"
                v-model="form.ns5"
                :rules="[rules.nameserver]"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <h5>Registrant Contact</h5>
              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    v-if="defaultContact"
                    x-small
                    color="success"
                    @click="copyContact('defaultContact', 'registrant')">Use default contact</v-btn>
                  <v-btn
                    v-if="typeof $store.state.user.auth_token !== 'undefined'
                    && $store.state.user.auth_token !== null"
                    x-small
                    color="success"
                    @click="copyContact('profile', 'registrant')">Use profile</v-btn>
                  <v-btn
                    v-if="typeof $store.state.user.auth_token !== 'undefined'
                    && $store.state.user.auth_token !== null"
                    x-small
                    color="warning"
                    @click="clearContact('registrant')">Clear</v-btn>
                </v-col>
              </v-row>
              <component
                v-bind:is="registrantComponent"
                ref="registrantProfileComp"
                :forceOrg="forceOrgAdmin"
                :setNewProfile="registrant"
              ></component>
            </v-col>
          </v-row>
          <addon
            :key="addonComponentKey"
            :addonData.sync="addonData"
            :parentProductId="selectedProductId"
            :parentTermId="selectedProductPrice"
            :parentProductTerms="parentProductTerms"
            v-on:addonPriceUpdate="updateAddonPrice"
          ></addon>
          <v-col class="text-right" v-if="!noCheckout">
            <v-btn
              text-align="right"
              color="primary"
              @click="checkout()"
              :disabled="checkoutDisabled && !checkoutProcessing"
              :loading="checkoutProcessing"
            >Add to cart ${{ total }}</v-btn>
          </v-col>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="showPriceTable" scrollable max-width="1024px">
      <v-card>
        <v-card-title>Price Table</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 1024px;">
          <v-simple-table dense fixed-header>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">TLD</th>
                <th class="text-left">1 Year</th>
                <th class="text-left">2 Years</th>
                <th class="text-left">3 Years</th>
                <th class="text-left">4 Years</th>
                <th class="text-left">5 Years</th>
                <th class="text-left">6 Years</th>
                <th class="text-left">7 Years</th>
                <th class="text-left">8 Years</th>
                <th class="text-left">9 Years</th>
                <th class="text-left">10 Years</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="tld in tldPriceList" :key="tld.name">
                  <td><b>{{ tld.tld }}</b></td>
                  <td v-for="(year, index) in years" :key="index">
                    <div v-if="tld.terms[year]">
                      <span :class="{ 'line-through': tld.terms[year].discount > 0 }">
                        ${{ tld.terms[year].price }}
                      </span>
                      <span class="discounted-price"
                              v-if="tld.terms[year].discount > 0">
                        ${{ tld.terms[year].discounted_price }}<br>
                        {{ tld.terms[year].discount_name }} ${{ tld.terms[year].discount_rate }}%OFF
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Profile from '../../components/Profile.vue';
import ProfileSg from '../../components/ProfileSg.vue';
import ProfileAu from '../../components/ProfileAu.vue';
import PromoBanner from '../../components/PromoBanner.vue';
import Addon from '../../components/Addon.vue';

export default {
  name: 'Add',
  components: {
    Profile,
    ProfileSg,
    ProfileAu,
    PromoBanner,
    Addon,
  },
  async created() {
    if (this.domain) {
      this.domain = this.domain.replace('www.', '');
      this.form.domain = this.domain;
    }

    if (this.useLocalStorage) {
      this.registrant = JSON.parse(localStorage.getItem('DomainRegistrantCache'));
    }

    await this.$api.get('partner/domain/price-table?operation=3').then((response) => {
      this.tldPriceList = response.data;
    });

    this.$api.get('partner/domain/default-contact').then((response) => {
      const { profile } = response.data;

      if (typeof this.$store.state.user.auth_token !== 'undefined'
        && this.$store.state.user.auth_token !== null) {
        if (profile !== null && !this.$underscore.isEmpty(profile.email)) {
          this.defaultContact = profile;
          this.copyContact('defaultContact', 'registrant');
        }
      }
    });

    this.$api.get('partner/settings').then((response) => {
      if (this.noCheckout === true && this.$store.state.brand === 'cldy') {
        if (window.systemCurrency && window.systemCurrency === 'aud') {
          this.form.ns1 = 'ns1.au.cldy.com';
          this.form.ns2 = 'ns2.au.cldy.com';
          this.form.ns3 = 'ns3.au.cldy.com';
        } else {
          this.form.ns1 = 'ns4.cldy.com';
          this.form.ns2 = 'ns5.cldy.com';
          this.form.ns3 = 'ns6.cldy.com';
        }
      } else {
        this.form.ns1 = response.data.ns1;
        this.form.ns2 = response.data.ns2;
        this.form.ns3 = response.data.ns3;
        this.form.ns4 = response.data.ns4;
        this.form.ns5 = response.data.ns5;
      }
    });

    this.$api.get('partner/domain/tld').then((response) => {
      const tldList = response.data;
      tldList.forEach((item, index) => {
        this.tld_list.push({
          text: `.${item.tld}`,
          value: item.id,
        });

        if (this.tld) {
          if (this.tld === item.tld) {
            this.form.tld = item.id;
            this.form.tld_name = item.tld;
            this.changeTld();
            this.checkDomain();
          }
        } else if (index === 0) {
          this.form.tld = item.id;
          this.form.tld_name = item.tld;
          this.changeTld();
        }
      });
    });
  },
  data() {
    return {
      total: 0,
      totalDomain: 0,
      totalAddon: 0,
      addonComponentKey: 0,
      addonData: null,
      selectedProductId: null,
      selectedProductPrice: null,
      parentProductTerms: [],
      form: {
        domain: '',
        product_id: null,
        product_term: null,
        tld: null,
        tld_name: null,
        auto_renew: false,
        ns1: '',
        ns2: '',
        ns3: '',
        ns4: '',
        ns5: '',
        registrant: {},
      },
      currentContactMethod: 'defaultContact',
      years: [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
      ],
      full_domain: '',
      needValidNRIC: false,
      defaultContact: null,
      registrant: null,
      admin: null,
      tech: null,
      billing: null,
      domainErr: null,
      tld_list: [],
      tld_value: '',
      tldPriceList: [],
      terms: [],
      checkDomainLoading: false,
      checkDomainInvalid: true,
      checkoutProcessing: false,
      checkoutDisabled: false,
      showPriceTable: false,
      showRegistrationForm: false,
      registrantComponent: Profile,
      adminComponent: Profile,
      forceOrgAdmin: false,
      techComponent: Profile,
      billingComponent: Profile,
      successMessage:
        'Your registration request is successfully added to the shopping cart.',
      valid: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?/i).test(value) || 'This field must be valid domain name.',
        nameserver: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
      },
      otherTlds: [],
    };
  },
  props: {
    noCheckout: {
      type: Boolean,
      default: false,
    },
    noCheckoutDomain: {
      type: String,
    },
    noCheckoutTld: {
      type: String,
    },
    domain: {
      type: String,
    },
    tld: {
      type: String,
    },
    hidePromoBanner: {
      type: Boolean,
      default: false,
    },
    useLocalStorage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateDomainPrice() {
      let total = 0;
      this.terms.forEach((priceTerm) => {
        if (parseInt(priceTerm.value, 0) === parseInt(this.form.product_term, 0)) {
          total += +priceTerm.price;
        }
      });
      this.totalDomain = +total;
    },
    updateAddonPrice(amount) {
      this.totalAddon = +amount;
    },
    processDomain() {
      if (this.form.domain.indexOf('.') > 0) {
        this.form.domain = this.form.domain.replace('www.', '');
        const tld = `.${this.form.domain.split(/\./).slice(1).join('.')}`;
        this.$underscore.map(this.tld_list, (tldPrice) => {
          if (tld === tldPrice.text) {
            this.$set(this.form, 'tld', tldPrice.value);
            this.changeTld();
            this.$set(this.form, 'domain', this.form.domain.split(/\./)[0]);
            this.checkDomain();
          }
        });
      }
    },
    processPopularDomain(domain) {
      if (domain.indexOf('.') > 0) {
        const tld = `.${domain.split(/\./).slice(1).join('.')}`;
        this.$underscore.map(this.tld_list, (tldPrice) => {
          if (tld === tldPrice.text) {
            this.$set(this.form, 'tld', tldPrice.value);
            this.changeTld();
            this.$set(this.form, 'domain', domain.split(/\./)[0]);
            this.checkDomain();
          }
        });
      }
    },
    checkDomain() {
      this.showRegistrationForm = false;
      this.domainErr = '';
      this.checkDomainLoading = true;
      this.$set(this, 'terms', []);
      this.$set(this, 'parentProductTerms', []);
      this.$underscore.map(this.tldPriceList, (tld) => {
        if (tld.tld_id === this.form.tld) {
          this.form.tld_name = tld.tld;
          this.tld_value = tld.tld;
          this.copyContact(this.currentContactMethod, 'registrant');
          this.full_domain = `${this.form.domain}.${this.form.tld_name}`;
          this.$underscore.map(tld.terms, (term, index) => {
            this.form.product_term = tld.terms[1].term_id;
            let yearPrice = index === '1' ? 'Year' : 'Years';
            let htmlText = `${index} ${yearPrice}`;
            yearPrice = `${yearPrice} $${term.price}`;
            let domainPrice = term.price;

            const termYearMap = {
              5: 1,
              6: 2,
              7: 3,
              8: 4,
              9: 5,
              10: 6,
              11: 7,
              12: 8,
              13: 9,
              14: 10,
            };

            if (term.discount > 0) {
              const yearlyPrice = term.discounted_price / termYearMap[term.term_id];
              htmlText += `&nbsp<span class="line-through">$${term.price}</span>
                        <span class="discounted-price">$${term.discounted_price}</span>
                        <span class="discounted-price">${term.discount_name} ${term.discount_rate}%OFF</span> ($${yearlyPrice}/year)`;
              domainPrice = term.discounted_price;
            } else {
              const yearlyPrice = term.price / termYearMap[term.term_id];
              htmlText += ` $${term.price}  ($${yearlyPrice}/year)`;
            }

            const termItem = {
              text: `${index} ${yearPrice}`,
              html: htmlText,
              value: term.term_id,
              price: domainPrice,
            };

            this.terms.push(termItem);
            this.parentProductTerms.push(termItem.value);
            if (!this.selectedProductPrice) {
              this.selectedProductPrice = termItem.value;
              this.updateDomainPrice();
            }
          });

          this.selectedProductId = tld.id;
          this.addonComponentKey += 1;
        }
      });

      this.$api.post('partner/domain/check-availability', this.form)
        .then((response) => {
          if (response.data.avail) {
            this.loadRegistrationForm();
          } else {
            this.showRegistrationForm = false;
            this.domainErr = 'The domain is not available.';
            if (response.data.more) {
              this.checkOtherTlds();
            }
          }
        })
        .catch(() => {
          this.showRegistrationForm = false;
          this.domainErr = 'The domain is not available.';
        })
        .finally(() => {
          this.checkDomainLoading = false;
        });
    },
    checkOtherTlds() {
      this.otherTlds.splice(0, this.otherTlds.length);
      const tlds = [
        '.com.sg',
        '.sg',
        '.com',
        '.net',
        '.org',
        '.co',
        '.asia',
      ];
      const tldPrices = {};

      this.$underscore.map(this.tldPriceList, (tld) => {
        this.$underscore.map(tld.terms, (term) => {
          if (term.term_id === 5) {
            tldPrices[`.${tld.tld}`] = term.discounted_price;
          }
        });
      });

      tlds.forEach((tld) => {
        if (tld === `.${this.tld_value}`) {
          return;
        }

        this.otherTlds.push({
          domain: `${this.form.domain}${tld}`,
          tldId: this.form.tld,
          price: tldPrices[tld],
          status: 'checking',
        });

        const tldIndex = this.otherTlds.length - 1;

        let checkUrl = 'https://manage.cldy.com/guard.php';

        if (this.$store.state.brand === 'qoxy') {
          checkUrl = 'https://manage.qoxy.com/guard.php';
        }

        if (this.$store.state.adminUser.role && this.$store.state.adminUser.role === 'Admin') {
          checkUrl = 'https://manage.gocloudeasy.com/guard.php';
        }

        this.$api.post(checkUrl, {
          domain: `${this.form.domain}${tld}`,
          url: '/v1/tool/check-avail',
        })
          .then((response) => {
            if (parseInt(response.data.avail, 10) === 0) {
              this.otherTlds[tldIndex].status = 'avail';
            } else {
              this.otherTlds[tldIndex].status = 'unavail';
            }
          })
          .catch(() => {
            this.otherTlds[tldIndex].status = 'unavail';
          })
          .finally(() => {
          });
      });
    },
    loadRegistrationForm() {
      this.domainErr = null;
      this.showRegistrationForm = true;
    },
    showDomainPriceTable() {
      this.showPriceTable = true;
    },
    checkout() {
      this.checkoutProcessing = true;
      this.checkoutDisabled = true;
      if (this.$refs.form.validate()) {
        this.$underscore.map(this.tldPriceList, (value) => {
          if (value.tld_id === this.form.tld) {
            this.form.product_id = value.id;
          }
        });
        this.form.registrant = this.$refs.registrantProfileComp.getProfileData();
        this.form.addons = this.addonData;

        if (this.$store.state.shoppingCartId) {
          this.form.shopping_cart_id = this.$store.state.shoppingCartId;
        }

        this.$api.post('partner/domain/register', this.form)
          .then((data) => {
            // eslint-disable-next-line no-undef
            if (this.$store.state.brand === 'cldy') {
              window.gtag('event', 'add_payment_info', {
                currency: window.systemCurrency.toUpperCase(),
                total: this.total,
              });
            }

            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: this.successMessage,
            });
            this.$store.dispatch('syncShoppingCart', data.data);
            setTimeout(() => {
              this.goToCart();
            }, 500);
          })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.checkoutProcessing = false;
            this.checkoutDisabled = false;
          });
      } else {
        this.checkoutProcessing = false;
        this.checkoutDisabled = false;
      }
    },
    clearContact(to) {
      this[to] = {
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: 'SG',
        postal_code: '',
        phone_country_code: '',
        phone: '',
        fax_country_code: '',
        fax: '',
        special_field1: 'p',
        special_field2: '',
      };
      const sgTld = [
        'com.sg',
      ];
      const auTld = [
        'com.au',
        'org.au',
        'net.au',
      ];
      if (sgTld.indexOf(this.tld_value) >= 0 || auTld.indexOf(this.tld_value) >= 0) {
        this[to].special_field1 = 'o';
      }
    },
    copyContact(from, to) {
      if (this.currentContactMethod !== from) {
        this.currentContactMethod = from;
      }

      if (from === 'defaultContact') {
        this[to] = this.defaultContact;
        const sgTld = [
          'sg',
          'com.sg',
        ];

        const auTld = [
          'au',
          'com.au',
          'org.au',
          'net.au',
          'id.au',
        ];

        if (sgTld.indexOf(this.tld_value) >= 0) {
          if (this.defaultContact) {
            if (this.$underscore.isEmpty(this.defaultContact.corporate_no)) {
              this[to].special_field1 = 'p';
              this[to].special_field2 = this.defaultContact.personal_identity_no;
            } else if (this.defaultContact.corporate_no) {
              this[to].special_field1 = 'o';
              this[to].special_field2 = this.defaultContact.corporate_no;
            }
          }
        }

        if (auTld.indexOf(this.tld_value) >= 0) {
          if (this.defaultContact) {
            if (this.$underscore.isEmpty(this.defaultContact.corporate_no)) {
              this[to].special_field1 = 'p';
            } else {
              this[to].special_field1 = 'o';
              this[to].special_field2 = this.defaultContact.corporate_no;
            }
          }
        }
      } else if (from === 'profile') {
        this.$api.get('partner/profile').then((response) => {
          this[to] = {};
          const sgTld = [
            'com.sg',
          ];
          const auTld = [
            'com.au',
            'org.au',
            'net.au',
          ];
          if (sgTld.indexOf(this.tld_value) >= 0 || auTld.indexOf(this.tld_value) >= 0) {
            this[to].special_field1 = 'o';
          } else {
            this[to].special_field1 = 'p';
          }
          this[to].first_name = response.data.first_name;
          this[to].last_name = response.data.last_name;
          this[to].company_name = response.data.company_name;
          this[to].email = response.data.email;
          this[to].address1 = response.data.address1;
          this[to].city = response.data.city;
          this[to].state = response.data.state;
          this[to].country = response.data.country;
          this[to].postal_code = response.data.postal_code;
          this[to].phone = response.data.phone;
          this[to].phone_country_code = response.data.phone_country_code;
        });
      } else {
        this[to] = this.$refs[`${from}ProfileComp`].getProfileData();
      }
    },
    goToCart() {
      const { brand } = this.$store.state;
      if (this.$store.state.user.role) {
        this.$router.push({
          name: 'Retail.ShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      } else {
        this.$router.push({
          name: 'Retail.GuestShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      }
    },
    changeTld() {
      this.showRegistrationForm = false;
      this.domainErr = null;
      this.tld_list.forEach((tld) => {
        if (this.form.tld === tld.value) {
          if (tld.text === '.sg') {
            this.registrantComponent = ProfileSg;
            this.adminComponent = ProfileSg;
            this.forceOrgAdmin = false;
            this.techComponent = ProfileSg;
            this.billingComponent = ProfileSg;
          } else if (tld.text === '.com.sg') {
            this.registrantComponent = ProfileSg;
            this.adminComponent = ProfileSg;
            this.forceOrgAdmin = true;
            this.techComponent = ProfileSg;
            this.billingComponent = ProfileSg;
          } else if (tld.text === '.au' || tld.text === '.id.au') {
            this.registrantComponent = ProfileAu;
            this.adminComponent = ProfileAu;
            this.forceOrgAdmin = false;
            this.techComponent = ProfileAu;
            this.billingComponent = ProfileAu;
          } else if (tld.text === '.com.au' || tld.text === '.org.au' || tld.text === '.net.au' || tld.text === '.asn.au') {
            this.registrantComponent = ProfileAu;
            this.adminComponent = ProfileAu;
            this.forceOrgAdmin = true;
            this.techComponent = ProfileAu;
            this.billingComponent = ProfileAu;
          } else {
            this.registrantComponent = Profile;
            this.adminComponent = Profile;
            this.forceOrgAdmin = false;
            this.techComponent = Profile;
            this.billingComponent = Profile;
          }
        }
      });
    },
    activate(domain, tldModuleId) {
      this.$set(this.form, 'domain', domain);
      this.$set(this.form, 'tld', tldModuleId);
      this.$set(this, 'terms', []);
      this.changeTld();
      this.$underscore.map(this.tldPriceList, (tld) => {
        if (tld.tld_id === this.form.tld) {
          this.tld_value = tld.tld;
          this.copyContact(this.currentContactMethod, 'registrant');
          this.$underscore.map(tld.terms, (term, index) => {
            this.form.product_term = tld.terms[1].term_id;
            let yearPrice = index === '1' ? 'Year' : 'Years';
            let htmlText = `${index} ${yearPrice}`;
            yearPrice = `${yearPrice} $${term.price}`;
            let domainPrice = term.price;

            if (term.discount > 0) {
              htmlText += `&nbsp<span class="line-through">$${term.price}</span>
                        <span class="discounted-price">$${term.discounted_price}</span>
                        <span class="discounted-price">${term.discount_name} ${term.discount_rate}%OFF</span>`;
              domainPrice = term.discounted_price;
            } else {
              htmlText += `$${term.price}`;
            }

            const termItem = {
              text: `${index} ${yearPrice}`,
              html: htmlText,
              value: term.term_id,
              price: domainPrice,
            };

            this.terms.push(termItem);

            this.parentProductTerms.push(termItem.value);
            if (!this.selectedProductPrice) {
              this.selectedProductPrice = termItem.value;
              this.updateDomainPrice();
            }
          });
          this.selectedProductId = tld.id;
          this.addonComponentKey += 1;
        }
      });
      this.loadRegistrationForm();
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    getFormData() {
      this.$underscore.map(this.tldPriceList, (value) => {
        if (value.tld_id === this.form.tld) {
          this.form.product_id = value.id;
        }
      });
      this.form.registrant = this.$refs.registrantProfileComp.getProfileData();
      this.form.addons = this.addonData;
      // this.form.admin = this.$refs.adminProfileComp.getProfileData();
      // this.form.tech = this.$refs.techProfileComp.getProfileData();
      // this.form.billing = this.$refs.billingProfileComp.getProfileData();
      return this.form;
    },
  },
  watch: {
    totalAddon: {
      handler(newData) {
        this.total = +newData + +this.totalDomain;
        this.$emit('updateDomainTotal', this.total);
      },
    },
    totalDomain: {
      handler(newData) {
        this.total = +newData + +this.totalAddon;
        this.$emit('updateDomainTotal', this.total);
      },
    },
    'form.domain': {
      handler(domain) {
        if (this.rules.domain(domain) !== true) {
          this.checkDomainInvalid = true;
        } else {
          this.checkDomainInvalid = false;
          this.domainErr = null;
        }
      },
    },
  },
};
</script>

<style scoped>
</style>

import CpBase from './CpBase.vue';
import CpLogin from './CpLogin.vue';
import CpLoginCldy from './CpLoginCldy.vue';
import CpLoginCpanel from './CpLoginCpanel.vue';

export default {
  CpBase,
  CpLogin,
  CpLoginCldy,
  CpLoginCpanel,
};

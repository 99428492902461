<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.transfer_queue_status="{ item }">
          <v-btn x-small color="warning"
                 v-if="item.transfer_queue_status === 'pending'">
            {{ item.transfer_queue_status }}
          </v-btn>
          <v-btn x-small color="success"
                 v-if="item.transfer_queue_status === 'complete'">
            {{ item.transfer_queue_status }}
          </v-btn>
          <v-btn x-small color="error"
                 v-if="item.transfer_queue_status === 'rejected'">
            {{ item.transfer_queue_status }}
          </v-btn>
          <v-btn x-small color="error"
                 v-if="item.transfer_queue_status === 'cancelled'">
            {{ item.transfer_queue_status }}
          </v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn v-if="item.transfer_queue_status === 'pending'" x-small color="error"
                 @click="cancel(item.id)">Cancel</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'List',
  data() {
    return {
      search: '',
      initiated: false,
      totalItems: 0,
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'First Name', value: 'first_name', sortable: true },
        { text: 'Last Name', value: 'last_name', sortable: true },
        { text: 'Email', value: 'email', sortable: true },
        { text: 'Service Name', value: 'service_name', sortable: true },
        { text: 'Domain', value: 'service_identity', sortable: true },
        { text: 'Status', value: 'transfer_queue_status', sortable: true },
        { text: 'Reject Reason', value: 'reject_reason', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    this.initiated = true;
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('partner/customer/transfer-queue/list', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      })
        .then((response) => {
          this.totalItems = response.data.total;
          this.items = response.data.data;
          this.loading = false;
        });
    },
    cancel(itemId) {
      const confirmCancel = window.confirm('Are you sure to cancel the transfer?');
      if (confirmCancel) {
        this.$api.post(`partner/service-transfer/cancel/${itemId}`)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Your transfer request is cancelled successfully.',
            });
            this.loadTable();
          });
      }
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row dense no-gutters v-if="$store.state.brand != 'cldy'">
      <v-app-bar
        app
        dense
      >
        <img v-if="$store.state.brand === 'qoxy'" src="/qoxylogo.png">
        <v-spacer></v-spacer>
        <a class="header-btn" @click="gotoLogin()">LOGIN</a>
        <span>|</span>
        <a class="header-btn" @click="gotoLogin()">REGISTER</a>
        <v-badge
          color="green"
          :content="$store.state.shoppingCart.total_count + ''"
          overlap
          offset-x="20"
          offset-y="20"
        >
          <v-btn
            icon
            large
            @click="goToCart()"
          >
            <v-avatar
              size="32px"
              item
            >
              <v-icon>mdi-cart-outline</v-icon>
            </v-avatar>
          </v-btn>
        </v-badge>
      </v-app-bar>
    </v-row>
    <CldyHeader v-if="$store.state.brand == 'cldy'"></CldyHeader>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Billing System</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form"
                    v-model="formValid">
              <v-text-field
                label="Password"
                name="password"
                prepend-icon="mdi-key"
                type="password"
                v-model="password"
                :rules="[rules.strongpassword]"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="processing"
              :disabled="processing"
              @click="reset()"
            >Reset</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-footer v-if="$store.state.brand != 'cldy'"
      dark
      padless
      :app="true"
      :fixed="true"
    >
      <v-card
        flat
        tile
        class="indigo lighten-1 white--text text-center"
        width="100%"
      >
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Powered By GoCloudEasy Pte Ltd</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <CldyFooter v-if="$store.state.brand == 'cldy'"></CldyFooter>
  </v-container>
</template>

<script>
import CldyHeader from './CldyHeader.vue';
import CldyFooter from './CldyFooter.vue';

export default {
  name: 'ResetPassword',
  components: {
    CldyHeader,
    CldyFooter,
  },
  data() {
    return {
      formValid: false,
      password: '',
      processing: false,
      error: {
        password: false,
      },
      errorMessage: {
        password: '',
      },
      valid: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        strongpassword: (value) => this.checkPasswordStrong(value)
          || 'You need at least 1 upper case character, 1 digits, 1 special symbol [?!@#$%^&*:;.,] and minimum length 10',
      },
    };
  },
  mounted() {
    this.$api.post('check-reset-password-token', {
      reset_password_token: this.$route.params.token,
    }).then(() => {
      this.valid = true;
    }).finally(() => {
    });
  },
  methods: {
    reset() {
      if (this.valid) {
        if (this.$refs.form.validate()) {
          this.processing = true;
          this.$api.post('reset-password', {
            reset_password_token: this.$route.params.token,
            password: this.password,
          }).then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Your password has been reset successfully.',
            });
            setTimeout(() => {
              const { brand } = this.$store.state;
              this.$router
                .push({ name: 'Retail.Login', params: { brand } })
                .catch(() => {
                });
            }, 1000);
          }).finally(() => {
            this.processing = false;
          });
        }
      }
    },
    checkPasswordStrong(password) {
      let patt = /[A-Z]/g;
      let result = patt.test(password);
      if (!result) {
        return false;
      }

      patt = /[0-9]/g;
      result = patt.test(password);
      if (!result) {
        return false;
      }

      patt = /[?!@#$%^&*:;.,]/g;
      result = patt.test(password);
      if (!result) {
        return false;
      }

      if (password.length < 10) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>

</style>

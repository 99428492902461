<template>
  <v-row
    no-gutters
    dense
  >
    <v-col
      cols="12"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card
          outlined
        >
          <v-card-text>
            <v-row
              dense
              no-gutters
            >
              <v-col
                cols="12"
              >
                <profile
                  ref="partnerProfile"
                  :emailLock="true"
                  :setNewProfile="profile"
                ></profile>
                <v-row
                  dense
                >
                  <v-col
                    cols="6"
                    class="text-right"
                  >
                    <v-text-field
                      dense
                      label="New Password"
                      v-model="newPassword"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.strongpassword]"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      color="primary"
                      :loading="processing"
                      @click="submitForm"
                    >Submit</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense no-gutters>
              <v-col cols="12" v-if="ccLinkages.length > 0">
                <v-row dense no-gutters>
                  <v-col cols="12">
                    <h3>These are the list of email address that will receive a copy of all the
                      emails our system sends out:
                    </h3>
                  </v-col>
                  <v-col cols="12" v-for="ccLinkage in ccLinkages" :key="ccLinkage.id">
                    {{ ccLinkage.user_linkage_value }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="emailLinkages.length > 0">
                <v-row dense no-gutters>
                  <v-col cols="12">
                    <h3>These are the list of email address that are authorised to obtain hosting
                      account password reset and hosting administrative changes:
                    </h3>
                  </v-col>
                  <v-col cols="12" v-for="emailLinkage in emailLinkages" :key="emailLinkage.id">
                    {{ emailLinkage.user_linkage_value }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="phoneLinkages.length > 0">
                <v-row dense no-gutters>
                  <v-col cols="12">
                    <h3>These are the lsit of numbers that are authorised to call:
                    </h3>
                  </v-col>
                  <v-col cols="12" v-for="phoneLinkage in phoneLinkages" :key="phoneLinkage.id">
                    {{ phoneLinkage.user_linkage_value }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="ssoLinkages.length > 0">
                <v-row dense no-gutters>
                  <v-col cols="12">
                    <h3>These are the list of email address which has the ability to use their SSO
                      account to access this billing portal:
                    </h3>
                  </v-col>
                  <v-col cols="12" v-for="ssoLinkage in ssoLinkages" :key="ssoLinkage.id">
                    {{ ssoLinkage.user_linkage_value }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import Profile from '../components/Profile.vue';

export default {
  name: 'UserProfile',
  components: {
    Profile,
  },
  created() {
    this.$api.get('partner/profile').then((response) => {
      this.setProfile(response.data);
    });
  },
  data() {
    return {
      processing: false,
      profile: null,
      newPassword: '',
      showPassword: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        strongpassword: (value) => this.checkPasswordStrong(value)
          || 'You need at least 1 upper case character, 1 digits, 1 special symbol [?!@#$%^&*:;.,] and minimum length 10',
      },
      valid: false,
      emailLinkages: [],
      ccLinkages: [],
      ssoLinkages: [],
      phoneLinkages: [],
    };
  },
  methods: {
    setProfile(profile) {
      this.profile = profile;
      if (profile.linkages.sso) {
        this.ssoLinkages = profile.linkages.sso;
      }

      if (profile.linkages.email) {
        this.emailLinkages = profile.linkages.email;
      }

      if (profile.linkages.cc) {
        this.ccLinkages = profile.linkages.cc;
      }

      if (profile.linkages.phone) {
        this.phoneLinkages = profile.linkages.phone;
      }
    },
    submitForm() {
      this.processing = true;
      if (this.$refs.form.validate()) {
        const formData = this.$refs.partnerProfile.getProfileData();
        formData.new_password = this.newPassword;
        this.$api.put('partner/profile', formData)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Profile is updated successfully.',
            });
          }).finally(() => {
            this.processing = false;
          });
      } else {
        this.processing = false;
      }
    },
    checkPasswordStrong(password) {
      if (password.length === 0) return true;
      let patt = /[A-Z]/g;
      let result = patt.test(password);
      if (!result) {
        return false;
      }

      patt = /[0-9]/g;
      result = patt.test(password);
      if (!result) {
        return false;
      }

      patt = /[?!@#$%^&*:;.,]/g;
      result = patt.test(password);
      if (!result) {
        return false;
      }

      if (password.length < 10) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>

</style>

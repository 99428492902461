<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="enrichedItems"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
        @item-selected="itemSelected"
        @toggle-select-all="itemSelectedAll"
        v-model="selected"
      >
        <template v-slot:top>
          <v-card>
            <v-card-text>
              <v-row>
              <v-col cols="4">
                <v-text-field label="BANK" v-model="bank"></v-text-field>
              </v-col>
              <v-col cols="8"></v-col>
              <v-col cols="4">
                <v-text-field label="Account Name" v-model="accountName"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Account NO. / PayNow" v-model="accountNo"></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="text-left">
                  <v-btn small color="primary"
                         @click="saveWithdrawSettings"
                  >Save withdraw settings</v-btn>
                  <v-btn
                    class="mx-2"
                    small color="primary" :disabled="withdrawDisabled"
                    @click="withdraw"
                  >Withdraw
                    <v-chip x-small color="warning">Minimum $100</v-chip>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
              <v-row>
                <v-col cols="3">
                  <h3 style="color:orange;">Total Pending: ${{ totalPending }}</h3>
                </v-col>
                <v-col cols="3">
                  <h3 style="color:orange;">Total Processing: ${{ totalProcessing }}</h3>
                </v-col>
                <v-col cols="3">
                  <h3 style="color:dodgerblue;">Total Withdrawn: ${{ totalWithdrawn }}</h3>
                </v-col>
                <v-col cols="3">
                  <h3 style="color:green;">Total Withdrawable: ${{ totalWithdrawable }}</h3>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary"
            x-small
            v-if='item.partner_commission_status_id === 3 ||
            item.partner_commission_status_id === 4'
            @click="view(item)"
          >
            View Detail
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'List',
  data() {
    return {
      bank: '',
      accountNo: '',
      accountName: '',
      withdrawDisabled: true,
      dateFrom: null,
      dateTo: null,
      modalFrom: false,
      modalTo: false,
      search: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      total: 0,
      items: [],
      selected: [],
      totalWithdrawable: 0,
      totalPending: 0,
      totalWithdrawn: 0,
      totalProcessing: 0,
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Referee First Name', value: 'description', sortable: true },
        { text: 'Commission', value: 'amount', sortable: true },
        { text: 'Created At', value: 'created_at', sortable: true },
        { text: 'Status', value: 'partner_commission_status_name', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    this.initiated = true;
    this.loadWithdrawSettings();
    this.loadTable();
  },
  methods: {
    loadWithdrawSettings() {
      this.$api.get('partner/commission/withdraw-settings')
        .then((response) => {
          this.bank = response.data.bank;
          this.accountNo = response.data.account_no;
          this.accountName = response.data.account_name;
        });
    },
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('partner/commission/list', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      })
        .then((response) => {
          this.totalItems = response.data.total;
          this.items = response.data.data;
          this.totalPending = response.data.total_pending;
          this.totalWithdrawn = response.data.total_withdrawn;
          this.totalWithdrawable = response.data.total_withdrawable;
          this.totalProcessing = response.data.total_processing;
          if (this.totalWithdrawable >= 100) {
            this.withdrawDisabled = false;
          }
          this.loading = false;
        });
    },
    itemSelectedAll(data) {
      const { items, value } = data;
      if (value) {
        let total = 0;
        for (let i = 0; i < items.length; i += 1) {
          if (items[i].isSelectable) {
            total += items[i].amount * 1;
          }
        }
        if (total >= 100) {
          this.withdrawDisabled = false;
        } else {
          this.withdrawDisabled = true;
        }
        this.total = total;
      } else {
        this.withdrawDisabled = true;
      }
    },
    itemSelected(data) {
      const { item, value } = data;
      if (value) {
        this.total += item.amount * 1;
      } else {
        this.total -= item.amount * 1;
      }

      if (this.total >= 100) {
        this.withdrawDisabled = false;
      } else {
        this.withdrawDisabled = true;
      }
    },
    withdraw() {
      const commissionIds = [];
      this.selected.forEach((item) => {
        commissionIds.push(item.id);
      });
      this.$api.post('partner/commission/withdraw', {
        bank: this.bank,
        account_no: this.accountNo,
        account_name: this.accountName,
        commission_ids: commissionIds,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your withdraw request have been submitted successfully.',
        });
        window.location.reload();
      }).finally(() => {
      });
    },
    saveWithdrawSettings() {
      this.$api.post('partner/commission/withdraw-settings/save', {
        bank: this.bank,
        account_no: this.accountNo,
        account_name: this.accountName,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your withdraw settings have been updated successfully.',
        });
      }).finally(() => {
      });
    },
    view(item) {
      this.$router.push({ name: 'Partner.PartnerCommissionWithdraw', params: { id: item.withdraw_id } });
    },
  },
  computed: {
    enrichedItems() {
      return this.items
        .map((x) => ({ ...x, isSelectable: x.partner_commission_status_id === 2 }));
    },
  },
};
</script>

<style scoped></style>

<template>
  <iframe :src="businessCardUrl" width="100%" height="960px"></iframe>
</template>

<script>
export default {
  name: 'BusinessCardIframe',
  data() {
    return {
      // businessCardUrl: `http://localhost/EnBizCard/public/?token=${this.$store.state.user.auth_token}&currency=${window.systemCurrency}`,
      businessCardUrl: `/tools/business-card-generator?token=${this.$store.state.user.auth_token}&currency=${window.systemCurrency}`,
    };
  },
  created() {
    if (this.vcardUuid) {
      this.businessCardUrl = `${this.businessCardUrl}&uuid=${this.vcardUuid}`;
    }
  },
  props: ['vcardUuid'],
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Advance Search")]),_c('v-expansion-panel-content',[_c('v-btn',{attrs:{"depressed":"","color":"primary"}},[_vm._v("Search")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{
          'items-per-page-options': [15,30,60,100,150,250],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteConfirm(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.renewable",fn:function(ref){
        var item = ref.item;
return [(item.renewable == 1)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_vm._e(),(item.renewable == 0)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-close-circle")]):_vm._e()]}},{key:"item.orderable",fn:function(ref){
        var item = ref.item;
return [(item.orderable == 1)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_vm._e(),(item.orderable == 0)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-close-circle")]):_vm._e()]}}])}),_c('v-dialog',{attrs:{"max-width":"320"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_vm._v("Are you sure to delete the product type?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","loading":_vm.deleteLoading,"disabled":_vm.deleteLoading},on:{"click":_vm.deleteRow}},[_vm._v(" Delete ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
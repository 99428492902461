<template>
  <v-row
    no-gutters
  >
    <v-col>
      <v-expansion-panels
        v-model="searchOpen"
        multiple>
        <v-expansion-panel
        >
          <v-expansion-panel-header>Advance Search</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field v-model="quickSearch"
                          @keyup.enter.native="search()"
                          label="Quick wildcard search email"></v-text-field>
            <v-btn depressed color="primary"
                   @click="search()"
            >Search</v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            v-if="item.type != 'Admin'"
            @click="updateAuthUsers(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog
        v-model="deletePartnerDialog"
        max-width="320"
      >
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>Are you sure to delete the partner?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="deletePartnerDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              :loading="deleteLoading"
              :disabled="deleteLoading"
              @click="deletePartner"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ListAuthUsers',
  data() {
    return {
      searchOpen: [0],
      quickSearch: '',
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      loginAsPartnerLoading: {},
      headers: [
        { text: 'Account Owner Type', value: 'type' },
        { text: 'Account Owner Email', value: 'email' },
        { text: 'Auth User Type', value: 'user_linkage_type' },
        { text: 'Auth User Email', value: 'user_linkage_value' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      deletePartnerDialog: false,
      deleteCandidate: null,
      deleteLoading: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.loadTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    search() {
      this.loadTable({
        quick_search: this.quickSearch,
      });
    },
    loadTable(searchFields = {}) {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/partner/linkage/list', {
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
        search: searchFields,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    updateAuthUsers(item) {
      this.$router.push({ name: 'Admin.UpdateAuthUsers', params: { id: item.id } });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <div class="credit-card" ref="card"></div>
    <div class="v-text-field__details">
      <div class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">{{ error }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StripePayment',
  data() {
    return {
      cardEl: null,
      savedCard: null,
      invalid: true,
      error: '',
    };
  },
  mounted() {
    const elements = window.stripeClient.elements();
    const style = {
      hidePostalCode: true,
      base: {
        fontSize: '16px',
      },
      invalid: {
        color: '#ff5252',
        iconColor: '#ff5252',
      },
    };
    this.cardEl = elements.create('card', { style });
    this.cardEl.mount(this.$refs.card);
    this.cardEl.on('change', (event) => {
      if (event.complete) {
        this.invalid = false;
        this.$emit('update:card', this.cardEl);
      } else if (event.error) {
        this.invalid = true;
        this.error = event.error.message;
      }
      this.$emit('update:invalid', this.invalid);
    });
  },
  beforeDestroy() {
    this.cardEl.destroy(this.$refs.card);
  },
  props: [
    'amount',
  ],
};
</script>

<style scoped>
  .credit-card {
    padding: 10px;
    margin-bottom: 8px;
    border: 1px rgba(0,0,0,0.87) solid;
    border-radius: 4px;
  }
</style>

<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-toolbar v-if="initiated" dense :flat="true">
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              hide-details
              prepend-icon="mdi-text-search"
              single-line
              v-model="search"
              @keyup.enter.native="loadTable()"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn small color="primary" @click="loadTable()">Search</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1 service-list"
        :footer-props="{
            'items-per-page-options': [15,30,60,100,150,250],
          }"
      >
        <template v-slot:item.status_name="{ item }">
          <b v-if="item.status_name == 'Active'" class="green--text">{{ item.status_name }}</b>
          <b v-else class="red--text">{{ item.status_name }}</b>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            x-small
            color="success"
            v-if="item.status_name === 'Active'"
            :loading="loginLoading"
            :disabled="loginLoading"
            @click="login(item)"
          >
            Login Cpanel
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CpLoginCpanel',
  data() {
    return {
      search: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      strange: false,
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'service_name' },
        { text: 'Domain', value: 'service_identity' },
        { text: 'Status', value: 'status_name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      loginLoading: false,
      cancelDialog: false,
      cancelItem: null,
      cancelLoading: false,
      renewDialog: false,
      renewItem: null,
      renewLoading: false,
      renewCheckoutLoading: false,
      renewalTerms: [],
      renewalTerm: null,
      resetPasswordDialog: false,
      resetPasswordItem: null,
      resetPasswordLoading: false,
      resetPasswordSaveLoading: false,
      resetPasswordError: false,
      newPassword: null,
      showPassword: false,
      downgradeDialog: false,
      downgradeItem: null,
      downgradeLoading: false,
      upgradeDialog: false,
      upgradeItem: null,
      upgradeLoading: false,
      upgrades: [],
      upgrade: null,
      upgradeLoaded: false,
      downgrades: [],
      downgrade: null,
      downgradeLoaded: false,
      suspendLoading: false,
      tag: null,
      tagItem: null,
      tagDialog: false,
      tagLoading: false,
      showServiceTransferDialog: false,
      showServiceRenewDialog: false,
      showServiceUpgradeDialog: false,
      serviceId: '',
      serviceName: '',
      serviceIdentity: '',
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    document.addEventListener('keyup', this.enterClickAction);
    this.initiated = true;
    this.loadTable();
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.enterClickAction);
  },
  methods: {
    enterClickAction(e) {
      if (e.code === 'Enter') {
        this.loadTable();
      }
    },
    login(item) {
      this.loginLoading = true;
      this.$api.post('cp/cpanel/login', {
        id: item.id,
      }).then((response) => {
        window.open(response.data.data.url, '_blank');
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.loginLoading = false;
      });
    },
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('cp/cpanel/list', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
table td {
  font-size: 0.75rem !important;
}

.product-type-tab .v-tab {
  justify-content: flex-start;
}

.product-type-tab .v-chip {
  margin-right: 8px;
}

.service-list .v-btn {
  margin-right: 4px;
  margin-bottom: 4px;
}
</style>

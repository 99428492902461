<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="8"
      md="4"
    >
      <v-card class="elevation-12">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>Partner System</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              label="Email"
              name="email"
              prepend-icon="mdi-account"
              type="text"
              v-model="email"
              :error="error.email"
              :error-messages="errorMessage.email"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="$router.go(-1)"
          >Back</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="processing"
            :disabled="processing"
            @click="resetPassword()"
          >Reset Password</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      processing: false,
      error: {
        email: false,
      },
      errorMessage: {
        email: '',
      },
    };
  },
  methods: {
    resetPassword() {
      try {
        this.validate();
      } catch (e) {
        return;
      }

      this.processing = true;
      this.$api.post('request-reset-password', {
        email: this.email,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'We have emailed you a link to reset your password.',
          });
          setTimeout(() => {
            this.$router.push({ name: 'Login' });
          }, 1000);
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    validate() {
      this.error.email = false;
      this.errorMessage.email = '';

      if (this.$underscore.isEmpty(this.email)) {
        this.error.email = true;
        this.errorMessage.email = 'Email Address is required.';
        throw new Error('Validation error.');
      }
    },
  },
};
</script>

<style scoped>
</style>

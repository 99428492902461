<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-form ref="form">
        <v-card outlined>
          <v-card-text>
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="4">
                        <cpanel-server-dropdown
                          label="Cpanel Server"
                          :rules="[]"
                          :single="true"
                          :servers.sync="serverId"
                          :defaultServers="defaultServers"
                          :nochips="true"
                        ></cpanel-server-dropdown>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Subject"
                          v-model="subject"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <p>Special Field In Body:</p>
                    <p>{domain}: replace with service domain</p>
                    <p>{service}: replace with service name + domain</p>
                    <p>{expiry}: replace with service expiry</p>
                    <p>{status}: replace with service status</p>
                    <p>If you wish to merge all services belonging to same customer
                      please use this in the body:</p>
                    <p>{merge_services}</p>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Body"
                      v-model="body"
                      rows="16"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      color="primary"
                      :loading="processing"
                      :disabled="processing"
                      @click="notify"
                    >Nofiy</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <table border="1">
                      <thead>
                      <tr>
                        <th>Partner/Retail</th>
                        <th>Email</th>
                        <th>Domain</th>
                        <th>Server Plan</th>
                        <th>Billing Plan</th>
                        <th>Expiry</th>
                        <th>Status</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="emailRow in emailList"
                          :key="emailRow.id">
                        <td>{{ emailRow.type }}</td>
                        <td>{{ emailRow.email }}</td>
                        <td>{{ emailRow.domain }}</td>
                        <td>{{ emailRow.plan }}</td>
                        <td>{{ emailRow.service_name }}</td>
                        <td>{{ emailRow.expiry_date }}</td>
                        <td>{{ emailRow.status_name }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import CpanelServerDropdown from '../../components/CpanelServerDropdown.vue';

export default {
  name: 'NotifyCustomer',
  components: {
    CpanelServerDropdown,
  },
  created() {
  },
  data() {
    return {
      processing: false,
      serverId: '',
      defaultServers: null,
      subject: '',
      body: '',
      emailList: '',
    };
  },
  methods: {
    notify() {
      const r = window.confirm('Are you sure to send email out?');
      if (r === true) {
        this.processing = true;
        this.$api.post('admin/notify-cpanel-server', {
          server: this.serverId,
          subject: this.subject,
          body: this.body,
        }).then(() => {
          this.subject = '';
          this.body = '';
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Emails are scheduled to send successfully.',
          });
        }).finally(() => {
          this.processing = false;
        });
      }
    },
  },
  watch: {
    serverId(newServer) {
      this.$api.post('admin/get-server-email-list', {
        server: newServer,
      }).then((response) => {
        this.$set(this, 'emailList', []);
        response.data.forEach((emailData) => {
          this.emailList.push(emailData);
        });
      }).finally(() => {
      });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <div v-if="initiated">
        <v-row dense no-gutters justify="end" align="end">
          <v-col cols="4">
            <v-text-field
              hide-details
              prepend-icon="mdi-text-search"
              single-line
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn small color="primary" @click="loadTable()">Search</v-btn>
          </v-col>
          <v-col cols="6"></v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-radio-group
            v-model="ratioFilter"
            row
          >
            <v-radio
              label=">90"
              value="90"
            ></v-radio>
            <v-radio
              label=">80"
              value="80"
            ></v-radio>
            <v-radio
              label=">70"
              value="70"
            ></v-radio>
            <v-radio
              label=">60"
              value="60"
            ></v-radio>
            <v-radio
              label=">50"
              value="50"
            ></v-radio>

            <v-radio
              label="All"
              value="0"
            ></v-radio>
          </v-radio-group>
        </v-row>
      </div>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.quota="{ item }">
          {{ item.quota }}M
        </template>
        <template v-slot:item.usage_ratio="{ item }">
          {{ item.usage_ratio }}%
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CpanelDiskusage',
  created() {
    this.loadTable();
  },
  data() {
    return {
      search: '',
      initiated: false,
      ratioFilter: '80',
      totalItems: 0,
      items: [],
      strange: false,
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'Server', value: 'hostname' },
        { text: 'Domain', value: 'domain' },
        { text: 'Usage(MB)', value: 'diskused' },
        { text: 'Quota(MB)', value: 'quota' },
        { text: 'Usage Ratio', value: 'usage_ratio' },
        { text: 'Server Plan', value: 'plan' },
        { text: 'Billing Plan', value: 'package_name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    ratioFilter: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/cpanel/diskusage', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        ratio_filter: this.ratioFilter,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
        this.initiated = true;
      });
    },
  },
};
</script>

<style scoped>
</style>

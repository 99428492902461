<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="3">
          <v-checkbox
            label="Renew without manual approval"
            v-model="configData.renew_without_actions"></v-checkbox>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Admin Field 1"
            v-model="configData.admin_field1"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Admin Field 2"
            v-model="configData.admin_field2"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Admin Field 3"
            v-model="configData.admin_field3"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Admin Field 4"
            v-model="configData.admin_field4"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Admin Field 5"
            v-model="configData.admin_field5"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Admin Field 6"
            v-model="configData.admin_field6"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Admin Field 7 "
            v-model="configData.admin_field7"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Admin Field 8"
            v-model="configData.admin_field8"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Admin Field 9"
            v-model="configData.admin_field9"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Admin Field 10"
            v-model="configData.admin_field10"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Customer Field 1"
            v-model="configData.customer_field1"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Customer Field 2"
            v-model="configData.customer_field2"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Customer Field 3"
            v-model="configData.customer_field3"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Customer Field 4"
            v-model="configData.customer_field4"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Customer Field 5"
            v-model="configData.customer_field5"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Customer Field 6"
            v-model="configData.customer_field6"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Customer Field 7 "
            v-model="configData.customer_field7"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Customer Field 8"
            v-model="configData.customer_field8"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Customer Field 9"
            v-model="configData.customer_field9"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :readonly="configData.readonly"
            label="Customer Field 10"
            v-model="configData.customer_field10"></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Config',
  components: {
  },
  data() {
    return {};
  },
  methods: {
    addField() {
      this.configData.fields.push({
        id: Date.now(),
        label: '',
        field_type: 'Generic String',
        default: '',
        min: null,
        max: null,
      });
    },
    removeField(index) {
      this.configData.fields.splice(index, 1);
    },
  },
  mounted() {
    this.$emit('update:configData', this.configData);
  },
  props: {
    configData: {
      type: Object,
      default() {
        return {
          readonly: false,
          renew_without_actions: 0,
          admin_field1: '',
          admin_field2: '',
          admin_field3: '',
          admin_field4: '',
          admin_field5: '',
          admin_field6: '',
          admin_field7: '',
          admin_field8: '',
          admin_field9: '',
          admin_field10: '',
          customer_field1: '',
          customer_field2: '',
          customer_field3: '',
          customer_field4: '',
          customer_field5: '',
          customer_field6: '',
          customer_field7: '',
          customer_field8: '',
          customer_field9: '',
          customer_field10: '',
        };
      },
    },
  },
  watch: {
    configData: {
      handler(newConfigData) {
        this.$emit('update:configData', newConfigData);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Billing System</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <h2 class="red--text" v-if="invalid">Invalid Token!</h2>
            <v-form ref="form" v-else>
              <v-row>
                <v-col cols="12">
                  <h3>Service: {{ service.service }}</h3>
                </v-col>
                <v-col cols="12">
                  <h3>Expiry: {{ service.expiry }}</h3>
                </v-col>
                <v-col cols="6" v-if="service.activated === 0">
                  <v-text-field
                    label="First Name"
                    name="firstname"
                    v-model="firstName"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="service.activated === 0">
                  <v-text-field
                    label="Last Name"
                    name="lastname"
                    v-model="lastName"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="service.activated === 0">
                  <v-text-field
                    label="Password"
                    name="password"
                    prepend-icon="mdi-key"
                    type="password"
                    v-model="password"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    v-model="action"
                    row
                  >
                    <v-radio
                      label="Approve"
                      value="approve"
                    ></v-radio>
                    <v-radio
                      label="Reject"
                      value="reject"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" v-if="action === 'reject'">
                  <v-textarea
                    outlined
                    label="Reject Reason"
                    :value="rejectReason"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!invalid"
              color="primary"
              :loading="processing"
              :disabled="processing"
              @click="submit()"
            >Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-footer
      dark
      padless
      :app="true"
      :fixed="true"
    >
      <v-card
        flat
        tile
        class="indigo lighten-1 white--text text-center"
        width="100%"
      >
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Powered By GoCloudEasy Pte Ltd</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  name: 'ServiceTransfer',
  data() {
    return {
      processing: false,
      action: 'approve',
      rejectReason: '',
      invalid: false,
      service: null,
      firstName: null,
      lastName: null,
      password: null,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
    };
  },
  created() {
    this.$api.post(`get-service-transfer/${this.$route.params.token}`)
      .then((response) => {
        if (response.data.status === 'error') {
          this.invalid = true;
        } else {
          this.service = response.data;
        }
      }).finally(() => {
      });
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.processing = true;
      this.$api.post(`service-transfer/${this.$route.params.token}`, {
        action: this.action,
        reject_reason: this.rejectReason,
        first_name: this.firstName,
        last_name: this.lastName,
        password: this.password,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'You have submit the request successfully. Please login to check the result.',
          });

          setTimeout(() => {
            const { brand } = this.$store.state;
            this.$router
              .push({ name: 'Retail.Login', params: { brand } })
              .catch(() => {
              });
          }, 1000);
        }).finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined>
        <v-card-text>
          <v-col cols="12">
            <v-text-field dense
                          label="Article Title"
                          v-model="title"
                          :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              clearable
              label="Article Categories"
              :multiple="true"
              :chips="true"
              v-model="selectedArticleCategories"
              :items="articleCategories"
            >
            </v-select>
          </v-col>
          <v-col cols="12">
            <div id="editor">
              <div id="toolbar-container">
                <span class="ql-formats">
                  <button class="ql-header" value="1"></button>
                  <button class="ql-header" value="2"></button>
                  <button class="ql-blockquote"></button>
                  <button class="ql-code-block"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-direction" value="rtl"></button>
                  <select class="ql-align"></select>
                </span>
                <span class="ql-formats">
                  <button class="ql-link"></button>
                  <button class="ql-image"></button>
                  <button class="ql-video"></button>
                  <button class="ql-formula"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-clean"></button>
                </span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn
              color="secondary"
              :loading="processing"
              :disabled="processing"
              @click="draft"
            >Draft</v-btn>
            <v-btn
              color="primary"
              :loading="processing"
              :disabled="processing"
              @click="publish"
            >Publish</v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Quill from '@/plugins/quill';
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme

export default {
  name: 'Add',
  mounted() {
    this.editor = new Quill('#editor', {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ['link', 'image', 'video'],
          ['clean'],
        ],
      },
      theme: 'snow',
    });
  },
  created() {
    this.getArticleCategories();
  },
  data() {
    return {
      editor: null,
      title: '',
      articleCategories: [],
      selectedArticleCategories: null,
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
    };
  },
  methods: {
    draft() {
      this.processing = true;
      const postData = this.getPostDate();
      postData.article_status_id = 1;
      this.$api.post('admin/article', postData)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Draft article is saved.',
          });

          window.location.reload();
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.processing = false;
          window.location.reload();
        });
    },
    publish() {
      this.processing = true;
      const postData = this.getPostDate();
      postData.article_status_id = 2;
      this.$api.post('admin/article', postData)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Article is published.',
          });

          window.location.reload();
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    getPostDate() {
      return {
        title: this.title,
        article_categories: this.selectedArticleCategories,
        body: this.editor.root.innerHTML,
        search_text: this.editor.getText(),
      };
    },
    getArticleCategories() {
      this.$api.post('admin/article-category/list', {
        current_page: 1,
        order: ':',
        per_page: 1000,
      })
        .then((response) => {
          const articleCategories = response.data.data;
          articleCategories.forEach((value) => {
            this.articleCategories.push({
              text: value.name,
              value: value.id,
            });
          });
        });
    },
  },
  watch: {
  },
};
</script>

<style>
  .ql-editor {
    min-height: 560px;
  }
</style>

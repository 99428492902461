<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined>
        <v-card-text>
          <v-col cols="4">
            <v-text-field dense
                          label="Email"
                          v-model="email"
                          :readonly="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field dense
                          label="First Name"
                          v-model="firstName"
                          :readonly="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field dense
                          label="Last Name"
                          v-model="lastName"
                          :readonly="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field dense
                          label="Affiliate Code"
                          v-model="affiliateCode"
                          :readonly="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              dense
              label="Affiliate Discount"
              v-model="selectedAffiliateDiscount"
              :items="affiliateDiscounts"
              :multiple="false"
              :chips="false"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field dense
                          label="Commission Rate (%)"
                          v-model="commissionRate"
                          :readonly="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field dense
                          label="Application Status"
                          v-model="affiliateStatusName"
                          :readonly="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="text-right" v-if="!readonly">
            <v-btn
              color="warning"
              :loading="processing"
              :disabled="processing"
              @click="reject"
            >Reject</v-btn>
            <v-btn
              color="primary"
              :loading="processing"
              :disabled="processing"
              @click="approve"
            >Approve</v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Review',
  mounted() {
    this.$api.get(`admin/affiliate/${this.$route.params.id}`).then(async (response) => {
      this.id = response.data.id;
      this.email = response.data.email;
      this.firstName = response.data.first_name;
      this.lastName = response.data.last_name;
      this.affiliateStatusId = response.data.affiliate_status_id;
      this.affiliateStatusName = response.data.name;
      this.commissionRate = response.data.commission_rate;
      this.affiliateCode = response.data.affiliate_code;
      if (this.affiliateStatusId === 1) {
        this.readonly = false;
        this.affiliateDiscounts = await this.$gceCommon.getDropdown('admin/discount/affiliate');
        this.selectedAffiliateDiscount = this.affiliateDiscounts[0].value;
      } else {
        this.affiliateDiscounts = await this.$gceCommon.getDropdown('admin/discount/affiliate');
        this.selectedAffiliateDiscount = response.data.discount_id;
      }
    });
  },
  created() {
    this.getAffiliateDiscounts();
  },
  data() {
    return {
      id: null,
      readonly: true,
      email: '',
      firstName: '',
      lastName: '',
      affiliateStatusName: '',
      affiliateCode: '',
      affiliateStatusId: null,
      commissionRate: null,
      affiliateDiscounts: [],
      selectedAffiliateDiscount: null,
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
    };
  },
  methods: {
    approve() {
      this.processing = true;
      const postData = this.getPostDate();
      this.$api.post(`admin/affiliate/${this.id}/approve`, postData)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Affiliate application is approved.',
          });
          window.location.reload();
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    reject() {
      this.processing = true;
      this.$api.post(`admin/affiliate/${this.id}/reject`)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'Affiliate application is rejected.',
          });
          window.location.reload();
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    getPostDate() {
      return {
        id: this.id,
        discount_id: this.selectedAffiliateDiscount,
        commission_rate: this.commissionRate,
      };
    },
    async getAffiliateDiscounts() {
      this.affiliateDiscounts = await this.$gceCommon.getDropdown('admin/discount-type');
    },
  },
};
</script>

<style>
</style>

<template>
  <v-row
    no-gutters
    dense
  >
    <v-col
      cols="6"
      offset="3"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card
          outlined
        >
          <v-card-text>
            <v-row
              dense
              no-gutters
            >
              <v-col
                cols="12"
              >
                <profile
                  ref="partnerProfile"
                  :setNewProfile="profile"
                ></profile>
                <v-row
                  dense
                >
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      color="primary"
                      :loading="processing"
                      :disabled="processing"
                      @click="submitForm"
                    >Submit</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import Profile from '../../../components/Profile.vue';

export default {
  name: 'Detail',
  components: {
    Profile,
  },
  created() {
    this.$api.get(`partner/customer/${this.$route.params.id}`).then((response) => {
      this.setProfile(response.data);
    });
  },
  data() {
    return {
      creditLimit: 0,
      processing: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
      },
      profile: null,
      valid: false,
    };
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$api.put(`partner/customer/${this.$route.params.id}`, this.$underscore.extend(this.$refs.partnerProfile.getProfileData(), {
          credit_limit: this.creditLimit,
        }))
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'Customer detail is updated successfully.',
            });
            setTimeout(() => {
              this.$router.go(0);
            }, 500);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
    setProfile(profile) {
      this.profile = profile;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-dialog width="1024" v-model="show">
    <v-card>
      <v-card-text>
        <v-form ref="form">
          <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <h2>{{ serviceName }} {{ serviceIdentity }}</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-radio-group
                  v-model="type"
                  row
                >
                  <v-radio
                    label="Use existed client"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="New client"
                    :value="2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" v-if="type == 1">
                <v-autocomplete
                  v-model="client"
                  :loading="searching"
                  :items="clients"
                  :search-input.sync="searchClient"
                  cache-items
                  flat
                  hide-no-data
                  hide-details
                  :clearable="true"
                  :rules="[rules.required]"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" v-if="type == 1 &&
                ($store.state.user.user_settings.brand == 'cldy' ||
                $store.state.user.user_settings.brand == 'clientarea')">
                <v-radio-group
                  v-model="needApproval"
                  row
                >
                  <v-radio
                    label="Direct Approve"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    label="Ask Approval"
                    :value="1"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6" v-if="type == 2">
                <v-text-field label="Client Email" v-model="clientEmail"
                              :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="$emit('closeServiceTransferDialog')"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          @click="submit()"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ServiceTransferDialog',
  data() {
    return {
      searching: false,
      loading: false,
      clients: [],
      client: null,
      clientEmail: '',
      needApproval: 1,
      type: 1,
      rules: {
        required: (value) => (value !== 0 && !!value) || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
      searchClient: null,
    };
  },
  props: ['show', 'serviceId', 'serviceName', 'serviceIdentity'],
  created() {
  },
  methods: {
    submit() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.loading = false;
        return;
      }
      this.$api.post('partner/service/transfer', {
        type: this.type,
        service_id: this.serviceId,
        email: this.clientEmail,
        client_id: this.client,
        need_approval: this.needApproval,
      })
        .then(() => {
          if (this.type === 1) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'We have notify the customer to register account and accept the transfer.',
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: 'We have notify the customer to accept the transfer.',
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    searchClient(text) {
      if (this.clients.length > 0) return;
      this.searching = true;
      this.$api.post('partner/customer/search', {
        email: text,
      }).then((response) => {
        this.clients = response.data;
      }).finally(() => {
        this.searching = false;
      });
    },
  },
};
</script>

<style scoped>

</style>

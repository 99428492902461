<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="4">
          <v-dialog
            ref="dialogFrom"
            v-model="modalFrom"
            :return-value.sync="dateFrom"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFrom"
                label="From Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFrom"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalFrom = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialogFrom.save(dateFrom)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-dialog
            ref="dialogTo"
            v-model="modalTo"
            :return-value.sync="dateTo"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateTo"
                label="To Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateTo"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalTo = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialogTo.save(dateTo)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col align="center" cols="4">
          <v-btn @click="downloadCsv">Download CSV</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.debit="{ item }">
          <span v-if="item.balance_before * 1 <= item.balance_after * 1">-</span>
          <span v-if="item.balance_before * 1 > item.balance_after * 1">
            {{ (item.balance_before - item.balance_after).toFixed(2) }}
          </span>
        </template>
        <template v-slot:item.credit="{ item }">
          <span v-if="item.balance_before * 1 >= item.balance_after * 1">-</span>
          <span v-if="item.balance_before * 1 < item.balance_after * 1">
            {{(item.balance_after - item.balance_before).toFixed(2) }}
          </span>
        </template>
<!--        <template v-slot:item.actions="{ item }">-->
<!--          <v-btn-->
<!--            x-small-->
<!--            color="success"-->
<!--            @click="view(item)"-->
<!--          >-->
<!--            view related service-->
<!--          </v-btn>-->
<!--        </template>-->
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'List',
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      modalFrom: false,
      modalTo: false,
      search: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Operator', value: 'operator', sortable: false },
        { text: 'Detail', value: 'detail', sortable: false },
        { text: 'Datetime', value: 'created_at', sortable: false },
        // { text: 'Balance Before', value: 'balance_before', sortable: false },
        { text: 'Debit', value: 'debit', sortable: false },
        { text: 'Credit', value: 'credit', sortable: false },
        { text: 'Balance After', value: 'balance_after', sortable: false },
        // { text: 'Locked Balance Before', value: 'locked_balance_before', sortable: false },
        // { text: 'Locked Balance After', value: 'locked_balance_after', sortable: false },
        { text: 'Notes', value: 'notes', sortable: false },
        // { text: 'Actions', value: 'actions', sortable: false },
      ],
      deleteDialog: false,
      deleteItem: null,
      deleteLoading: false,
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    this.initiated = true;
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('partner/balance/list', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        product_type_id: this.productTypeId,
        order: `${sortBy}:${sortDesc}`,
      })
        .then((response) => {
          this.totalItems = response.data.total;
          this.items = response.data.data;
          this.loading = false;
        });
    },
    downloadCsv() {
      const authToken = window.btoa(this.$store.state.user.auth_token);
      window.open(`${process.env.VUE_APP_API_URL}/v1/partner/balance/list/download-csv?
      auth_token=${authToken}&per_page=50000&current_page=1&from=${this.dateFrom}&to=${this.dateTo}`, '_blank');
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <div>
    <v-text-field :label="label"
                  :rules="rules"
                  v-model="datetimeString"
                  @click.prevent="openDatetimePicker($event)"
    ></v-text-field>
    <datetime
      class="datetime-picker"
      ref="datetimePicker"
      type="datetime"
      v-model="datetime"
      :hour-step="1"
      :minute-step="5"
      format="yyyy-MM-dd HH:mm:ss"
      auto
    ></datetime>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'DatetimePicker',
  created() {
    this.datetimeString = DateTime.utc().toFormat('yyyy-MM-dd HH:mm:ss');
  },
  mounted() {
    this.emitUpdate();
  },
  data() {
    return {
      datetimeString: '',
      datetime: '',
    };
  },
  methods: {
    openDatetimePicker(event) {
      this.$refs.datetimePicker.open(event);
    },
    emitUpdate() {
      this.$emit('update:datetimeString', this.datetimeString);
    },
  },
  props: ['label', 'rules'],
  watch: {
    datetime: {
      handler(newDatetime) {
        if (newDatetime !== null) {
          this.datetimeString = DateTime.fromISO(newDatetime).toFormat('yyyy-MM-dd HH:mm:ss');
        }
      },
      deep: true,
    },
    datetimeString: {
      handler(newDatetimeString) {
        this.$emit('update:datetimeString', newDatetimeString);
      },
    },
  },
};
</script>

<style scoped>

</style>

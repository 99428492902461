<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader
        ref="skeleton"
        v-if="!showPage"
        type="card"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-row dense align="center" v-if="showPage">
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <h3>Addon - {{ form.service_name }}</h3>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <h5>Status: {{ form.status_name }}</h5>
            </v-col>
            <v-col cols="3">
              <h5 v-if="form.provision_date">Registration Date: {{ form.provision_date }}</h5>
            </v-col>
            <v-col cols="3">
              <h5 v-if="form.expiry_date">Expiry Date: {{ form.expiry_date }}</h5>
            </v-col>
            <v-col cols="3">
              <div class="my-1">
                <v-btn x-small color="success" v-if="form.expiry_date"
                       @click="renew()">RENEW</v-btn>
              </div>
              <div class="my-1">
                <v-btn x-small color="primary" v-if="form.expiry_date"
                       @click="upgrade()">UPGRADE/DOWNGRADE</v-btn>
              </div>
            </v-col>
          </v-row>
          <manual-manage
            v-if="form.module_name == 'manual'"
            :moduleData.sync="form.module_data"
            :parentModuleData="parentModuleData"
            :readonly="false"
            :hide-admin-info="true"
          >
          </manual-manage>
          <cpanel-manage
            v-if="form.module_name == 'cpanel'"
            :moduleData.sync="form.module_data"
            :parentModuleData="parentModuleData"
            :readonly="false"
            :hide-admin-info="true"
          >
          </cpanel-manage>
          <domain-privacy-manage
            v-if="form.module_name == 'domainPrivacy'"
            :moduleData.sync="form.module_data"
            :parentModuleData="parentModuleData"
            :readonly="false"
            :hide-admin-info="true"
          >
          </domain-privacy-manage>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CpanelManage from './provisioning_modules/cpanel/Manage.vue';
import ManualManage from './provisioning_modules/manual/Manage.vue';
import DomainPrivacyManage from './provisioning_modules/domain/privacy/Manage.vue';

export default {
  name: 'ServiceAddon',
  components: {
    CpanelManage,
    ManualManage,
    DomainPrivacyManage,
  },
  created() {
    this.id = +this.serviceId;
    this.$api.get('partner/product/list').then((response) => {
      this.orderableProducts = response.data;
      this.reloadDomain();
    });
  },
  data() {
    return {
      id: null,
      showPage: false,
      epp: null,
      form: {
        domain: '',
        service_name: '',
        module_name: '',
        product_id: null,
        product_term: null,
        status_name: '',
        tld: null,
        auto_renew: true,
        locked: true,
        ns1: '',
        ns2: '',
        ns3: '',
        ns4: '',
        ns5: '',
        provision_date: '',
        expiry_date: '',
        registrant: {},
        admin: {},
        tech: {},
        billing: {},
      },
      childNss: [],
      addChildNss: [],
      deleteChildNss: [],
      updateChildNss: [],
      defaultContact: null,
      registrant: null,
      admin: null,
      tech: null,
      billing: null,
      domainErr: null,
      tld_list: [],
      tldPriceList: [],
      terms: [],
      checkDomainLoading: false,
      checkDomainInvalid: true,
      checkoutProcessing: false,
      checkoutDisabled: false,
      showPriceTable: false,
      showRegistrationForm: false,
      eppLoading: false,
      autoRenewLoading: false,
      lockLoading: false,
      updateNsLoading: false,
      updateRegistrantLoading: false,
      updateAdminLoading: false,
      updateTechLoading: false,
      updateBillingLoading: false,
      updateChildNsLoading: false,
      renewDialog: false,
      renewLoading: false,
      renewCheckoutLoading: false,
      renewalTerms: [],
      renewalTerm: null,
      successMessage:
        'We are registering your domain now. '
        + 'An email will be sent to you when your service is ready.',
      valid: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?/i).test(value) || 'This field must be valid domain name.',
        childNs: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(`${value}.${this.form.domain}`) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
        nameserver: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
        ip: (value) => (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/).test(value) || 'This field must be valid IP.',
      },

      orderableProducts: [],
      addonData: null,
      serviceTermId: null,
      parentProductTerms: [],
      addonRegistrationComponentKey: 1,
      totalAddon: 0,
      buying: false,
    };
  },
  methods: {
    renew() {
      this.$emit('renew', this.id);
    },
    upgrade() {
      this.$emit('upgrade', this.id);
    },
    revalidate() {
    },
    updateAddonPrice(amount) {
      this.totalAddon = +amount;
    },
    buyAddons() {
      this.buying = true;
      this.$api.post('partner/addon/register', {
        service_id: this.id,
        domain: this.form.domain,
        addons: this.addonData,
        addon_only: true,
      })
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: this.successMessage,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .finally(() => {
          this.buying = false;
        });
    },
    reloadDomain() {
      this.$api.get(`partner/service/${this.id}`).then((response) => {
        const serviceData = response.data;
        this.serviceTermId = this.$gceCommon.getTermIdByTermMonth(serviceData.service_month_term);
        this.form.domain = serviceData.service_identity;
        this.form.module_name = serviceData.module_name;
        this.form.service_name = serviceData.service_name;
        this.form.product_id = serviceData.product_id;
        this.form.provision_date = serviceData.provision_date;
        this.form.status_name = serviceData.service_status_name;
        this.form.expiry_date = serviceData.expiry_date;
        this.form.auto_renew = serviceData.auto_renew === 1;
        this.parentModuleData = serviceData.service_config;
        this.showPage = true;
        this.$underscore.each(this.orderableProducts, (products) => {
          this.$underscore.each(products, (product) => {
            if (product.id === serviceData.product_id) {
              this.$underscore.each(product.currencies, (value) => {
                const currentProductPrice = {
                  text: `${value.termName}: $${value.productPrice}`,
                  value: value.productTermId,
                  price: value.productPrice,
                };
                this.parentProductTerms.push(currentProductPrice.value);
              });
            }
          });
        });
      });
    },
    toggleAutoRenew() {
      this.autoRenewLoading = true;
      this.$api.post('partner/service/set-auto-renew', {
        id: this.id,
        auto_renew: this.form.auto_renew ? 0 : 1,
      }).then(() => {
        this.$set(this.form, 'auto_renew', !this.form.auto_renew);
        const successMessage = this.form.auto_renew ? 'on' : 'off';
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: `Auto renew is turned ${successMessage}.`,
        });
      }).finally(() => {
        this.autoRenewLoading = false;
      });
    },
    reset() {
      this.$router.go(0);
    },
  },
  watch: {
  },
  props: ['serviceId', 'parentId'],
};
</script>

<style scoped>

</style>

<template>
  <public-base>
    <v-row
      no-gutters
      dense
    >
      <v-col cols="12">
        <v-card
          outlined
        >
          <v-card-text>
            <h2>Save your Credit Card for Purchases and Auto-Renewals</h2>
            <v-form ref="form">
              <div v-if="paymentMethod == 3">
                <v-radio-group v-model="stripePaymentMode">
                  <v-radio value="useSavedCard" v-if="hasSavedCard">
                    <template v-slot:label>
                      <div>Use saved card</div>
                    </template>
                  </v-radio>
                  <v-row no-gutters dense>
                    <v-col cols="6">
                      <stripe-saved-cards
                        :card.sync="savedCard"
                        :hasCard.sync="hasSavedCard"
                        v-on:remove-card="hasSavedCard = false"
                      ></stripe-saved-cards>
                    </v-col>
                  </v-row>
                  <v-row v-if="hasSavedCard"></v-row>
                  <v-radio value="useNewCard" v-if="hasSavedCard">
                    <template v-slot:label>
                      <div>Use new card</div>
                    </template>
                  </v-radio>
                  <v-row no-gutters dense>
                    <v-col cols="12" md="6">
                      <stripe-payment
                        :amount="amount"
                        :card.sync="creditCard"
                        :invalid.sync="creditCardInvalid"
                      ></stripe-payment>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </div>
              <div v-if="paymentMethod == 4">
                <v-radio-group v-model="stripePaymentMode">
                  <v-radio value="useSavedCard" v-if="hasSavedCard">
                    <template v-slot:label>
                      <div>Use saved card</div>
                    </template>
                  </v-radio>
                  <v-row no-gutters dense>
                    <v-col cols="6">
                      <paypal-saved-cards
                        :card.sync="savedCard"
                        :hasCard.sync="hasSavedCard"
                        v-on:remove-card="hasSavedCard = false"
                      ></paypal-saved-cards>
                    </v-col>
                  </v-row>
                  <v-row v-if="hasSavedCard"></v-row>
                  <v-radio value="useNewCard" v-if="hasSavedCard">
                    <template v-slot:label>
                      <div>Use new card</div>
                    </template>
                  </v-radio>
                  <v-row no-gutters dense>
                    <v-col cols="12" md="6">
                      <paypal-payment
                        :amount="amount"
                        :orderid="paypalOrderId"
                        @interface="getChildInterface"
                        :saveasdefaultcard="true"
                        :card.sync="creditCard"
                        :invalid.sync="creditCardInvalid"
                      ></paypal-payment>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </div>
            </v-form>
            <v-row no-gutters dense>
              <v-col cols="12" class="text-left">
                <v-tooltip bottom
                           v-if="paymentMethod === 3 || paymentMethod === 4"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="success"
                      :loading="loading"
                      v-bind="attrs"
                      v-on="on"
                      @click="saveCard">
                      Save Default Card (Visa and MasterCard supported)
                    </v-btn>
                    <br>
                  </template>
                  <span>$5 will be authenticate for validation, there is no actual charge.</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <br>
            <br>
            <v-row no-gutters dense>
              <v-col cols="12">
                <h4>Name: {{ customerInfo['first_name'] }} {{ customerInfo['last_name'] }}</h4>
                <h4>Company: {{ customerInfo['company_name'] }}</h4>
                <h4>Address1: {{ customerInfo['address1'] }}</h4>
                <h4>Country: {{ customerInfo['country'] }}</h4>
                <h4>Postcode: {{ customerInfo['postal_code'] }}</h4>
              </v-col>
            </v-row>
            <br>
            <br>
            <v-row no-gutters dense>
              <v-col cols="12">
                <h2>You have {{ this.services.length }} services that is auto renew enabled.</h2>
              </v-col>
            </v-row>
            <br>
            <v-row no-gutters dense>
              <v-col cols="12">
                <v-row no-gutters dense v-if="this.services.length > 0">
                  <v-col cols="8" lg="4"><h3>Service</h3></v-col>
                  <v-col cols="4" lg="4"><h3>Expiry Date</h3></v-col>
                </v-row>
                <v-row no-gutters dense v-for="service in services" :key="service.id">
                  <v-col cols="8" lg="4">
                    <span v-if="service.service_name.indexOf('Domain TLD') === 0">Domain</span>
                    <span v-else>{{ service.service_name }}</span>
                    &nbsp;<span>{{ service.service_identity }}</span>
                  </v-col>
                  <v-col cols="4" lg="4">
                    <span>{{ service.expiry_date }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </public-base>
</template>

<script>
import { DateTime } from 'luxon';
import {
  StripePayment, StripeSavedCards, PaypalPayment, PaypalSavedCards,
} from '../components/payment_modules';
import PublicBase from './PublicBase.vue';

export default {
  name: 'ExpressSaveCardPaypal',
  components: {
    PublicBase,
    StripeSavedCards,
    StripePayment,
    PaypalSavedCards,
    PaypalPayment,
  },
  data() {
    return {
      customerInfo: null,
      bank: '',
      referenceNo: '',
      transferDatetime: null,
      pickerTransferDatetime: null,
      paymentMethod: 0,
      paymentMethods: [],
      paypalOrderId: '',
      amount: '',
      loading: false,
      sendSaveCardLoading: false,
      saveAsDefaultCard: false,
      creditCardInvalid: null,
      creditCard: null,
      savedCard: null,
      hasSavedCard: false,
      stripePaymentMode: 'useNewCard',
      rules: {
        required: (value) => !!value || 'This field is required.',
      },
      services: [],
    };
  },
  created() {
    this.transferDatetime = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');
    this.pickerTransferDatetime = DateTime.local().toISO();
    this.$api.get('partner/payment-methods').then((response) => {
      this.$set(this, 'paymentMethods', response.data);
    });
    this.$set(this, 'paymentMethod', 4);
    // this.$api.get('partner/default-payment-method').then((response) => {
    //   this.$set(this, 'paymentMethod', response.data);
    // });
    this.$api.get(`partner/service/auto-renew/list?token=${this.$route.params.token}`).then((response) => {
      this.$set(this, 'services', response.data);
    });
    this.$api.post('get-customer-info', {
      token: this.$route.params.token,
    })
      .then((response) => {
        this.customerInfo = response.data;
      });
  },
  methods: {
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    saveCard() {
      this.loading = true;

      const postData = {
        payment_type_id: this.paymentMethod,
      };

      if (this.$route.params.token) {
        postData.token = this.$route.params.token;
      }

      if (this.paymentMethod === 3) {
        this.$api.post('partner/payment/save-card', postData)
          .then((response) => {
            const stripePaymentObj = {
              payment_method: {
                card: this.creditCard,
              },
            };

            if (this.saveAsDefaultCard) {
              stripePaymentObj.setup_future_usage = 'off_session';
            }

            window.stripeClient.confirmCardPayment(response.data.client_secret, stripePaymentObj)
              .then(async (result) => {
                if (result.error) {
                  await this.$store.dispatch('addAlert', {
                    id: Date.now(),
                    type: 'error',
                    message: result.error,
                  });
                }

                if (result.paymentIntent.status === 'requires_capture') {
                  const postData2 = {
                    payment_type_id: this.paymentMethod,
                    confirm: 1,
                    id: result.paymentIntent.id,
                  };

                  if (this.$route.params.token) {
                    postData2.token = this.$route.params.token;
                  }

                  await this.$api.post('partner/payment/save-card', postData2)
                    .then(() => {
                      const successMessage = 'Your card is saved successfully.';
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'success',
                        message: successMessage,
                      });
                      this.reset();
                    });
                }
              })
              .finally(() => {
                this.loading = false;
              });
          })
          .finally(() => {
            // this.loading = false;
          });
      }

      if (this.paymentMethod === 4) {
        this.$api.post('partner/payment/save-card', postData).then((response) => {
          this.$set(this, 'paypalOrderId', response.data.order_id);
          this.$nextTick(() => {
            this.$options.childInterface.checkout()
              .then((payload) => {
                if (payload.liabilityShift !== 'NO'
                  && payload.authenticationStatus
                  && payload.authenticationStatus !== 'YES') {
                  this.$store.dispatch('addAlert', {
                    id: Date.now(),
                    type: 'error',
                    message: '3D Secure Authentication fails. Please retry again.',
                  });
                  this.loading = false;
                  return;
                }

                if (payload.liabilityShift === 'NO'
                  && payload.liabilityShifted === false
                  && payload.authenticationStatus
                  && payload.authenticationStatus === 'NO') {
                  this.$store.dispatch('addAlert', {
                    id: Date.now(),
                    type: 'error',
                    message: 'You have exited 3D Secure Authentication. Please retry again.',
                  });
                  this.loading = false;
                  return;
                }

                const postData2 = {
                  payment_type_id: this.paymentMethod,
                  confirm: 1,
                  id: payload.orderId,
                };

                if (this.$route.params.token) {
                  postData2.token = this.$route.params.token;
                }

                this.$api.post('partner/payment/save-card', postData2)
                  .then(() => {
                    this.$store.dispatch('addAlert', {
                      id: Date.now(),
                      type: 'success',
                      message: 'Your card is saved successfully.',
                    });
                    this.reset();
                  })
                  .catch((error) => {
                    if (error.response) {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: error.response.data.message,
                      });
                    } else {
                      this.$store.dispatch('addAlert', {
                        id: Date.now(),
                        type: 'error',
                        message: error.message,
                      });
                    }
                  })
                  .finally(() => {
                    this.loading = false;
                  });
              })
              .catch((error) => {
                if (error.message.indexOf('semantically incorrect') > 0) {
                  this.$store.dispatch('addAlert', {
                    id: Date.now(),
                    type: 'error',
                    message: 'We are sorry, the transaction failed. Please check the card information or try with another card.',
                  });
                } else {
                  this.$store.dispatch('addAlert', {
                    id: Date.now(),
                    type: 'error',
                    message: `An error occurred ${error.message}. Please retry again.`,
                  });
                }
                this.loading = false;
              });
          });
        });
      }
    },
    sendSaveCardLink() {
      this.sendSaveCardLoading = true;
      this.$api.post('customer/payment/send-save-card-link', {
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: '"Save card" link is sent to your mailbox successfully.',
        });
        this.reset();
      }).catch((error) => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'error',
          message: `An error occurred ${error.message}. Please retry again.`,
        });
        this.loading = false;
      });
    },
    reset() {
      window.location.reload();
    },
    openDatetimePicker(event) {
      this.$refs.datetimePicker.open(event);
    },
  },
  watch: {
    pickerTransferDatetime: {
      handler(newPickerTransferDatetime) {
        if (newPickerTransferDatetime !== null) {
          this.transferDatetime = DateTime.fromISO(newPickerTransferDatetime).toFormat('yyyy-MM-dd HH:mm:ss');
        }
      },
      deep: true,
    },
    hasSavedCard: {
      handler(hasSavedCard) {
        if (hasSavedCard) {
          this.stripePaymentMode = 'useSavedCard';
        } else {
          this.stripePaymentMode = 'useNewCard';
        }
      },
    },
  },
};
</script>
<style>
.datetime-picker input {
  visibility: hidden !important;
}
</style>

<template>
  <v-container
    fluid
  >
    <v-row dense no-gutters v-if="$store.state.brand != 'cldy'">
      <v-app-bar
        app
        dense
      >
        <img v-if="$store.state.brand === 'qoxy'" src="/qoxylogo.png">
        <v-spacer></v-spacer>
        <a class="header-btn" @click="gotoLogin()">LOGIN</a>
        <span>|</span>
        <a class="header-btn" @click="gotoLogin()">REGISTER</a>
        <v-badge
          color="green"
          :content="$store.state.shoppingCart.total_count + ''"
          overlap
          offset-x="20"
          offset-y="20"
        >
          <v-btn
            icon
            large
            @click="goToCart()"
          >
            <v-avatar
              size="32px"
              item
            >
              <v-icon>mdi-cart-outline</v-icon>
            </v-avatar>
          </v-btn>
        </v-badge>
      </v-app-bar>
    </v-row>
    <CldyHeader v-if="$store.state.brand == 'cldy'"></CldyHeader>
    <v-row class="form cldy-container" justify="center">
      <v-col cols="12">
        <slot></slot>
      </v-col>
    </v-row>
    <v-footer v-if="$store.state.brand != 'cldy'"
              dark
              padless
              :app="true"
              :fixed="true"
    >
      <v-card
        flat
        tile
        class="indigo lighten-1 white--text text-center"
        width="100%"
      >
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Powered By GoCloudEasy Pte Ltd</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <CldyFooter v-if="$store.state.brand == 'cldy'"></CldyFooter>
  </v-container>
</template>

<script>
import CldyHeader from './CldyHeader.vue';
import CldyFooter from './CldyFooter.vue';

export default {
  name: 'Retail',
  components: {
    CldyHeader,
    CldyFooter,
  },
  data() {
    return {
      email: '',
      password: '',
      processing: false,
      signUpProcessing: false,
      valid: false,
      error: {
        email: false,
        password: false,
      },
      errorMessage: {
        email: '',
        password: '',
      },
      multiAccountDialog: false,
      accounts: null,
      accountCurrency: null,
    };
  },
  methods: {
    gotoHome() {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.Guest', params: { brand } })
        .catch(() => {
        });
    },
    gotoForgotPassword() {
      const { brand } = this.$store.state;
      this.$router.push({ name: 'Retail.ForgotPassword', params: { brand } })
        .catch(() => {
        });
    },
    goToCart() {
      const { brand } = this.$store.state;
      if (this.$store.state.user.role) {
        this.$router.push({
          name: 'Retail.ShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      } else {
        this.$router.push({
          name: 'Retail.GuestShoppingCart',
          params: { brand },
        })
          .catch(() => {
          });
      }
    },
  },
};
</script>

<style scoped>
.v-application .form a {
  color: black;
  font-size: 0.8rem;
  text-decoration: none;
}

.header-btn {
  margin-right: 5px;
  margin-left: 5px;
}

.currency-row {
  cursor: pointer;
}

.currency-selected {
  border: 1px solid #07f;
}

.cldy-container {
  max-width: 1350px;
  min-height: 540px;
  margin: 0 auto;
}
</style>

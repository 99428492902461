<template>
  <v-row>
    <v-col cols="12">
      <v-tabs class="product-type-tab">
        <v-tab v-for="productType in productTypes" :key="productType.id"
               @change="changeProductType(productType)">
          <v-chip
            :label="true"
            x-small
            color="green"
            text-color="white"
          >{{ productType.active_services }}</v-chip>
          {{ productType.type_name }}
        </v-tab>
      </v-tabs>
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-toolbar v-if="initiated" dense :flat="true">
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              hide-details
              prepend-icon="mdi-text-search"
              single-line
              v-model="search"
              @keyup.enter.native="loadTable()"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn small color="primary" @click="loadTable()">Search</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
            'items-per-page-options': [15,30,60,100,150,250],
          }"
      >
        <template v-slot:item.status_name="{ item }">
          <b v-if="item.status_name == 'Active'" class="green--text">{{ item.status_name }}</b>
          <b v-else class="red--text">{{ item.status_name }}</b>
        </template>
        <template v-slot:item.actions="{ item }">
          <div v-if="item.term_name !== 'One Time'">
            <v-btn
              x-small
              block
              color="success"
              @click="view(item)"
            >
              Manage
            </v-btn>&nbsp;&nbsp;
            <v-btn
              x-small
              block
              color="primary"
              v-if="(!item.status_name.includes('Pending')
                    && item.status_name !== 'Terminated'
                    && item.status_name !== 'Cancelled'
                    && item.addon_renewal_type === 2
                  )"
              @click="openRenewDialog(item)"
            >
              Renew
            </v-btn>
          </div>
        </template>

        <template v-slot:item.auto_renew="{ item }">
          <div v-if="item.term_name !== 'One Time'">
            <v-switch
              v-if="item.status_name == 'Active' && item.addon_renewal_type === 2"
              v-model="item.auto_renew"
              :loading="item.loading"
              :readonly="true"
              @click.stop="toggleAutoRenew(item)"
            ></v-switch>
            <v-switch
              v-else
              v-model="item.auto_renew"
              :loading="item.loading"
              :readonly="true"
              :disabled="true"
            ></v-switch>
          </div>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog
      v-model="renewDialog"
      width="1024"
    >
      <v-card>
        <v-card-text>
          <h1 class="text-center" v-if="renewalTerms.length === 0">Loading renewal terms ...</h1>
          <v-row v-else>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Current Expiry"
                    :readonly="true"
                    v-model="renewItem.expiry_date"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select label="Renewal Terms"
                            :items="renewalTerms"
                            v-model="renewalTerm"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p>Parent Service Expiry: {{ selectRenewalTerms[renewalTerm].parent_expiry }}</p>
                  <p>Renewal Days: {{ selectRenewalTerms[renewalTerm].renewal_day }}</p>
                  <p>Renewal Fee: ${{ selectRenewalTerms[renewalTerm].renewal_fee }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="renewDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="renewCheckoutLoading"
            :disabled="selectRenewalTerms[renewalTerm] &&
            selectRenewalTerms[renewalTerm].renewal_fee <= 0"
            @click="renew()"
          >
            Checkout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cancelDialog"
      width="1024">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <h3>Your service will be immediately terminated. Please be careful!</h3>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="cancelDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="cancelLoading"
            @click="cancel()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="upgradeDialog"
      width="1024"
    >
      <v-card>
        <v-card-text>
          <h1 class="text-center"
              v-if="!upgradeLoaded">Loading available upgrades ...</h1>
          <v-row v-else>
            <v-col v-if="upgrades.length > 0" cols="12">
              <v-row>
                <v-col cols="6">
                  <v-select label="Upgrade Options"
                            :items="upgrades"
                            v-model="upgrade"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p>Service Leftover Days: {{ upgrade.upgrade_day_diff }}</p>
                  <p>Service Total Days: {{ upgrade.life_cycle_day }}</p>
                  <p v-if="upgrade.upgrade_cost > 0">Upgrade cost: ${{ upgrade.upgrade_cost }}</p>
                  <p v-else>Downgrade cost: $0</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-else>
              No upgrade/downgrades available.
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="upgradeDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="upgradeLoading"
            :disabled="upgrades.length < 1"
            @click="processUpgrade()"
          >
            Checkout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="tagDialog"
      width="1024"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="tag" label="Tag:"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="tagDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="tagLoading"
            @click="updateTag()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <service-transfer-dialog
      :show="showServiceTransferDialog"
      v-on:closeServiceTransferDialog="showServiceTransferDialog = false"
      :service-id="serviceId" :service-identity="serviceIdentity" :service-name="serviceName"
    ></service-transfer-dialog>
  </v-row>
</template>

<script>
import ServiceTransferDialog from '../../components/ServiceTransferDialog.vue';

export default {
  name: 'List',
  components: {
    ServiceTransferDialog,
  },
  data() {
    return {
      search: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      strange: false,
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'service_name' },
        { text: 'Parent Name', value: 'parent_service_name' },
        { text: 'Domain', value: 'service_identity' },
        { text: 'Status', value: 'status_name' },
        { text: 'Term', value: 'term_name' },
        { text: 'Creation Date', value: 'provision_date' },
        { text: 'Expiry', value: 'expiry_date' },
        // { text: 'Auto Renew', value: 'auto_renew' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      loginLoading: false,
      cancelDialog: false,
      cancelItem: null,
      cancelLoading: false,
      renewDialog: false,
      renewItem: null,
      renewLoading: false,
      renewCheckoutLoading: false,
      selectRenewalTerms: {},
      renewalTerms: [],
      renewalTerm: null,
      resetPasswordDialog: false,
      resetPasswordItem: null,
      resetPasswordLoading: false,
      resetPasswordSaveLoading: false,
      resetPasswordError: false,
      newPassword: null,
      showPassword: false,
      downgradeDialog: false,
      downgradeItem: null,
      downgradeLoading: false,
      upgradeDialog: false,
      upgradeItem: null,
      upgradeLoading: false,
      upgrades: [],
      upgrade: null,
      upgradeLoaded: false,
      downgrades: [],
      downgrade: null,
      downgradeLoaded: false,
      tag: null,
      tagItem: null,
      tagDialog: false,
      tagLoading: false,
      showServiceTransferDialog: false,
      serviceId: '',
      serviceName: '',
      serviceIdentity: '',
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    document.addEventListener('keyup', this.enterClickAction);
    this.$api.get('partner/addon/product-type/menu').then((response) => {
      this.productTypes = response.data;
      if (response.data[0]) {
        this.productTypeId = response.data[0].id;
      } else {
        this.productTypeId = 0;
      }
      this.initiated = true;
      this.loadTable();
    });
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.enterClickAction);
  },
  methods: {
    enterClickAction(e) {
      if (e.code === 'Enter') {
        this.loadTable();
      }
    },
    changeProductType(productType) {
      this.productTypeId = productType.id;
      this.loadTable();
    },
    view(item) {
      this.$router.push({ name: 'Partner.ServiceDetail', params: { id: item.parent_id } });
    },
    login(item) {
      this.loginLoading = true;
      this.$api.post('partner/service/login', {
        id: item.id,
      }).then((response) => {
        window.open(response.data.data.url, '_blank');
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.loginLoading = false;
      });
    },
    openRenewDialog(item) {
      this.renewItem = item;
      this.renewalTerms.splice(0, this.renewalTerms.length);
      this.renewDialog = true;
      this.$api.post('partner/addon/get-renewal-terms', {
        id: item.id,
      }).then((response) => {
        response.data.forEach((term, index) => {
          this.renewalTerms.push({
            text: `${term.term_name} $${term.product_price}`,
            value: term.product_term_id,
          });
          this.selectRenewalTerms[term.product_term_id] = term;
          if (index === 0) {
            this.renewalTerm = term.product_term_id;
          }
        });
      }).finally(() => {
      });
    },
    openCancelDialog(item) {
      this.cancelDialog = true;
      this.cancelItem = item;
    },
    openTagDialog(item) {
      this.tagDialog = true;
      this.tag = item.tag;
      this.tagItem = item;
    },
    updateTag() {
      this.tagLoading = true;
      this.$api.post('partner/service/update-tag', {
        id: this.tagItem.id,
        tag: this.tag,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Tag is updated successfully',
        });
        this.tagDialog = false;
        this.loadTable();
      }).finally(() => {
        this.tagLoading = false;
      });
    },
    openDowngradeDialog(item) {
      this.downgrade = null;
      this.downgradeDialog = true;
      this.downgradeItem = item;
      this.downgradeLoaded = false;
      this.downgrades.splice(0, this.downgrades.length);
      this.$api.post('partner/service/get-downgrades', {
        id: item.id,
      }).then((response) => {
        let optionCount = 0;
        this.$underscore.forEach(response.data, (downgrade) => {
          this.downgrades.push({
            text: `${downgrade.product_name} $${downgrade.product_price}`,
            value: downgrade,
          });

          if (optionCount === 0) {
            this.downgrade = downgrade;
          }

          optionCount += 1;
        });
      }).finally(() => {
        this.downgradeLoaded = true;
      });
    },
    openUpgradeDowngradeDialog(item) {
      this.upgrade = null;
      this.upgradeDialog = true;
      this.upgradeItem = item;
      this.upgradeLoaded = false;
      this.upgrades.splice(0, this.upgrades.length);
      this.$api.post('partner/addon/get-upgrade-downgrade', {
        id: item.id,
      }).then((response) => {
        let optionCount = 0;

        this.$underscore.forEach(response.data, (upgrade) => {
          this.upgrades.push({
            text: `${upgrade.product_name} ${upgrade.term_name} $${upgrade.product_price}`,
            value: upgrade,
          });

          if (optionCount === 0) {
            this.upgrade = upgrade;
          }

          optionCount += 1;
        });
      }).finally(() => {
        this.upgradeLoaded = true;
      });
    },
    openResetPasswordDialog(item) {
      this.resetPasswordItem = item;
      this.resetPasswordDialog = true;
      this.newPassword = '';
    },
    toggleAutoRenew(touchedItem) {
      const foundItemIndex = this.items.findIndex((item) => item.id === touchedItem.id);
      if (foundItemIndex >= 0) {
        this.$set(this.items[foundItemIndex], 'loading', true);
        this.$api.post('partner/service/set-auto-renew', {
          id: this.items[foundItemIndex].id,
          auto_renew: this.items[foundItemIndex].auto_renew ? 0 : 1,
        }).then(() => {
          this.$set(this.items[foundItemIndex], 'auto_renew', !this.items[foundItemIndex].auto_renew);
          const successMessage = this.items[foundItemIndex].auto_renew ? 'on' : 'off';
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: `Auto renew is turned ${successMessage}.`,
          });
        }).catch((error) => {
          if (error.response) {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.response.data.message || error.response.data,
            });
          } else {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'error',
              message: error.message,
            });
          }
        }).finally(() => {
          this.$set(this.items[foundItemIndex], 'loading', false);
        });
      }
    },
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('partner/addon/list', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        product_type_id: this.productTypeId,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
      });
    },
    edit(item) {
      this.$router.push({ name: 'Admin.ProductDetail', params: { id: item.id } });
    },
    renew() {
      this.renewCheckoutLoading = true;
      this.$api.post('partner/service/renew', {
        id: this.renewItem.id,
        product_term: this.renewalTerm,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your renewal request is submitted successfully.',
        });
        this.renewDialog = false;
        this.loadTable();
      }).catch((error) => {
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.renewCheckoutLoading = false;
      });
    },
    resetPassword() {
      this.resetPasswordSaveLoading = true;
      this.$api.post('partner/service/reset-password', {
        id: this.resetPasswordItem.id,
        new_password: this.newPassword,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your hosting password is reset successfully.',
        });
        this.resetPasswordDialog = false;
      }).catch((error) => {
        this.resetPasswordError = true;
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.resetPasswordSaveLoading = false;
      });
    },
    cancel() {
      this.cancelLoading = true;
      this.$api.delete(`partner/service/${this.cancelItem.id}`)
        .then(() => {
          this.cancelDialog = false;
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'You have successfully cancelled this service.',
          });
        })
        .catch(() => {
        })
        .finally(() => {
          this.cancelLoading = false;
          this.loadTable();
        });
    },
    processUpgrade() {
      this.upgradeLoading = true;
      this.$api.post('partner/addon/upgrade-downgrade', {
        id: this.upgradeItem.id,
        product_id: this.upgrade.product_id,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your upgrade/downgrade request is submitted successfully.',
        });
        this.upgradeDialog = false;
        this.loadTable();
      }).finally(() => {
        this.upgradeLoading = false;
      });
    },
    processDowngrade() {
      this.downgradeLoading = true;
      this.$api.post('partner/service/downgrade', {
        id: this.downgradeItem.id,
        product_price_id: this.downgrade.product_price_id,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your downgrade request is submitted successfully.',
        });
        this.downgradeDialog = false;
        this.loadTable();
      }).finally(() => {
        this.downgradeLoading = false;
      });
    },
    openServiceTransferDialog(item) {
      this.serviceId = item.id;
      this.serviceName = item.service_name;
      this.serviceIdentity = item.service_identity;
      this.showServiceTransferDialog = true;
    },
    downloadCsv() {
      const authToken = window.btoa(this.$store.state.user.auth_token);
      window.open(`${process.env.VUE_APP_API_URL}/v1/partner/service/download-cpanel-stats-csv?auth_token=${authToken}`, '_blank');
    },
    downloadHttpCsv() {
      const authToken = window.btoa(this.$store.state.user.auth_token);
      window.open(`${process.env.VUE_APP_API_URL}/v1/partner/service/download-cpanel-stats-csv?type=http&auth_token=${authToken}`, '_blank');
    },
  },
};
</script>

<style scoped>
  table td {
    font-size: 0.75rem !important;
  }

  .product-type-tab .v-tab {
    justify-content: flex-start;
  }

  .product-type-tab .v-chip {
    margin-right: 8px;
  }
</style>

<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <div v-if="initiated">
        <v-row dense no-gutters justify="end" align="end">
          <v-col cols="4">
            <v-text-field
              hide-details
              prepend-icon="mdi-text-search"
              single-line
              v-model="search"
              @keyup.enter.native="loadTable()"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn small color="primary" @click="loadTable()">Search</v-btn>
          </v-col>
          <v-col cols="6"></v-col>
        </v-row>
      </div>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.status_name="{ item }">
          <b v-if="item.status_name == 'Active'" class="green--text">{{ item.status_name }}</b>
          <b v-else class="red--text">{{ item.status_name }}</b>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.product_module_id === 2"
            x-small
            class="mr-2"
            @click="edit(item)"
          >
            Sync Cpanel Info
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ListMigrationServices',
  components: {},
  data() {
    return {
      search: '',
      initiated: false,
      statusCount: {},
      typeCount: {},
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      strange: false,
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Package', value: 'service_name' },
        { text: 'Domain', value: 'service_identity' },
        { text: 'User Type', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Product Type', value: 'type_name' },
        { text: 'Status', value: 'status_name' },
        { text: 'Term', value: 'term_name' },
        { text: 'Registration Date', value: 'provision_date' },
        { text: 'Expiry Date', value: 'expiry_date' },
        { text: 'Auto Renew', value: 'auto_renew' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('admin/service/list-migration-services', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        order: `${sortBy}:${sortDesc}`,
      }).then((response) => {
        this.totalItems = response.data.total;
        this.items = response.data.data;
        this.loading = false;
        this.initiated = true;
      });
    },
    edit(item) {
      this.$router.push({ name: 'Admin.ServiceCpanelSync', params: { id: item.id } });
    },
  },
};
</script>

<style scoped>
table td {
  font-size: 0.75rem !important;
}

.product-type-tab .v-tab {
  justify-content: flex-start;
}

.product-type-tab .v-chip {
  margin-right: 8px;
}
</style>

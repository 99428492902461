<template>
  <v-row :key="componentKey">
    <v-col cols="6">
      <partner-dropdown
        label="Partner"
        :rules="[rules.required]"
        :single="true"
        :partners.sync="form.partner"
        :nochips="true"
      ></partner-dropdown>
    </v-col>
    <v-col cols="6">
      <registrar-dropdown
        label="Registrar"
        :rules="[rules.required]"
        :single="true"
        :registrar.sync="form.registrar"
        :nochips="true"
      ></registrar-dropdown>
    </v-col>
    <v-col cols="6">
      <v-text-field label="Domain" v-model="form.domain" :rules="[rules.required]"></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-checkbox v-model="form.update_contact" label="Update admin/tech/billing contact?">
      </v-checkbox>
    </v-col>
    <v-col cols="12">
      <v-text-field label="NS1" v-model="form.ns1" :rules="[]"></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field label="NS2" v-model="form.ns2" :rules="[]"></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field label="NS3" v-model="form.ns3" :rules="[]"></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field label="NS4" v-model="form.ns4" :rules="[]"></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field label="NS5" v-model="form.ns5" :rules="[]"></v-text-field>
    </v-col>
    <v-col cols="12" class="text-right">
      <v-btn color="primary" @click="submit">Submit</v-btn>
    </v-col>
  </v-row>
</template>
<script>
import PartnerDropdown from '../../components/PartnerDropdown.vue';
import RegistrarDropdown from '../../components/RegistrarDropdown.vue';

export default {
  name: 'ManualInvoice',
  components: {
    PartnerDropdown,
    RegistrarDropdown,
  },
  data() {
    return {
      componentKey: 0,
      form: {
        partner: null,
        domain: '',
        registrar: '',
        update_contact: true,
        ns1: '',
        ns2: '',
        ns3: '',
        ns4: '',
        ns5: '',
      },
      rules: {
        required: (value) => (value !== 0 && !!value) || 'Required.',
      },
    };
  },
  methods: {
    reset() {
      this.$router.push({
        path: '/refresh',
        query: {
          path: this.$route.fullPath,
        },
      });
    },
    submit() {
      this.$api.post('admin/domain/import', this.form)
        .then(() => {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'success',
            message: 'The domain has been imported successfully.',
          });
          this.reset();
        });
    },
  },
};
</script>

<template>
  <v-select
    dense
    v-model="price"
    :items="priceList"
    :label="label"
    :rules="rules"
    @change="emitUpdate"
  ></v-select>
</template>

<script>
export default {
  name: 'ProductTermDropdown',
  data() {
    return {
      price: null,
      priceList: [],
    };
  },
  created() {
    if (typeof this.defaultPrice !== 'undefined') {
      this.$set(this, 'price', this.defaultPrice);
    }
  },
  mounted() {
    this.loadPrices();
    this.emitUpdate();
  },
  methods: {
    loadPrices() {
      if (this.productId) {
        let url = `admin/product/${this.productId}/term-drop-down`;
        if (this.partnerId) {
          url += `?partner_id=${this.partnerId}`;
        }
        this.$api
          .get(url)
          .then((response) => {
            const list = response.data;
            this.priceList.length = 0;
            this.$underscore.forEach(list, (value) => {
              this.priceList.push({
                text: `${value.termName}|$${value.productPrice}`,
                value: value.productTermId,
              });
            });
            if (this.defaultTermId) {
              for (let i = 0; i < this.priceList.length; i += 1) {
                if (this.priceList[i].value === this.defaultTermId) {
                  this.price = this.priceList[i];
                }
              }
            }
          });
      }
    },
    emitUpdate() {
      this.$emit('update:price', this.price);
    },
  },
  props: ['label', 'rules', 'defaultPrice', 'productId', 'partnerId', 'defaultTermId'],
  watch: {
    defaultPrice: {
      handler(newPrices) {
        this.price = newPrices;
      },
      deep: true,
    },
    productId: {
      handler() {
        this.loadPrices();
        this.price = null;
      },
      deep: true,
    },
    defaultTermId: {
      handler(newTermId) {
        if (newTermId) {
          for (let i = 0; i < this.priceList.length; i += 1) {
            if (this.priceList[i].value === newTermId) {
              this.price = this.priceList[i];
            }
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

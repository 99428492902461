<template>
  <v-autocomplete
    autocomplete="off"
    dense
    label="Module"
    outlined
    placeholder="Select..."
    v-model="module"
    :disabled="disabled"
    :rules="rules"
    :items="modules"
    @change="$emit('update:module', module);"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'AddonModuleSelect',
  data() {
    return {
      module: { module_name: 'manual', id: 1 },
      modules: [
        { text: 'manual', value: { module_name: 'manual', id: 1 } },
        { text: 'domain privacy', value: { module_name: 'domainPrivacy', id: 3 } },
      ],
    };
  },
  mounted() {
    this.$emit('update:module', this.module);
  },
  props: {
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

</style>

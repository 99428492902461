<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-skeleton-loader
        v-if="!initiated"
        ref="skeleton"
        type="table"
        class="mx-auto"
      ></v-skeleton-loader>
      <v-data-table
        v-if="initiated"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [15,30,60,100,150,250],
        }"
      >
        <template v-slot:item.status_name="{ item }">
          <b v-if="item.payment_status_id == 1"
                class="orange--text">{{ item.status_name }}</b>
          <b v-if="item.payment_status_id == 2"
                class="green--text">{{ item.status_name }}</b>
          <b v-if="item.payment_status_id >= 3"
                class="red--text">{{ item.status_name }}</b>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TopupHistory',
  data() {
    return {
      search: '',
      initiated: false,
      productTypeId: null,
      productTypes: [],
      totalItems: 0,
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      headers: [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'Payment Datetime', value: 'payment_datetime', sortable: true },
        { text: 'Payment Type', value: 'type_name', sortable: true },
        { text: 'Payment Reference', value: 'payment_reference', sortable: false },
        { text: 'Status', value: 'status_name', sortable: true },
        { text: 'Amount', value: 'amount', sortable: true },
        { text: 'Reject Reason', value: 'reject_reason', sortable: false },
        { text: 'IP', value: 'ip', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      deleteDialog: false,
      deleteItem: null,
      deleteLoading: false,
    };
  },
  watch: {
    options: {
      handler() {
        if (this.initiated) {
          this.loadTable();
        }
      },
      deep: true,
    },
  },
  created() {
    this.initiated = true;
    this.loadTable();
  },
  methods: {
    loadTable() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      this.loading = true;
      this.$api.post('partner/payment/history', {
        search: {
          '*': this.search,
        },
        current_page: page,
        per_page: itemsPerPage,
        product_type_id: this.productTypeId,
        order: `${sortBy}:${sortDesc}`,
      })
        .then((response) => {
          this.totalItems = response.data.total;
          this.items = response.data.data;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>

</style>

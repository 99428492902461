<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="6">
          <server-group-dropdown
            :chips="true"
            :multiple="true"
            :disabled="disabled"
            :defaultServerGroupId="defaultServerGroupId"
            :serverGroupId.sync="configData.server_group_ids"></server-group-dropdown>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Package"
            :disabled="disabled"
            v-model="configData.package_name"></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ServerGroupDropdown from '../../ServerGroupDropdown.vue';

export default {
  name: 'Config',
  components: {
    ServerGroupDropdown,
  },
  data() {
    return {
      configData: {
        server_group_ids: null,
        package_name: '',
        id: null,
      },
      defaultServerGroupId: null,
    };
  },
  created() {
    if (typeof this.defaultConfigData !== 'undefined') {
      this.configData.id = this.defaultConfigData.id;
      this.configData.package_name = this.defaultConfigData.package_name;
      this.defaultServerGroupId = this.defaultConfigData.server_group_ids;
    }
  },
  mounted() {
    this.$emit('update:configData', this.configData);
  },
  props: ['disabled', 'defaultConfigData'],
  watch: {
    configData: {
      handler(newConfigData) {
        this.$emit('update:configData', newConfigData);
      },
      deep: true,
    },
    defaultConfigData: {
      handler(newConfigData) {
        this.configData.id = newConfigData.id;
        this.configData.package_name = newConfigData.package_name;
        this.defaultServerGroupId = newConfigData.server_group_ids;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-dialog width="1024" v-model="display"
            @click:outside="closeDialog"
  >
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  label="Password"
                  v-model="newPassword"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :error="resetPasswordError"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="closeDialog()"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          @click="resetPassword()"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ServiceResetPasswordDialog',
  data() {
    return {
      display: true,
      loading: false,
      resetPasswordError: false,
      showPassword: false,
      newPassword: null,
    };
  },
  props: ['serviceId'],
  created() {
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    resetPassword() {
      this.loading = true;
      this.resetPasswordError = false;
      this.$api.post('partner/service/reset-password', {
        id: this.serviceId,
        new_password: this.newPassword,
      }).then(() => {
        this.$store.dispatch('addAlert', {
          id: Date.now(),
          type: 'success',
          message: 'Your hosting password is reset successfully.',
        });
        this.loading = false;
        this.closeDialog();
      }).catch((error) => {
        this.resetPasswordError = true;
        if (error.response) {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.response.data.message || error.response.data,
          });
        } else {
          this.$store.dispatch('addAlert', {
            id: Date.now(),
            type: 'error',
            message: error.message,
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
</style>
